import * as React from "react";
import { Grid, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  Table,
  Input,
  ConfigProvider,
  Select,
  Button as BtnAntD,
  Space,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CreateUpdateSpecimen from "./createUpdateSpecimen";
import useCheckPermission from "hooks/useCheckPermission";
import useDebounce from "hooks/useDebounce";
import {
  getAllSpecimen,
  getLaboratoryTestSpecimenSize,
  getLaboratoryTestMeasurementType,
} from "store";
import { EmptyData } from "components/jkt/global/emptyData";
import InfoIcon from "@mui/icons-material/Info";
import SpecimenDetailsModal from "./specimenDetailsModal";
import { getTagColorAndTitle } from "utils/getTagColorAndTitle";
import { useCurrentHeight } from "utils/getScreenSize";

const GetAllSpecimen = () => {
  const dispatch = useDispatch();
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToModify = handleVerifyPermission(
    "Laboratory:Specimen:Modify"
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = React.useState("name");
  const [sortDirection, setSortDirection] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [specimenData, setSpecimenData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [current, setCurrent] = React.useState(1);
  const [specimenDetailsModalOptions, setSpecimenDetailsModalOptions] =
    React.useState({
      openDetailsModal: false,
      selectedSpecimenId: null,
    });

  const [isForUpdate, setIsForUpdate] = React.useState(false);
  const [idForUpdate, setIdForUpdate] = React.useState(null);

  const [measurementType, setMeasurementType] = React.useState([]);
  const [testSpecimenSize, setTestSpecimenSize] = React.useState([]);

  const LoadData = React.useCallback(() => {
    //HANDLE LOADING
    if (getAllSpecimen.pending().type === "specimen/get-all-specimen/pending") {
      setIsLoading(true);
    }

    dispatch(
      getAllSpecimen({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      })
    ).then((element) => {
      if (element.type === "specimen/get-all-specimen/fulfilled") {
        setIsLoading(false);
        setSpecimenData(element.payload.data.items);
        setTotal(element.payload.data.totalPages * pageSize);

        dispatch(getLaboratoryTestSpecimenSize()).then((spc) => {
          if (
            spc.type === "labtests/get-laboratory-test-specimen-size/fulfilled"
          ) {
            setTestSpecimenSize(spc.payload);
          }
        });
        dispatch(getLaboratoryTestMeasurementType()).then((meatp) => {
          if (
            meatp.type ===
            "labtests/get-laboratory-test-measurement-types/fulfilled"
          ) {
            setMeasurementType(meatp.payload);
          }
        });
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
  ]);

  React.useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  const onChange = (page) => {
    setCurrent(page);
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const handleOpenSpecimenDetailsModal = (id) => {
    setSpecimenDetailsModalOptions({
      openDetailsModal: true,
      selectedSpecimenId: id,
    });
  };

  const handleCloseSpecimenDetailsModal = (id) => {
    setSpecimenDetailsModalOptions({
      openDetailsModal: false,
      selectedSpecimenId: null,
    });
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No Specimen Detected"
      action={showCreateUpdate}
      btnTitle="Add New Specimen"
      renderButton={accessRightsToModify}
    />
  );
  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = React.useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(null);
  };

  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };

  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
    setIdForUpdate(null);
    setIsForUpdate(false);
  };

  const handleUpdate = (row) => {
    setIsForUpdate(true);
    setIdForUpdate(row);
    showCreateUpdate();
  };

  const handleChangeSortField = (value) => {
    setSortStr(value);
  };

  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 120,
    },
    {
      title: "Container",
      dataIndex: "container",
      key: "container",
      width: 100,
    },
    {
      title: "Measurement Type",
      dataIndex: "measurementType",
      key: "measurementType",
      width: 60,
      render: (rec, row) => <>{measurementType[rec]}</>,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 30,
    },
    {
      title: "Unit Of Measure",
      dataIndex: "unitOfMeasure",
      key: "unitOfMeasure",
      width: 60,
    },
    {
      title: "Require Sample Origin",
      dataIndex: "requiresSampleOrigin",
      key: "requiresSampleOrigin",
      width: 65,
      render: (rec, row) => (
        <Tag color={getTagColorAndTitle(row.requiresSampleOrigin)?.color}>
          {getTagColorAndTitle(row.requiresSampleOrigin)?.title}
        </Tag>
      ),
    },
    {
      title: "Require Blood Draw",
      dataIndex: "requiresBloodDraw",
      key: "requiresBloodDraw",
      width: 60,
      render: (rec, row) => (
        <Tag color={getTagColorAndTitle(row.requiresBloodDraw)?.color}>
          {getTagColorAndTitle(row.requiresBloodDraw)?.title}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 80,
      render: (rec, row) => (
        <Space size={3}>
          <BtnAntD
            onClick={() => handleOpenSpecimenDetailsModal(row.id)}
            size="small"
            type="primary"
            style={{ background: "#1677FF", color: "white" }}
            icon={<InfoIcon fontSize="inherit" />}
          >
            Details
          </BtnAntD>
          {accessRightsToModify && (
            <BtnAntD
              size="small"
              icon={<EditIcon fontSize="inherit" />}
              className="hover:bg-orange-500 bg-orange-600"
              style={{
                border: "1px solid #ed6c02",
                color: "white",
              }}
              onClick={() => {
                handleUpdate(row);
              }}
            >
              Update
            </BtnAntD>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            size=""
            placeholder="Search Specimen"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortStr}
            onChange={handleChangeSortField}
            style={{
              width: 120,
            }}
            options={[
              {
                value: "name",
                label: "Name",
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          />
        </Grid>

        {specimenData.length !== 0 && accessRightsToModify ? (
          <Grid item xs={12} md={3} lg={3}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              New Specimen
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={
            accessRightsToModify
              ? columns
              : columns.filter((col) => col.dataIndex !== "")
          }
          dataSource={specimenData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>

      <CreateUpdateSpecimen
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
        measurementType={measurementType}
        testSpecimenSize={testSpecimenSize}
      />
      <SpecimenDetailsModal
        open={specimenDetailsModalOptions.openDetailsModal}
        onCancel={handleCloseSpecimenDetailsModal}
        id={specimenDetailsModalOptions.selectedSpecimenId}
        measurementType={measurementType}
      />
    </>
  );
};
export default GetAllSpecimen;
