import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ROLE_CLAIMS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const getAllPermissionsKeys = createAsyncThunk(
  "rolesClaims/get-all-permissions-keys",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(URL_ROLE_CLAIMS + "/permissions-keys");
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getAllPermissionsKeys };
