import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT_INFO } from "config/connString";
import useAxios from "utils/useAxios";

const clientLookUp = createAsyncThunk("client/client-lookup", async () => {
  try {
    const api = useAxios();
    const response = await api.get(URL_CLIENT_INFO + "-lookup");
    return response.data;
  } catch (error) {
    return error;
  }
});

export { clientLookUp };
