import moment from "moment";

const getSpecimenRecursively = (data, selectedSpecimens = {}) => {
  if (data?.testDetails?.labTestSpecimens) {
    data?.testDetails?.labTestSpecimens?.map((specimenData) => {
      const specimenName = specimenData.name;
      const specimenQuantityValue =
        specimenData?.testVolumeOrSizeRequirements?.length === 0
          ? ""
          : specimenData?.testVolumeOrSizeRequirements
              .find((data) => Math.min(data.minTest))
              ?.minVolume?.toString();

      return (selectedSpecimens[specimenName] = {
        requiresBloodDraw: specimenData?.requiresBloodDraw,
        requiresSampleOrigin: specimenData?.requiresSampleOrigin,
        specimenId: specimenData?.id,
        unitOfMeasure: specimenData?.unitOfMeasure,
        specimenExpirationInHours: data?.testDetails?.maxSpecimenAgeInHours,
        specimenQuantity: specimenQuantityValue,
        specimenVolReqData: specimenData?.testVolumeOrSizeRequirements,
        specimen: specimenName,
        selectedCollectionSiteOption: null,
        collectionDateTime: moment().format(),
        collectorId: specimenData?.requiresBloodDraw ? "" : null,
        collectionSite: specimenData?.requiresSampleOrigin ? "" : null,
        status: 0,
        key: specimenName,
      });
    });
  }
  if (data?.child && data?.child?.length > 0) {
    data?.child?.map((item) => {
      return getSpecimenRecursively(item, selectedSpecimens);
    });
  }

  return Object.values(selectedSpecimens);
};

export default getSpecimenRecursively;
