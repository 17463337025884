import { Box, Grid, Skeleton, Typography } from "@mui/material";
import styles from "components/jkt/testRequest/forms/fourthStep/styles.module.css";
import moment from "moment";
import useTranslation from "hooks/useTranslation";
import { isDataEmpty } from "utils/isDataEmpty";
import { useSelector } from "react-redux";

const TestProfileSectionTwo = (props) => {
  const {
    sampleCollectorName,
    specimens,
    isLoading,
    testOrderDetails,
    courierRemarks,
    testOrderNotes,
  } = props;
  const { user } = useSelector((state) => state.auth);
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const isAdditionalPatientInfoEmpty = testOrderDetails?.every(
    (order) => order?.patientTestRequirementDatas?.length === 0
  );

  const testProfileDetails = [
    {
      id: 1,
      label: getGlobalTranslation("OrderPrepBy"),
      value: `${user?.displayName}`,
    },
    {
      id: 2,
      label: getGlobalTranslation("SpcCollectedBy"),
      value: `${isDataEmpty(sampleCollectorName) ? "-" : sampleCollectorName}`,
    },
    {
      id: 3,
      label: getGlobalTranslation("SpcGivenBy"),
      value: "-",
    },
  ];

  const isDateValid = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const renderCollectionSite = (collectionSite) => {
    const lowerCasedCollectionSite = collectionSite?.toLowerCase();
    if (collectionSite === null) {
      return "N/A";
    }
    const isCollectionSiteCustom =
      lowerCasedCollectionSite !== "right arm" &&
      lowerCasedCollectionSite !== "left arm";

    return isCollectionSiteCustom
      ? collectionSite
      : getTranslation(collectionSite);
  };

  return (
    <Grid container>
      <Grid sx={{ marginRight: "auto" }} xs={6} sm={6} md={6} lg={6} xl={6}>
        <Box className={styles["grid-container"]}>
          {testProfileDetails.map((info) => (
            <Box key={info.id} className={styles["grid-item"]}>
              <span className={styles.label}>{info.label}</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>
                {isLoading ? <Skeleton /> : info.value}
              </span>
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid
        sx={{ marginTop: "0.8rem" }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Typography
          variant="p"
          sx={{
            color: isLoading ? "" : "darkBlue.main",
            fontSize: "11pt",
            display: "flex",
            gap: 1,
          }}
        >
          {getTranslation("Additional patient information")}:{" "}
          {!isLoading && isAdditionalPatientInfoEmpty && (
            <p className="text-black">-</p>
          )}
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-3 mt-2 gap-4">
          {!isLoading &&
            !isAdditionalPatientInfoEmpty &&
            testOrderDetails
              ?.filter(
                (details) => details.patientTestRequirementDatas?.length > 0
              )
              .map((test) => (
                <Box
                  key={test?.labTest?.id}
                  sx={{
                    padding: "0.6rem",
                    backgroundColor: isLoading ? "" : "#EBEFF8",
                  }}
                >
                  <p
                    style={{ fontSize: "1rem", marginBottom: "0.3rem" }}
                    className={styles["additional-info-text"]}
                  >
                    For{" "}
                    <span className={styles["test-type"]}>
                      {test?.labTest?.name}
                    </span>{" "}
                    test
                  </p>
                  {testOrderDetails
                    .find((detail) => detail.testId === test?.labTest?.id)
                    ?.patientTestRequirementDatas[0]?.patientRequirementDataDetails.filter(
                      (dataDetail) => dataDetail.dataValue !== ""
                    )
                    .map((requirement) => {
                      const dataValue =
                        requirement.dataValue === "true"
                          ? "Yes"
                          : requirement.dataValue === "false"
                          ? "No"
                          : isDateValid(requirement.dataValue) &&
                            requirement.dataValue.length === 10
                          ? moment(requirement.dataValue).format("LL")
                          : requirement.dataValue;
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.4rem",
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              color: "darkBlue.main",
                              fontSize: "0.8rem",
                            }}
                          >
                            {requirement.description}:
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {dataValue}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              ))}
          {isLoading && <Skeleton />}
        </div>
      </Grid>
      <Grid
        sx={{ marginTop: "0.8rem" }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        item
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "6fr 24fr 20fr 20fr 40fr",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "11pt",
              color: "darkBlue.main",
            }}
          >
            No.
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontSize: "11pt",
              color: "darkBlue.main",
            }}
          >
            {getTranslation("Specimen type")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontSize: "11pt",
              color: "darkBlue.main",
            }}
          >
            {getTranslation("Quantity")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontSize: "11pt",
              color: "darkBlue.main",
            }}
          >
            {getTranslation("Collection Site")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontSize: "11pt",
              color: "darkBlue.main",
            }}
          >
            {getTranslation("Notes")}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: isLoading ? "" : "#EBEFF8",
            paddingBottom: "1rem",
            marginTop: "0.5rem",
            display: specimens?.length === 0 ? "none" : "block",
          }}
        >
          {isLoading ? (
            <Skeleton />
          ) : (
            specimens &&
            specimens.map((data, index) => (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "5fr 26fr 20fr 20fr 40fr",
                  padding: "0.3rem 0.5rem",
                }}
                key={data.id}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "11pt",
                  }}
                  variant="p"
                >
                  {index + 1}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "11pt",
                  }}
                  variant="p"
                >
                  {data?.specimen?.name}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "11pt",
                  }}
                  variant="p"
                >
                  {data?.specimenQuantity}{" "}
                  {data?.specimenQuantity !== "N/A" &&
                    data?.specimen?.unitOfMeasure}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "11pt",
                  }}
                  variant="p"
                >
                  {renderCollectionSite(data?.collectionSite)}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "11pt",
                  }}
                  variant="p"
                >
                  {data?.notes}
                </Typography>
              </Box>
            ))
          )}
        </Box>
      </Grid>
      {!isDataEmpty(courierRemarks) && (
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "block",
            mt: 2,
          }}
          item
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "600", color: "darkBlue.main" }}
          >
            Courier Remarks
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                padding: "0.6rem",
                backgroundColor: isLoading ? "" : "#EBEFF8",
              }}
            >
              {courierRemarks}
            </Box>
          </Box>
        </Grid>
      )}
      {!isDataEmpty(testOrderNotes) && (
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "block",
            mt: 2,
          }}
          item
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "600", color: "darkBlue.main" }}
          >
            Test Order Notes
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                padding: "0.6rem",
                backgroundColor: isLoading ? "" : "#EBEFF8",
              }}
            >
              {testOrderNotes}
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default TestProfileSectionTwo;
