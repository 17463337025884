import { Popconfirm as AntdPopConfirm } from "antd";
import useTranslation from "hooks/useTranslation";

const PopConfirm = ({
  title,
  description,
  onConfirm,
  okText = "Yes",
  cancelText = "No",
  placement = "rightTop",
  children,
}) => {
  const { getGlobalTranslation } = useTranslation("Global");
  return (
    <AntdPopConfirm
      okButtonProps={{
        className: "popconfirm-btn",
      }}
      title={title}
      description={description}
      onConfirm={onConfirm}
      okText={getGlobalTranslation(okText)}
      cancelText={getGlobalTranslation(cancelText)}
      placement={placement}
    >
      {children}
    </AntdPopConfirm>
  );
};

export default PopConfirm;
