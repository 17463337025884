import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ROLE_CLAIMS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const getClaims = createAsyncThunk("rolesClaims/get-claims", async (data) => {
  try {
    const api = useAxios();
    const response = await api.get(
      URL_ROLE_CLAIMS + "/" + data.roleId + "/claims"
    );
    return response.data;
  } catch (error) {
    return error;
  }
});

export { getClaims };
