import { Box, Typography } from "@mui/material";
import { Modal, Typography as AntDTypography } from "antd";
import React from "react";

const ErrorDetails = ({
  handleCloseModal,
  selectedErrorDetails,
  openErrorModal,
  modalTitle,
}) => {
  const descMinLength = 620;

  const parseText = (text) => {
    return JSON.parse(text);
  };
  const stringifyText = (text) => {
    return JSON.stringify(text, null, 5);
  };

  const exceptionText =
    modalTitle === "Exception" && selectedErrorDetails.exception;
  const parsedLogEventText =
    modalTitle === "Log Event" && parseText(selectedErrorDetails.logEvent);

  const isDescriptionShort =
    selectedErrorDetails.exception?.length < descMinLength ||
    selectedErrorDetails.logEvent?.length < descMinLength;

  const logEventEntries = Object.entries(parsedLogEventText);

  const renderLogText = (logEntries) => {
    if (modalTitle === "Exception") {
      return <code className="font-bold">{exceptionText}</code>;
    }
    return logEntries.map((item, idx) => (
      <div className="flex items-center justify-start gap-1" key={idx}>
        <code className="text-green-600 flex gap-1">{item[0]}: </code>
        <code className="font-bold">{stringifyText(item[1])}</code>
      </div>
    ));
  };

  return (
    <Modal
      open={openErrorModal}
      closable={true}
      footer={false}
      onCancel={handleCloseModal}
      width={800}
      title={
        <AntDTypography style={{ fontSize: "0.9rem" }}>
          <blockquote>{modalTitle}</blockquote>
        </AntDTypography>
      }
    >
      <Box
        sx={{
          height: "26rem",
          mt: 4,
          overflowY: isDescriptionShort ? "auto" : "scroll",
        }}
      >
        <Typography
          variant="p"
          sx={{ lineHeight: "2", fontSize: "1rem", marginTop: "0.7rem" }}
        >
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {/* Log Event Text */}
            {modalTitle === "Log Event" && renderLogText(logEventEntries)}
            {/* Exception Text */}
            {modalTitle === "Exception" && renderLogText(exceptionText)}
          </pre>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ErrorDetails;
