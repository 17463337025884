import { REGEX } from "config/settings";
import { useState } from "react";
import { useSelector } from "react-redux";

const useValidateEmail = (email) => {
  const inputs = useSelector((state) => state.inputs);

  const [errorMessage, setErrorMessage] = useState(null);

  const isEmailVerified = () => {
    return REGEX.test(email);
  };

  const isEmailAlreadyPresent = () => {
    return inputs?.emails?.includes(email);
  };

  const isEmailValid = () => {
    let error = null;
    if (!isEmailVerified()) {
      error = `${email} is not a valid email address`;
    }
    if (isEmailAlreadyPresent()) {
      error = `${email} is already in the list`;
    }
    if (error) {
      setErrorMessage(error);
      return false;
    }
    return true;
  };

  return { setErrorMessage, errorMessage, isEmailValid };
};

export default useValidateEmail;
