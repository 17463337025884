import * as React from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import decodedUserDetails from "utils/decodedUserDetails";
import eRequestLogo from "assests/erequest.png";
import TopicIcon from "@mui/icons-material/Topic";
import MenuItemRoute from "./menuItemRoutes";
import useClearReduxStore from "hooks/useClearReduxStore";
import notification from "./openNotification";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import LanguageSelectOption from "./languageSelectOption";
import AdminMenuItemRoute from "./adminMenuItemRoutes";
import {
  setCloseSidebar,
  setExpandBothSidebarItems,
  setOpenSidebar,
  setToggleSidebar,
} from "store/slice/appBarSlice";
import { logout, refreshUser, getUserInfoById } from "store";
import RegularItemRoute from "./regularItemRoute";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import { parsedClientDetails } from "utils/parsedClientDetails";
import {
  menuAppBar as MenuAppBar,
  menuAppBarDrawer as MenuAppBarDrawer,
} from "./menuBarTools";
import useTranslation from "hooks/useTranslation";

export default function MenuBar(prop) {
  const { pageName } = prop;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { openSidebar } = useSelector((state) => state.appBarSettings);
  const location = useLocation();
  const userNeedsToChangePassword =
    decodedUserDetails()?.ForcePasswordChange === "True";
  const loggedUserId = decodedUserDetails()?.id;
  const isSuperAdmin = decodedUserDetails()?.isSuperAdmin === "True";
  const isMenuOpen = Boolean(anchorEl);
  const hasClientAdminRole =
    decodedUserDetails()?.role?.includes("ClientAdmin");
  const { handleResetReduxStore } = useClearReduxStore();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const { clientDetails } = parsedClientDetails();
  const { getTranslation } = useTranslation("SidebarItems");

  //To avoid confusion from user, the page will automatically reload if admin updated their client config.
  // const compareClientInfo = () => {
  //   dispatch(
  //     getClientInfoById({
  //       id: decodedUserDetails()?.clientId,
  //     })
  //   ).then((res) => {
  //     const isFetchSuccessful = res?.payload?.success;
  //     const responseData = res?.payload?.data;
  //     if (isFetchSuccessful) {
  //       const isDataInLocalStorageOutdated =
  //         JSON.stringify(responseData) !== JSON.stringify(clientDetails);
  //       if (isDataInLocalStorageOutdated) {
  //         localStorage.setItem("clInf", JSON.stringify(responseData));
  //         setTimeout(() => {
  //           notification.warning({
  //             message: "Client Details Updated",
  //             description:
  //               "Page will automatically reload, because admin has updated your client details.",
  //           });
  //         }, 300);
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 600);
  //       }
  //     }
  //   });
  // };

  React.useEffect(() => {
    if (isUserLoggedIn()) {
      localStorage.setItem("visited-route", location.pathname);
      if (userNeedsToChangePassword) {
        navigate("/change-password");
      }
    }
  }, [dispatch, navigate, userNeedsToChangePassword, location.pathname]);

  // React.useEffect(() => {
  //   if (isUserLoggedIn()) {
  //     setTimeout(() => {
  //       compareClientInfo();
  //     }, 900);
  //   }
  // }, []);

  const toggleDrawer = () => {
    dispatch(setToggleSidebar());
  };

  //To check if the currently logged user exists in database
  React.useEffect(() => {
    if (isSuperAdmin) return;

    if (isUserLoggedIn()) {
      dispatch(getUserInfoById(loggedUserId)).then((res) => {
        const userExist = res?.payload?.success;

        if (!userExist) {
          handleResetReduxStore();
          localStorage.clear();
          dispatch(logout());
          dispatch(refreshUser());
          window.location.replace("/login");
          notification.error({
            message: "User Not Found",
            description: `Session has been automatically logged out`,
          });
        }
      });
    }
  }, [dispatch, handleResetReduxStore, loggedUserId, isSuperAdmin]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleResetReduxStore();
    localStorage.clear();
    setAnchorEl(null);
    dispatch(logout());
    dispatch(refreshUser());
    dispatch(setOpenSidebar());
    dispatch(setExpandBothSidebarItems());
    navigate("/login");
    notification.success({
      message: "User",
      description: `Logout successful`,
    });
  };

  const handleChangePassword = () => {
    setAnchorEl(null);
    navigate("/change-password");
  };

  const handleClickRegisterNewUser = () => {
    navigate("/register-new-user");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
      {hasClientAdminRole && (
        <MenuItem onClick={handleClickRegisterNewUser}>
          Register New User
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  React.useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    if (screenWidth <= 600) {
      dispatch(setCloseSidebar());
    } else {
      dispatch(setOpenSidebar());
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [dispatch, screenWidth]);

  return (
    <>
      <CssBaseline />
      <MenuAppBar
        sx={{ width: "100%", zIndex: 5 }}
        position="fixed"
        open={openSidebar}
      >
        <Toolbar
          sx={{
            pr: "24px",
            backgroundColor: "white",
            position: "relative",
          }}
        >
          <img
            className="w-52 mr-5 hidden md:block pointer-events-none"
            src={eRequestLogo}
            alt="eRequest"
          />
          <Typography
            sx={{
              width: "100%",
              color: "darkBlue.main",
              fontWeight: "bold",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.25rem",
                lg: "1.25rem",
                xl: "1.25rem",
              },
              px: { xs: 2, sm: 2, md: 1 },
            }}
          >
            {pageName}
          </Typography>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              marginLeft: {
                xs: 0,
                sm: 0,
                md: "15rem",
                lg: "15rem",
                xl: "15rem",
              },
              marginRight: {
                xs: "3rem",
                sm: "3rem",
                md: "10rem",
                lg: "10rem",
                xl: "10rem",
              },
            }}
          >
            <Grid item>
              <LanguageSelectOption />
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="end"
            alignItems="flex-end"
            sx={{
              marginLeft: "auto",
              width: "20%",
              color: "black",
            }}
          >
            <Grid item>
              <Typography
                sx={{
                  width: "max-content",
                  fontWeight: "bold",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
                component="h6"
                variant="h6"
              >
                {"Hi, "}
                {user?.displayName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontWeight: "bold",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                  width: "max-content",
                  maxWidth: "100%",
                }}
                variant="caption"
                display="block"
                gutterBottom
              >
                {clientDetails?.clientName}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              sx={{ color: "darkBlue.main" }}
            >
              <AccountCircle fontSize="large" />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              sx={{ color: "darkBlue.main" }}
            >
              <MoreIcon />
            </IconButton>
          </Box>
          {!userNeedsToChangePassword && (
            <Tooltip title="Collapse Side Bar" placement="right" arrow>
              <IconButton
                color="inherit"
                aria-label="drawer-controller"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  marginLeft: "20px",
                  position: "absolute",
                  ...(!openSidebar
                    ? { top: 87, left: 25 }
                    : { top: 87, left: 200 }),
                  transition: "0.2s ease-in-out",
                  ":hover": {
                    backgroundColor: "lightBlue.main",
                  },
                  backgroundColor: "darkBlue.main",
                }}
              >
                <DoubleArrowIcon
                  fontSize="small"
                  sx={{
                    transform: !openSidebar ? "rotate(0)" : "rotate(180deg)",
                    color: "white",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </MenuAppBar>
      {!userNeedsToChangePassword && (
        <MenuAppBarDrawer variant="permanent" open={openSidebar}>
          <List
            sx={{ marginTop: "4rem", marginBottom: "3rem" }}
            component="nav"
            className="MenuListCss"
          >
            <RegularItemRoute pageName={pageName} />
            <MenuItemRoute />
            <AdminMenuItemRoute />
            <Tooltip
              arrow
              placement="right"
              title={!openSidebar ? getTranslation("Internet Reporting") : ""}
            >
              <a
                href="https://www.abclab.com/JKTWEB/"
                rel="noreferrer"
                target="_blank"
              >
                <ListItemButton
                  onClick={() =>
                    localStorage.removeItem("selectedParentCollapseKey")
                  }
                >
                  <ListItemIcon>
                    <TopicIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={getTranslation("Internet Reporting")}
                  />
                </ListItemButton>
              </a>
            </Tooltip>
          </List>
        </MenuAppBarDrawer>
      )}
      {renderMenu}
    </>
  );
}
