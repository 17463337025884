import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  successfullyCreatedOrder: false,
  orderAgain: false,
  isOrderDataForUpdate: false,
  orderNo: "[pending]",
  orderStatus: "",
  selectedTestOrderId: 0,
  autoGeneratedOrderNumber: null,
  duplicatedTestOrderData: [],
  reasonInput: "",
  encryptedTestOrderData: null,
};

const orderSlice = createSlice({
  name: "orderData",
  initialState,
  reducers: {
    handleChangeOrderData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { handleChangeOrderData } = orderSlice.actions;
export const orderDataReducer = orderSlice.reducer;
