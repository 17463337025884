import { Space, Typography } from "antd";
import React from "react";

const { Text } = Typography;

const renderDetailsText = (label, value) => {
  return (
    <Space className="my-1" wrap>
      <Text wrap className="text-[0.80rem] font-normal">
        {label}
      </Text>
      :<Text keyboard>{value}</Text>
    </Space>
  );
};

export default renderDetailsText;
