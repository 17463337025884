import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ICD10CODES } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const getAllICD10Codes = createAsyncThunk(
  "icd10codes/get-all-icd10codes",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const config = {
        params: {
          clientId: clientId,
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
        },
      };
      const response = await api.get(URL_ICD10CODES, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getAllICD10Codes };
