import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SPECIMEN } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const deleteSpecimen = createAsyncThunk(
  "specimen/delete-specimen",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(URL_SPECIMEN + "/" + data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { deleteSpecimen };
