import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const deleteClientType = createAsyncThunk(
  "clientTypes/delete-client-type",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(URL_CLIENT + data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { deleteClientType };
