import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CITY_MUNICIPALITY } from "config/connString";
import useAxios from "utils/useAxios";
const createCityMunicipalities = createAsyncThunk(
  "citymunicipalities/create-citymunicipalities",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_CITY_MUNICIPALITY, {
        name: data.name,
        provinceId: data.provinceId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createCityMunicipalities };
