import * as React from "react";
import { Grid, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { Table, ConfigProvider, Button as BtnAntD } from "antd";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CreateUpdateRole from "./createUpdateRole";
import { EmptyData } from "../global/emptyData";
import { getRoles } from "store";
import { useCurrentHeight } from "utils/getScreenSize";

const GetAllUserLists = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const [isForUpdate, setIsForUpdate] = React.useState(false);
  const [idForUpdate, setIdForUpdate] = React.useState(0);
  const [rolesData, setRolesData] = React.useState([]);

  const LoadData = React.useCallback(() => {
    if (getRoles.pending().type === "rolesClaims/get-roles/pending") {
      setIsLoading(true);
      setRolesData([]);
    }
    dispatch(getRoles()).then((data) => {
      if (data.type === "rolesClaims/get-roles/fulfilled") {
        setRolesData(data.payload.data);
        setIsLoading(false);
      }
    });
  }, [dispatch]);

  React.useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  const renderEmptyData = () => (
    <EmptyData
      description="No Role Detected"
      action={showCreateUpdate}
      btnTitle="Add New Role"
      renderButton={true}
    />
  );

  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = React.useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(0);
  };
  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };
  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
    setIdForUpdate(0);
    setIsForUpdate(false);
  };

  const handleUpdate = (id) => {
    setIsForUpdate(true);
    setIdForUpdate(id);
  };

  const columns = [
    {
      title: "Role Name",
      dataIndex: "name",
      key: "name",
      width: 100,
    },

    {
      title: "",
      dataIndex: "",
      key: "",
      width: 50,
      render: (rec, row) => (
        <>
          <BtnAntD
            size="small"
            icon={<EditIcon fontSize="inherit" />}
            className="hover:bg-orange-500 bg-orange-600"
            style={{
              border: "1px solid #ed6c02",
              color: "white",
            }}
            onClick={() => {
              handleUpdate(row);
              showCreateUpdate();
            }}
          >
            Update
          </BtnAntD>
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        {rolesData.length !== 0 && (
          <Grid item xs={12} md={6} lg={6}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              New Role
            </Button>
          </Grid>
        )}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={columns}
          dataSource={rolesData}
          loading={isLoading}
          size="small"
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <CreateUpdateRole
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
      />
    </>
  );
};
export default GetAllUserLists;
