import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SAMPLE_COLLECTORS } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getSpecificSampleCollector = createAsyncThunk(
  "sample-collectors/get-specific-sample-collectors",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.get(URL_SAMPLE_COLLECTORS + "/" + data.id);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getSpecificSampleCollector };
