import { useState } from "react";

const useSelectDropdownLoader = () => {
  const [isSelectOpening, setIsSelectOpening] = useState(false);

  const onDropdownVisibleChange = () => {
    setIsSelectOpening(true);
    setTimeout(() => {
      setIsSelectOpening(false);
    }, 2000);
  };

  const onBlur = () => {
    setIsSelectOpening(false);
  };
  return { onDropdownVisibleChange, onBlur, isSelectOpening };
};

export default useSelectDropdownLoader;
