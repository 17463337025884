import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "config/connString";
import useAxios from "utils/useAxios";

const getCouriersLookup = createAsyncThunk(
  "user/get-couriers-lookup",
  async () => {
    try {
      const api = useAxios();

      const response = await api.get(URL_USER + "/get-couriers-lookup");

      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getCouriersLookup };
