import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_TEST_ORDERS } from "config/connString";
import useAxios from "utils/useAxios";
const updateOrderLabStatus = createAsyncThunk(
  "test-orders/update-test-order-lab-status",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.put(
        URL_TEST_ORDERS + "/" + data.id + "/lab-status",
        {
          id: data.id,
          labStatus: data.labStatus,
          labStatusComment: data.labStatusComment,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateOrderLabStatus };
