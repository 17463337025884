import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_AUTH } from "config/connString";
import axios from "axios";

const userLogin = createAsyncThunk("auth/login", async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const response = await axios.post(
      URL_AUTH + "login",
      {
        username: data.username,
        password: data.password,
      },
      config
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
});

export { userLogin };
