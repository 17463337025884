import React, { useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import PopConfirm from "components/jkt/global/popConfirm";
import useTranslation from "hooks/useTranslation";

const SelectedDiagnosisTable = ({
  diagnosisOptions,
  setDiagnosisInput,
  setSelectedDiagnosisId,
}) => {
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedInputs = useSelector((state) => state.savedInputs);
  const [selectedEditIconId, setSelectedEditIconId] = useState(null);
  const { getGlobalTranslation } = useTranslation();

  const dispatch = useDispatch();

  const description = //Description before deleting a clinical diagnosis
    <p>{getGlobalTranslation("Are you sure")}?</p>;

  const optionIcdDisplayName = diagnosisOptions?.map(
    //Get all of the icd-10 ocde option name that is saved
    (diagnosis) => diagnosis.displayName
  );

  const handleShowEditIcon = (selectedEditIcon) => {
    //Showing of edit icon beside the added clinical diagnosis
    setSelectedEditIconId((prevEditIconId) =>
      prevEditIconId === selectedEditIcon ? null : selectedEditIcon
    );
  };
  const handleEditDiagnosis = (selectedDiagnosis) => {
    //Function for updating a manually typed clinical diagnosis
    if (!optionIcdDisplayName.includes(selectedDiagnosis.displayName)) {
      setDiagnosisInput({ ...selectedDiagnosis });
      setSelectedDiagnosisId(selectedDiagnosis.id);
    }
  };

  const handleDeleteDiagnosis = (selectedId) => {
    //Deletion / Removal of clinical diagnosis
    const filteredDiagnosis = multiStepFormInputs.diagnosesList.filter(
      (diagnosis) => diagnosis.id !== selectedId
    );
    dispatch(
      handleChangeMultiFields({
        diagnosesList: filteredDiagnosis,
      })
    );
  };

  return (
    <Box sx={{ marginTop: "1rem" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "7fr 35fr",
          padding: "0.4rem",
        }}
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "bold", color: "darkBlue.main" }}
        >
          No.
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontWeight: "bold",
            color: "darkBlue.main",
          }}
        >
          Diagnosis
        </Typography>
      </Box>
      <Box
        sx={{
          border: "2px solid",
          borderColor: savedInputs.isClinicalDiagnosisEmpty
            ? "red"
            : "lightBlue.secondary",
          borderRadius: "5px",
          height: "auto",
          position: "relative",
          padding: "0.4rem",
          backgroundColor: "white",
          paddingBottom: "3.4rem",
          width: "98%",
        }}
      >
        {multiStepFormInputs.diagnosesList &&
          multiStepFormInputs.diagnosesList.map((data, index) => (
            <Box
              key={data.id}
              sx={{
                display: "grid",
                gridTemplateColumns: "7fr 35fr",
                marginTop: "0.3rem",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                variant="p"
              >
                {index + 1}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "0.2rem",
                  cursor: optionIcdDisplayName.includes(data.displayName)
                    ? "auto"
                    : "pointer",
                }}
                variant="p"
                onMouseEnter={() => handleShowEditIcon(index)}
                onMouseLeave={() => setSelectedEditIconId(null)}
                onClick={() => handleEditDiagnosis(data)}
              >
                {data.displayName}
                {!optionIcdDisplayName.includes(data.displayName) &&
                  selectedEditIconId === index && (
                    <BorderColorIcon
                      sx={{
                        fontSize: "1rem",
                        marginBottom: "0.5rem",
                        cursor: "pointer",
                      }}
                    />
                  )}
              </Typography>
              <PopConfirm
                title={getGlobalTranslation("Remove diagnosis")}
                description={description}
                onConfirm={() => handleDeleteDiagnosis(data.id)}
                okText="Yes"
                cancelText="No"
              >
                <CloseIcon
                  sx={{
                    color: "red",
                    fontSize: "1.4rem",
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                    transform: {
                      xs: "translateX(20px)",
                      sm: "translateX(20px)",
                      md: "translateX(24px)",
                      lg: "translateX(24px)",
                      xl: "translateX(24px)",
                    },
                  }}
                />
              </PopConfirm>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default SelectedDiagnosisTable;
