import { createSlice } from "@reduxjs/toolkit";
import { getAllPhysicians } from "api/healthCareProvider/getAllPhysicians";
import { getHealthcareProvidersLookUp } from "api/healthCareProvider/getHealthcareProvidersLookUp";
import { createHealthcareProvider } from "api/healthCareProvider/createHealthcareProvider";

const healthcareProviderSlice = createSlice({
  name: "healthcareProvider",
  initialState: {
    data: [],
    isLoading: false,
    successMessage: [],
    error: null,
    errorMessage: [],
  },

  extraReducers(builder) {
    //FETCHING ALL OF THE PHYSICIANS
    builder.addCase(getAllPhysicians.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
      state.data = [];
    });
    builder.addCase(getAllPhysicians.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.error = false;
        state.successMessage = state.successMessage ? state.successMessage : [];
        state.data = action.payload.data.items;
        state.errorMessage = state.errorMessage ? state.errorMessage : [];
      } else {
        state.isLoading = false;
        state.error = true;
        state.errorMessage = action.payload.response.errorMessages;
        state.successMessage = [];
        state.data = [];
      }
    });
    builder.addCase(getAllPhysicians.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = ["Fetch error!"];
      state.successMessage = [];
      state.data = [];
    });

    //HEALTHCARE PROVIDERS LOOK-UP
    builder.addCase(getHealthcareProvidersLookUp.pending, (state) => {
      state.data = [];
      state.isLoading = true;
      state.successMessage = [];
      state.error = false;
      state.errorMessage = [];
    });
    builder.addCase(getHealthcareProvidersLookUp.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.data = action.payload.data;
        state.isLoading = false;
        state.successMessage = state.successMessage ? state.successMessage : [];
        state.error = false;
        state.errorMessage = state.errorMessage ? state.errorMessage : [];
      } else {
        state.data = [];
        state.isLoading = false;
        state.successMessage = [];
        state.error = true;
        state.errorMessage = action.payload.response.errorMessages;
      }
    });
    builder.addCase(getHealthcareProvidersLookUp.rejected, (state) => {
      state.data = [];
      state.isLoading = false;
      state.successMessage = [];
      state.error = true;
      state.errorMessage = ["Fetch error!"];
    });

    //CREATE A NEW PHYSICIAN
    builder.addCase(createHealthcareProvider.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(createHealthcareProvider.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.error = false;
        state.errorMessage = [];
        state.successMessage = [action.payload.successMessage];
      } else {
        state.isLoading = false;
        state.error = true;
        state.errorMessage = [action.payload.response.data.errorMessages];
        state.successMessage = [];
      }
    });
    builder.addCase(createHealthcareProvider.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action.payload.errorMessages;
      state.successMessage = [];
    });
  },
});

export const healthcareProviderReducer = healthcareProviderSlice.reducer;
