import { Box } from "@mui/material";
import { Space, Typography } from "antd";
import moment from "moment";
import React from "react";
import renderDetailsText from "utils/renderDetailsText";
import {
  clientWillDrawSpecimen,
  formattedPatientName,
  renderDiagnosesList,
  renderLabtest,
  renderPatientSpecimens,
  renderSpecialHandlings,
} from "./textRenderer";
import { isDataEmpty } from "utils/isDataEmpty";

const { Text } = Typography;

const AfterModificationData = ({ oldValue, newValue }) => {
  const emptySpecialHandling =
    newValue?.TestOrderSpecialHandlings?.length === 0;
  const emptyPatientSpecimens = newValue?.PatientSpecimens?.length === 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.3rem",
            mt: 2,
          }}
        >
          {renderDetailsText(
            "Patient Name",
            formattedPatientName(
              newValue?.Patient?.Firstname,
              newValue?.Patient?.Lastname
            )
          )}
          {!isDataEmpty(newValue?.Notes) &&
            renderDetailsText("Notes", newValue?.Notes)}
          {renderDetailsText("Order Status", newValue?.OrderStatus)}
          {renderDetailsText(
            "Order Date",
            moment(newValue?.OrderDate).format("LLL")
          )}
          {renderDetailsText("Client Code", newValue?.Client?.ClientCode)}
          {renderDetailsText("Client Name", newValue?.Client?.ClientName)}
          {renderDetailsText(
            "Client Will Draw Specimen",
            clientWillDrawSpecimen(newValue?.ClientWillDrawSpecimen)
          )}
          {renderDetailsText(
            "Auto Generated Order Number",
            newValue?.AutoGeneratedOrderNumber
          )}
          <Space
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Text className="text-[0.80rem] font-normal">Diagnoses List:</Text>
            {renderDiagnosesList(
              newValue?.DiagnosesList,
              "newValue",
              oldValue,
              newValue
            )}
          </Space>
          <Space
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Text className="text-[0.80rem] font-normal">
              Special Handlings:{" "}
              {newValue?.TestOrderSpecialHandlings?.length === 0 && (
                <Text keyboard>N/A</Text>
              )}
            </Text>
            {!emptySpecialHandling &&
              renderSpecialHandlings(
                newValue?.TestOrderSpecialHandlings,
                "newValue",
                oldValue,
                newValue
              )}
          </Space>
          <Space
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Text className="text-[0.80rem] font-normal">Labtest:</Text>
            {renderLabtest(
              newValue?.TestOrderDetails,
              "newValue",
              oldValue,
              newValue
            )}
          </Space>
          <Space
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Text className="text-[0.80rem] font-normal">
              Patient Specimens:{" "}
              {newValue?.PatientSpecimens?.length === 0 && (
                <Text keyboard>N/A</Text>
              )}
            </Text>
            {!emptyPatientSpecimens &&
              renderPatientSpecimens(
                newValue?.PatientSpecimens,
                "newValue",
                oldValue,
                newValue
              )}
          </Space>
        </Box>
      </Box>
    </Box>
  );
};

export default AfterModificationData;
