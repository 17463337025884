import { Box } from "@mui/material";
import React from "react";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import WestRoundedIcon from "@mui/icons-material/WestRounded";

const PaginationButtons = ({
  handleClickNextPage,
  handleClickPrevPage,
  disablePrevButton,
  disableNextButton,
}) => {
  //Disabled button color: lightBlue.main
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
        <Box
          component="button"
          onClick={handleClickPrevPage}
          sx={{
            backgroundColor: disablePrevButton
              ? "lightBlue.main"
              : "darkBlue.main",
            px: "0.4rem",
            py: "0.2rem",
            borderRadius: "0.3rem",
            pointerEvents: disablePrevButton ? "none" : "auto",
            cursor: "pointer",
          }}
        >
          <WestRoundedIcon sx={{ color: "white" }} />
        </Box>
        <Box
          component="button"
          onClick={handleClickNextPage}
          sx={{
            backgroundColor: disableNextButton
              ? "lightBlue.main"
              : "darkBlue.main",
            px: "0.4rem",
            py: "0.2rem",
            borderRadius: "0.3rem",
            pointerEvents: disableNextButton ? "none" : "auto",
            cursor: "pointer",
          }}
        >
          <EastRoundedIcon sx={{ color: "white" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default PaginationButtons;
