import { Box, Grid, Skeleton, Typography } from "@mui/material";
import moment from "moment";
import styles from "components/jkt/testRequest/forms/fourthStep/styles.module.css";
import useTranslation from "hooks/useTranslation";
import useCalculateBirthdate from "hooks/useCalculateBirthdate";
import { parsedClientDetails } from "utils/parsedClientDetails";

const PatientInformation = (props) => {
  const {
    patientDetails,
    provider,
    specimens,
    isLoading,
    clientWillDrawSpecimen,
  } = props;
  const { getAge } = useCalculateBirthdate();
  const { allowEmailSendingOfRequest } = parsedClientDetails();
  const providerTitle = !provider?.title
    ? ""
    : provider?.title && !provider?.title.includes(".")
    ? provider?.title + "."
    : provider?.title;
  const providerFirstName = provider?.firstName ? provider?.firstName : "";
  const providerLastName = provider?.lastName ? provider?.lastName : "";
  const dash = provider?.specialty ? " - " : "";
  const providerSpecialty = provider?.specialty ? provider.specialty : "";
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const patientFullName = `${patientDetails?.firstname} ${
    patientDetails?.middlename === null
      ? ""
      : patientDetails?.middlename?.includes(".")
      ? patientDetails?.middlename
      : patientDetails?.middlename + "."
  } ${patientDetails?.lastname === null ? "" : patientDetails?.lastname}`;

  const patientMedicalRecordNo = `${patientDetails?.medicalRecordNo || ""}`;

  const patientInfoLeft = [
    {
      id: 1,
      label: getTranslation("Patient name"),
      value: patientFullName,
    },
    {
      id: 2,
      label: getTranslation("Medical Record no"),
      value: patientMedicalRecordNo,
    },
    {
      id: 3,
      label: getTranslation("DateOfBirth"),
      value:
        patientDetails?.dateOfBirth === null
          ? "DD/MM/YY"
          : moment(patientDetails?.dateOfBirth).format("DD/MM/YYYY"),
    },
    {
      id: 4,
      label: getTranslation("Patient age"),
      value: `${
        getAge(patientDetails?.dateOfBirth)?.years || "0"
      } ${getTranslation("years")} ${
        getAge(patientDetails?.dateOfBirth)?.months || "0"
      } ${getTranslation("months")} ${
        getAge(patientDetails?.dateOfBirth)?.days || "0"
      } ${getTranslation("days")}`,
    },
    {
      id: 5,
      label: getTranslation("Sex code"),
      value: patientDetails?.gender === "Male" ? "M" : "F" || "M",
    },
  ];

  const patientInfoRight = [
    {
      id: 6,
      label: getTranslation("Date Collected"),
      value: clientWillDrawSpecimen
        ? moment(specimens ? specimens[0]?.collectionDateTime : "").format(
            "DD/MM/YYYY"
          ) || ""
        : "",
    },
    {
      id: 7,
      label: getTranslation("Time Collected"),
      value: clientWillDrawSpecimen
        ? moment(
            specimens?.find((data) =>
              Math.max(moment(data?.collectionDateTime))
            )?.collectionDateTime
          ).format("LT") || ""
        : "",
    },
    {
      id: 9,
      label: getTranslation("Physician name"),
      value: `${providerTitle} ${providerFirstName} ${providerLastName} ${dash} ${providerSpecialty}`,
    },
    {
      id: 10,
      label: getGlobalTranslation("Email address"),
      value: patientDetails?.emails?.map((email) => email).join(", "),
    },
  ];

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography
          sx={{
            color: "darkBlue.main",
            marginBottom: "0.5rem",
            marginTop: "0.7rem",
            fontSize: "1.4rem",
            fontWeight: "semibold",
          }}
          variant="h4"
        >
          {getTranslation("Patient Information")}
        </Typography>
        <p className={styles.divider} />
      </Grid>
      <Grid
        item
        sx={{ marginRight: "auto" }}
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
      >
        <Box className={styles["grid-container"]}>
          {patientInfoLeft.map((info) => (
            <Box key={info.id} className={styles["grid-item"]}>
              <span className={styles.label}>{info.label}</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>
                {isLoading ? <Skeleton /> : info.value}
              </span>
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
        <Box className={styles["grid-container"]}>
          {patientInfoRight
            .filter((item) =>
              !allowEmailSendingOfRequest ? item.id !== 10 : item
            )
            .map((info) => (
              <Box key={info.id} className={styles["grid-item"]}>
                <span className={styles.label}>{info.label}</span>
                <span className={styles.colon}>:</span>
                <span className={styles.value}>
                  {isLoading ? <Skeleton /> : info.value}
                </span>
              </Box>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PatientInformation;
