import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ROLE_CLAIMS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const deleteRoles = createAsyncThunk(
  "rolesClaims/delete-roles",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(URL_ROLE_CLAIMS + "/" + data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { deleteRoles };
