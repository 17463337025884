import { Box, Grid } from "@mui/material";
import useCalculateBirthdate from "hooks/useCalculateBirthdate";
import useTranslation from "hooks/useTranslation";
import moment from "moment";
import React from "react";
import QRCode from "react-qr-code";

const SessionSummaryDisplay = ({ data, showOrderStatus = false }) => {
  const { getAge } = useCalculateBirthdate();
  const { getTranslation } = useTranslation("SessionSummary");

  const renderOrderDetailsGridItems = (label, value) => {
    const isItemForSpecialInstruction = label === "Spc instruction";
    const isItemForClinicalDiagnosis = label === "Clin diagnosis";
    const isItemForLabtest = label === "Tests";
    const isItemForAdditionalInfo = label === "Add info";
    const regularGridItem =
      !isItemForSpecialInstruction &&
      !isItemForClinicalDiagnosis &&
      !isItemForLabtest &&
      !isItemForAdditionalInfo;

    return (
      <Box
        sx={{
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateColumns: "0.30fr 0.030fr 0.6fr",
          fontSize: "11pt",
        }}
      >
        <span className="text-[#415e9e]">{getTranslation(label)}</span>
        <span>:</span>
        {regularGridItem && <span>{value}</span>}
        {isItemForSpecialInstruction && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {value?.specialHandlings?.length > 0 &&
              value?.specialHandlings?.map((instruction) => {
                let specialInstructions = "";
                const statText = (
                  <span>
                    STAT - [
                    <span className="font-bold text-sm px-1">
                      {value?.testOrderDetails
                        ?.filter((test) => test?.isStat === true)
                        ?.map((testOrder) => testOrder?.labTest?.name)
                        .join(", ")}
                    </span>
                    ]
                  </span>
                );
                if (instruction?.instructions?.toLowerCase() === "stat") {
                  specialInstructions = statText;
                } else {
                  specialInstructions = `${instruction?.instructions}`;
                }
                return <span key={instruction?.id}>{specialInstructions}</span>;
              })}
          </Box>
        )}
        {isItemForClinicalDiagnosis && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {value?.diagnosesList?.map((diagnosis) => (
              <span key={diagnosis.id}>{diagnosis?.displayName}</span>
            ))}
          </Box>
        )}
        {isItemForLabtest && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {value?.testOrderDetails?.map((test) => (
              <span key={test?.labTest?.code}>
                {test?.labTest?.code} {test?.labTest?.name}
              </span>
            ))}
          </Box>
        )}
        {isItemForAdditionalInfo && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {value?.testOrderDetails?.map((order) =>
              order?.patientTestRequirementDatas?.map((test) =>
                test?.patientRequirementDataDetails
                  ?.filter((testData) => testData?.dataValue !== "")
                  .map((req, reqIndex) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                      key={req.id}
                    >
                      <span>{req?.description}</span>
                      <span className="list-disc">
                        &#x2022;{" "}
                        {req.dataValue === "true"
                          ? "Yes"
                          : req.dataValue === "false"
                          ? "No"
                          : req.dataValue}
                      </span>
                    </Box>
                  ))
              )
            )}
          </Box>
        )}
      </Box>
    );
  };

  const isMinimalOrder = data?.isMinimalOrder;
  const providerFirstName =
    !isMinimalOrder && data?.provider?.firstName
      ? data?.provider?.firstName
      : "";
  const providerLastName =
    !isMinimalOrder && data?.provider?.lastName ? data?.provider?.lastName : "";
  const patientDobAgeAndGender =
    !isMinimalOrder &&
    `${moment(data?.patient?.dateOfBirth).format("DD/MM/YYYY")} ${
      getAge(data?.patient?.dateOfBirth)?.years
    }${getTranslation("year")} ${getTranslation(data?.patient?.gender)}`;
  const timeCollected =
    data?.patientSpecimens.length === 0
      ? moment().format("DD/MM/YYYY hh:mm a")
      : moment(data?.patientSpecimens[0]?.collectionDateTime).format(
          "DD/MM/YYYY hh:mm a"
        );

  const renderQrCodeElement = () => {
    if (data.autoGeneratedOrderNumber !== null) {
      return (
        <QRCode
          style={{
            width: "2cm",
            height: "2cm",
            position: "absolute",
            bottom: -9,
            right: 0,
          }}
          value={data?.autoGeneratedOrderNumber + ""}
        />
      );
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        borderBottom: "2px solid black",
        breakInside: "avoid",
        paddingBottom: "1.2rem",
        pt: 1,
      }}
    >
      {/* Order details */}
      {!isMinimalOrder && (
        <Grid
          sx={{
            width: "100%",
            position: "relative",
          }}
          container
        >
          <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
            {renderOrderDetailsGridItems(
              "Patient Name",
              `${data?.patient?.firstname?.toUpperCase()} ${
                data?.patient?.lastname === undefined ||
                data?.patient?.lastname === null
                  ? ""
                  : data?.patient?.lastname?.toUpperCase()
              }`
            )}
            {renderOrderDetailsGridItems("DoB/Age/Sex", patientDobAgeAndGender)}
            {renderOrderDetailsGridItems("Time collected", timeCollected)}
            {renderOrderDetailsGridItems(
              "Specimens",
              `${data?.patientSpecimens
                ?.map((data) => data?.specimen?.name)
                .join(", ")}`
            )}
            {renderOrderDetailsGridItems("Spc instruction", data)}
            {renderOrderDetailsGridItems("Clin diagnosis", data)}
            {renderOrderDetailsGridItems("Tests", data)}
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
            {showOrderStatus &&
              renderOrderDetailsGridItems("Order Status", data?.orderStatus)}
            {renderOrderDetailsGridItems(
              "Order no",
              data?.autoGeneratedOrderNumber
            )}
            {renderOrderDetailsGridItems(
              "Order date",
              moment(data?.orderDate).format("DD/MM/YYYY")
            )}
            {renderOrderDetailsGridItems(
              "Physician name",
              `${providerFirstName} ${providerLastName}`
            )}
            {renderOrderDetailsGridItems("Add info", data)}
            {renderQrCodeElement()}
          </Grid>
        </Grid>
      )}
      {isMinimalOrder && (
        <Grid
          sx={{
            width: "100%",
            position: "relative",
            pb: 8,
          }}
          container
        >
          <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
            <h1 className="text-2xl font-bold">LRF Pickup Request</h1>
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
            {renderOrderDetailsGridItems(
              "Order no",
              data?.autoGeneratedOrderNumber
            )}
            {renderOrderDetailsGridItems(
              "Order date",
              moment(data?.orderDate).format("DD/MM/YYYY")
            )}
            {renderQrCodeElement()}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SessionSummaryDisplay;
