import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_ICD10CODES } from "../../config/connString";

const getIcd10CodesLookUp = createAsyncThunk(
  "icd10codes/get-icd10codes-look-up",
  async () => {
    try {
      const api = useAxios();
      const response = await api.get(URL_ICD10CODES + "/icd10-codes-lookup");
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getIcd10CodesLookUp };
