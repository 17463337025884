import { phase } from "constants/connections";

const {
  // COUNTRY_SELECTOR
  REACT_APP_COUNTRY,
  // MNL
  REACT_APP_MNL_LIVE_API,
  REACT_APP_MNL_BETA_API,
  REACT_APP_MNL_DEV_API,
  // JKT
  REACT_APP_JKT_LIVE_API,
  REACT_APP_JKT_BETA_API,
  REACT_APP_JKT_DEV_API,
} = process.env;

export const getBaseUrl = () => {
  const locationOrigin = window.location.origin;
  const isOriginLocal = locationOrigin.includes("localhost");
  const isOriginDev = locationOrigin.includes("dev");
  const isOriginBeta = locationOrigin.includes("beta");

  const baseUrlMap = {
    MNL: {
      LIVE: REACT_APP_MNL_LIVE_API,
      BETA: REACT_APP_MNL_BETA_API,
      DEV: REACT_APP_MNL_DEV_API,
    },
    JKT: {
      LIVE: REACT_APP_JKT_LIVE_API,
      BETA: REACT_APP_JKT_BETA_API,
      DEV: REACT_APP_JKT_DEV_API,
    },
  };

  const selectedCountryConnection = baseUrlMap[REACT_APP_COUNTRY];

  const selectedBaseUrl = selectedCountryConnection[phase];

  if (isOriginLocal) {
    const validatedBaseUrl =
      selectedBaseUrl === undefined
        ? selectedCountryConnection["DEV"]
        : selectedBaseUrl;
    return validatedBaseUrl;
  } else if (isOriginDev) {
    return selectedCountryConnection["DEV"];
  } else if (isOriginBeta) {
    return selectedCountryConnection["BETA"];
  } else {
    return selectedCountryConnection["LIVE"];
  }
};
