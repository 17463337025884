import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_DEPT } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getLabDeptLookUp = createAsyncThunk(
  "labDept/get-lab-dept-look-up",
  async () => {
    try {
      const api = useAxios();
      const response = await api.get(URL_LAB_DEPT + "-lookup");
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getLabDeptLookUp };
