export const getTagColorAndTitle = (condition) => {
  const tagColorAndTitleMap = {
    true: { color: "green", title: "Yes" },
    false: { color: "red", title: "No" },
  };

  const matchedTagColorAndTitle = tagColorAndTitleMap[condition];

  return {
    color: matchedTagColorAndTitle?.color,
    title: matchedTagColorAndTitle?.title,
  };
};
