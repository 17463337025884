import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ICD10CODES } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const updateICD10Codes = createAsyncThunk(
  "icd10codes/update-icd10codes",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.put(URL_ICD10CODES + "/" + data[1], {
        id: data[1],
        icD10_Code: data[0].icD10_Code,
        description: data[0].description,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateICD10Codes };
