import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ROLE_CLAIMS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import authHeader from "../../utils/authHeader";
const updateRoles = createAsyncThunk(
  "rolesClaims/update-roles",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.put(
        URL_ROLE_CLAIMS,
        {
          id: data[0],
          roleName: data[1].name,
        },
        authHeader()
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateRoles };
