import { Select } from "antd";
import React from "react";

const statusOptions = [
  {
    label: "Ready For Pickup",
    value: "ReadyForPickup",
  },
  {
    label: "In Transit",
    value: "InTransit",
  },
  {
    label: "Completed Delivery",
    value: "CompletedDelivery",
  },
  {
    label: "Picked Up",
    value: "PickedUp",
  },
  {
    label: "Accepted",
    value: "Accepted",
  },
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Cancelled",
    value: "Canceled",
  },
  {
    label: "Hold",
    value: "Hold",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },
  {
    label: "Missed Pickup",
    value: "MissedPickup",
  },
];

const OrderStatusFilter = ({ selectedStatuses, handleSelectStatus }) => {
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      allowClear
      mode="multiple"
      style={{ width: selectedStatuses.length > 0 ? "auto" : 170 }}
      options={statusOptions}
      value={selectedStatuses}
      virtual={false}
      onChange={handleSelectStatus}
      maxTagCount={1}
      placeholder="Select order status"
      filterOption={filterOption}
    />
  );
};

export default OrderStatusFilter;
