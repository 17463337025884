import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_TESTS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "../../utils/decodedUserDetails";

const getFrequentlyOrderedTest = createAsyncThunk(
  "labtests/get-top-10-labtest",
  async () => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();
      const response = await api.get(
        URL_LAB_TESTS + "/" + clientId + "/top-10"
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const getFrequentlyOrderedTestForUpdate = createAsyncThunk(
  "labtests/get-top-10-labtest",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();
      const specimenIdsQuery = data.specimenIds
        .map((id) => `specimenIds=${id}`)
        .join("&");

      const response = await api.get(
        URL_LAB_TESTS + "/" + clientId + "/top-10?" + specimenIdsQuery
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getFrequentlyOrderedTest, getFrequentlyOrderedTestForUpdate };
