import { createSlice } from "@reduxjs/toolkit";
import decodedUserDetails from "utils/decodedUserDetails";
const clientId = decodedUserDetails()?.clientId;
const initialState = {
  id: 0,
  clientId: clientId,
  clientWillDrawSpecimen: true,
  patientId: 0,
  providerId: null,
  diagnosesList: [],
  specialHandlingIds: [],
  testOrderDetails: [],
  patientSpecimens: [],
};

const multiStepFormInputSlice = createSlice({
  name: "multiStepFormInputs",
  initialState,
  reducers: {
    handleChangeMultiFields: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { handleChangeMultiFields } = multiStepFormInputSlice.actions;
export const multiStepFormReducer = multiStepFormInputSlice.reducer;
