import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_TEST_ORDERS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const updateOrderPickup = createAsyncThunk(
  "test-orders/update-test-order-pickup",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.put(URL_TEST_ORDERS + "/" + data.id + "/qr", {
        id: data.id.toString(),
        orderNumber: data.qr.toString(),
        comment: data.comment,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateOrderPickup };
