import { LoadingButton } from "@mui/lab";
import { Box, Button, Fade, Modal, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeOrderData } from "store/slice/orderSlice";

const ReasonFormInputModal = ({
  open,
  handleClose,
  modalTitle,
  onSubmit,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state.orderData);
  const disableSubmitButton = orderData.reasonInput.trim().length === 0;

  const handleReasonInputChange = (e) => {
    dispatch(
      handleChangeOrderData({
        reasonInput: e.target.value,
      })
    );
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Fade in={open}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              px: "1rem",
              py: "0.9rem",
              borderRadius: "0.5rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.8rem",
              width: 400,
              mx: 2,
              boxShadow: 3,
            }}
          >
            <Typography sx={{ fontWeight: "bold", mb: 2 }} variant="p">
              {modalTitle}
            </Typography>
            <TextField
              onChange={handleReasonInputChange}
              value={orderData.reasonInput}
              autoFocus={true}
              label="Enter your reason..."
              variant="filled"
            />
            <LoadingButton
              disabled={disableSubmitButton || isLoading}
              loading={isLoading}
              onClick={handleSubmit}
              variant="contained"
              type="primary"
            >
              {isLoading ? "Submitting..." : "Submit"}
            </LoadingButton>
            <Button onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ReasonFormInputModal;
