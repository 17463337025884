import { Paper, ThemeProvider, Stack, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import AppBar from "components/mnl/global/menuBar";
import { styled } from "@mui/material/styles";
import { theme } from "components/mnl/global/theme";
import Toolbar from "@mui/material/Toolbar";
import V2sessionTimeout from "components/mnl/global/v2SessionTimeout";
import RegisterNewUser from "components/mnl/user/registerNewUser";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
}));

const RegisterNewUserPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar pageName={"Register New User"} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Stack sx={{ mt: 5 }} gap={5}>
            <Stack spacing={2} direction="row" justifyContent="center">
              <Item sx={{ borderRadius: "1rem" }}>
                <RegisterNewUser />
              </Item>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default RegisterNewUserPage;
