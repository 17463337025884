import { Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clientLookUp } from "store";

const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const ClientFilter = ({ handleSelectClient }) => {
  const [clientOptions, setClientOptions] = useState([]);
  const dispatch = useDispatch();

  const handleFetchClientsData = () => {
    dispatch(clientLookUp()).then((res) => {
      const formattedClientData = res?.payload?.data?.map((client) => ({
        label: client.displayName,
        value: client.id,
      }));
      setClientOptions(formattedClientData);
    });
  };

  useEffect(() => {
    handleFetchClientsData();
    // eslint-disable-next-line
  }, []);

  return (
    <Select
      className="ml-2 w-48"
      allowClear
      virtual={false}
      showSearch
      placeholder="Select or search client"
      optionFilterProp="children"
      onChange={handleSelectClient}
      filterOption={filterOption}
      options={clientOptions}
    />
  );
};

export default ClientFilter;
