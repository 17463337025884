import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_TEST_ORDERS } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const cancelTestOrder = createAsyncThunk(
  "test-orders/cancel-test-order",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.put(
        URL_TEST_ORDERS + "/" + data.id + "/cancel-order",
        {
          id: data.id,
          cancelReason: data.cancelReason,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { cancelTestOrder };
