import { Box } from "@mui/system";
import { Space, Typography } from "antd";
import moment from "moment";
import { isDataEmpty } from "utils/isDataEmpty";
import renderDetailsText from "utils/renderDetailsText";

const { Text } = Typography;

const CreatedRequestData = ({ newValue }) => {
  const patientName = `${newValue?.Patient?.Firstname} ${
    newValue?.Patient?.Middlename === null ? "" : newValue?.Patient?.Middlename
  }${
    newValue?.Patient?.Middlename !== null &&
    !newValue?.Patient?.Middlename?.includes(".")
      ? "."
      : ""
  } ${newValue?.Patient?.Lastname === null ? "" : newValue?.Patient?.Lastname}`;
  const isOrderLrf = newValue?.IsMinimalOrder;

  if (isOrderLrf) {
    return (
      <Box sx={{ mx: "auto" }}>
        {" "}
        <h1 className="text-center text-lg font-semibold">
          LRF Pickup Request
        </h1>
      </Box>
    );
  }

  return (
    <Box>
      {renderDetailsText("Patient Name", patientName)}
      {renderDetailsText("Order Status", newValue?.OrderStatus)}
      {renderDetailsText(
        "Order Date",
        moment(newValue?.OrderDate).format("LLL")
      )}
      {renderDetailsText("Client Code", newValue?.Client?.ClientCode)}
      {renderDetailsText("Client Name", newValue?.Client?.ClientName)}
      {renderDetailsText(
        "Client Will Draw Specimen",
        newValue?.ClientWillDrawSpecimen ? "Yes" : "No"
      )}
      {renderDetailsText(
        "Auto Generated Order Number",
        newValue?.AutoGeneratedOrderNumber
      )}
      {!isDataEmpty(newValue?.Notes) &&
        renderDetailsText("Notes", newValue?.Notes)}
      <Space
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Text className="text-[0.80rem] font-normal">Diagnoses List:</Text>
        <ul className="list-disc">
          {newValue?.DiagnosesList?.map((item) => {
            return (
              <li className="ml-7" key={item?.id}>
                <Text keyboard>{item?.displayName}</Text>
              </li>
            );
          })}
        </ul>
      </Space>
      <Space
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Text className="text-[0.80rem] font-normal">
          Special Handlings:{" "}
          {newValue?.TestOrderSpecialHandlings?.length === 0 && (
            <Text keyboard>N/A</Text>
          )}
        </Text>
        <ul className="list-disc">
          {newValue?.TestOrderSpecialHandlings?.map((item) => {
            const specialHandlingData = item?.SpecialHandling;
            return (
              <li className="ml-7" key={item?.id}>
                <Text keyboard>{specialHandlingData?.Instructions}</Text>
              </li>
            );
          })}
        </ul>
      </Space>
      <Space
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Text className="text-[0.80rem] font-normal">Labtest:</Text>
        <ul className="list-disc">
          {newValue?.TestOrderDetails?.map((item) => {
            const labtestData = item?.LabTest;
            return (
              <li className="ml-7" key={item?.id}>
                <Text keyboard>{labtestData?.Name}</Text>
              </li>
            );
          })}
        </ul>
      </Space>
      <Space
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Text className="text-[0.80rem] font-normal">
          Patient Specimens:{" "}
          {newValue?.PatientSpecimens?.length === 0 && (
            <Text keyboard>N/A</Text>
          )}
        </Text>
        <ul className="list-disc">
          {newValue?.PatientSpecimens?.map((item) => {
            const specimenData = item?.Specimen;
            return (
              <li className="ml-7" key={item?.id}>
                <Text keyboard>{specimenData?.Name}</Text>
              </li>
            );
          })}
        </ul>
      </Space>
    </Box>
  );
};

export default CreatedRequestData;
