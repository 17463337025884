const useConvertHours = () => {
  const convertHoursToDay = (numberOfHours) => {
    const days = Math.floor(numberOfHours / 24);
    const remainder = numberOfHours % 24;
    const hours = Math.floor(remainder);
    const daysText = days > 1 ? `${days} days` : `${days} day`;
    const hoursText = hours > 1 ? `${hours} hours` : `${hours} hour`;

    if (numberOfHours >= 24) {
      if (remainder) {
        return daysText + " and " + hoursText;
      } else {
        return daysText;
      }
    } else {
      return `${numberOfHours} ${numberOfHours > 1 ? "hours" : "hour"}`;
    }
  };

  const convertProvidedCollectionDateTime = (numberOfHours) => {
    const days = Math.floor(numberOfHours / 24);
    const remainder = numberOfHours % 24;
    const hours = Math.floor(remainder);
    const minutes = Math.floor(60 * (remainder - hours));
    const daysText = days > 1 ? `${days} days` : `${days} day`;
    const hoursText = hours > 1 ? `${hours} hours` : `${hours} hour`;
    const minutesText =
      minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;

    if (numberOfHours >= 24) {
      if (remainder && minutes === 0) {
        return daysText + " and " + hoursText;
      } else if (remainder && minutes) {
        return daysText + "," + hoursText + " and " + minutesText;
      } else {
        return daysText;
      }
    } else {
      return `${numberOfHours} ${numberOfHours > 1 ? "hours" : "hour"}`;
    }
  };

  return { convertHoursToDay, convertProvidedCollectionDateTime };
};

export default useConvertHours;
