import {
  Modal,
  Typography as AntTypography,
  Tag,
  Table,
  Button,
  Space,
  Typography,
  Badge,
  Card,
} from "antd";
import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Chip, Divider, Grid, Paper } from "@mui/material";
import styles from "./styles.module.css";
import { useDispatch } from "react-redux";
import { getLabTestRequirements } from "store";
import decodedUserDetails from "utils/decodedUserDetails";
import moment from "moment";
import { getStringWeekName } from "./data";

const TestProfileInfoDetails = ({
  showTestModal,
  handleCloseTestModal,
  testData,
  isTestLoading,
}) => {
  const [selectedTestProfile, setSelectedTestProfile] = useState(null);
  const isPackageSelected = testData?.type === "Package";
  const isTestSelected = testData?.type === "Test";
  const isProfileSelected = testData?.type === "Profile";
  const isPanelSelected = testData?.type === "Panel";
  const dispatch = useDispatch();
  const { Text } = AntTypography;
  const isSuperAdmin = decodedUserDetails()?.isSuperAdmin === "True";
  const isRoleAdmin =
    decodedUserDetails()?.role?.includes("Admin") || isSuperAdmin;

  const checkSpecimenIfEmpty = (specimen) => {
    if (specimen.length === 0) {
      return true;
    }
    return false;
  };

  const handleFetchLabtestInfo = (selectedTest) => {
    dispatch(
      getLabTestRequirements({
        id: selectedTest.id,
      })
    ).then((res) => {
      setSelectedTestProfile(res?.payload?.data);
    });
  };
  const handleGenerateRow = (data) => {
    return data?.map((item) => {
      return {
        ...item,
        id: item?.id,
        key: item?.id,
        children:
          item.child?.length === 0 ? null : handleGenerateRow(item?.child),
      };
    });
  };

  const testRows = [
    {
      ...testData,
      id: testData?.id,
      key: testData?.id,
      children:
        testData.child?.length === 0
          ? null
          : handleGenerateRow(testData?.child),
    },
  ];

  const testColumns = [
    {
      title: "Code",
      dataIndex: "code",
      width: selectedTestProfile !== null ? 60 : 30,
      key: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 60,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: selectedTestProfile !== null ? 30 : 40,
    },
    {
      key: "action",
      render: (record) => (
        <>
          {record.type === "Test" && (
            <Button
              size="small"
              type="primary"
              icon={<InfoIcon fontSize="inherit" />}
              className="submitBtn"
              onClick={() => handleFetchLabtestInfo(record)}
            >
              Details
            </Button>
          )}
        </>
      ),
      width: selectedTestProfile !== null ? 40 : 20,
    },
  ];

  const handleCloseTestDetails = () => {
    setSelectedTestProfile(null);
  };

  const handleCloseModal = () => {
    setSelectedTestProfile(null);
    handleCloseTestModal();
  };

  return (
    <Modal
      open={showTestModal}
      closable={true}
      footer={false}
      style={{ paddingBottom: "0.8rem" }}
      width={isTestSelected && selectedTestProfile === null ? 600 : 1000}
      onCancel={handleCloseModal}
      title={
        <Text>
          <blockquote>
            {testData?.code} {testData?.name} - {testData?.type}
          </blockquote>
        </Text>
      }
    >
      {isTestSelected && selectedTestProfile === null && (
        <Box sx={{ height: "30rem", overflowY: "scroll" }}>
          <Space direction="vertical">
            <Space align="center">
              Abbreviation
              <Text code>{testData?.abbreviation}</Text>
            </Space>
            <Space align="center">
              Alias
              <Text code>
                {testData?.alias === null ? "N/A" : testData?.alias}
              </Text>
            </Space>
            <Space align="center">
              Methodology
              <Text code>{testData?.testDetails.methodology}</Text>
            </Space>
            <Space align="center">
              Sample Preparation
              <Typography>
                <pre> {testData?.testDetails.samplePreparation}</pre>
              </Typography>
            </Space>
            <Space align="center">
              Purpose
              <Typography>
                <pre>
                  {" "}
                  {testData?.testDetails.purpose === null
                    ? "N/A"
                    : testData?.testDetails.purpose}
                </pre>
              </Typography>
            </Space>
            <Space align="center">
              Reject Criteria
              <Typography>
                <pre>
                  {" "}
                  {testData?.testDetails.rejectCriteria === null
                    ? "N/A"
                    : testData?.testDetails.rejectCriteria}
                </pre>
              </Typography>
            </Space>
            <Space align="center">
              Allow Stat
              {testData?.testDetails.isStatAllowed ? (
                <Tag color="success">Yes</Tag>
              ) : (
                <Tag>No</Tag>
              )}
            </Space>
            <Space align="center">
              Specimen
              <Text code>
                {checkSpecimenIfEmpty(testData?.testDetails?.labTestSpecimens)
                  ? "N/A"
                  : testData?.testDetails?.labTestSpecimens
                      .map((specimen) => specimen?.name)
                      .join(" / ")}
              </Text>
            </Space>
            <Space align="center">
              Specimen Container
              <Text code>
                {checkSpecimenIfEmpty(testData?.testDetails?.labTestSpecimens)
                  ? "N/A"
                  : testData?.testDetails?.labTestSpecimens
                      .map((specimen) => specimen?.container)
                      .join(" / ")}
              </Text>
            </Space>
            {isRoleAdmin && (
              <Space align="center">
                Unit of Measure
                <Text code>
                  {checkSpecimenIfEmpty(testData?.testDetails?.labTestSpecimens)
                    ? "N/A"
                    : testData?.testDetails?.labTestSpecimens
                        .map((specimen) => specimen?.unitOfMeasure)
                        .join(" / ")}
                </Text>
              </Space>
            )}
            <Space align="center">
              {`Turn Around Time(TAT)`}
              <Text code>
                {testData?.testDetails.tat === null
                  ? "N/A"
                  : testData?.testDetails.tat}
              </Text>
            </Space>
            <Space align="center">
              Specimen Stability
              <Text code>
                {testData?.testDetails?.specimenStability
                  ? testData?.testDetails?.specimenStability
                  : "N/A"}
              </Text>
            </Space>
            <Space align="center">
              Volume Requirement
              <Text code>
                {checkSpecimenIfEmpty(testData?.testDetails?.labTestSpecimens)
                  ? "N/A"
                  : testData?.testDetails?.labTestSpecimens
                      .map((specimen) =>
                        specimen?.testVolumeOrSizeRequirements.length === 0
                          ? "N/A"
                          : specimen?.testVolumeOrSizeRequirements?.find(
                              (vol) => Math.min(vol?.minVolume)
                            )?.minVolume + ` ${specimen.unitOfMeasure}`
                      )
                      .join(" / ")}
              </Text>
            </Space>
            {/* <Space align="center">
              Cut Off of Acceptance
              <Tag>
                {testData?.testDetails?.cutoff === null
                  ? "N/A"
                  : getStringWeekName(testData?.testDetails?.cutoff)?.long}
              </Tag>
            </Space> */}
            {testData?.testDetails.schedules.length > 0 && (
              <Box sx={{ marginTop: "1rem" }}>
                <Divider textAlign="left">
                  <Chip label="Schedule" />
                </Divider>
                <Box sx={{ marginTop: "0.8rem" }}>
                  <Space
                    direction="vertical"
                    style={{ marginBottom: "0.7rem" }}
                  >
                    <Badge status="success" text="Scheduled to run" />
                    <Badge status="default" text="Not scheduled to run" />
                  </Space>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "1rem",
                      justifyContent: "start",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    {[0, 1, 2, 3, 4, 5, 6].map((item) => {
                      const matchingSchedules =
                        testData?.testDetails?.schedules.filter(
                          (i) => i.day === item
                        );

                      return matchingSchedules.length > 0 ? (
                        matchingSchedules.map((schedule) => {
                          const startTimeString =
                            schedule.startTime === "00:00:00"
                              ? schedule.startTime
                              : moment(schedule.startTime, "HH:mm:ss").format(
                                  "h:mm a"
                                );

                          const endTimeString =
                            schedule.endTime === "00:00:00"
                              ? schedule.endTime
                              : moment(schedule.endTime, "HH:mm:ss").format(
                                  "h:mm a"
                                );

                          return (
                            <Badge.Ribbon
                              className="w-full"
                              text={getStringWeekName(item).long}
                              color="green"
                            >
                              <Card
                                className="pt-5 bg-green-50 relative"
                                size="small"
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mx: 1,
                                  }}
                                >
                                  <strong>{startTimeString}</strong> to{" "}
                                  <strong>{endTimeString}</strong>
                                </Box>
                              </Card>
                            </Badge.Ribbon>
                          );
                        })
                      ) : (
                        <Badge.Ribbon
                          className="w-full"
                          text={getStringWeekName(item).long}
                          color="gray"
                        >
                          <Card
                            className="pt-5 font-bold bg-gray-100 w-28"
                            size="small"
                          ></Card>
                        </Badge.Ribbon>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            )}
          </Space>
        </Box>
      )}
      <Grid
        columnSpacing={2}
        rowSpacing={2}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        container
      >
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={selectedTestProfile !== null ? 7 : 12}
          xl={selectedTestProfile !== null ? 7 : 12}
          item
        >
          {(isPackageSelected || isProfileSelected || isPanelSelected) && (
            <>
              <Table
                columns={testColumns}
                dataSource={testRows}
                loading={isTestLoading}
                size="small"
                bordered={true}
                style={{ marginTop: 10 }}
                pagination={false}
                scroll={{
                  y: 400,
                }}
                rowClassName={(record) =>
                  selectedTestProfile?.id === record?.id &&
                  styles["selected-row"]
                }
              />
            </>
          )}
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={5} xl={5} item>
          {selectedTestProfile !== null && (
            <Paper
              sx={{
                padding: "0.5rem",
                marginTop: "0.7rem",
                height: "27.5rem",
                overflowY: "scroll",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ marginRight: "auto" }}>
                  Test Details
                </Typography>
                <CloseRoundedIcon
                  onClick={handleCloseTestDetails}
                  sx={{ cursor: "pointer", fontSize: "1rem" }}
                />
              </Box>
              <Text style={{ marginRight: "auto" }}>
                <blockquote>
                  {selectedTestProfile?.code} {selectedTestProfile?.name} -{" "}
                  {selectedTestProfile?.type}
                </blockquote>
              </Text>
              <Space direction="vertical">
                <Space align="center">
                  Abbreviation
                  <Text code>{selectedTestProfile?.abbreviation}</Text>
                </Space>
                <Space align="center">
                  Alias
                  <Text code>
                    {selectedTestProfile?.alias === null
                      ? "N/A"
                      : testData?.alias}
                  </Text>
                </Space>
                <Space align="center">
                  Methodology
                  <Text code>
                    {selectedTestProfile.testDetails?.methodology}
                  </Text>
                </Space>
                <Space align="center">
                  Sample Preparation
                  <Typography>
                    <pre>
                      {" "}
                      {selectedTestProfile.testDetails?.samplePreparation}
                    </pre>
                  </Typography>
                </Space>
                <Space align="center">
                  Purpose
                  <Typography>
                    <pre>
                      {" "}
                      {selectedTestProfile?.testDetails.purpose === null
                        ? "N/A"
                        : selectedTestProfile?.testDetails.purpose}
                    </pre>
                  </Typography>
                </Space>
                <Space align="center">
                  Reject Criteria
                  <Typography>
                    <pre>
                      {" "}
                      {selectedTestProfile?.testDetails.rejectCriteria === null
                        ? "N/A"
                        : selectedTestProfile?.testDetails.rejectCriteria}
                    </pre>
                  </Typography>
                </Space>
                <Space align="center">
                  Specimen
                  <Text code>
                    {checkSpecimenIfEmpty(
                      selectedTestProfile?.testDetails?.labTestSpecimens
                    )
                      ? "N/A"
                      : selectedTestProfile?.testDetails?.labTestSpecimens
                          ?.map((specimen) => specimen.name)
                          .join(" / ")}
                  </Text>
                </Space>
                <Space align="center">
                  Specimen Container
                  <Text code>
                    {checkSpecimenIfEmpty(
                      selectedTestProfile?.testDetails?.labTestSpecimens
                    )
                      ? "N/A"
                      : selectedTestProfile?.testDetails?.labTestSpecimens
                          .map((specimen) => specimen?.container)
                          .join(" / ")}
                  </Text>
                </Space>
                {isRoleAdmin && (
                  <Space align="center">
                    Unit of Measure
                    <Text code>
                      {checkSpecimenIfEmpty(
                        selectedTestProfile?.testDetails?.labTestSpecimens
                      )
                        ? "N/A"
                        : selectedTestProfile?.testDetails?.labTestSpecimens
                            .map((specimen) => specimen?.unitOfMeasure)
                            .join(" / ")}
                    </Text>
                  </Space>
                )}
                <Space align="center">
                  {`Turn Around Time(TAT)`}
                  <Text code>
                    {selectedTestProfile?.testDetails.tat === null
                      ? "N/A"
                      : selectedTestProfile?.testDetails.tat}
                  </Text>
                </Space>
                <Space align="center">
                  Specimen Stability
                  <Text code>
                    {selectedTestProfile?.testDetails.specimenStability
                      ? selectedTestProfile?.testDetails.specimenStability
                      : "N/A"}
                  </Text>
                </Space>
                <Space align="center">
                  Volume Requirement
                  <Text code>
                    {checkSpecimenIfEmpty(
                      selectedTestProfile?.testDetails?.labTestSpecimens
                    )
                      ? "N/A"
                      : selectedTestProfile?.testDetails?.labTestSpecimens
                          .map((specimen) =>
                            specimen?.testVolumeOrSizeRequirements.length === 0
                              ? "N/A"
                              : specimen?.testVolumeOrSizeRequirements?.find(
                                  (vol) => Math.min(vol?.minVolume)
                                )?.minVolume + ` ${specimen.unitOfMeasure}`
                          )
                          .join(" / ")}
                  </Text>
                </Space>
                {/* <Space align="center">
                  Cut Off of Acceptance
                  <Tag>
                    {selectedTestProfile?.testDetails?.cutoff === null
                      ? "N/A"
                      : getStringWeekName(
                          selectedTestProfile?.testDetails?.cutoff
                        )?.long}
                  </Tag>
                </Space> */}
                {selectedTestProfile?.testDetails?.schedules.length > 0 && (
                  <Box sx={{ marginTop: "1rem" }}>
                    <Divider textAlign="left">
                      <Chip label="Schedule" />
                    </Divider>
                    <Box sx={{ marginTop: "0.8rem" }}>
                      <Space
                        direction="vertical"
                        style={{ marginBottom: "0.7rem" }}
                      >
                        <Badge status="success" text="Scheduled to run" />
                        <Badge status="default" text="Not scheduled to run" />
                      </Space>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "1rem",
                          justifyContent: "start",
                          alignItems: "center",
                          mt: 3,
                        }}
                      >
                        {[0, 1, 2, 3, 4, 5, 6].map((item) => {
                          const matchingSchedules =
                            selectedTestProfile.testDetails?.schedules.filter(
                              (i) => i.day === item
                            );

                          return matchingSchedules.length > 0 ? (
                            matchingSchedules.map((schedule) => {
                              const startTimeString =
                                schedule.startTime === "00:00:00"
                                  ? schedule.startTime
                                  : moment(
                                      schedule.startTime,
                                      "HH:mm:ss"
                                    ).format("h:mm a");

                              const endTimeString =
                                schedule.endTime === "00:00:00"
                                  ? schedule.endTime
                                  : moment(schedule.endTime, "HH:mm:ss").format(
                                      "h:mm a"
                                    );

                              return (
                                <Badge.Ribbon
                                  className="w-full"
                                  text={getStringWeekName(item).long}
                                  color="green"
                                >
                                  <Card
                                    className="pt-5 bg-green-50 relative"
                                    size="small"
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        mx: 1,
                                      }}
                                    >
                                      <strong>{startTimeString}</strong> to{" "}
                                      <strong>{endTimeString}</strong>
                                    </Box>
                                  </Card>
                                </Badge.Ribbon>
                              );
                            })
                          ) : (
                            <Badge.Ribbon
                              className="w-full"
                              text={getStringWeekName(item).long}
                              color="gray"
                            >
                              <Card
                                className="pt-5 font-bold bg-gray-100 w-28"
                                size="small"
                              ></Card>
                            </Badge.Ribbon>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Space>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default TestProfileInfoDetails;
