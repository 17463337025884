import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import PlaceIcon from "@mui/icons-material/Place";
import PublicIcon from "@mui/icons-material/Public";
import { Button } from "antd";
import { handleChangeFields } from "store/slice/inputSlice";
import PopConfirm from "components/mnl/global/popConfirm";
import notification from "components/mnl/global/openNotification";

const AddressSelector = ({
  cityMunicipalityOptions,
  countryOptions,
  provinceOptions,
  barangayOptions,
  phAddressesTypeOptions,
}) => {
  const inputs = useSelector((state) => state.inputs);
  const dispatch = useDispatch();
  const gridOptions = {
    columnSpacing: 2,
    rowSpacing: 1,
    sx: { marginTop: "1rem", marginBottom: "1rem" },
  };
  const isPhAddressesEmpty = inputs.phAddresses.length === 0;
  const threeGrid = {
    xs: 12,
    sm: 12,
    md: 4,
    lg: 4,
    xl: 4,
  };

  const formInputStyle = {
    width: "100%",
    outline: "none",
    borderRadius: "5px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "2px solid",
        borderColor: "darkBlue.secondary",
      },
    },
  };

  const inputLabelStyle = {
    fontWeight: "bold",
    color: "darkBlue.main",
  };

  const handleAddAddressInputs = () => {
    //PhAddresses input fields that will be added in phAddresses property
    const addressInputsToBeAdded = {
      type: null,
      barangayId: null,
      cityMunicipalityId: null,
      provinceId: null,
      houseBuildingNumber: null,
      streetName: null,
      postalCode: null,
      country: "Philippines",
    };
    dispatch(
      handleChangeFields({
        phAddresses: [...inputs.phAddresses, addressInputsToBeAdded],
      })
    );
  };

  const handleChangeInputValues = (value, name, selectedAddressIndex) => {
    //Dynamic changing of input fields in address
    dispatch(
      handleChangeFields({
        phAddresses: inputs.phAddresses.map((address, addressIdx) =>
          addressIdx === selectedAddressIndex
            ? {
                ...address,
                [name]: value,
              }
            : address
        ),
      })
    );
  };

  const handleRemoveAddress = (selectedIndex) => {
    //Function for removing an address
    const filteredPhAddressInputs = inputs?.phAddresses?.filter(
      (_, idx) => idx !== selectedIndex
    );
    dispatch(
      handleChangeFields({
        phAddresses: filteredPhAddressInputs,
      })
    );
  };
  return (
    <Box>
      {!isPhAddressesEmpty &&
        inputs.phAddresses?.map((addressInput, index) => {
          const filteredCityMunicipalityOptions =
            cityMunicipalityOptions?.filter(
              (data) => data.provinceId === addressInput.provinceId
            );
          const filteredBarangayOptions = barangayOptions?.filter(
            (data) =>
              data.cityMunicipalityId === addressInput.cityMunicipalityId
          );
          const isCityMunicipalityOptionDisabled =
            addressInput.provinceId === null ||
            filteredCityMunicipalityOptions.length === 0;
          const isBarangayOptionDisabled =
            addressInput.cityMunicipalityId === null ||
            filteredBarangayOptions.length === 0;
          return (
            <Box
              sx={{
                border: "2px solid",
                borderColor: "darkBlue.main",
                padding: "0.5rem",
                borderRadius: "0.5rem",
                margin: "0.6rem 0",
              }}
              key={index}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    position: "relative",
                    ml: 2,
                    "::before": {
                      content: "''",
                      position: "absolute",
                      left: -8,
                      height: "100%",
                      width: "4px",
                      backgroundColor: "defaultBlue.main",
                      borderRadius: "1rem",
                    },
                  }}
                  variant="p"
                >
                  Address # {index + 1}
                </Typography>
                <PopConfirm
                  cancelText="No"
                  okText="Yes"
                  description="Are you sure you want to remove this address?"
                  title={`Removing Address #${index + 1}`}
                  onConfirm={() => handleRemoveAddress(index)}
                >
                  <Tooltip title="Remove address" arrow>
                    <Button
                      type="primary"
                      danger
                      shape="circle"
                      icon={
                        <DeleteIcon className="pb-[2px]" fontSize="small" />
                      }
                    />
                  </Tooltip>
                </PopConfirm>
              </Box>
              <Grid {...gridOptions} container>
                <Grid xs={12} sm={12} md={4} lg={4} xl={4} item>
                  <Typography variant="p" sx={inputLabelStyle}>
                    Address Type
                  </Typography>
                  <Autocomplete
                    clearOnBlur={false}
                    options={phAddressesTypeOptions}
                    getOptionLabel={(addressType) =>
                      addressType.value ? addressType.value : ""
                    }
                    value={phAddressesTypeOptions.find(
                      (data) => data.key === addressInput.type
                    )}
                    size="small"
                    disablePortal
                    noOptionsText="Address type doesn't exist"
                    disableClearable
                    renderOption={(props, option) => (
                      <li style={{ margin: "0.2rem" }} {...props}>
                        {option.value}
                      </li>
                    )}
                    onChange={(event, value) => {
                      handleChangeInputValues(value.key, "type", index);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        placeholder="Select address type..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <Box sx={{ overflow: "hidden", opacity: 0 }}>
                                {params.InputProps.endAdornment}
                              </Box>
                              <InputAdornment
                                sx={{ transform: "translateX(25px)" }}
                                position="end"
                              >
                                <ArrowBackIosNewRoundedIcon
                                  sx={{
                                    transform: "rotate(-90deg)",
                                    color: "lightBlue.main",
                                    pointerEvents: "none",
                                  }}
                                />
                              </InputAdornment>
                            </>
                          ),
                        }}
                        sx={formInputStyle}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid {...gridOptions} container>
                <Grid {...threeGrid} item>
                  <Typography
                    variant="p"
                    sx={inputLabelStyle}
                    className="required"
                  >
                    Province
                  </Typography>
                  <Autocomplete
                    clearOnBlur={false}
                    options={provinceOptions}
                    getOptionLabel={(province) =>
                      province.name && province.id ? `${province.name}` : ""
                    }
                    value={provinceOptions.find(
                      (data) => data.id === addressInput.provinceId
                    )}
                    size="small"
                    disablePortal
                    noOptionsText="Province doesn't exist"
                    disableClearable
                    renderOption={(props, option) => (
                      <li style={{ margin: "0.2rem" }} {...props}>
                        <PlaceIcon sx={{ color: "darkBlue.main" }} />{" "}
                        {option.name}
                      </li>
                    )}
                    onChange={(event, value) => {
                      handleChangeInputValues(value.id, "provinceId", index);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        placeholder="Select province..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <Box sx={{ overflow: "hidden", opacity: 0 }}>
                                {params.InputProps.endAdornment}
                              </Box>
                              <InputAdornment
                                sx={{ transform: "translateX(25px)" }}
                                position="end"
                              >
                                <ArrowBackIosNewRoundedIcon
                                  sx={{
                                    transform: "rotate(-90deg)",
                                    color: "lightBlue.main",
                                    pointerEvents: "none",
                                  }}
                                />
                              </InputAdornment>
                            </>
                          ),
                        }}
                        sx={formInputStyle}
                      />
                    )}
                  />
                </Grid>
                <Grid {...threeGrid} item>
                  <Typography
                    variant="p"
                    sx={inputLabelStyle}
                    className="required"
                  >
                    City Municipality
                  </Typography>
                  <Autocomplete
                    onClick={() =>
                      isCityMunicipalityOptionDisabled &&
                      notification.warning({
                        message: "Unable to Open City Municipality Option",
                        description:
                          "Please select Province that contain City Municipalities first",
                      })
                    }
                    value={filteredCityMunicipalityOptions.find(
                      (data) => data.id === addressInput.cityMunicipalityId
                    )}
                    disabled={isCityMunicipalityOptionDisabled}
                    clearOnBlur={false}
                    options={filteredCityMunicipalityOptions}
                    getOptionLabel={(city) =>
                      city.name && city.id ? `${city.name}` : ""
                    }
                    size="small"
                    disablePortal
                    noOptionsText="City Municipality doesn't exist"
                    disableClearable
                    renderOption={(props, option) => (
                      <li style={{ margin: "0.2rem" }} {...props}>
                        <PlaceIcon sx={{ color: "darkBlue.main" }} />{" "}
                        {option.name}
                      </li>
                    )}
                    onChange={(event, value) => {
                      handleChangeInputValues(
                        value.id,
                        "cityMunicipalityId",
                        index
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        onClick={() =>
                          isCityMunicipalityOptionDisabled &&
                          notification.warning({
                            message: "Unable to Open City Municipality Option",
                            description:
                              "Please select Province that contain City Municipalities first",
                          })
                        }
                        size="small"
                        {...params}
                        placeholder="Select city municipality..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <Box sx={{ overflow: "hidden", opacity: 0 }}>
                                {params.InputProps.endAdornment}
                              </Box>
                              <InputAdornment
                                sx={{ transform: "translateX(25px)" }}
                                position="end"
                              >
                                <ArrowBackIosNewRoundedIcon
                                  sx={{
                                    transform: "rotate(-90deg)",
                                    color: "lightBlue.main",
                                    pointerEvents: "none",
                                  }}
                                />
                              </InputAdornment>
                            </>
                          ),
                        }}
                        sx={formInputStyle}
                      />
                    )}
                  />
                </Grid>
                <Grid {...threeGrid} item>
                  <Typography variant="p" sx={inputLabelStyle}>
                    Barangay
                  </Typography>
                  <Autocomplete
                    onClick={() =>
                      isBarangayOptionDisabled &&
                      notification.warning({
                        message: "Unable to Open Barangay Option",
                        description:
                          "Please select City Municipality that contain Barangays first",
                      })
                    }
                    value={filteredBarangayOptions.find(
                      (data) => data.id === addressInput.barangayId
                    )}
                    disabled={isBarangayOptionDisabled}
                    clearOnBlur={false}
                    options={filteredBarangayOptions}
                    getOptionLabel={(barangay) =>
                      barangay.name && barangay.id ? `${barangay.name}` : ""
                    }
                    size="small"
                    disablePortal
                    noOptionsText="Barangay doesn't exist"
                    disableClearable
                    renderOption={(props, option) => (
                      <li style={{ margin: "0.2rem" }} {...props}>
                        <PlaceIcon sx={{ color: "darkBlue.main" }} />{" "}
                        {option.name}
                      </li>
                    )}
                    onChange={(event, value) => {
                      handleChangeInputValues(value.id, "barangayId", index);
                    }}
                    renderInput={(params) => (
                      <TextField
                        onClick={() =>
                          isBarangayOptionDisabled &&
                          notification.warning({
                            message: "Unable to Open Barangay Option",
                            description:
                              "Please select City Municipality that contain Barangays first",
                          })
                        }
                        size="small"
                        {...params}
                        placeholder="Select barangay..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <Box sx={{ overflow: "hidden", opacity: 0 }}>
                                {params.InputProps.endAdornment}
                              </Box>
                              <InputAdornment
                                sx={{ transform: "translateX(25px)" }}
                                position="end"
                              >
                                <ArrowBackIosNewRoundedIcon
                                  sx={{
                                    transform: "rotate(-90deg)",
                                    color: "lightBlue.main",
                                    pointerEvents: "none",
                                  }}
                                />
                              </InputAdornment>
                            </>
                          ),
                        }}
                        sx={formInputStyle}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid {...gridOptions} container>
                <Grid {...threeGrid} item>
                  <Typography variant="p" sx={inputLabelStyle}>
                    Country
                  </Typography>
                  <Autocomplete
                    clearOnBlur={false}
                    options={countryOptions}
                    getOptionLabel={(country) =>
                      country.name ? country.name : ""
                    }
                    value={countryOptions.find(
                      (country) => country.name === addressInput.country
                    )}
                    size="small"
                    disablePortal
                    noOptionsText="Country doesn't exist"
                    disableClearable
                    renderOption={(props, option) => (
                      <li style={{ margin: "0.2rem" }} {...props}>
                        <PublicIcon
                          sx={{ color: "darkBlue.main", fontSize: "1.3rem" }}
                        />{" "}
                        {option.name}
                      </li>
                    )}
                    onChange={(event, value) => {
                      handleChangeInputValues(value.name, "country", index);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        placeholder="Select country..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <Box sx={{ overflow: "hidden", opacity: 0 }}>
                                {params.InputProps.endAdornment}
                              </Box>
                              <InputAdornment
                                sx={{ transform: "translateX(25px)" }}
                                position="end"
                              >
                                <ArrowBackIosNewRoundedIcon
                                  sx={{
                                    transform: "rotate(-90deg)",
                                    color: "lightBlue.main",
                                    pointerEvents: "none",
                                  }}
                                />
                              </InputAdornment>
                            </>
                          ),
                        }}
                        sx={formInputStyle}
                      />
                    )}
                  />
                </Grid>
                <Grid {...threeGrid} item>
                  <Typography variant="p" sx={inputLabelStyle}>
                    Postal Code
                  </Typography>
                  <TextField
                    value={addressInput.postalCode}
                    onChange={(e) =>
                      handleChangeInputValues(
                        e.target.value,
                        "postalCode",
                        index
                      )
                    }
                    sx={formInputStyle}
                    autoSave={false}
                    placeholder="Enter your postal code..."
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid {...threeGrid} item>
                  <Typography variant="p" sx={inputLabelStyle}>
                    House building number
                  </Typography>
                  <TextField
                    value={addressInput.houseBuildingNumber}
                    onChange={(e) =>
                      handleChangeInputValues(
                        e.target.value,
                        "houseBuildingNumber",
                        index
                      )
                    }
                    sx={formInputStyle}
                    autoSave={false}
                    placeholder="Enter your house building number..."
                    size="small"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid {...gridOptions} container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                  <Typography variant="p" sx={inputLabelStyle}>
                    Street Name
                  </Typography>
                  <TextField
                    value={addressInput.streetName}
                    onChange={(e) =>
                      handleChangeInputValues(
                        e.target.value,
                        "streetName",
                        index
                      )
                    }
                    sx={formInputStyle}
                    autoSave={false}
                    placeholder="Enter your street name..."
                    size="small"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          );
        })}
      <Box
        onClick={handleAddAddressInputs}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          justifyContent: "center",
          width: "100%",
          margin: "0.8rem 0",
          border: "0.1px dashed gray",
          borderRadius: "0.1rem",
          padding: "0.5rem 0",
          cursor: "pointer",
          transition: "0.3s ease-in-out",
          ":hover": {
            border: "0.1px dashed",
            borderColor: "defaultBlue.main",
            color: "defaultBlue.main",
          },
        }}
      >
        <AddIcon />
        <Typography variant="p">Add new address</Typography>
      </Box>
    </Box>
  );
};

export default AddressSelector;
