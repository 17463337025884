import { Typography } from "@mui/material";
import { isDataEmpty } from "./isDataEmpty";

export const showInputErrorMessage = (inputName, isFormDirty, displayName) => {
  if (isDataEmpty(inputName) && isFormDirty) {
    return (
      <Typography
        sx={{ color: "red", fontSize: "0.8rem", marginTop: "0.4rem" }}
        variant="p"
      >
        {displayName} is required
      </Typography>
    );
  }
};
