import { Box, Chip, Divider, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { Badge, Button, Card, Form, Select, TimePicker } from "antd";
import { getWeekDay } from "./data";
import { isDataEmpty } from "utils/isDataEmpty";

const AddSchedule = ({
  savedSchedule,
  scheduleInputs,
  handleRemoveSchedule,
  handleScheduleChange,
  handleAddSchedule,
  filteredWeekOption,
  handleStartTimeChange,
  handleEndTimeChange,
}) => {
  const isScheduleInputsDirty = scheduleInputs.day === null;

  return (
    <Box>
      <Divider textAlign="center" sx={{ margin: "1rem 0" }}>
        <Chip label="Schedule" />
      </Divider>
      <Form.Item label="Schedules">
        <Select
          value={scheduleInputs.day}
          onChange={handleScheduleChange}
          style={{ width: "100%" }}
          placeholder="Please select schedule"
          options={filteredWeekOption}
        />
      </Form.Item>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Form.Item name="startTime" label="Start Time">
            <TimePicker
              changeOnBlur={true}
              placeholder="Start Time"
              use12Hours
              format="h:mm a"
              onChange={handleStartTimeChange}
              className="w-44"
            />
          </Form.Item>
          <Form.Item name="endTime" label="End Time">
            <TimePicker
              changeOnBlur={true}
              placeholder="End Time"
              use12Hours
              format="h:mm a"
              className="w-44"
              onChange={handleEndTimeChange}
            />
          </Form.Item>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem", mt: 12 }}>
          <Button
            disabled={isScheduleInputsDirty}
            type="primary"
            htmlType="button"
            className="submitBtn"
            onClick={handleAddSchedule}
          >
            Add Schedule
          </Button>
        </Box>
      </Box>
      {savedSchedule.length > 0 && (
        <Box
          sx={{
            display: "flex",
            padding: "0.3rem 0.4rem",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid whitesmoke",
            borderRadius: "0.3rem",
            gap: 2,
            mt: 2,
          }}
        >
          {savedSchedule &&
            savedSchedule.map((schedule) => {
              const isStartTimeEmpty = isDataEmpty(schedule.startTimeString);
              const isEndTimeEmpty = isDataEmpty(schedule.endTimeString);
              const hideMediator =
                isEndTimeEmpty || (isStartTimeEmpty && isEndTimeEmpty);

              return (
                <Box
                  sx={{ width: "7rem" }}
                  key={schedule.day}
                  className="group"
                >
                  <Badge.Ribbon
                    className="w-full"
                    text={getWeekDay(schedule.day).label}
                    color="green"
                  >
                    <Card className="pt-5 bg-green-50 relative" size="small">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          mx: 1,
                        }}
                      >
                        <strong>
                          {!isStartTimeEmpty && schedule.startTimeString}
                        </strong>{" "}
                        {!hideMediator && `to`}{" "}
                        <strong>
                          {!isEndTimeEmpty && schedule.endTimeString}
                        </strong>
                      </Box>
                      <Box
                        onClick={() => handleRemoveSchedule(schedule.day)}
                        className="hidden group-hover:block absolute cursor-pointer bg-black/40 rounded-md h-full w-full top-0 left-0 right-0 mx-auto z-50"
                      >
                        <Tooltip
                          title="Remove Schedule"
                          placement="right"
                          arrow
                        >
                          <DeleteIcon
                            sx={{
                              color: "red",
                              fontSize: "1.2rem",
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              margin: "0 auto",
                              zIndex: "100",
                              height: "100%",
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </Card>
                  </Badge.Ribbon>
                </Box>
              );
            })}
        </Box>
      )}
    </Box>
  );
};

export default AddSchedule;
