import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_HEALTHCARE_PROVIDER } from "../../config/connString";
import decodedUserDetails from "utils/decodedUserDetails";

const createHealthcareProvider = createAsyncThunk(
  "healthcareProviders/create-healthcare-provider",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const response = await api.post(URL_HEALTHCARE_PROVIDER, {
        firstName: data.firstName,
        licenseNumber: null,
        licenseExpirationDate: null,
        lastName: data.lastName,
        title: null,
        specialty: null,
        phoneNumber: null,
        email: null,
        clientId: clientId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createHealthcareProvider };
