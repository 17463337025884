import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { userResendConfirmEmail } from "store";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import notification from "components/jkt/global/openNotification";

const ResendConfirmEmailButton = ({
  setIsConfirmEmailResent,
  setShowResendConfirmationBtn,
  userId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleResendConfirmEmail = (e) => {
    e.preventDefault();
    if (
      userResendConfirmEmail.pending().type ===
      "auth/resend-confirmation-email/pending"
    ) {
      setIsLoading(true);
    }
    dispatch(
      userResendConfirmEmail({
        userId: userId,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        setIsConfirmEmailResent(true);
        setTimeout(() => {
          setShowResendConfirmationBtn(false);
          setIsLoading(false);
          window.location.replace("/login");
        }, 1400);
      }
      if (!isFetchSuccessful) {
        notification.error({
          message: "Failed to resent confirmation email",
          description: errorMessagesDescription(
            res?.payload?.response?.data?.errorMessages
          ),
        });
        setIsLoading(false);
      }
    });
  };

  const handleCancelConfirmation = () => {
    window.location.replace("/login");
  };

  return (
    <Box
      sx={{
        marginTop: "0.7rem",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: "0.5rem",
        width: "100%",
        flexWrap: {
          xs: "wrap",
          sm: "wrap",
          md: "unset",
          lg: "unset",
          xl: "unset",
        },
      }}
    >
      <LoadingButton
        loading={isLoading}
        fullWidth
        onClick={handleResendConfirmEmail}
        variant="contained"
        disabled={isLoading}
        loadingPosition="start"
      >
        {isLoading ? "Sending..." : "Yes"}
      </LoadingButton>
      <Button
        disabled={isLoading}
        onClick={handleCancelConfirmation}
        fullWidth
        variant="contained"
        color="error"
      >
        No
      </Button>
    </Box>
  );
};

export default ResendConfirmEmailButton;
