import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useDispatch, useSelector } from "react-redux";
import useCalculateBirthdate from "hooks/useCalculateBirthdate";
import { handleChangeFields } from "store/slice/inputSlice";
import useTranslation from "hooks/useTranslation";

const ThirdSection = () => {
  const dispatch = useDispatch();
  const inputs = useSelector((state) => state.inputs);
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const isPatientCreated = multiStepFormInputs.patientId !== 0;
  const { getAge } = useCalculateBirthdate(inputs.dateOfBirth); //Calculation of age

  const ageSpanStyle = {
    //Span style
    backgroundColor: "#E7EBF4",
    marginRight: "0.5rem",
    padding: "0.3rem",
    fontWeight: "bold",
  };

  return (
    <Grid rowSpacing={2} columnSpacing={4} sx={{ marginTop: 3 }} container>
      <Grid xs={12} sm={12} md={4} lg={4} xl={4} item>
        <Typography
          variant="p"
          sx={{ fontWeight: "bold", color: "darkBlue.main" }}
          className="required"
        >
          {getTranslation("DateOfBirth")}
        </Typography>
        <br />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            slotProps={{
              textField: {
                size: "small",
                placeholder: getGlobalTranslation("Date Format"),
              },
            }}
            onChange={(value) =>
              dispatch(handleChangeFields({ dateOfBirth: value }))
            }
            value={isPatientCreated ? inputs.dateOfBirth : null}
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "auto",
                lg: "auto",
                xl: "auto",
              },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  border: "2px solid",
                  borderColor: "darkBlue.secondary",
                },
              },
              borderRadius: "5px",
              outline: "none",
              marginTop: "0.5rem",
              pointerEvents: "none",
            }}
            readOnly
            format="DD/MM/YYYY"
            inputFormat="DD/MM/YYYY"
            slots={{
              openPickerIcon: CalendarMonthIcon,
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid xs={12} sm={12} md={4} lg={4} xl={4} item>
        <Typography
          variant="p"
          sx={{ fontWeight: "bold", color: "darkBlue.main" }}
        >
          {getTranslation("Patient age")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            gap: "1rem",
            marginTop: "0.9rem",
            fontWeight: "bold",
          }}
        >
          <Typography variant="p">
            <span style={ageSpanStyle}>
              {getAge(inputs.dateOfBirth)?.years || "00"}
            </span>
            {getTranslation("years")}
          </Typography>
          <Typography variant="p">
            <span style={ageSpanStyle}>
              {getAge(inputs.dateOfBirth)?.months || "00"}
            </span>
            {getTranslation("months")}
          </Typography>
          <Typography variant="p">
            <span style={ageSpanStyle}>
              {getAge(inputs.dateOfBirth)?.days || "00"}
            </span>
            {getTranslation("days")}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} sm={12} md={4} lg={4} xl={4} item>
        <Typography
          variant="p"
          sx={{ fontWeight: "bold", color: "darkBlue.main" }}
          className="required"
        >
          {getTranslation("Sex code")}
        </Typography>
        <br />
        <FormControl sx={{ pointerEvents: "none" }}>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            <FormControlLabel
              name="gender"
              value={isPatientCreated && inputs.gender}
              checked={isPatientCreated && inputs.gender === "Male"}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                      {
                        color: "darkBlue.secondary",
                      },
                    "& .MuiSvgIcon-root": {
                      fontSize: 22,
                    },
                  }}
                  readOnly
                />
              }
              label={getTranslation("Male")}
            />
            <FormControlLabel
              name="gender"
              value={isPatientCreated && inputs.gender}
              checked={isPatientCreated && inputs.gender === "Female"}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                      {
                        color: "darkBlue.secondary",
                      },
                    "& .MuiSvgIcon-root": {
                      fontSize: 22,
                    },
                  }}
                  readOnly
                />
              }
              label={getTranslation("Female")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ThirdSection;
