import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_PATIENT } from "../../config/connString";
import decodedUserDetails from "../../utils/decodedUserDetails";
import moment from "moment";
import { isDataEmpty } from "utils/isDataEmpty";

const updatePatient = createAsyncThunk(
  "patients/update-patient",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const response = await api.put(URL_PATIENT + "/" + data.id, {
        id: data?.id,
        firstname: data?.firstname,
        lastname: data?.lastname,
        isActive: data?.isActive,
        middlename: data?.middlename,
        gender: data?.gender,
        dateOfBirth: moment(data?.dateOfBirth).format(
          "YYYY-MM-DDTHH:MM:SS+HH:MM"
        ),
        contactNumber: isDataEmpty(data?.contactNumber)
          ? null
          : data?.contactNumber,
        emails: data?.emails.length === 0 ? null : data?.emails,
        client_ID: clientId,
        medicalRecordNo: isDataEmpty(data?.medicalRecordNo)
          ? null
          : data?.medicalRecordNo,
        cardNumber: isDataEmpty(data?.cardNumber) ? null : data?.cardNumber,
        governmentId: isDataEmpty(data?.governmentId)
          ? null
          : data?.governmentId,
        phAddresses: data?.phAddresses,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updatePatient };
