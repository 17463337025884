import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_ICD10CODES } from "../../config/connString";

const createICD10Codes = createAsyncThunk(
  "icd10codes/create-icd10codes",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_ICD10CODES, {
        icD10_Code: data.icD10_Code,
        description: data.description,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createICD10Codes };
