import { useEffect, useCallback, useState } from "react";
import { Modal, Form, Input, Button, Popconfirm, Select } from "antd";
import { useDispatch } from "react-redux";
import { createBarangay, deleteBarangay, updateBarangay } from "store";
import useCheckPermission from "hooks/useCheckPermission";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import notification from "components/jkt/global/openNotification";

const CreateUpdateBarangay = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
    cityMunicipalityData,
  } = props;
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToDelete = handleVerifyPermission(
    "Administration:Barangay:Delete"
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const LoadUpdateData = useCallback(() => {
    form.setFieldsValue({
      name: idForUpdate.name,
      cityMunicipalityId: idForUpdate.cityMunicipalityId,
    });
  }, [form, idForUpdate]);

  useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const onFinish = (values) => {
    if (isForUpdate) {
      if (
        updateBarangay.pending().type === "barangay/update-barangay/pending"
      ) {
        setLoading(true);
      }
      dispatch(updateBarangay([idForUpdate.id, values])).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Updated Barangay",
            description: `Successfully updated ${values.name}`,
          });
        }
        if (!val.payload?.success) {
          notification.success({
            message: "Updated Barangay",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
        }
      });
    } else {
      if (
        createBarangay.pending().type === "barangay/create-barangay/pending"
      ) {
        setLoading(true);
      }
      dispatch(createBarangay(values)).then((val) => {
        if (val.payload.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Created Barangay",
            description: `Successfully created ${values.name}`,
          });
        }
        if (!val.payload.success) {
          notification.error({
            message: "Failed to Update Barangay",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    errorInfo.errorFields.map((item) => {
      return notification.error({
        message: "Required Field",
        description: item?.errors[0].props.children,
      });
    });
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (deleteBarangay.pending().type === "barangay/delete-barangay/pending") {
      setLoading(true);
    }
    dispatch(deleteBarangay(idForUpdate.id)).then((val) => {
      if (val.payload?.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted Barangay",
          description: `Successfully deleted ${idForUpdate.name}`,
        });
      }
      if (!val.payload?.success) {
        setLoading(false);
        notification.error({
          message: "Failed to Delete Barangay",
          description: errorMessagesDescription(
            val.payload?.response.data.errorMessages
          ),
        });
      }
    });
  };

  const cityMunicipalitiesOptions = cityMunicipalityData.map((element) => {
    return { label: element.name, value: element.id };
  });

  return (
    <Modal
      open={openCreateUpdate}
      title={isForUpdate ? "Update Barangay" : "Create Barangay"}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="barangay"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        {/*  Name */}
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: <i>{"Please input Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        {/* City Municipality */}
        <Form.Item
          label="City Municipality"
          name="cityMunicipalityId"
          rules={[
            {
              required: true,
              message: <i>{"Please input City Municipality."}</i>,
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            virtual={false}
            allowClear
            loading={loading}
            options={cityMunicipalitiesOptions}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder={"City Municipality"}
          />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {accessRightsToDelete && isForUpdate ? (
            <Popconfirm
              title="Delete the barangay"
              description="Are you sure to delete?"
              onConfirm={handleClickDelete}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loading, className: "submitBtn" }}
            >
              <Button loading={loading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateBarangay;
