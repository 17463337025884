import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const createClientType = createAsyncThunk(
  "clientTypes/create-client-type",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_CLIENT, {
        typeName: data.typeName,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createClientType };
