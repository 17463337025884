import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const addUserClaims = createAsyncThunk("user/add-user-claims", async (data) => {
  try {
    const api = useAxios();
    const response = await api.post(URL_USER + "/" + data.userId + "/claims", {
      newClaims: data.permissionKeys,
    });
    return response.data;
  } catch (error) {
    return error;
  }
});

export { addUserClaims };
