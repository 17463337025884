import { Grid, Typography } from "@mui/material";
import LoadingSpinner from "components/jkt/global/loadingSpinner";
import useTranslation from "hooks/useTranslation";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientInfo } from "store";

const FirstSection = () => {
  const [clientDetails, setClientDetails] = useState({});
  const { getTranslation } = useTranslation("RequestPage");
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state.orderData);
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const showLoadingSpinner =
    orderData.isOrderDataForUpdate &&
    savedLabtests.savedLabtestsIdAndSpecimen.length === 0;

  // Fetching of client details
  useEffect(() => {
    dispatch(getClientInfo()).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        setClientDetails(res.payload.data);
      }
    });
  }, [dispatch]);

  return (
    <Grid container>
      <Grid
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
          marginBottom: "0.5rem",
        }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        item
      >
        <Typography
          variant="p"
          sx={{
            fontWeight: "bold",
            color: "#294992",
            fontSize: { xs: "1rem", sm: "1rem" },
          }}
        >
          {getTranslation("Client name")}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            backgroundColor: "grayishBlue.secondary",
            padding: "0.3rem",
            width: "max-content",
            fontSize: { xs: "1rem", sm: "1rem", md: "1.3rem" },
          }}
        >
          {clientDetails?.clientName}
        </Typography>
      </Grid>
      <Grid xs={6} sm={6} md={2} lg={2} xl={2} item>
        <Typography
          variant="p"
          sx={{
            fontWeight: "bold",
            color: "darkBlue.main",
            fontSize: { xs: "1rem", sm: "1rem" },
          }}
        >
          {getTranslation("Client no")}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            backgroundColor:
              clientDetails?.clientCode && "grayishBlue.secondary",
            padding: "0.3rem",
            width: "max-content",
            fontSize: { xs: "1rem", sm: "1rem", md: "1.3rem" },
          }}
        >
          {clientDetails?.clientCode}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
        xs={12}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        item
      >
        <Typography
          variant="p"
          sx={{
            fontWeight: "bold",
            color: "darkBlue.main",
            fontSize: { xs: "1rem", sm: "1rem" },
          }}
        >
          {getTranslation("Client name")}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            backgroundColor:
              clientDetails?.clientName && "grayishBlue.secondary",
            padding: "0.3rem",
            width: "max-content",
            fontSize: { xs: "1rem", sm: "1rem", md: "1.3rem" },
          }}
        >
          {clientDetails?.clientName}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          flexDirection: "column",
        }}
        xs={6}
        sm={6}
        md={2}
        lg={2}
        xl={2}
        item
      ></Grid>
      <LoadingSpinner open={showLoadingSpinner} />
    </Grid>
  );
};

export default FirstSection;
