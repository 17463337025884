import React from "react";
import { DatePicker } from "antd";
import useTranslation from "hooks/useTranslation";

const DateFilter = ({ handleChangeDate }) => {
  //Date filtering, user can select a start and end date
  const { RangePicker } = DatePicker;
  const { getTranslation } = useTranslation("TestOrderDataTable");

  return (
    <RangePicker
      onChange={handleChangeDate}
      format={"MM/DD/YYYY"}
      placeholder={[getTranslation("Start date"), getTranslation("End date")]}
    />
  );
};

export default DateFilter;
