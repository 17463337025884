import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  Zoom,
  createFilterOptions,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TestModal from "./labtestModal/testModal";
import { useDispatch, useSelector } from "react-redux";
import { getLabTestRequirements, getSampleCollectorLookUp } from "store";
import { Modal } from "antd";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import getLabtestRequirementsRecursively from "components/jkt/testRequest/proxies/getLabtestRequirementsRecursively";
import { handleChangeSavedTestInfo } from "store/slice/savedTestInputsInfo";
import notification from "components/jkt/global/openNotification";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import { handleChangeSampleCollectorFields } from "store/slice/sampleCollectorInputSlice";
import EditAdditionalPatientInfoModal from "./labtestModal/editAdditionalPatientInfoModal";
import CreateUpdateSampleCollectorModal from "./createUpdateSampleCollectorModal";
import useTranslation from "hooks/useTranslation";
import { parsedClientDetails } from "utils/parsedClientDetails";

const SecondSection = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const savedTestInputsInfo = useSelector((state) => state.savedTestInputsInfo);
  const [inputValues, setInputValues] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sampleCollectorData, setSampleCollectorData] = useState([]);
  const [isSampleCollectorNew, setIsSampleCollectorNew] = useState(false);
  const [selectedSampleCollectorId, setSelectedSampleCollectorId] =
    useState(null);
  const savedInputs = useSelector((state) => state.savedInputs);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const orderData = useSelector((state) => state.orderData);
  const [showEditCollectorModal, setShowEditCollectorModal] = useState(false);
  const specimenInputs = useSelector((state) => state.specimenInputs);
  const disableSampleCollectorInput = multiStepFormInputs.patientSpecimens.some(
    (specimen) => specimen.requiresBloodDraw === true
  );
  const [searchCollector, setSearchCollector] = useState(
    !localStorage.getItem("searchCollector")
      ? ""
      : localStorage.getItem("searchCollector")
  );
  const filter = createFilterOptions();
  const dispatch = useDispatch();
  const { clientDetails } = parsedClientDetails();
  const { getTranslation } = useTranslation("RequestPage");
  const isOrderStatusPickedup = orderData.orderStatus === "pickedup";

  const pStyle = {
    //p element style
    color: "#2A4993",
  };
  const spanStyle = {
    //span element style
    color: "black",
    fontWeight: "600",
  };

  const handleOpenAdditionalInfo = (selectedTest) => {
    //For showing additional patient information modal
    setSelectedProfile(selectedTest); //Save the data of selected labtest in state
    if (
      getLabTestRequirements.pending().type ===
      "labTest/get-lab-test-requirements/pending"
    ) {
      setIsLoading(true); //Check if the request is processing
    }
    dispatch(
      getLabTestRequirements({
        id: selectedTest.id,
      }) //Get the details of the labtest that have been selected
    ).then((res) => {
      const childLabTestRequirements = getLabtestRequirementsRecursively(
        res?.payload?.data
      ); //Recrursively get the labtest requirements that is inside of package/panel/profile

      if (res.payload?.success) {
        //Check if the it is fetched successfully
        if (selectedTest.type !== "Test") {
          //Check if the type of the selected labtest is not Test
          setInputValues(
            //Store the labtest requirement to a state, that will be used for additional patient information
            childLabTestRequirements.map((req, index) => {
              const inputValuesId = index + 1;
              const existingData = multiStepFormInputs.testOrderDetails
                .find((detail) => detail.testId === selectedTest.id)
                ?.patientTestRequirementDatas[0]?.patientRequirementDataDetails.find(
                  (inputData) => inputData.dataKey === inputValuesId
                );
              return {
                id: inputValuesId,
                value: existingData ? existingData.dataValue : "",
                description: req.requirementDetails,
              };
            })
          );

          dispatch(
            handleChangeSavedTestInfo({
              fetchedInputFields: childLabTestRequirements,
            })
          ); //Saved the data to fetched input fields, that is used to display additional patient information in second section
        } else {
          //If the selected labtest is a type of test get its labtest requirements
          setInputValues(
            res.payload.data?.testDetails?.labTestRequirements?.map((req) => {
              const existingData = multiStepFormInputs.testOrderDetails
                .find((detail) => detail.testId === selectedTest.id)
                ?.patientTestRequirementDatas[0]?.patientRequirementDataDetails.find(
                  (inputData) => inputData.dataKey === req.id
                );
              return {
                id: req.id,
                value: existingData ? existingData.dataValue : "",
                description: req.requirementDetails,
              };
            })
          ); //Get the regular response
          dispatch(
            handleChangeSavedTestInfo({
              fetchedInputFields: res.payload.data,
            })
          );
        }
        setIsOpenModal(true);
        setIsLoading(false);
      }
      if (!res.payload?.success) {
        //If the request fails, display a toast warning
        notification.error({
          message: "Additional Info",
          description:
            "Failed to open additional information, please try again",
        });
        setIsLoading(false);
      }
    });
  };

  const handleCloseModal = () => {
    //Close additional patient information modal
    setIsOpenModal(false);
  };

  const handleCloseSampleCollectorModal = () => {
    //Close sample collector modal
    setShowEditCollectorModal(false);
    setIsSampleCollectorNew(false);
    setSelectedSampleCollectorId(null);
  };

  useEffect(() => {
    if (!localStorage.getItem("searchCollector")) {
      setSearchCollector("");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("searchCollector", searchCollector || "");
  }, [searchCollector]);

  const handleSelectClient = (e) => {
    //Handler for selecting where to draw blood
    dispatch(
      handleChangeMultiFields({
        clientWillDrawSpecimen: e.target.value === "true" ? true : false,
      })
    );
  };

  const handleOpenSampleCollectorModal = () => {
    //For showing sample collector form modal
    if (specimenInputs.collectorId === null) {
      return setIsSampleCollectorNew(true);
    } else {
      return setShowEditCollectorModal(true);
    }
  };

  const handleChange = (e) => {
    //Get the sample collector search dropdown input field
    setSearchCollector(e.target.value);
  };

  const handleFetchSampleCollectorLookUp = () => {
    //Fetch sample collector options
    dispatch(getSampleCollectorLookUp()).then((res) => {
      if (
        res.type === "sample-collectors/get-sample-collectors-look-up/fulfilled"
      ) {
        setSampleCollectorData(res.payload.data.items);
      }
    });
  };

  const handleSelectCollectorData = (selectedCollector) => {
    //Function for selecting sample collector id
    dispatch(
      handleChangeMultiFields({
        patientSpecimens: multiStepFormInputs.patientSpecimens.map(
          (specimen) => ({
            ...specimen,
            collectorId: selectedCollector.id,
          })
        ),
      })
    );
    dispatch(
      handleChangeSavedFields({
        sampleCollectorName: `${selectedCollector.firstName} ${
          selectedCollector.lastName === null ? "" : selectedCollector.lastName
        }`,
        sampleCollectorId: selectedCollector.id,
      })
    );
    dispatch(
      handleChangeSampleCollectorFields({
        collectorType: selectedCollector.collectorType,
        id: selectedCollector.id,
        sampleCollectorFullName: selectedCollector.displayName,
      })
    );
    setSelectedSampleCollectorId(selectedCollector.id);
    setSearchCollector(`${selectedCollector.displayName}`);
  };

  const handleClearSampleCollector = () => {
    //For clearing sample collector search input field
    setSearchCollector("");
    setSelectedSampleCollectorId(null);
    dispatch(
      handleChangeSavedFields({
        sampleCollectorId: null,
      })
    );
    dispatch(
      handleChangeSampleCollectorFields({
        id: 0,
        sampleCollectorFullName: "",
        collectorType: "Phlebotomist",
        firstName: "",
        lastName: "",
      })
    );
  };

  useEffect(() => {
    handleFetchSampleCollectorLookUp();
    // eslint-disable-next-line
  }, [searchCollector, dispatch]);

  useEffect(() => {
    if (searchCollector === "") {
      //If sample collector search input field is clear, remove the data in sample collector form input field
      dispatch(
        handleChangeSavedFields({
          sampleCollectorId: null,
        })
      );
      dispatch(
        handleChangeSampleCollectorFields({
          id: 0,
          sampleCollectorFullName: "",
          firstName: "",
          lastName: "",
          collectorType: "Phlebotomist",
        })
      );
    }
  }, [dispatch, searchCollector]);

  return (
    <Grid sx={{ marginTop: "2rem" }} container>
      <Grid
        sx={{
          marginTop: "1rem",
          marginRight: multiStepFormInputs.clientWillDrawSpecimen
            ? "0"
            : "auto",
        }}
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
      >
        <FormControl>
          <Typography
            sx={{
              color: "grayishBlue.light",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
            variant="p"
            className="required"
          >
            {getTranslation("Please select who will draw the blood")}
          </Typography>
          <RadioGroup
            value={multiStepFormInputs.clientWillDrawSpecimen}
            onChange={handleSelectClient}
          >
            <FormControlLabel
              disabled={isOrderStatusPickedup}
              value="true"
              checked={multiStepFormInputs.clientWillDrawSpecimen === true}
              control={<Radio />}
              label={`${clientDetails?.clientName}`}
            />
            <FormControlLabel
              disabled={isOrderStatusPickedup}
              value="false"
              control={<Radio />}
              label="Laboratorium ABC"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid
        sx={{
          marginTop: "1rem",
          marginRight: "auto",
          display: multiStepFormInputs.clientWillDrawSpecimen ? "flex" : "none",
          flexDirection: "column",
          gap: "0.7rem",
        }}
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
      >
        <Typography
          sx={{
            color: "grayishBlue.light",
            fontWeight: "600",
            fontSize: "0.9rem",
          }}
          variant="p"
        >
          {getTranslation("Specimen Collected By")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "0.3rem",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Autocomplete
            disableClearable
            disabled={!disableSampleCollectorInput || isOrderStatusPickedup}
            clearOnBlur
            size="small"
            value={searchCollector}
            options={sampleCollectorData}
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "15rem",
                xl: "15rem",
              },
            }}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              if (option.displayName) {
                return option.displayName;
              }
            }}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setTimeout(() => {
                  handleOpenSampleCollectorModal(event, newValue);
                });
              } else if (newValue && newValue.inputValue) {
                handleOpenSampleCollectorModal();
              } else {
                handleSelectCollectorData(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  displayName: `${getTranslation("Add new sample collector")}*`,
                });
              }
              return filtered;
            }}
            renderOption={(props, collector) => (
              <>
                <li
                  style={{
                    margin: "0.2rem",
                    backgroundColor:
                      collector.displayName.includes("Add new") ||
                      collector.displayName.includes("Tambah petugas")
                        ? "#c1dbff"
                        : "",
                  }}
                  {...props}
                >
                  {collector.displayName}
                </li>
              </>
            )}
            renderInput={(params) => (
              <TextField
                disabled={!disableSampleCollectorInput || isOrderStatusPickedup}
                error={savedTestInputsInfo.isPhleboInputEmpty}
                size="small"
                onChange={handleChange}
                value={searchCollector}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "2px solid",
                      borderColor: "darkBlue.secondary",
                    },
                  },
                  borderRadius: "5px",
                  outline: "none",
                }}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <Box sx={{ overflow: "hidden", opacity: 0 }}>
                        {params.InputProps.endAdornment}
                      </Box>
                      <InputAdornment
                        sx={{ transform: "translateX(30px)" }}
                        position="end"
                      >
                        {!searchCollector ? (
                          <SearchOutlinedIcon
                            sx={{
                              color: "lightBlue.main",
                              pointerEvents: "none",
                            }}
                          />
                        ) : (
                          <CloseRoundedIcon
                            onClick={handleClearSampleCollector}
                            sx={{
                              fontSize: "1rem",
                              cursor: "pointer",
                              ":hover": {
                                color: "black",
                              },
                            }}
                          />
                        )}
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
          />
          <Tooltip
            title={
              searchCollector === ""
                ? getTranslation("Add new sample collector")
                : getTranslation("Edit sample collector")
            }
            placement="right-end"
            TransitionComponent={Zoom}
            arrow
          >
            <Box
              sx={{
                backgroundColor: "darkBlue.main",
                padding: "0.7rem",
                height: "2.55rem",
                borderRadius: "0.3rem",
                cursor: "pointer",
                transition: "0.3s ease-in-out",
                ":hover": {
                  backgroundColor: "darkBlue.secondary",
                },
              }}
              onClick={handleOpenSampleCollectorModal}
            >
              {savedInputs.sampleCollectorId === null ? (
                <PersonAddOutlinedIcon
                  sx={{
                    fontSize: "20px",
                    marginBottom: "0.1rem",
                    color: "white",
                    transform: "translateY(-3px)",
                  }}
                />
              ) : (
                <EditOutlinedIcon
                  sx={{
                    fontSize: "20px",
                    marginBottom: "0.3rem",
                    color: "white",
                    transform: "translateY(-3px)",
                  }}
                />
              )}
            </Box>
          </Tooltip>
        </Box>
      </Grid>
      <Grid
        item
        sx={{ marginTop: "1rem" }}
        xs={12}
        sm={12}
        md={12}
        lg={3}
        xl={3}
      >
        <Typography
          sx={{
            color: "grayishBlue.light",
            fontWeight: "600",
            fontSize: "0.9rem",
          }}
          variant="p"
          className="required"
        >
          {getTranslation("Additional patient information")}
        </Typography>
        {savedTestInputsInfo.savedTestInfo.length > 0 ? (
          savedTestInputsInfo.savedTestInfo.map((test) => (
            <div
              key={test.id}
              style={{
                backgroundColor: "#ebeff8",
                opacity:
                  test.forUpdate && isOrderStatusPickedup ? "50%" : "100%",
                marginTop: "0.5rem",
                padding: "0.6rem",
                pointerEvents:
                  test.forUpdate && isOrderStatusPickedup ? "none" : "auto",
                cursor:
                  test.forUpdate && isOrderStatusPickedup
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={() => handleOpenAdditionalInfo(test)}
            >
              <p style={pStyle}>
                For <span style={spanStyle}>{test.name}</span> test
              </p>
            </div>
          ))
        ) : (
          <Box
            sx={{
              backgroundColor: "grayishBlue.main",
              marginTop: "0.5rem",
              padding: "0.6rem",
            }}
          >
            <p style={pStyle}>
              <span style={spanStyle}>{getTranslation("None")}</span>
            </p>
          </Box>
        )}
      </Grid>
      <TestModal isOpen={isOpenModal}>
        <EditAdditionalPatientInfoModal
          selectedProfile={selectedProfile}
          inputValues={inputValues}
          setInputValues={setInputValues}
          handleCloseModal={handleCloseModal}
        />
      </TestModal>
      <Modal
        closable
        onCancel={handleCloseSampleCollectorModal}
        open={isSampleCollectorNew || showEditCollectorModal}
        width={500}
        footer={null}
      >
        <CreateUpdateSampleCollectorModal
          showEditCollectorModal={showEditCollectorModal}
          setSearchCollector={setSearchCollector}
          handleFetchSampleCollectorLookUp={handleFetchSampleCollectorLookUp}
          handleCloseModal={handleCloseSampleCollectorModal}
          selectedSampleCollectorId={selectedSampleCollectorId}
        />
      </Modal>
      <Backdrop
        open={isLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default SecondSection;
