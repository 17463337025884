import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const getTestOrderByPatientId = createAsyncThunk(
  "test-orders/get-test-order-by-patientId",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          search: "",
          pageSize: 0,
          pageNumber: 1,
          sortField: "orderDate",
          sortDirection: 0,
        },
      };
      const response = await api.get(
        `${URL_TEST_ORDERS}/patientId/${data.patientId}`,
        config
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getTestOrderByPatientId };
