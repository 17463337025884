import { createSlice } from "@reduxjs/toolkit";
import { getPatientInfo } from "api/patient/getPatientInfo";

const patientInfo = createSlice({
  name: "patientInfo",
  initialState: {
    data: [],
    isLoading: false,
    successMessage: [],
    error: null,
    errorMessage: [],
  },
  extraReducers(builder) {
    //GET PATIENT INFO
    builder.addCase(getPatientInfo.pending, (state) => {
      state.data = [];
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(getPatientInfo.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.data = action.payload.data;
        state.isLoading = false;
        state.error = false;
        state.errorMessage = state.errorMessage ? state.errorMessage : [];
        state.successMessage = state.successMessage ? state.successMessage : [];
      } else {
        state.data = [];
        state.isLoading = false;
        state.error = true;
        state.errorMessage = action?.payload?.response?.errorMessages;
        state.successMessage = [];
      }
    });
    builder.addCase(getPatientInfo.rejected, (state) => {
      state.data = [];
      state.isLoading = false;
      state.error = true;
      state.errorMessage = ["Fetch error!"];
      state.successMessage = [];
    });
  },
});

export const patientInfoReducer = patientInfo.reducer;
