import React from "react";
import PrintSessionSummaryHeader from "./printSessionSummaryHeader";

const PrintComponent = ({ children, clientCode, clientName }) => {
  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>
            <PrintSessionSummaryHeader
              clientCode={clientCode}
              clientName={clientName}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{children}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default PrintComponent;
