const useCalculateBirthdate = () => {
  //Calculation of age
  const getAge = (dateOfBirth) => {
    if (dateOfBirth === null) return;
    let today = new Date().getTime();
    let otherDate = new Date(dateOfBirth).getTime();
    let difference = Math.abs(today - otherDate);

    let days = Math.floor(difference / (1000 * 3600 * 24));
    let years = Math.floor(days / 365);
    days -= years * 365;
    let months = Math.floor(days / 31);
    days -= months * 31;

    return { years: years, months: months, days: days };
  };

  return { getAge };
};

export default useCalculateBirthdate;
