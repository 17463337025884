import { createSlice } from "@reduxjs/toolkit";
import { getAllUsers } from "api/user/getAllUser";
import { createUser } from "api/user/createUser";
import { updateUser } from "api/user/updateUser";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    successMessage: [],
    error: null,
    errorMessage: [],
    data: [],
  },
  reducers: {
    clearMessageUser: (state) => {
      state.isLoading = false;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    },
    refreshUser: (state) => {
      state.isLoading = false;
      state.successMessage = [];
      state.error = null;
      state.errorMessage = [];
      state.data = [];
    },
  },
  extraReducers(builder) {
    //GET ALL USERS
    builder.addCase(getAllUsers.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = state.errorMessage ? state.errorMessage : [];
      state.successMessage = state.successMessage ? state.successMessage : [];
      state.data = [];
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.successMessage = state.successMessage ? state.successMessage : [];
        state.data = action.payload.data.items;
      } else {
        state.isLoading = false;
        state.error = true;
        state.errorMessage = action.payload.response.errorMessages;
        state.successMessage = [];
        state.data = [];
      }
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = ["fetch error!"];
      state.successMessage = [];
      state.data = [];
    });

    //CREATE USER
    builder.addCase(createUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.error = false;
        state.errorMessage = [];
        state.successMessage = [action.payload.successMessage];
      } else {
        state.isLoading = false;
        state.error = true;
        state.errorMessage = [action.payload.response.data.errorMessages];
        state.successMessage = [];
      }
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action.payload.errorMessages;
      state.successMessage = [];
    });

    //UPDATE USER
    builder.addCase(updateUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.error = false;
        state.errorMessage = [];
        state.successMessage = [action.payload.successMessage];
      } else {
        state.isLoading = false;
        state.error = true;
        state.errorMessage = [action.payload.response.data.errorMessages];
        state.successMessage = [];
      }
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action.payload.errorMessages;
      state.successMessage = [];
    });
  },
});
export const { clearMessageUser, refreshUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
