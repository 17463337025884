const getLabtestRequirementsRecursively = (data) => {
  let labTestRequirementsData = [];
  // eslint-disable-next-line
  data?.child?.map((item) => {
    if (item?.testDetails?.labTestRequirements) {
      // eslint-disable-next-line
      item?.testDetails?.labTestRequirements.map((test, index) => {
        labTestRequirementsData.push({
          id: index + 1,
          labTestInfoId: item?.testDetails?.labTestId,
          requirementType: test.requirementType,
          isRequired: test.isRequired,
          requirementDetails: test.requirementDetails,
        });
      });
    }

    if (item?.child && item?.child?.length > 0) {
      const childLabTestRequirements = getLabtestRequirementsRecursively(item);
      labTestRequirementsData.push(...childLabTestRequirements);
    }
  });

  return labTestRequirementsData;
};

export default getLabtestRequirementsRecursively;
