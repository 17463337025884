import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const getTestOrderByOrderNo = createAsyncThunk(
  "test-orders/get-test-order-by-order-no",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(
        URL_TEST_ORDERS + "/orderNo/" + data.orderNo
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getTestOrderByOrderNo };
