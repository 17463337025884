import { useSelector } from "react-redux";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DomainIcon from "@mui/icons-material/Domain";
import GroupedAppbarItemsButton from "./groupedAppbarItemsButton";
import {
  setExpandAdminSidebarItems,
  setExpandLaboratorySidebarItems,
} from "store/slice/appBarSlice";
import useTranslation from "hooks/useTranslation";

const MenuItemRoute = () => {
  const { expandAdminSidebarItems, expandLaboratorySidebarItems, openSidebar } =
    useSelector((state) => state.appBarSettings);
  const { claims } = useSelector((state) => state.auth);
  const { getTranslation } = useTranslation("SidebarItems");

  const showGroupedAppbarItems = (groupName) => {
    const filteredClaims = claims?.filter((data) =>
      data?.permissionKey?.includes(groupName)
    );
    const isUserAuthorized = filteredClaims?.some((claim) => claim?.isGranted);

    if (!isUserAuthorized) {
      return false;
    }
    return true;
  };

  return (
    <>
      {/* Administration */}
      {showGroupedAppbarItems("Administration") && (
        <GroupedAppbarItemsButton
          expandItem={expandAdminSidebarItems}
          onExpandSidebarItems={setExpandAdminSidebarItems}
          icon={<AdminPanelSettingsIcon />}
          isSidebarOpen={openSidebar}
          tooltipTitle={getTranslation("Administration")}
        />
      )}

      {/* Laboratory */}
      {showGroupedAppbarItems("Laboratory") && (
        <GroupedAppbarItemsButton
          expandItem={expandLaboratorySidebarItems}
          onExpandSidebarItems={setExpandLaboratorySidebarItems}
          icon={<DomainIcon />}
          isSidebarOpen={openSidebar}
          tooltipTitle={getTranslation("Laboratory")}
        />
      )}
    </>
  );
};
export default MenuItemRoute;
