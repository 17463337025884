import { createSlice } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    open: true,
  },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});
export const { setOpen } = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
