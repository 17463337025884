import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SPECIAL_HANDLING } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "../../utils/decodedUserDetails";

const createSpecialHandling = createAsyncThunk(
  "special-handling/create-special-handling",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();
      const response = await api.post(URL_SPECIAL_HANDLING, {
        clientId: clientId,
        instructions: data.instructions,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createSpecialHandling };
