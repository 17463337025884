import { createSlice } from "@reduxjs/toolkit";
import { getClientInfo } from "api/client/getClientInfo";

const clientInfoStorage =
  JSON.parse(localStorage.getItem("clientInfo")) === null
    ? []
    : JSON.parse(localStorage.getItem("clientInfo"));

const clientInfoSlice = createSlice({
  name: "clientInfo",
  initialState: {
    data: clientInfoStorage ? clientInfoStorage : [],
    isLoading: false,
    successMessage: [],
    error: null,
    errorMessage: [],
  },

  extraReducers(builder) {
    builder.addCase(getClientInfo.pending, (state) => {
      state.data = [];
      state.isLoading = true;
      state.successMessage = [];
      state.error = false;
      state.errorMessage = [];
    });
    builder.addCase(getClientInfo.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.data = action.payload.data;
        state.isLoading = false;
        state.successMessage = state.successMessage ? state.successMessage : [];
        state.error = false;
        state.errorMessage = state.errorMessage ? state.errorMessage : [];
      } else {
        state.data = [];
        state.isLoading = false;
        state.successMessage = [];
        state.error = true;
        state.errorMessage = action?.payload?.response?.errorMessages;
      }
    });
    builder.addCase(getClientInfo.rejected, (state) => {
      state.data = [];
      state.isLoading = false;
      state.successMessage = [];
      state.error = true;
      state.errorMessage = ["Fetch error!"];
    });
  },
});

export const clientInfoReducer = clientInfoSlice.reducer;
