import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_PROVINCES } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const updateProvince = createAsyncThunk(
  "provinces/update-provinces",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.put(URL_PROVINCES + "/" + data[0], {
        id: data[0],
        name: data[1].name,
        countryId: data[1].countryId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateProvince };
