import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  physicianCode: 0,
  physicianName: "",
  sampleCollectorName: "",
  sampleCollectorType: "",
  sampleCollectorId: null,
  searchPatientName: "",
  searchMedicalRecordNo: "",
  refetchData: false,
  selectedLanguage: "IN",
  selectedPatientId: null,
  isPatientNull: false,
  isClinicalDiagnosisEmpty: false,
  isTestProfileEmpty: false,
  isSampleCollectorEmpty: false,
  isCollectionSiteEmpty: false,
  isSpecimenQuantityEmpty: false,
};

const savedInputSlice = createSlice({
  name: "savedInputs",
  initialState,
  reducers: {
    handleChangeSavedFields: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { handleChangeSavedFields } = savedInputSlice.actions;
export const savedInputReducer = savedInputSlice.reducer;
