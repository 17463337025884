import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_REGISTER_QRCODE } from "../../config/connString";

const getQrCodeById = createAsyncThunk(
  "qr-code/get-qrcode-by-id",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(URL_REGISTER_QRCODE + "/" + data.qrcode);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getQrCodeById };
