import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getAllUsers = createAsyncThunk("user/get-all-users", async (data) => {
  try {
    const api = useAxios();

    const response = await api.post(`${URL_USER}/get-all`, {
      search: data.search,
      userStatus: data.userStatus,
      clientId: data.clientId,
      roles: data.roles,
      pageSize: data.pageSize,
      pageNumber: data.pageNumber,
      sortField: data.sortField,
      sortDirection: data.sortDirection,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
});

export { getAllUsers };
