import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Fab,
  Grid,
  Paper,
  Skeleton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import QrReader from "modern-react-qr-reader";
import useSound from "use-sound";
import qrSound from "sounds/144418__zerolagtime__store-scanner-beep.mp3";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch } from "react-redux";
import QrCodeFormInputModal from "./qrCodeFormInputModal";
import QRCode from "react-qr-code";
import { LoadingButton } from "@mui/lab";
import { updateOrderPickup } from "store";

const ScanOrder = (props) => {
  const {
    item,
    handleBack,
    handlePickup,
    selectedData,
    handleOpenSnackbarNotification,
    testOrderCount,
    patientSpecimensCount,
    testOrders,
    patientSpecimens,
    isTestOrderFetching,
  } = props;
  const [qrdata, setQrData] = React.useState(null);
  const [play, { stop }] = useSound(qrSound);
  const [openTest, setOpenTest] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpenQr, setisOpenQr] = React.useState(false);
  const [openSpecimen, setOpenSpecimen] = React.useState(true);
  const [openDia, setOpenDia] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [showQrCodeFormModal, setShowQrCodeFormModal] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setOpenDia(false);
    setisOpenQr(false);
    setQrData(null);
    stop();
  }, [stop]);

  const handleShowQrCodeFormModal = () => {
    setShowQrCodeFormModal(true);
  };

  const handleCloseQrCodeFormModal = () => {
    setShowQrCodeFormModal(false);
  };

  const handleClickTest = () => {
    setOpenTest(!openTest);
  };
  const handleClickSpecimen = () => {
    setOpenSpecimen(!openSpecimen);
  };
  const handleCancelPickup = () => {
    setOpenDia(false);
    setisOpenQr(false);
    setQrData(null);
    stop();
  };

  const handleConfirmPickup = () => {
    if (
      updateOrderPickup.pending().type ===
      "test-orders/update-test-order-pickup/pending"
    ) {
      setIsLoading(true);
    }
    dispatch(
      updateOrderPickup({
        id: item?.id,
        qr: qrdata,
        comment: comment === "" ? null : comment,
      })
    ).then((updateItem) => {
      const isFetchSuccessful = updateItem?.payload?.success;
      if (isFetchSuccessful) {
        setOpenDia(false);
        setisOpenQr(false);
        setQrData(null);
        stop();
        const notificationToastTimeout = setTimeout(() => {
          setIsLoading(false);
          handleOpenSnackbarNotification(
            `${updateItem?.payload?.successMessage}`,
            "success"
          );
          handlePickup();
          window.scroll(0, 0);
        }, 300);
        return () => clearTimeout(notificationToastTimeout);
      }
      if (!isFetchSuccessful) {
        handleOpenSnackbarNotification(
          `${updateItem?.payload?.response?.data?.errorMessages[0]}`,
          "error"
        );
        setisOpenQr(false);
        setQrData(null);
        stop();
        setOpenDia(false);
        setIsLoading(false);
      }
    });
  };

  return (
    <Box style={{ height: "auto", marginBottom: "7rem" }}>
      <Dialog open={openDia}>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.5rem",
            marginTop: "1rem",
          }}
        >
          <QRCode size={70} value={qrdata + ""} />
          <Typography sx={{ color: "green", fontSize: "1.2rem" }} variant="p">
            {qrdata}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <LoadingButton
            sx={{ width: "100%" }}
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
            disabled={isLoading}
            autoFocus
            onClick={handleConfirmPickup}
          >
            {isLoading ? "Confirming..." : "Confirm"}
          </LoadingButton>
          <Button disabled={isLoading} onClick={handleCancelPickup}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "end",
          gap: "0.5rem",
        }}
      >
        <Tooltip title="Manually type qr code" placement="left" arrow>
          <Fab
            sx={{
              position: "absolute",
              bottom: 80,
              right: 25,
              height: "1rem",
              width: "2.3rem",
              marginTop: "5rem",
              marginBottom: "0.7rem",
              opacity: "0.5",
              ":hover": {
                opacity: 1,
              },
            }}
            color="primary"
            onClick={handleShowQrCodeFormModal}
          >
            <BorderColorIcon sx={{ fontSize: "1rem" }} />
          </Fab>
        </Tooltip>
        <Tooltip title="Scan qr code" placement="left" arrow>
          <Fab
            sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
              marginTop: "5rem",
              marginBottom: "0.7rem",
            }}
            color="secondary"
            onClick={() => {
              setisOpenQr(!isOpenQr);
              window.scroll(0, 0);
              if (!isOpenQr) {
                setQrData(null);
              }
            }}
          >
            {isOpenQr ? "Hide" : <QrCodeScannerIcon />}
          </Fab>
        </Tooltip>
      </Box>
      <Fab
        size="small"
        variant="extended"
        style={{ position: "absolute", bottom: 24, left: 16, margin: "1rem 0" }}
        color="primary"
        onClick={handleBack}
      >
        <ArrowBackIcon sx={{ mr: 1 }} />
        Back
      </Fab>
      <Toolbar />
      <Paper sx={{ marginLeft: "auto", marginRight: "auto" }} elevation={24}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              className="bg-blue-500"
            >
              <Grid item xs={3}>
                <Typography variant="h6" color={"white"}>
                  Order #: {item?.id}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12}>
            {isOpenQr ? (
              <Grid item xs={12} md={12}>
                <QrReader
                  facingMode={"environment"}
                  onScan={(result, error) => {
                    if (result) {
                      if (!qrdata) {
                        play();
                        setQrData(result);
                        setOpenDia(true);
                      }
                    }
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Box component={"form"}>
              <Grid container spacing={1}>
                <Grid sx={{ marginTop: "0.8rem" }} item xs={12} md={12}>
                  <Typography
                    sx={{
                      paddingLeft: "0.6rem",
                      fontSize: {
                        xs: "1.2rem",
                        sm: "1.2rem",
                        md: "1.5rem",
                        lg: "1.5rem",
                        xl: "1.5rem",
                      },
                    }}
                    variant="h5"
                  >
                    Order Status: <strong>{item?.orderStatus}</strong>
                  </Typography>
                  {!item?.isMinimalOrder && (
                    <>
                      <Typography
                        sx={{
                          paddingLeft: "0.6rem",
                          fontSize: {
                            xs: "1.2rem",
                            sm: "1.2rem",
                            md: "1.5rem",
                            lg: "1.5rem",
                            xl: "1.5rem",
                          },
                        }}
                        variant="h5"
                      >
                        Patient:{" "}
                        <strong>
                          {item?.patient.firstname} {item?.patient.lastname}
                        </strong>
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "0.6rem",
                          fontSize: {
                            xs: "1rem",
                            sm: "1rem",
                            md: "1.5rem",
                            lg: "1.5rem",
                            xl: "1.5rem",
                          },
                        }}
                        variant="h5"
                      >
                        DOB:{" "}
                        <strong>
                          {moment(item?.patient?.dateOfBirth).format(
                            "MMMM DD,YYYY"
                          )}
                        </strong>
                      </Typography>
                    </>
                  )}
                  {item?.isMinimalOrder && (
                    <Typography
                      sx={{
                        paddingLeft: "0.6rem",
                        fontSize: {
                          xs: "1.2rem",
                          sm: "1.2rem",
                          md: "1.5rem",
                          lg: "1.5rem",
                          xl: "1.5rem",
                        },
                        fontWeight: "bold",
                      }}
                      variant="h5"
                    >
                      LRF Pickup Request
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <List>
                    {/* LIST OF test */}
                    {!isTestOrderFetching ? (
                      <ListItemButton onClick={handleClickTest}>
                        <ListItemText
                          primary={`No. of tests - ${testOrderCount}`}
                        />
                        {openTest ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    ) : (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "2.3rem", mx: "1rem" }}
                      />
                    )}
                    <Collapse in={openTest} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {testOrders?.map((testItem) => {
                          return (
                            <Typography
                              key={testItem?.id}
                              sx={{
                                paddingLeft: "2.5rem",
                                fontWeight: "semibold",
                                color: "green",
                              }}
                              variant="body1"
                            >
                              &#x2022; {testItem?.labTest?.name}
                            </Typography>
                          );
                        })}
                      </List>
                    </Collapse>
                    {/* LIST OF Specimen */}
                    {!isTestOrderFetching ? (
                      <ListItemButton onClick={handleClickSpecimen}>
                        <ListItemText
                          primary={`No. of specimen - ${
                            patientSpecimens.length > 0
                              ? patientSpecimensCount
                              : "0"
                          }`}
                        />
                        {openSpecimen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    ) : (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "2.3rem", mx: "1rem" }}
                      />
                    )}
                    <Collapse in={openSpecimen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {patientSpecimens?.map((specimenItem) => {
                          return (
                            <Typography
                              key={specimenItem?.id}
                              sx={{
                                paddingLeft: "2.5rem",
                                fontWeight: "semibold",
                                color: "green",
                                display: "flex",
                                alignItems: "center",
                                gap: "0.7rem",
                                flexWrap: "wrap",
                              }}
                              variant="body1"
                            >
                              &#x2022; {specimenItem?.specimen?.name}
                              <Typography
                                sx={{ fontWeight: "bold", color: "black" }}
                                variant="body1"
                              >
                                - {specimenItem?.specimenQuantity}{" "}
                                {specimenItem?.specimen?.unitOfMeasure}
                              </Typography>
                            </Typography>
                          );
                        })}
                      </List>
                    </Collapse>
                  </List>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-textarea"
                    placeholder="Special Instruction or Comment"
                    multiline
                    rows={4}
                    fullWidth
                    variant="filled"
                    sx={{ p: 2 }}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <QrCodeFormInputModal
        refetchData={handlePickup}
        handleOpenSnackbarNotification={handleOpenSnackbarNotification}
        comment={comment}
        selectedData={selectedData}
        handleClose={handleCloseQrCodeFormModal}
        open={showQrCodeFormModal}
      />
    </Box>
  );
};
export default ScanOrder;
