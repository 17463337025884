import { createSlice } from "@reduxjs/toolkit";
import { getSpecialHandlingLookUp } from "api/specialInstruction/getSpecialHandlingLookUp";

const specialHandlingSlice = createSlice({
  name: "specialHandling",
  initialState: {
    savedHandling: [],
    data: [],
    isLoading: false,
    successMessage: [],
    error: null,
    errorMessage: [],
  },
  reducers: {
    handleChangeHandling: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers(builder) {
    //SPECIAL HANDLING LOOK-UP
    builder.addCase(getSpecialHandlingLookUp.pending, (state) => {
      state.data = [];
      state.isLoading = true;
      state.successMessage = [];
      state.error = false;
      state.errorMessage = [];
    });
    builder.addCase(getSpecialHandlingLookUp.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.data = action.payload.data.items;
        state.isLoading = false;
        state.successMessage = state.successMessage ? state.successMessage : [];
        state.error = false;
        state.errorMessage = state.errorMessage ? state.errorMessage : [];
      } else {
        state.data = [];
        state.isLoading = false;
        state.successMessage = [];
        state.error = true;
        state.errorMessage = action.payload.response.errorMessages;
      }
    });
    builder.addCase(getSpecialHandlingLookUp.rejected, (state) => {
      state.data = [];
      state.isLoading = false;
      state.successMessage = [];
      state.error = true;
      state.errorMessage = ["Fetch error!"];
    });
  },
});

export const { handleChangeHandling } = specialHandlingSlice.actions;
export const specialHandlingReducer = specialHandlingSlice.reducer;
