import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT_INFO } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const deleteClient = createAsyncThunk("clients/delete-client", async (data) => {
  try {
    const api = useAxios();
    const response = await api.delete(URL_CLIENT_INFO + "/" + data);
    return response.data;
  } catch (error) {
    return error;
  }
});

export { deleteClient };
