import { Box, Modal, TextField, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateOrderPickup } from "store";
import { LoadingButton } from "@mui/lab";

const QrCodeFormInputModal = ({
  comment,
  handleClose,
  open,
  selectedData,
  handleOpenSnackbarNotification,
  refetchData,
}) => {
  const [qrCodeInput, setQrCodeInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const disableSubmitButton = qrCodeInput.length === 0;
  const handleQrCodeInputChange = (e) => {
    setQrCodeInput(e.target.value);
  };

  const handleSubmitQrCode = () => {
    if (
      updateOrderPickup.pending().type ===
      "test-orders/update-test-order-pickup/pending"
    ) {
      setIsLoading(true);
    }
    dispatch(
      updateOrderPickup({
        id: selectedData.id,
        qr: qrCodeInput,
        comment: comment === "" ? null : comment,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.success;

      if (isFetchSuccessful) {
        setIsLoading(false);
        setQrCodeInput("");
        handleClose();
        const notificationToastTimeout = setTimeout(() => {
          handleOpenSnackbarNotification(
            `${res?.payload?.successMessage}`,
            "success"
          );
          refetchData();
          window.scroll(0, 0);
        }, 500);

        return () => clearTimeout(notificationToastTimeout);
      }

      if (!isFetchSuccessful) {
        handleOpenSnackbarNotification(
          `${res?.payload?.response?.data?.errorMessages[0]}`,
          "error"
        );
        handleClose();
        setIsLoading(false);
      }
    });
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            px: "1rem",
            py: "0.9rem",
            borderRadius: "0.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
            border: "1px solid black",
            width: 400,
            mx: 2,
          }}
        >
          <TextField
            onChange={handleQrCodeInputChange}
            value={qrCodeInput}
            autoFocus={true}
            label="Enter qr code"
            variant="filled"
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.1rem" }}>
            <Typography sx={{ fontSize: "0.7rem" }} variant="p">
              Special Instruction or Comment:
            </Typography>
            <Typography
              sx={{ fontSize: "0.8rem", fontWeight: "semibold" }}
              variant="p"
            >
              {comment === null || comment === undefined || comment === ""
                ? "N/A"
                : comment}
            </Typography>
          </Box>
          <LoadingButton
            disabled={disableSubmitButton || isLoading}
            loading={isLoading}
            onClick={handleSubmitQrCode}
            variant="contained"
            type="primary"
          >
            {isLoading ? "Submitting..." : "Submit"}
          </LoadingButton>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default QrCodeFormInputModal;
