import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getRoles } from "store";

const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const RolesFilter = ({ handleSelectRole }) => {
  const [roleOptions, setRoleOptions] = useState([]);
  const dispatch = useDispatch();

  const handleFetchRoleData = () => {
    dispatch(getRoles()).then((res) => {
      const formattedRoleData = res?.payload?.data?.map((role) => ({
        label: role.name,
        value: role.name,
      }));
      setRoleOptions(formattedRoleData);
    });
  };

  useEffect(() => {
    handleFetchRoleData();
    // eslint-disable-next-line
  }, []);

  return (
    <Select
      className="ml-2 w-48"
      allowClear
      virtual={false}
      showSearch
      placeholder="Select or search role"
      optionFilterProp="children"
      mode="multiple"
      filterOption={filterOption}
      onChange={handleSelectRole}
      options={roleOptions}
      maxTagCount="responsive"
    />
  );
};

export default RolesFilter;
