import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_PH_ADD } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const getPhilippineAddressTypes = createAsyncThunk(
  "philippine-address/get-address-type",
  async () => {
    try {
      const api = useAxios();
      const response = await api.get(URL_PH_ADD + "/address-types");
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getPhilippineAddressTypes };
