import ScienceIcon from "@mui/icons-material/Science";
import GroupsIcon from "@mui/icons-material/Groups";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PublicIcon from "@mui/icons-material/Public";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LandscapeIcon from "@mui/icons-material/Landscape";
import Groups3Icon from "@mui/icons-material/Groups3";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonIcon from "@mui/icons-material/Person";
import DatasetIcon from "@mui/icons-material/Dataset";
import SummarizeIcon from "@mui/icons-material/Summarize";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Biotech } from "@mui/icons-material";

export const appBarItems = {
  "Laboratory:TestOrder:Read": {
    routeName: "Test Order",
    routeLink: "/test-orders",
    routeIcon: <FactCheckIcon />,
  },
  "Laboratory:TestOrderSummary:Read": {
    routeName: "Test Order Summary",
    routeLink: "/session-summary",
    routeIcon: <ReceiptIcon />,
  },
  "Laboratory:Patient:Read": {
    routeName: "Patient",
    routeLink: "/patient",
    routeIcon: <PersonIcon />,
  },
  "Administration:Country:Read": {
    routeName: "Country",
    routeLink: "/country",
    routeIcon: <PublicIcon />,
  },
  "Administration:Province:Read": {
    routeName: "Province",
    routeLink: "/province",
    routeIcon: <LandscapeIcon />,
  },
  "Administration:RegisterQRCode:Read": {
    routeName: "Qr Code Registration",
    routeLink: "/qr-registration",
    routeIcon: <QrCodeScannerIcon />,
  },
  "Administration:CityMunicipality:Read": {
    routeName: "City Municipality",
    routeLink: "/city-municipality",
    routeIcon: <LocationCityIcon />,
  },
  "Administration:Barangay:Read": {
    routeName: "Barangay",
    routeLink: "/barangay",
    routeIcon: <AccountBalanceIcon />,
  },
  "Administration:Client:Read": {
    routeName: "Client",
    routeLink: "/client",
    routeIcon: <GroupsIcon />,
  },
  "Administration:ClientType:Read": {
    routeName: "Client Type",
    routeLink: "/client-type",
    routeIcon: <Groups3Icon />,
  },
  "Laboratory:LabTest:Read": {
    routeName: "Laboratory Test",
    routeLink: "/labtest",
    routeIcon: <Biotech />,
  },
  "Laboratory:HealthcareProvider:Read": {
    routeName: "Healthcare Provider",
    routeLink: "/healthcare-provider",
    routeIcon: <HealthAndSafetyIcon />,
  },
  "Administration:LabDepartment:Read": {
    routeName: "Department",
    routeLink: "/department",
    routeIcon: <AccountTreeIcon />,
  },
  "Laboratory:ICD10Code:Read": {
    routeName: "ICD",
    routeLink: "/icd-codes",
    routeIcon: <DatasetIcon />,
  },
  "Laboratory:SampleCollector:Read": {
    routeName: "Sample Collector",
    routeLink: "/sample-collector",
    routeIcon: <SummarizeIcon />,
  },
  "Laboratory:Specimen:Read": {
    routeName: "Specimen",
    routeLink: "/specimen",
    routeIcon: <ScienceIcon />,
  },
};
