const getDuplicatedSpecimenNames = (data) => {
  let specimenNames = [];
  // eslint-disable-next-line
  data?.child?.map((item) => {
    if (item?.testDetails?.labTestSpecimens) {
      // eslint-disable-next-line
      item?.testDetails?.labTestSpecimens?.map((specimenData) => {
        specimenNames.push({
          specimenId: specimenData.id,
          specimenName: specimenData.name,
          requiresSampleOrigin: specimenData?.requiresSampleOrigin,
          requiresBloodDraw: specimenData?.requiresBloodDraw,
        });
      });
    }
    if (item?.child && item?.child?.length > 0) {
      const childSpecimens = getDuplicatedSpecimenNames(item);
      specimenNames.push(...childSpecimens);
    }
  });

  return specimenNames;
};

export default getDuplicatedSpecimenNames;
