import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SPECIMEN } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const updateSpecimen = createAsyncThunk(
  "specimen/update-specimen",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.put(URL_SPECIMEN + "/" + data[0], {
        id: data[0],
        name: data[1].name,
        container: data[1].container,
        measurementType: data[1].measurementType,
        size: data[1].size,
        unitOfMeasure: data[1].unitOfMeasure,
        requiresBloodDraw: data[1].requiresBloodDraw ? true : false,
        requiresSampleOrigin: data[1].requiresSampleOrigin ? true : false,
        testVolumeOrSizeRequirements: data[1].testVolumeOrSizeRequirements,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateSpecimen };
