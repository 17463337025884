import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import unAuthorized from "assests/unauthorized.svg";

export default function UnAuthorizedPage() {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center flex-col min-h-screen fixed w-full bg-[#F4F7FF] md:gap-2">
      <img className="w-80 h-80" src={unAuthorized} alt="UnAuthorized" />
      <h1 className="text-2xl md:text-6xl text-[#113569]">UNAUTHORIZED</h1>
      <p className="text-md md:text-xl">
        Sorry, you do not have permission to access this page. Please contact
        the administrator for assistance.
      </p>
      <Button
        startIcon={<ArrowBackIcon />}
        variant="contained"
        onClick={() => navigate("/test-request")}
        color="primary"
        sx={{ mt: 2 }}
      >
        Return
      </Button>
    </div>
  );
}
