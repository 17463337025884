import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const deleteUserByRole = createAsyncThunk(
  "user/delete-user-by-role",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(
        URL_USER + "/" + data.userId + "/roles" + data.role,
        {
          roleName: data.roleName,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { deleteUserByRole };
