import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/jkt/global/menuBar";
import Toolbar from "@mui/material/Toolbar";
import GetAllSessionSummary from "components/jkt/admin/sessionSummary/getAllSessionSummary";
import { theme } from "components/jkt/global/theme";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";
import useTranslation from "hooks/useTranslation";

const SessionSummaryPage = () => {
  const { getTranslation } = useTranslation("SidebarItems");

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={getTranslation("Test Order Summary")} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100%",
            minHeight: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <GetAllSessionSummary />
        </Box>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default SessionSummaryPage;
