import * as React from "react";
import {
  Grid,
  Button,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Alert,
  Stack,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { userChangePassword, clearMessage, getClientInfoById } from "store";
import { useNavigate } from "react-router-dom";
import notification from "components/jkt/global/openNotification";
import decodedUserDetails from "utils/decodedUserDetails";

const ChangePasswordForm = () => {
  const { register, handleSubmit, watch } = useForm({ mode: "all" });
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const userNeedsToChangePassword =
    decodedUserDetails()?.ForcePasswordChange === "True";
  const newPassword = watch("newPassword") || "";
  const confirmPassword = watch("confirmPassword") || "";
  const passwordsUnmatched = confirmPassword !== newPassword;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, error, errorMessage, successMessage } = useSelector(
    (state) => state.auth
  );

  React.useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);
  const handleMouseDownCurrentPassword = (event) => {
    event.preventDefault();
  };
  const handleTogglePasswordVisiblity = () =>
    setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const fetchClientInfo = (userClientId) => {
    dispatch(getClientInfoById({ id: userClientId })).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        localStorage.setItem("clInf", JSON.stringify(res.payload.data));
      }
    });
  };

  const submitForm = (data) => {
    dispatch(
      userChangePassword({
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      })
    ).then((val) => {
      const isPostSuccessful = val?.payload?.success;

      if (isPostSuccessful) {
        fetchClientInfo(val?.payload?.data?.user?.clientId);
        notification.success({
          message: "Change Password",
          description: "Your password has been updated!",
        });
        navigate("/test-request");
      }
    });
  };

  const errorMessageLists = errorMessage.map((value) => {
    return (
      <Alert variant="outlined" severity="error">
        {value}
      </Alert>
    );
  });
  const successMessageLists = successMessage.map((value) => {
    return (
      <Alert variant="outlined" severity="success">
        {value}
      </Alert>
    );
  });

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={3}
      sx={{
        width: { xs: "auto", sm: "auto", md: "auto", lg: "33rem", xl: "33rem" },
        px: 2,
      }}
      component="form"
      onSubmit={handleSubmit(submitForm)}
    >
      <Typography
        component="h1"
        variant="h4"
        sx={{
          fontWeight: "bold",
          width: "max-content",
          mx: "auto",
          my: 2,
          fontSize: {
            xs: "1.5rem",
            sm: "1.5rem",
            md: "1.8rem",
            lg: "1.8rem",
            xl: "1.8rem",
          },
          position: "relative",
          "::before": {
            content: "''",
            position: "absolute",
            left: 0,
            right: 0,
            height: "4px",
            bottom: "-4px",
            width: "20%",
            backgroundColor: "defaultBlue.main",
            borderRadius: "1rem",
            margin: "0 auto",
          },
        }}
      >
        Change Password
      </Typography>

      {error && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          {errorMessageLists}
        </Stack>
      )}
      {successMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          {successMessageLists}
        </Stack>
      )}
      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-currentpassword">
          Current Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-currentpassword"
          type={showCurrentPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowCurrentPassword}
                onMouseDown={handleMouseDownCurrentPassword}
                edge="end"
              >
                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Current Password"
          {...register("currentPassword")}
          required
          disabled={isLoading}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-newpassword">
          New Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-newpassword"
          type={showNewPassword ? "text" : "password"}
          name="newPassword"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePasswordVisiblity}
                onMouseDown={handleMouseDownNewPassword}
                edge="end"
              >
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="New Password"
          {...register("newPassword")}
          required
          disabled={isLoading}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel
          error={passwordsUnmatched}
          htmlFor="outlined-adornment-confirmpassword"
        >
          Confirm New Password
        </InputLabel>
        <OutlinedInput
          error={passwordsUnmatched}
          id="outlined-adornment-confirmpassword"
          type={showNewPassword ? "text" : "password"}
          label="Confirm New Password"
          required
          {...register("confirmPassword")}
          disabled={isLoading}
        />
      </FormControl>
      {passwordsUnmatched && (
        <Box sx={{ textAlign: "start", padding: "0.3rem 0" }}>
          <Typography sx={{ color: "red" }} variant="p">
            Passwords doesn't match
          </Typography>
        </Box>
      )}
      {userNeedsToChangePassword && (
        <Box>
          <p className="mt-4 text-center px-3 italic">
            For enhanced security, please change your password before
            proceeding.
          </p>
        </Box>
      )}
      <Grid container>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          disabled={
            newPassword.trim().length === 0 || passwordsUnmatched || isLoading
          }
        >
          {isLoading ? "Changing Password..." : "Change Password"}
        </Button>
      </Grid>
    </Grid>
  );
};
export default ChangePasswordForm;
