import {
  ConfigProvider,
  Space,
  Table,
  Typography as AntdTypography,
} from "antd";
import { Typography as MuiTypography } from "@mui/material";
import { EmptyData } from "components/mnl/global/emptyData";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getLabWorkloads } from "store";
import { useCurrentHeight } from "utils/getScreenSize";
import StatusSelectFilter from "./statusSelectFilter";
import ClientSelectFilter from "./clientSelectFilter";
import { sortDataAlphabetically } from "utils/sortDataAlphabetically";
import DepartmentSelectFilter from "./departmentSelectFilter";
import { InfoCircleOutlined } from "@ant-design/icons";
import LabWorkloadDetailsModal from "./labWorkloadDetailsModal";

const { Text } = AntdTypography;

const GetAllLabWorkload = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState();
  const [selectedStatuses, setSelectedStatuses] = useState([1]);
  const [selectedClientIds, setSelectedClientIds] = useState([]);
  const [labWorkloadDetailsConfig, setLabWorkloadDetailsConfig] = useState({
    orderedByClientsData: [],
    openLabWorkloadDetailsModal: false,
  });
  const [selectedLabDepartmentId, setSelectedLabDepartmentId] = useState(null);
  const [labWorkloadData, setLabWorkloadData] = useState([]);
  const emptyLabWorkloadData = labWorkloadData?.length === 0;

  const fetchWorkloadDatas = useCallback(() => {
    if (
      getLabWorkloads.pending().type === "test-orders/get-lab-workloads/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(
      getLabWorkloads({
        statuses: selectedStatuses,
        clientIds: selectedClientIds,
        departmentId: selectedLabDepartmentId,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        setIsLoading(false);
        setLabWorkloadData(
          sortDataAlphabetically(res?.payload?.data, "department")
        );
      }
    });
  }, [dispatch, selectedStatuses, selectedClientIds, selectedLabDepartmentId]);

  useEffect(() => {
    fetchWorkloadDatas();
  }, [dispatch, fetchWorkloadDatas]);

  const renderEmptyData = () => (
    <EmptyData
      description="No Lab Workload to be displayed"
      action={() => {
        return;
      }}
      renderButton={false}
    />
  );

  const renderLabtestName = (text, record) => {
    return (
      <MuiTypography
        sx={{
          color: "darkBlue.light",
          fontWeight: "bold",
          fontSize: "0.88rem",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        variant="p"
        onClick={() =>
          handleOpenLabWorkloadDetailsModal(record?.orderedByClients)
        }
      >
        {text} <InfoCircleOutlined style={{ marginLeft: 2, marginTop: 2 }} />
      </MuiTypography>
    );
  };

  const handleOpenLabWorkloadDetailsModal = (orderedByClients) => {
    setLabWorkloadDetailsConfig({
      orderedByClientsData: orderedByClients,
      openLabWorkloadDetailsModal: true,
    });
  };

  const handleCloseLabWorkloadDetailsModal = () => {
    setLabWorkloadDetailsConfig({
      orderedByClientsData: [],
      openLabWorkloadDetailsModal: false,
    });
  };

  const columns = [
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: 180,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => renderLabtestName(text, record),
    },
    {
      title: "Lab Workload",
      dataIndex: "labWorkLoad",
      key: "labWorkLoad",
    },
  ];

  const handleSelectStatus = (value) => {
    setSelectedStatuses(value);
  };

  const handleSelectClientId = (value) => {
    setSelectedClientIds(value);
  };

  const handleSelectLabDepartmentId = (value) => {
    setSelectedLabDepartmentId(value);
  };

  const tableSummary = (pageData) => {
    let totalLabWorkload = 0;

    pageData.forEach(({ labWorkLoad }) => {
      totalLabWorkload += labWorkLoad;
    });

    return (
      <>
        {!emptyLabWorkloadData && (
          <Table.Summary.Row>
            {Array.from({ length: columns.length - 1 }).map((index) => (
              <Table.Summary.Cell key={index}>
                <Text />
              </Table.Summary.Cell>
            ))}
            <Table.Summary.Cell>
              <Text>
                Total: <strong>{totalLabWorkload}</strong>
              </Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      </>
    );
  };

  return (
    <div>
      <Space style={{ width: "100%" }}>
        <DepartmentSelectFilter
          selectedLabDepartmentId={selectedLabDepartmentId}
          handleSelectLabDepartmentId={handleSelectLabDepartmentId}
        />
        <StatusSelectFilter
          selectedStatuses={selectedStatuses}
          handleSelectStatus={handleSelectStatus}
        />
        <ClientSelectFilter
          selectedClientIds={selectedClientIds}
          handleSelectClientId={handleSelectClientId}
        />
      </Space>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          loading={isLoading}
          dataSource={labWorkloadData}
          size="small"
          bordered={true}
          columns={columns}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
          }}
          summary={tableSummary}
        />
      </ConfigProvider>
      <LabWorkloadDetailsModal
        orderedByClientsData={labWorkloadDetailsConfig.orderedByClientsData}
        open={labWorkloadDetailsConfig.openLabWorkloadDetailsModal}
        onCancel={handleCloseLabWorkloadDetailsModal}
      />
    </div>
  );
};

export default GetAllLabWorkload;
