export const convertedFullName = (fullName) => {
  const convertedFullNameToArr = fullName
    ?.split(" ")
    .filter((name) => name !== "");

  const firstName =
    convertedFullNameToArr.length > 2
      ? convertedFullNameToArr
          .filter((name) => name !== "")
          .slice(0, -1)
          .toString()
          .replace(/[^\w\s]/gi, " ")
      : convertedFullNameToArr.filter((name) => name !== "")[0];
  const lastName =
    convertedFullNameToArr.length > 1
      ? convertedFullNameToArr.filter((name) => name !== "")[
          convertedFullNameToArr.length - 1
        ]
      : null;

  return { firstName, lastName };
};
