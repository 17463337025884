import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const getUserPermissions = createAsyncThunk(
  "rolesClaims/get-user-permissions",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(
        URL_USER + "/" + data.userId + "/get-user-permissions"
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getUserPermissions };
