import { createTheme } from "@mui/material";
const theme = createTheme({
  palette: {
    background: {
      default: "#EAEEF7",
    },
    First: {
      main: "#294992",
    },
    Second: {
      main: "#7494C3",
    },
    Third: {
      main: "#F3F7FF",
    },
    darkBlue: {
      main: "#2A4993",
      secondary: "#7393C2",
    },
    grayishBlue: {
      light: "#294993",
      main: "#ebeff8",
      secondary: "#E7EBF4",
    },
    lightBlue: {
      main: "#7393C2",
      secondary: "#a5cbff",
      light: "#7ea1e3",
    },
    softBlue: {
      main: "#a5cbff",
      secondary: "#1F75F9",
    },
    defaultBlue: {
      main: "#1976D2",
    },
  },
});
export { theme };
