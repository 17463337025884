import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_SAMPLE_COLLECTORS } from "../../config/connString";
import decodedUserDetails from "../../utils/decodedUserDetails";

const getSampleCollectorLookUp = createAsyncThunk(
  "sample-collectors/get-sample-collectors-look-up",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();
      const config = {
        params: {
          clientId: clientId,
          search: "",
          pageSize: 90,
          pageNumber: 1,
        },
      };
      const response = await api.get(
        URL_SAMPLE_COLLECTORS + "/sample-collectors-lookup",
        config
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getSampleCollectorLookUp };
