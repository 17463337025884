import React, { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styles from "components/jkt/testRequest/styles/createPatientModalStyles/styles.module.css";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  createSampleCollector,
  updateSampleCollector,
  getSampleCollectorTypes,
} from "store";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import useFetchSpecificSampleCollector from "components/jkt/testRequest/proxies/useFetchSpecificSampleCollector";
import { handleChangeSampleCollectorFields } from "store/slice/sampleCollectorInputSlice";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import notification from "components/jkt/global/openNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import useTranslation from "hooks/useTranslation";
import { convertedFullName } from "utils/convertedFullName";
import { checkedData } from "utils/checkedData";
import { Switch } from "antd";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";

const CreateUpdateSampleCollectorModal = ({
  handleCloseModal,
  handleFetchSampleCollectorLookUp,
  setSearchCollector,
  selectedSampleCollectorId,
}) => {
  const dispatch = useDispatch();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sampleCollectorTypes, setSampleCollectorTypes] = useState([]);
  const { savedInputs, multiStepFormInputs, sampleCollectorInputs } =
    useSelector((state) => ({
      savedInputs: state.savedInputs,
      multiStepFormInputs: state.multiStepFormInputs,
      sampleCollectorInputs: state.sampleCollectorInputs,
    }));
  const isSpecimensRequiresBloodDraw =
    multiStepFormInputs.patientSpecimens.some(
      (specimen) => specimen.requiresBloodDraw === true
    );
  const { getTranslation, getGlobalTranslation } = useTranslation(
    "SampleCollectorPage"
  );

  const isSampleCollectorForUpdate = savedInputs.sampleCollectorId !== null; //Check if the sample collector is for update
  useFetchSpecificSampleCollector(selectedSampleCollectorId);

  const inputHasError =
    sampleCollectorInputs.sampleCollectorFullName.trim().length === 0;

  const handleCloseFormModal = () => {
    //For closing sample collector form modal
    handleCloseModal();
    setIsFormDirty(false);
    if (!isSpecimensRequiresBloodDraw) {
      dispatch(
        handleChangeSampleCollectorFields({
          id: 0,
          firstName: "",
          sampleCollectorFullName: "",
          collectorType: "Phlebotomist",
          lastName: "",
          isActive: true,
        })
      );
    }
  };

  const handleSubmit = (e) => {
    //For submitting sample collector data
    e.preventDefault();
    if (inputHasError) {
      return setIsFormDirty(true); //Check if the input fields are empty
    }
    if (isSampleCollectorForUpdate) {
      handleUpdateSampleCollector(); //If it is for update, then perform the update sample collector function
    } else {
      if (
        createSampleCollector.pending().type ===
        "sample-collectors/create-sample-collectors/pending"
      ) {
        setIsLoading(true); //Check if the function is processing
      }
      const { firstName, lastName } = convertedFullName(
        sampleCollectorInputs.sampleCollectorFullName
      );
      dispatch(
        createSampleCollector({
          firstName: firstName,
          lastName: lastName,
          collectorType: sampleCollectorInputs.collectorType,
          isActive: sampleCollectorInputs.isActive,
        })
      ).then((res) => {
        if (res.payload.success) {
          //Check the create action if it is successful
          const responseData = res?.payload?.data;
          const sampleCollectorNameRes = `${
            responseData?.firstName
          } ${checkedData(responseData?.lastName)}`;

          dispatch(
            handleChangeSampleCollectorFields({
              id: 0,
              sampleCollectorFullName: "",
              firstName: "",
              lastName: "",
              collectorType: "Phlebotomist",
              isActive: true,
            })
          );
          dispatch(
            handleChangeSavedFields({
              refetchData: !savedInputs.refetchData,
            })
          );

          notification.success({
            message: "Created Sample Collector",
            description: `Successfully added ${sampleCollectorNameRes} to the list!`,
          });

          handleFetchSampleCollectorLookUp();
          setIsFormDirty(false);
          handleCloseModal();
          setIsLoading(false);
        }

        if (!res.payload.success) {
          setIsLoading(false);
          notification.error({
            message: "Failed to create Sample Collector",
            description: errorMessagesDescription(
              res?.payload?.result?.value?.errorMessages
            ),
          });
        }
      });
    }
  };

  const handleUpdateSampleCollector = () => {
    //For updating sample collector
    if (inputHasError) {
      return setIsFormDirty(true); //Check if the input fields are empty
    }

    if (
      updateSampleCollector.pending().type ===
      "sample-collectors/update-sample-collector/pending"
    ) {
      setIsLoading(true);
    }
    const { firstName, lastName } = convertedFullName(
      sampleCollectorInputs.sampleCollectorFullName
    );

    dispatch(
      updateSampleCollector({
        firstName: firstName,
        id: sampleCollectorInputs.id,
        collectorType: sampleCollectorInputs.collectorType,
        isActive: sampleCollectorInputs.isActive,
        lastName: lastName,
      })
    ).then((res) => {
      const responseData = res?.payload?.data;
      const sampleCollectorNameRes = `${responseData?.firstName} ${checkedData(
        responseData?.lastName
      )}`;
      const sampleCollectorId = responseData?.id;
      const isSampleCollectorInactive = !responseData?.isActive;

      if (res.payload.success) {
        handleFetchSampleCollectorLookUp();
        dispatch(
          handleChangeSampleCollectorFields({
            id: isSampleCollectorInactive ? 0 : responseData?.id,
            firstName: isSampleCollectorInactive ? "" : responseData?.firstName,
            lastName: isSampleCollectorInactive ? "" : responseData?.lastName,
            collectorType: responseData?.collectorType,
            isActive: responseData?.isActive,
          })
        );
        setSearchCollector(
          isSampleCollectorInactive ? "" : `${sampleCollectorNameRes}`
        );
        handleCloseModal();
        setIsLoading(false);
        dispatch(
          handleChangeSavedFields({
            sampleCollectorId: isSampleCollectorInactive
              ? null
              : responseData?.id,
            refetchData: !savedInputs.refetchData,
          })
        );

        if (isSampleCollectorInactive) {
          localStorage.setItem("searchCollector", "");
          dispatch(
            handleChangeMultiFields({
              ...multiStepFormInputs,
              patientSpecimens: multiStepFormInputs.patientSpecimens.map(
                (specimen) =>
                  sampleCollectorId === specimen.collectorId
                    ? {
                        ...specimen,
                        collectorId: "",
                      }
                    : specimen
              ),
            })
          );
        }

        notification.success({
          message: "Updated Sample Collector",
          description: `Successfully updated ${sampleCollectorInputs.firstName} ${sampleCollectorInputs.lastName}`,
        });
      }

      if (!res.payload.success) {
        setIsLoading(false);
        notification.error({
          message: "Failed to update Sample Collector",
          description: res?.payload?.result?.value?.errorMessages,
        });
      }
    });
  };

  const showErrorMessage = (inputName, displayName) => {
    if (!inputName && isFormDirty) {
      return (
        <Typography
          sx={{ color: "red", fontSize: "0.8rem", marginTop: "0.4rem" }}
          variant="p"
        >
          {displayName} {getGlobalTranslation("is required")}
        </Typography>
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    dispatch(getSampleCollectorTypes()).then((res) => {
      setSampleCollectorTypes(res?.payload);
    });
  }, [dispatch]);

  return (
    <Box
      component="form"
      sx={{
        flexGrow: 1,
        padding: 3,
        width: { xs: "100%", sm: "100%", md: "100%", lg: "auto", xl: "auto" },
        height: { xs: "100%", sm: "100%", md: "100%", lg: "auto", xl: "auto" },
      }}
    >
      <Grid sx={{ marginTop: "0.5rem" }} spacing={2}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider sx={{ marginBottom: "1rem" }} textAlign="center">
            <Typography
              variant="h6"
              display="block"
              sx={{ fontWeight: "500", color: "darkBlue.main" }}
            >
              {savedInputs.sampleCollectorId !== null
                ? getTranslation("Edit Sample Collector")
                : getTranslation("Add Sample Collector")}
            </Typography>
          </Divider>
        </Grid>
        {/* Physician Name */}
        <Grid
          columnSpacing={2}
          sx={{ marginTop: "0.7rem", marginBottom: "0.5rem" }}
          container
        >
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
              className="required"
            >
              {getTranslation("Sample Collector Name")}
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
              }}
              error={
                !sampleCollectorInputs.sampleCollectorFullName && isFormDirty
              }
              className={styles["form-input"]}
              autoSave={false}
              value={sampleCollectorInputs.sampleCollectorFullName}
              onChange={(e) =>
                dispatch(
                  handleChangeSampleCollectorFields({
                    sampleCollectorFullName: e.target.value,
                  })
                )
              }
              autoComplete="off"
              placeholder={getGlobalTranslation(
                "Enter your sample collector name"
              )}
              size="small"
              variant="outlined"
            />
            {showErrorMessage(
              sampleCollectorInputs.sampleCollectorFullName,
              getTranslation("Sample Collector Name")
            )}
          </Grid>
          <Grid
            sx={{ marginTop: "1rem" }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            item
          >
            <Typography
              className="required"
              variant="p"
              sx={{
                fontWeight: "bold",
                color: "darkBlue.main",
              }}
            >
              {getTranslation("Collector Type")}
            </Typography>
            <FormControl
              size="small"
              error={isFormDirty && !sampleCollectorInputs.collectorType}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
              }}
              fullWidth
            >
              <Select
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "darkBlue.secondary",
                    fontSize: "1.5rem",
                    transform: "rotate(90deg)",
                    marginRight: "0.3rem",
                  },
                }}
                IconComponent={ArrowForwardIosRoundedIcon}
                value={sampleCollectorInputs.collectorType}
                onChange={(e) =>
                  dispatch(
                    handleChangeSampleCollectorFields({
                      collectorType: e.target.value,
                    })
                  )
                }
              >
                {sampleCollectorTypes.map((type) => (
                  <MenuItem
                    key={type.collectorType}
                    selected={sampleCollectorInputs.collectorType}
                    value={type}
                  >
                    {getTranslation(type)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {showErrorMessage(
              sampleCollectorInputs.collectorType,
              getTranslation("Collector Type")
            )}
          </Grid>
        </Grid>
        {/* Status */}
        {isSampleCollectorForUpdate && (
          <Grid
            columnSpacing={2}
            sx={{ marginTop: "0.7rem", marginBottom: "0.5rem" }}
            container
          >
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <Typography
                variant="p"
                sx={{ fontWeight: "bold", color: "darkBlue.main" }}
              >
                Status
              </Typography>
              <br />
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                value={sampleCollectorInputs.isActive}
                onChange={(isChecked, _) =>
                  dispatch(
                    handleChangeSampleCollectorFields({
                      isActive: isChecked,
                    })
                  )
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* Footer buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          gap: "2rem",
          marginTop: "1.5rem",
        }}
      >
        <LoadingButton
          size="medium"
          disabled={isLoading}
          loading={isLoading}
          type="button"
          variant="contained"
          endIcon={
            savedInputs.sampleCollectorId !== null ? <SaveIcon /> : <SendIcon />
          }
          onClick={handleSubmit}
        >
          {savedInputs.sampleCollectorId !== null
            ? getGlobalTranslation("Save Changes")
            : getGlobalTranslation("Submit")}
        </LoadingButton>
        <Button
          size="medium"
          onClick={handleCloseFormModal}
          variant="dark"
          endIcon={<CloseIcon />}
          type="button"
        >
          {getGlobalTranslation("Cancel")}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateUpdateSampleCollectorModal;
