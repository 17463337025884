import React from "react";
import { Grid, Typography } from "@mui/material";
import SelectedSpecimensTable from "./selectedSpecimensTable";
import useTranslation from "hooks/useTranslation";
const ThirdSection = () => {
  const { getTranslation } = useTranslation("RequestPage");
  return (
    <>
      <Grid container>
        <Grid
          sx={{ marginTop: "2.3rem", display: "flex", flexDirection: "column" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "600", color: "darkBlue.main" }}
          >
            {getTranslation("Specimen provided")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontSize: "0.8rem",
              color: "darkBlue.main",
              fontStyle: "italic",
            }}
          >
            Please fill the required fields below.
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginTop: "1rem",
            backgroundColor: "grayishBlue.main",
            padding: "1rem 0.9rem",
          }}
          container
        >
          <SelectedSpecimensTable />
        </Grid>
      </Grid>
    </>
  );
};

export default ThirdSection;
