import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_COUNTRIES } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const deleteCountry = createAsyncThunk(
  "countries/delete-countries",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(URL_COUNTRIES + "/" + data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { deleteCountry };
