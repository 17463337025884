import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ATTACHMENTS } from "config/connString";
import useAxios from "utils/useAxios";

const uploadFile = createAsyncThunk("attachments/upload-file", async (data) => {
  try {
    const api = useAxios();
    const formData = new FormData();
    formData.append("files", data.file);

    const response = await api.post(URL_ATTACHMENTS + "/upload", formData);
    return response.data;
  } catch (err) {
    return err;
  }
});

export { uploadFile };
