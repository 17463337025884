import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_PATIENT } from "../../config/connString";
import decodedUserDetails from "../../utils/decodedUserDetails";

const checkPatientExistingOrder = createAsyncThunk(
  "patients/check-existing-order",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();
      const response = await api.get(
        URL_PATIENT +
          "/" +
          clientId +
          "/" +
          data?.patientId +
          "/" +
          "CheckExistingOrder"
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export { checkPatientExistingOrder };
