import notification from "components/mnl/global/openNotification";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "store";

const useCheckUserSession = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const autoLogoutUser = () => {
    const lastLoginDate = localStorage.getItem("user-login-date");
    if (lastLoginDate !== null) {
      const convertedLoginDate = moment(lastLoginDate);
      const hoursDifference = moment().diff(convertedLoginDate, "hours");
      if (hoursDifference >= 24) {
        notification.warning({
          message: "Session Expired",
          description: "Please re-login your account",
        });
        dispatch(logout());
        navigate("/login");
      }
    }
  };
  return { autoLogoutUser };
};

export default useCheckUserSession;
