import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSpecificPhysician } from "store";
import moment from "moment";
import { handleChangePhysicianFields } from "store/slice/physicianInputSlice";

const useFetchSpecificPhysician = (selectedPhysicianId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedPhysicianId !== null) {
      dispatch(
        getSpecificPhysician({
          id: selectedPhysicianId,
        })
      ).then((res) => {
        if (res.payload.success) {
          dispatch(
            handleChangePhysicianFields({
              clientId: res.payload?.data?.clientId,
              licenseNumber: res.payload?.data.licenseNumber,
              licenseExpirationDate: moment(
                res.payload?.data?.licenseExpirationDate
              ),
              id: res.payload?.data?.id,
              firstName: res.payload?.data?.firstName,
              lastName: res.payload?.data?.lastName,
              title: res.payload?.data?.title,
              specialty: res.payload?.data?.specialty,
              phoneNumber: res.payload?.data?.phoneNumber,
              email: res.payload?.data?.email,
              isActive: res.payload?.data?.isActive,
            })
          );
        }
      });
    }
  }, [selectedPhysicianId, dispatch]);
};

export default useFetchSpecificPhysician;
