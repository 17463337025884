import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { List, Paper, Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { getTestOrderById } from "store";
import { useDispatch } from "react-redux";
import moment from "moment";

const PickedUpOrderDetails = ({ selectedOrderId }) => {
  const [openTest, setOpenTest] = useState(true);
  const [openSpecimen, setOpenSpecimen] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);
  const dispatch = useDispatch();

  const handleClickTest = () => {
    setOpenTest(!openTest);
  };

  const handleClickSpecimen = () => {
    setOpenSpecimen(!openSpecimen);
  };

  useEffect(() => {
    dispatch(getTestOrderById({ id: selectedOrderId })).then((res) => {
      setOrderDetails(res?.payload?.data);
    });
  }, [dispatch, selectedOrderId]);

  return (
    <Paper
      sx={{
        ml: "auto",
        mr: "auto",
        mt: 8,
        mb: 2,
      }}
      elevate={24}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#22C55E",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">Order #: {selectedOrderId}</Typography>
      </Box>
      <Box sx={{ marginTop: "0.8rem" }}>
        <Typography
          sx={{
            paddingLeft: "0.6rem",
            fontSize: {
              xs: "1.2rem",
              sm: "1.2rem",
              md: "1.5rem",
              lg: "1.5rem",
              xl: "1.5rem",
            },
          }}
          variant="h5"
        >
          Order Status: <strong>{orderDetails?.orderStatus}</strong>
        </Typography>
        {!orderDetails?.isMinimalOrder && (
          <>
            <Typography
              sx={{
                paddingLeft: "0.6rem",
                fontSize: {
                  xs: "1.2rem",
                  sm: "1.2rem",
                  md: "1.5rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
              }}
              variant="h5"
            >
              Patient:{" "}
              <strong>
                {orderDetails?.patient?.firstname}{" "}
                {orderDetails?.patient?.lastname}
              </strong>
            </Typography>

            <Typography
              sx={{
                paddingLeft: "0.6rem",
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.5rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
              }}
              variant="h5"
            >
              DOB:{" "}
              <strong>
                {moment(orderDetails?.patient?.dateOfBirth).format(
                  "MMMM DD,YYYY"
                )}
              </strong>
            </Typography>
          </>
        )}
        {orderDetails?.isMinimalOrder && (
          <Typography
            sx={{
              paddingLeft: "0.6rem",
              fontSize: {
                xs: "1.2rem",
                sm: "1.2rem",
                md: "1.5rem",
                lg: "1.5rem",
                xl: "1.5rem",
              },
              fontWeight: "bold",
            }}
            variant="h5"
          >
            LRF Pickup Request
          </Typography>
        )}
      </Box>
      <List sx={{ pb: 4 }}>
        {/* LIST OF test */}
        <ListItemButton onClick={handleClickTest}>
          <ListItemText
            primary={`No. of tests - ${orderDetails?.testOrderDetails?.length}`}
          />
          {openTest ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openTest} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {orderDetails?.testOrderDetails?.map((testItem) => {
              return (
                <Typography
                  key={testItem?.id}
                  sx={{
                    pl: "2rem",
                    fontWeight: "semibold",
                    color: "green",
                    pr: 2,
                  }}
                  variant="body1"
                >
                  &#x2022; {testItem?.labTest?.name}
                </Typography>
              );
            })}
          </List>
        </Collapse>
        {/* LIST OF Specimen */}
        <ListItemButton onClick={handleClickSpecimen}>
          <ListItemText
            primary={`No. of specimen - ${
              orderDetails?.patientSpecimens?.length === 0
                ? "0"
                : orderDetails?.patientSpecimens?.length
            }`}
          />
          {openSpecimen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSpecimen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {orderDetails?.patientSpecimens?.map((specimenItem) => {
              return (
                <Typography
                  key={specimenItem?.id}
                  sx={{
                    pl: "2rem",
                    fontWeight: "semibold",
                    color: "green",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.7rem",
                    flexWrap: "wrap",
                    pr: 2,
                  }}
                  variant="body1"
                >
                  &#x2022; {specimenItem?.specimen?.name}
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                    variant="body1"
                  >
                    - {specimenItem?.specimenQuantity}{" "}
                    {specimenItem?.specimen?.unitOfMeasure}
                  </Typography>
                </Typography>
              );
            })}
          </List>
        </Collapse>
      </List>
    </Paper>
  );
};

export default PickedUpOrderDetails;
