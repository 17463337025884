import React from "react";
import { Select } from "antd";
import useTranslation from "hooks/useTranslation";

const AssortedFilter = ({ defaultValue, handleChangeSortField }) => {
  //For filtering test order by date, order status and order id. It is either by ascending or descending
  const { getTranslation } = useTranslation("TestOrderDataTable");

  return (
    <Select
      defaultValue={defaultValue}
      onChange={handleChangeSortField}
      style={{
        width: 120,
      }}
      options={[
        {
          value: "orderDate",
          label: getTranslation("Date Ordered"),
        },
        {
          value: "orderStatus",
          label: getTranslation("Order Status"),
        },
        {
          value: "id",
          label: "ID",
        },
      ]}
    />
  );
};

export default AssortedFilter;
