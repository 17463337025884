const parseJwt = (token) => {
  if (!token) {
    return null;
  }

  try {
    return JSON.parse(atob(token?.split(".")[1]));
  } catch (error) {
    return null;
  }
};
export default parseJwt;
