import React from "react";
import { Badge, Card, Modal, Space, Tag, Typography } from "antd";
import { Box, Divider, Chip } from "@mui/material";
import moment from "moment";

const LabtestDetails = ({
  openInfo,
  selectedInfoDetails,
  handleCancelInfo,
  handleOkInfo,
  getStringWeekName,
}) => {
  const { Text, Paragraph } = Typography;
  const isLabtestSpecimenEmpty =
    selectedInfoDetails?.testDetails?.labTestSpecimens.length === 0;

  return (
    <Modal
      title={
        <Paragraph>
          <blockquote>
            {selectedInfoDetails?.code + " - " + selectedInfoDetails?.name}
          </blockquote>
        </Paragraph>
      }
      open={openInfo && selectedInfoDetails !== null}
      onOk={handleOkInfo}
      onCancel={handleCancelInfo}
      footer={null}
      width={600}
    >
      <Box sx={{ height: "30rem", overflowY: "scroll" }}>
        <Space direction="vertical">
          <Space align="center">
            Abbreviation
            <Text code>{selectedInfoDetails?.abbreviation}</Text>
          </Space>
          <Space align="center">
            Alias
            <Text code>
              {selectedInfoDetails?.alias === null
                ? "N/A"
                : selectedInfoDetails?.alias}
            </Text>
          </Space>
          <Space align="center">
            Methodology
            <Text code>{selectedInfoDetails?.testDetails.methodology}</Text>
          </Space>
          <Space align="center">
            Sample Preparation
            <Paragraph>
              <pre> {selectedInfoDetails?.testDetails.samplePreparation}</pre>
            </Paragraph>
          </Space>
          <Space align="center">
            Purpose
            <Paragraph>
              <pre>
                {" "}
                {selectedInfoDetails?.testDetails.purpose === null
                  ? "N/A"
                  : selectedInfoDetails?.testDetails.purpose}
              </pre>
            </Paragraph>
          </Space>
          <Space align="center">
            Reject Criteria
            <Paragraph>
              <pre>
                {" "}
                {selectedInfoDetails?.testDetails.rejectCriteria === null
                  ? "N/A"
                  : selectedInfoDetails?.testDetails.rejectCriteria}
              </pre>
            </Paragraph>
          </Space>
          <Space align="center">
            Allow Stat
            {selectedInfoDetails?.testDetails.isStatAllowed ? (
              <Tag color="success">Yes</Tag>
            ) : (
              <Tag>No</Tag>
            )}
          </Space>

          <Space align="center">
            Specimen
            <Text code>
              {isLabtestSpecimenEmpty
                ? "N/A"
                : selectedInfoDetails?.testDetails?.labTestSpecimens
                    ?.map((specimen) => specimen.name)
                    .join(" / ")}
            </Text>
          </Space>
          <Space align="center">
            Specimen Container
            <Text code>
              {isLabtestSpecimenEmpty
                ? "N/A"
                : selectedInfoDetails?.testDetails?.labTestSpecimens
                    .map((specimen) => specimen?.container)
                    .join(" / ")}
            </Text>
          </Space>
          <Space align="center">
            Unit of Measure
            <Text code>
              {isLabtestSpecimenEmpty
                ? "N/A"
                : selectedInfoDetails?.testDetails?.labTestSpecimens
                    .map((specimen) => specimen?.unitOfMeasure)
                    .join(" / ")}
            </Text>
          </Space>
          <Space align="center">
            Volume Requirement
            <Text code>
              {isLabtestSpecimenEmpty
                ? "N/A"
                : selectedInfoDetails?.testDetails?.labTestSpecimens
                    .map((specimen) =>
                      specimen?.testVolumeOrSizeRequirements.length > 0
                        ? specimen?.testVolumeOrSizeRequirements?.find((vol) =>
                            Math.min(vol?.minVolume)
                          )?.minVolume + ` ${specimen.unitOfMeasure}`
                        : "N/A"
                    )
                    .join(" / ")}
            </Text>
          </Space>
          <Space align="center">
            {`Turn Around Time(TAT)`}
            <Text code>
              {selectedInfoDetails?.testDetails.tat === null
                ? "N/A"
                : selectedInfoDetails?.testDetails.tat}
            </Text>
          </Space>
          <Space align="center">
            Specimen Stability
            <Text code>
              {selectedInfoDetails?.testDetails.specimenStability
                ? selectedInfoDetails?.testDetails.specimenStability
                : "N/A"}
            </Text>
          </Space>
          {/* {isRoleAdmin && (
            <Space align="center">
              Cut Off of Acceptance
              <Text code>
                {selectedInfoDetails?.testDetails?.cutoff === null
                  ? "N/A"
                  : getStringWeekName(selectedInfoDetails?.testDetails?.cutoff)
                      ?.long}
              </Text>
            </Space>
          )} */}
          {selectedInfoDetails?.testDetails.schedules.length > 0 && (
            <Box sx={{ marginTop: "1rem" }}>
              <Divider textAlign="left">
                <Chip label="Schedule" />
              </Divider>
              <Box sx={{ marginTop: "0.8rem" }}>
                <Space direction="vertical" style={{ marginBottom: "0.7rem" }}>
                  <Badge status="success" text="Scheduled to run" />
                  <Badge status="default" text="Not scheduled to run" />
                </Space>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "1rem",
                    justifyContent: "start",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  {[0, 1, 2, 3, 4, 5, 6].map((item) => {
                    const matchingSchedules =
                      selectedInfoDetails.testDetails?.schedules.filter(
                        (i) => i.day === item
                      );

                    return matchingSchedules.length > 0 ? (
                      matchingSchedules.map((schedule) => {
                        const startTimeString =
                          schedule.startTime === "00:00:00"
                            ? schedule.startTime
                            : moment(schedule.startTime, "HH:mm:ss").format(
                                "h:mm a"
                              );

                        const endTimeString =
                          schedule.endTime === "00:00:00"
                            ? schedule.endTime
                            : moment(schedule.endTime, "HH:mm:ss").format(
                                "h:mm a"
                              );

                        return (
                          <Badge.Ribbon
                            className="w-full"
                            text={getStringWeekName(item).long}
                            color="green"
                          >
                            <Card
                              className="pt-5 bg-green-50 relative"
                              size="small"
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  mx: 1,
                                }}
                              >
                                <strong>{startTimeString}</strong> to{" "}
                                <strong>{endTimeString}</strong>
                              </Box>
                            </Card>
                          </Badge.Ribbon>
                        );
                      })
                    ) : (
                      <Badge.Ribbon
                        className="w-full"
                        text={getStringWeekName(item).long}
                        color="gray"
                      >
                        <Card
                          className="pt-5 font-bold bg-gray-100 w-28"
                          size="small"
                        ></Card>
                      </Badge.Ribbon>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          )}
        </Space>
      </Box>
    </Modal>
  );
};

export default LabtestDetails;
