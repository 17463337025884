import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  key: "",
  specimenId: 0,
  specimenQuantity: "",
  collectionDateTime: "",
  collectorId: null,
  collectionSite: "",
  status: 0,
};

const specimenInputSlice = createSlice({
  name: "specimenInputs",
  initialState,
  reducers: {
    handleChangeSpecimenFields: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { handleChangeSpecimenFields } = specimenInputSlice.actions;
export const specimenInputReducer = specimenInputSlice.reducer;
