import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCouriersLookup } from "store";

const filterOption = (input, option) =>
  option.label.toLowerCase().includes(input.toLowerCase());

const CourierSelect = () => {
  const [courierOptionsData, setCourierOptionsData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCouriersLookup()).then((res) => {
      setCourierOptionsData(res?.payload?.data);
    });
  }, [dispatch]);

  const formattedCourierOptionsData = courierOptionsData?.map((item) => ({
    label: item.fullName,
    value: item.id,
  }));

  return (
    <Form.Item
      label="Courier Code"
      name="courierCode"
      rules={[
        {
          required: true,
          message: <i>{"Please select Courier."}</i>,
        },
      ]}
    >
      <Select
        showSearch
        options={formattedCourierOptionsData}
        filterOption={filterOption}
        placeholder="Courier"
	virtual={false}
      />
    </Form.Item>
  );
};

export default CourierSelect;
