import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const getPatientTestOrderById = createAsyncThunk(
  "test-orders/get-patient-test-order-by-id",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          patientId: data.patientId,
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
        },
      };
      const response = await api.get(
        URL_TEST_ORDERS + "/" + data.patientId,
        config
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getPatientTestOrderById };
