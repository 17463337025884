import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_TEST_ORDERS } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const updateTestOrderNotes = createAsyncThunk(
  "test-orders/update-test-order-notes",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.put(
        URL_TEST_ORDERS + "/" + data.id + "/update-Notes",
        {
          id: data.id,
          notes: data.notes,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateTestOrderNotes };
