import { Empty } from "antd";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button } from "@mui/material";

export const EmptyData = ({ action, description, btnTitle, renderButton }) => (
  <Empty description={<span>{description}</span>}>
    {renderButton && (
      <Button startIcon={<AddOutlinedIcon />} variant="text" onClick={action}>
        {btnTitle}
      </Button>
    )}
  </Empty>
);
