import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/jkt/global/menuBar";
import { theme } from "components/jkt/global/theme";
import GetAllICD10Codes from "components/jkt/admin/icdCodes/getAllICD10Codes";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";
import TableContentLayout from "views/jkt/layout/tableContentLayout";

const Icd10CodesPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"ICD10 Code"} />
        <TableContentLayout>
          <GetAllICD10Codes />
        </TableContentLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default Icd10CodesPage;
