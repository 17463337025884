import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CURRENT_URL, URL_AUTH } from "config/connString";

const userForgotPassword = createAsyncThunk(
  "auth/forgot-password",
  async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(
        URL_AUTH + "forgot-password",
        {
          Email: data.email,
          CallbackUrl: CURRENT_URL + "/reset-password",
        },
        config
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);

export { userForgotPassword };
