import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_AUTH } from "config/connString";

const userResendConfirmEmail = createAsyncThunk(
  "auth/resend-confirmation-email",
  async (data) => {
    try {
      const response = await axios.post(
        URL_AUTH + "resend-confirmation-email",
        {
          userId: data.userId,
        }
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);

export { userResendConfirmEmail };
