import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_PATIENT } from "../../config/connString";

const getPatientInfo = createAsyncThunk(
  "patients/get-patient-info",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(URL_PATIENT + "/" + data?.id);
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export { getPatientInfo };
