import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_TESTS } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const withoutSecondAuthorityDeleteLabtest = createAsyncThunk(
  "labTest/delete-labTest",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(URL_LAB_TESTS + "/" + data.id, {
        data: {
          username: data.username,
          password: data.password,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const hasSecondAuthorityDeleteLabtest = createAsyncThunk(
  "labTest/delete-labTest",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(URL_LAB_TESTS + "/" + data.id, {
        data: {
          username: null,
          password: null,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { withoutSecondAuthorityDeleteLabtest, hasSecondAuthorityDeleteLabtest };
