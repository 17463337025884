import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_BARANGAY } from "config/connString";
import useAxios from "utils/useAxios";
const createBarangay = createAsyncThunk(
  "barangay/create-barangay",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_BARANGAY, {
        name: data.name,
        cityMunicipalityId: data.cityMunicipalityId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createBarangay };
