import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const getFlattenUserPermissions = createAsyncThunk(
  "rolesClaims/get-flatten-user-permissions",
  async () => {
    try {
      const api = useAxios();
      const response = await api.get(
        URL_USER + "/get-flatten-user-permissions"
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getFlattenUserPermissions };
