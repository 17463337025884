import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { Table, Input, ConfigProvider, Select, Space, Badge } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import ErrorDetails from "./errorDetails";
import useDebounce from "hooks/useDebounce";
import { getAllLogs } from "store";
import { EmptyData } from "components/jkt/global/emptyData";
import { useCurrentHeight } from "utils/getScreenSize";

const GetAllLogs = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = React.useState("timeStamp");
  const [sortDirection, setSortDirection] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [clientData, setClientData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [current, setCurrent] = React.useState(1);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [selectedErrorDetails, setSelectedErrorDetails] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");

  const LoadData = React.useCallback(() => {
    //HANDLE LOADING
    if (getAllLogs.pending().type === "logs/get-all-logs/pending") {
      setIsLoading(true);
    }

    dispatch(
      getAllLogs({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      })
    ).then((element) => {
      if (element.type === "logs/get-all-logs/fulfilled") {
        setIsLoading(false);
        setClientData(element.payload.data.items);
        setTotal(element.payload.data.totalPages * pageSize);
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
  ]);

  React.useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  const onChange = (page) => {
    setCurrent(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No Logs Detected"
      btnTitle=""
      renderButton={false}
    />
  );

  const handleChangeSortField = (value) => {
    setSortStr(value);
  };

  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };

  const handleOpenModal = (selectedError) => {
    setSelectedErrorDetails(selectedError);
    setOpenErrorModal(true);
  };

  const handleCloseModal = () => {
    setSelectedErrorDetails([]);
    setOpenErrorModal(false);
    setModalTitle("");
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 40,
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: 70,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: 300,
    },
    {
      title: "Exception",
      width: 90,
      render: (rec) => (
        <>
          {rec.exception !== null && (
            <Space>
              <Badge status="error" />
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleOpenModal(rec);
                  setModalTitle("Exception");
                }}
                variant="p"
              >
                View Exception
              </Typography>
            </Space>
          )}
        </>
      ),
    },
    {
      title: "Log Event",
      width: 90,
      render: (rec) => (
        <>
          {rec.logEvent !== null && (
            <Space>
              <Badge status="error" />
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleOpenModal(rec);
                  setModalTitle("Log Event");
                }}
                variant="p"
              >
                View Log Event
              </Typography>
            </Space>
          )}
        </>
      ),
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: 80,
    },
    {
      title: "Time Stamp",
      dataIndex: "timeStamp",
      key: "timeStamp",
      width: 70,
      render: (rec, row) => {
        return moment(rec).format("MMMM DD,YYYY hh:mm");
      },
    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            size=""
            placeholder="Search Logs"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortStr}
            onChange={handleChangeSortField}
            style={{
              width: 120,
            }}
            options={[
              {
                value: "id",
                label: "Id",
              },
              {
                value: "message",
                label: "Message",
              },
              {
                value: "user",
                label: "User",
              },
              {
                value: "level",
                label: "Level",
              },
              {
                value: "timeStamp",
                label: "time Stamp",
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}></Grid>
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={columns}
          dataSource={clientData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <ErrorDetails
        openErrorModal={openErrorModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        selectedErrorDetails={selectedErrorDetails}
        modalTitle={modalTitle}
      />
    </>
  );
};
export default GetAllLogs;
