import { Box } from "@mui/material";
import React, { useEffect } from "react";
import HeaderContent from "./headerContent";
import PatientInformation from "./patientInformation";
import TestProfileSection from "./testProfileSection";
import BillingInformation from "./billingInformation";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import { handleChangeHandling } from "store/slice/specialHandlingSlice";
import notification from "components/jkt/global/openNotification";

export const FourthStep = React.forwardRef((props, ref) => {
  const { id, printTestOrder } = props;
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedHandlingItems = useSelector((state) => state.specialHandling);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const isAnyStatAllowed = multiStepFormInputs.testOrderDetails.some(
    (item) => item.hasOwnProperty("isStat") && item.isStat === true
  );

  const isStatAdded = multiStepFormInputs.specialHandlingIds.includes(1);

  useEffect(() => {
    if (!isAnyStatAllowed && isStatAdded) {
      const filteredHandling = multiStepFormInputs.specialHandlingIds.filter(
        (handling) => handling !== 1
      );

      const filteredSavedHandling = savedHandlingItems.savedHandling.filter(
        (handling) => handling.id !== 1
      );
      dispatch(
        handleChangeMultiFields({
          specialHandlingIds: filteredHandling,
        })
      );
      dispatch(
        handleChangeHandling({
          savedHandling: filteredSavedHandling,
        })
      );
      setTimeout(() => {
        notification.warning({
          message: "Removed STAT",
          description: (
            <div>
              Since <strong>STAT</strong> is not used in the laboratory tests
              you added, it has been removed.
            </div>
          ),
        });
      }, 1000);
    }
    // eslint-disable-next-line
  }, [dispatch, isAnyStatAllowed]);

  return (
    <div id={id}>
      <Box
        ref={ref}
        sx={{
          flexGrow: 1,
          padding: {
            xs: "0.3rem",
            sm: "0.3rem",
            md: "0.9rem",
            lg: "2rem",
            xl: "2rem",
          },
        }}
        className="test-order-wrapper"
      >
        <HeaderContent printTestOrder={printTestOrder} />
        <PatientInformation printTestOrder={printTestOrder} />
        <TestProfileSection printTestOrder={printTestOrder} />
        <BillingInformation printTestOrder={printTestOrder} />
      </Box>
    </div>
  );
});
