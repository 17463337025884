import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_DEPT } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const createLabDepartment = createAsyncThunk(
  "labDepartments/create-lab-department",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_LAB_DEPT, {
        name: data.name,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createLabDepartment };
