import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_TESTS } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getLabTestRequirements = createAsyncThunk(
  "labTest/get-lab-test-requirements",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(
        URL_LAB_TESTS + "/" + data.id + "/required-test-data"
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getLabTestRequirements };
