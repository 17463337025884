import { Box } from "@mui/material";
import React from "react";
import { isDataEmpty } from "utils/isDataEmpty";
import renderDetailsText from "utils/renderDetailsText";

const BeforeNotesModificationData = ({ oldNotes }) => {
  const noteValue =
    typeof newNotes === "string"
      ? JSON.parse(oldNotes)?.Notes
      : oldNotes?.Notes;

  if (!isDataEmpty(noteValue)) {
    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ mt: 2 }}>{renderDetailsText("Notes", noteValue)}</Box>
      </Box>
    );
  }
};

export default BeforeNotesModificationData;
