export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  const isUserLoggedIn = user && user?.token;

  if (isUserLoggedIn) {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
    };
  }
}
