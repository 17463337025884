import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_HEALTHCARE_PROVIDER } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getAllHealthcareProvider = createAsyncThunk(
  "healthcareProvider/get-all-healthcare-provider",
  async (data) => {
    try {
      const userStorage =
        localStorage.getItem("user") === null
          ? {
              token: null,
              refreshToken: null,
              user: { displayName: null, clientId: null },
            }
          : JSON.parse(localStorage.getItem("user"));
      const api = useAxios();
      const config = {
        params: {
          clientId: userStorage.user.clientId,
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
        },
      };
      const response = await api.get(URL_HEALTHCARE_PROVIDER, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getAllHealthcareProvider };
