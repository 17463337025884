import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_REGISTER_QRCODE } from "../../config/connString";

const createQrCode = createAsyncThunk(
  "qr-code/register-qrcode",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.post(URL_REGISTER_QRCODE, data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createQrCode };
