import * as React from "react";
import { Modal, Form, Input, Button, Popconfirm, Select } from "antd";
import { useDispatch } from "react-redux";
import {
  createCityMunicipalities,
  deleteCityMunicipalities,
  updateCityMunicipalities,
} from "store";
import useCheckPermission from "hooks/useCheckPermission";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import notification from "components/jkt/global/openNotification";

const CreateUpdateCityMunicipalities = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
    provinceData,
  } = props;

  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToDelete = handleVerifyPermission(
    "Administration:CityMunicipality:Delete"
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const LoadUpdateData = React.useCallback(() => {
    form.setFieldsValue({
      name: idForUpdate.name,
      provinceId: idForUpdate.provinceId,
    });
  }, [form, idForUpdate]);

  React.useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const onFinish = (values) => {
    if (isForUpdate) {
      if (
        updateCityMunicipalities.pending().type ===
        "citymunicipalities/update-citymunicipalities/pending"
      ) {
        setLoading(true);
      }
      dispatch(updateCityMunicipalities([idForUpdate.id, values])).then(
        (val) => {
          if (val.payload?.success) {
            handleSubmit();
            setLoading(false);
            notification.success({
              message: "Updated City Municipality",
              description: `Successfully updated ${values.name}`,
            });
          }
          if (!val?.payload?.success) {
            setLoading(false);
            notification.success({
              message: "Failed to Update City Municipality",
              description: errorMessagesDescription(
                val.payload?.response.data.errorMessages
              ),
            });
          }
        }
      );
    } else {
      if (
        createCityMunicipalities.pending().type ===
        "citymunicipalities/create-citymunicipalities/pending"
      ) {
        setLoading(true);
      }
      dispatch(createCityMunicipalities(values)).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Created City Municipality",
            description: `Successfully created ${values.name}`,
          });
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.success({
            message: "Failed to Create City Municipality",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    errorInfo.errorFields.map((item) => {
      return notification.error({
        message: "Required Field",
        description: item?.errors[0].props.children,
      });
    });
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (
      deleteCityMunicipalities.pending().type ===
      "citymunicipalities/delete-citymunicipalities/pending"
    ) {
      setLoading(true);
    }
    dispatch(deleteCityMunicipalities(idForUpdate.id)).then((val) => {
      if (val.payload?.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted City Municipality",
          description: `Successfully deleted ${idForUpdate.name}`,
        });
      }
      if (!val.payload?.success) {
        setLoading(false);
        notification.error({
          message: "Failed to Delete City Municipality",
          description: errorMessagesDescription(
            val.payload?.response.data.errorMessages
          ),
        });
      }
    });
  };

  const provinceOptions = provinceData.map((element) => {
    return { label: element.name, value: element.id };
  });

  return (
    <Modal
      open={openCreateUpdate}
      title={
        isForUpdate ? "Update City Municipality" : "Create City Municipality"
      }
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="cityMunicipality"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        {/*  Name */}
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: <i>{"Please input Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        {/* Country */}
        <Form.Item
          label="Province"
          name="provinceId"
          rules={[
            {
              required: true,
              message: <i>{"Please input Province."}</i>,
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            virtual={false}
            optionFilterProp="children"
            options={provinceOptions}
            placeholder={"Province"}
          />
        </Form.Item>
        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {accessRightsToDelete && isForUpdate ? (
            <Popconfirm
              title="Delete the city municipality"
              description="Are you sure to delete?"
              onConfirm={handleClickDelete}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loading, className: "submitBtn" }}
            >
              <Button loading={loading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateCityMunicipalities;
