import {
  Button,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  Alert,
  Stack,
  AlertTitle,
} from "@mui/material";
import * as React from "react";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { userForgotPassword } from "store";
import eRequestLogo from "assests/erequest.png";
import { useNavigate } from "react-router-dom";
import Copyright from "components/jkt/global/copyright";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm({ mode: "all" });
  const dispatch = useDispatch();
  const { isLoading, error, errorMessage, successMessage } = useSelector(
    (state) => state.auth
  );

  const submitForm = (data) => {
    dispatch(userForgotPassword(data));
  };
  const errorMessageLists = errorMessage.map((value) => {
    return (
      <Alert variant="outlined" severity="error">
        {value}
      </Alert>
    );
  });

  const successMessageLists = successMessage.map((value) => {
    return (
      <Alert variant="outlined" severity="success">
        <AlertTitle>
          <b>Reset password email sent</b>
        </AlertTitle>
        {value}
      </Alert>
    );
  });
  const handleClickToLogin = () => {
    navigate("/login");
  };
  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img alt="eRequest Client" src={eRequestLogo} />
        <Typography component="h1" variant="h4" sx={{ fontWeight: "bold" }}>
          Forgot Password
        </Typography>

        <Box
          sx={{ mt: 1, mb: 2 }}
          component="form"
          onSubmit={handleSubmit(submitForm)}
        >
          {error ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              {errorMessageLists}
            </Stack>
          ) : (
            ""
          )}
          {successMessage ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              {successMessageLists}
            </Stack>
          ) : (
            ""
          )}
          <Typography variant="caption" display="block" sx={{ mt: 2 }}>
            Enter your email address and we'll send you an email with
            instructions to reset your password.
          </Typography>

          <TextField
            fullWidth
            id="email"
            label="Email"
            type={"email"}
            {...register("email")}
            disabled={isLoading}
            required
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isLoading ? (
                <LoadingButton
                  fullWidth
                  sx={{ mt: 2, mb: 2 }}
                  loading
                  loadingPosition="end"
                  endIcon={<SendIcon />}
                  variant="contained"
                >
                  Request reset link
                </LoadingButton>
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Request reset link
                </Button>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography sx={{ fontSize: "0.9rem" }} variant="p">
              Already have an account?{" "}
              <Typography
                onClick={handleClickToLogin}
                sx={{
                  cursor: "pointer",
                  color: "#1976D2",
                  textDecoration: "underline",
                }}
                variant="p"
              >
                Sign in
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Copyright color="gray" />
    </Grid>
  );
};
export default ForgotPasswordForm;
