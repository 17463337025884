import { Modal, Space, Tag, Typography } from "antd";
import moment from "moment";
import React from "react";

const ClientDetailsModal = ({
  openInfo,
  handleOkInfo,
  handleCancelInfo,
  selectedInfoDetails,
  clientTypeData,
}) => {
  const { Text, Paragraph } = Typography;
  const isRegisteredUserCapEmpty =
    selectedInfoDetails?.registeredUserCap === null ||
    selectedInfoDetails?.registeredUserCap === "";

  const renderBooleanTag = (isValueTrue) => {
    return (
      <Tag color={isValueTrue ? "green" : "red"}>
        {isValueTrue ? "Yes" : "No"}
      </Tag>
    );
  };

  return (
    <Modal
      title={
        <Paragraph>
          <blockquote>
            {selectedInfoDetails?.clientCode +
              " - " +
              selectedInfoDetails?.clientName}
          </blockquote>
        </Paragraph>
      }
      open={openInfo}
      onOk={handleOkInfo}
      onCancel={handleCancelInfo}
      footer={null}
    >
      <Space style={{ marginTop: "0.5rem" }} direction="vertical">
        <Space align="center">
          Client Type
          <Text code>
            {
              clientTypeData?.filter(
                (element) => element?.id === selectedInfoDetails?.clientType_ID
              )[0]?.typeName
            }
          </Text>
        </Space>
        <Space align="center">
          Contact Name
          <Text code>{selectedInfoDetails?.contactName}</Text>
        </Space>
        <Space align="center">
          Contact Number
          <Text code>{selectedInfoDetails?.contactNumber}</Text>
        </Space>
        <Space align="center">
          Client Start Date
          <Text code>
            {moment(selectedInfoDetails?.clientStartDate).format("LL")}
          </Text>
        </Space>
        <Space align="center">
          Client End Date
          <Text code>
            {" "}
            {selectedInfoDetails?.clientEndDate === null
              ? "N/A"
              : moment(selectedInfoDetails?.clientEndDate).format("LL")}
          </Text>
        </Space>
        <Space align="center">
          Registered User Cap
          <Text code>
            {isRegisteredUserCapEmpty
              ? "N/A"
              : selectedInfoDetails?.registeredUserCap}
          </Text>
        </Space>
        <Space align="center">
          Client Processing Window Time
          <Text code>
            {selectedInfoDetails?.inClientProcessingWindowTime === null ||
            selectedInfoDetails?.inClientProcessingWindowTime === ""
              ? "N/A"
              : selectedInfoDetails?.inClientProcessingWindowTime}
          </Text>
        </Space>
        <Space align="center">
          Enable Collection Site
          {renderBooleanTag(selectedInfoDetails?.enableCollectionSite)}
        </Space>
        <Space align="center">
          Enable Volume Check
          {renderBooleanTag(selectedInfoDetails?.enableVolumeCheck)}
        </Space>
        <Space align="center">
          Enable Processing Window Time
          {renderBooleanTag(selectedInfoDetails?.enableProcessingWindowTime)}
        </Space>
        <Space align="center">
          Allow Email Sending of Request
          {renderBooleanTag(selectedInfoDetails?.allowEmailSendingOfRequest)}
        </Space>
      </Space>
    </Modal>
  );
};

export default ClientDetailsModal;
