import React from "react";
import { Select } from "antd";

const assortedFilterOptions = [
  {
    value: "userName",
    label: "User Name",
  },
  {
    value: "email",
    label: "Email",
  },
  {
    value: "displayName",
    label: "Display Name",
  },
  {
    value: "clientId",
    label: "Client",
  },
];

const AssortedFilter = ({ defaultValue, handleChangeSortField }) => {
  //For filtering test order by date, order status and order id. It is either by ascending or descending
  return (
    <Select
      defaultValue={defaultValue}
      onChange={handleChangeSortField}
      style={{
        width: 120,
      }}
      options={assortedFilterOptions}
    />
  );
};

export default AssortedFilter;
