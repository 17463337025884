import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_TESTS } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getLabTestSpecimenTypes = createAsyncThunk(
  "labtests/get-specimen-types",
  async () => {
    try {
      const api = useAxios();
      const response = await api.get(URL_LAB_TESTS + "/specimen-types");
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getLabTestSpecimenTypes };
