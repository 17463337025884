import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_PROVINCES } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const createProvince = createAsyncThunk(
  "provinces/create-provinces",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_PROVINCES, {
        name: data.name,
        countryId: data.countryId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createProvince };
