import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getUserById = createAsyncThunk("user/get-user-by-id", async (data) => {
  try {
    const api = useAxios();
    const response = await api.get(URL_USER + "/" + data);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
});

export { getUserById };
