import React from "react";
import { Select } from "antd";
import useTranslation from "hooks/useTranslation";
const DirectionFilter = ({ defaultValue, handleChangeSortDirection }) => {
  const { getTranslation } = useTranslation("TestOrderDataTable");

  return (
    <Select
      defaultValue={defaultValue}
      onChange={handleChangeSortDirection}
      style={{
        width: 120,
        marginLeft: 2,
      }}
      options={[
        {
          value: 0,
          label: getTranslation("Ascending"),
        },
        {
          value: 1,
          label: getTranslation("Descending "),
        },
      ]}
    />
  );
};

export default DirectionFilter;
