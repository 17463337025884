import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/jkt/global/menuBar";
import { theme } from "components/jkt/global/theme";
import GetAllBarangay from "components/jkt/admin/barangay/getAllBarangay";
import V2SessionTimeout from "components/jkt/global/v2SessionTimeout";
import TableContentLayout from "views/jkt/layout/tableContentLayout";

const BarangayPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"Barangay"} />

        <TableContentLayout>
          <GetAllBarangay />
        </TableContentLayout>
      </Box>
      <V2SessionTimeout />
    </ThemeProvider>
  );
};
export default BarangayPage;
