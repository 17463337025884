import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import { theme } from "components/jkt/global/theme";
import Toolbar from "@mui/material/Toolbar";
import DashboardCards from "components/jkt/dashboard/dashboardCards";
import MenuBar from "components/jkt/global/menuBar";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";
// import DashboardGraphs from "components/jkt/dashboard/dashboardGraphs";

const drawerWidth = 240;

const DashboardPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"Dashboard"} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <DashboardCards />
          {/* <DashboardGraphs /> */}
        </Box>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default DashboardPage;
