import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_TEST_ORDERS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const updateTestOrder = createAsyncThunk(
  "test-orders/update-test-order",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const response = await api.put(URL_TEST_ORDERS + "/" + data.id, {
        clientWillDrawSpecimen: data.clientWillDrawSpecimen,
        id: data.id,
        patientId: data.patientId,
        clientId: clientId,
        providerId: data.providerId,
        diagnosesList: data.diagnosesList,
        specialHandlingIds: data.specialHandlingIds,
        testOrderDetails: data.testOrderDetails,
        patientSpecimens: data.patientSpecimens,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateTestOrder };
