import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/jkt/global/menuBar";
import { theme } from "components/jkt/global/theme";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";
import TableContentLayout from "views/jkt/layout/tableContentLayout";
import AuthenticateExternalSystemCredentials from "components/jkt/externalSystemCredentials/authenticateExternalSystemCredentials";

const ExternalSystemCredentialPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"External System Credential"} />
        <TableContentLayout>
          <AuthenticateExternalSystemCredentials />
        </TableContentLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default ExternalSystemCredentialPage;
