import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_COUNTRIES } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getCountryLookUp = createAsyncThunk(
  "countries/get-countries-look-up",
  async () => {
    try {
      const api = useAxios();
      const response = await api.get(URL_COUNTRIES + "-lookup");
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getCountryLookUp };
