import * as React from "react";
import { Modal, Form, Input, Button, Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import useCheckPermission from "hooks/useCheckPermission";
import { updateCountry } from "store";
import notification from "components/jkt/global/openNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import { createCountry, deleteCountry } from "store";

const CreateUpdateCountries = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
  } = props;
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToDelete = handleVerifyPermission(
    "Administration:Country:Delete"
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const LoadUpdateData = React.useCallback(() => {
    form.setFieldsValue({
      name: idForUpdate.name,
    });
  }, [form, idForUpdate]);

  React.useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const onFinish = (values) => {
    if (isForUpdate) {
      if (
        updateCountry.pending().type === "countries/update-countries/pending"
      ) {
        setLoading(true);
      }
      dispatch(updateCountry([idForUpdate.id, values])).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Updated Country",
            description: `Successfully updated ${values.name}`,
          });
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to update country",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
        }
      });
    } else {
      if (
        createCountry.pending().type === "countries/create-countries/pending"
      ) {
        setLoading(true);
      }
      dispatch(createCountry(values)).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Created Country",
            description: `Successfully created ${values.name}`,
          });
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to create country",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    errorInfo.errorFields.map((item) => {
      return notification.error({
        message: "Required Field",
        description: item?.errors[0].props.children,
      });
    });
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (deleteCountry.pending().type === "countries/delete-countries/pending") {
      setLoading(true);
    }
    dispatch(deleteCountry(idForUpdate.id)).then((val) => {
      if (val.payload?.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted Country",
          description: `Successfully deleted ${idForUpdate.name}`,
        });
      }
      if (!val.payload?.success) {
        setLoading(false);
        notification.error({
          message: "Failed to create country",
          description: errorMessagesDescription(
            val.payload?.response.data.errorMessages
          ),
        });
      }
    });
  };

  return (
    <Modal
      open={openCreateUpdate}
      title={isForUpdate ? "Update Country" : "Create Country"}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="country"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        {/* Type Name */}
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: <i>{"Please input Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {accessRightsToDelete && isForUpdate ? (
            <Popconfirm
              title="Delete the country"
              description="Are you sure to delete?"
              onConfirm={handleClickDelete}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loading, className: "submitBtn" }}
            >
              <Button loading={loading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateCountries;
