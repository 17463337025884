import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CITY_MUNICIPALITY } from "config/connString";
import useAxios from "utils/useAxios";

const updateCityMunicipalities = createAsyncThunk(
  "citymunicipalities/update-citymunicipalities",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.put(URL_CITY_MUNICIPALITY + "/" + data[0], {
        id: data[0],
        name: data[1].name,
        provinceId: data[1].provinceId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateCityMunicipalities };
