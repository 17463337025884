import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_SUPPLY_ORDER } from "config/connString";

const createSupplyOrder = createAsyncThunk(
  "supply-orders/create-supply-order",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.post(URL_SUPPLY_ORDER, {
        requestedItems: data?.requestedItems,
        clientCode: data?.clientCode,
        createdBy: data?.createdBy,
        requestedBy: data?.requestedBy,
        requestSource: data?.requestSource,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createSupplyOrder };
