import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const getTestOrderByClientId = createAsyncThunk(
  "test-orders/get-test-order-by-clientId",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
        },
      };
      const response = await api.get(
        `${URL_TEST_ORDERS}/clientId/${data.clientId}`,
        config
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getTestOrderByClientId };
