import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SPECIMEN } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const createSpecimen = createAsyncThunk(
  "specimen/create-specimen",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_SPECIMEN, {
        name: data.name,
        container: data.container,
        measurementType: data.measurementType,
        size: data.size,
        requiresBloodDraw: data.requiresBloodDraw ? true : false,
        requiresSampleOrigin: data.requiresSampleOrigin ? true : false,
        unitOfMeasure: data.unitOfMeasure,
        testVolumeOrSizeRequirements: data.testVolumeOrSizeRequirements,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createSpecimen };
