import { Tabs } from "antd";
import React from "react";
import ClientInformationsInput from "./clientInformationsInput";
import ClientConfigurationsInput from "./clientConfigurationsInput";
import { useCurrentWidth } from "utils/getScreenSize";

const FormInputTabs = ({
  isForUpdate,
  loading,
  optionType,
  setClientStartDateValue,
  clientStartDateValue,
  optionAddressType,
  countryOptions,
  handleSelectCountry,
  selectedAddressIds,
  provinceOptions,
  handleSelectProvince,
  cityMunicipalityOptions,
  handleSelectCityMunicipality,
  barangayOptions,
  isProcessingWindowTimeEnabled,
}) => {
  const isMobileView = useCurrentWidth() < 600;

  const tabItems = [
    {
      key: "1",
      label: "Informations",
      children: (
        <ClientInformationsInput
          isForUpdate={isForUpdate}
          loading={loading}
          optionType={optionType}
          setClientStartDateValue={setClientStartDateValue}
          clientStartDateValue={clientStartDateValue}
          optionAddressType={optionAddressType}
          countryOptions={countryOptions}
          handleSelectCountry={handleSelectCountry}
          selectedAddressIds={selectedAddressIds}
          provinceOptions={provinceOptions}
          handleSelectProvince={handleSelectProvince}
          cityMunicipalityOptions={cityMunicipalityOptions}
          handleSelectCityMunicipality={handleSelectCityMunicipality}
          barangayOptions={barangayOptions}
        />
      ),
    },
    {
      key: "2",
      label: "Configurations",
      children: (
        <ClientConfigurationsInput
          isProcessingWindowTimeEnabled={isProcessingWindowTimeEnabled}
        />
      ),
    },
  ];
  return (
    <Tabs
      tabBarStyle={{
        position: "fixed",
        width: isMobileView ? 500 : 537,
        backgroundColor: "white",
        zIndex: 100,
      }}
      defaultActiveKey="1"
      items={tabItems}
    />
  );
};

export default FormInputTabs;
