export const isDataEmpty = (data) => {
  if (
    data === null ||
    data === "" ||
    data === undefined ||
    (typeof data === "string" && data?.trim()?.length === 0)
  ) {
    return true;
  }

  return false;
};
