import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/jkt/global/menuBar";
import { theme } from "components/jkt/global/theme";
import TableContentLayout from "views/jkt/layout/tableContentLayout";
import GetAllCityMunicipalities from "components/jkt/admin/cityMunicipalities/getAllCityMunicipalities";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";

const CityMunicipalityPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"City Municipalities"} />
        <TableContentLayout>
          <GetAllCityMunicipalities />
        </TableContentLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default CityMunicipalityPage;
