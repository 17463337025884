import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const updateClientType = createAsyncThunk(
  "clientTypes/update-client-type",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.put(URL_CLIENT + data[0], {
        id: data[0],
        typeName: data[1].typeName,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateClientType };
