import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_SPECIAL_HANDLING } from "../../config/connString";
import decodedUserDetails from "../../utils/decodedUserDetails";
const getSpecialHandlingLookUp = createAsyncThunk(
  "special-handlings/get-special-handling-look-up",
  async () => {
    try {
      const clientId = +decodedUserDetails()?.clientId;
      const api = useAxios();
      const config = {
        params: {
          clientId: clientId,
          search: "",
          pageSize: 0,
          pageNumber: 1,
        },
      };
      const response = await api.get(
        URL_SPECIAL_HANDLING + "/special-handlings-lookup",
        config
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getSpecialHandlingLookUp };
