import { Modal, Space, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSpecimen } from "store";
import { getTagColorAndTitle } from "utils/getTagColorAndTitle";

const { Paragraph, Text } = Typography;

const SpecimenDetailsModal = ({ open, onCancel, id, measurementType }) => {
  const [specimenData, setSpecimenData] = useState(null);
  const dispatch = useDispatch();

  //Fetching of specimen details
  useEffect(() => {
    if (id !== null) {
      dispatch(
        getSpecimen({
          id: id,
        })
      ).then((response) => {
        const isDataFetchedSuccessfully = response?.payload?.success;
        if (isDataFetchedSuccessfully) {
          setSpecimenData(response?.payload?.data);
        }
      });
    }
  }, [dispatch, id]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      title={
        <Paragraph>
          <blockquote>{specimenData?.name}</blockquote>
        </Paragraph>
      }
    >
      <Space direction="vertical">
        <Space align="center">
          Specimen Name:
          <Text code>{specimenData?.name}</Text>
        </Space>
        <Space align="center">
          Container:
          <Text code>{specimenData?.container}</Text>
        </Space>
        <Space align="center">
          Measurement Type:
          <Text code>{measurementType[specimenData?.measurementType]}</Text>
        </Space>
        <Space align="center">
          Size:
          <Text code>{specimenData?.size}</Text>
        </Space>
        <Space align="center">
          Unit Of Measure:
          <Text code>{specimenData?.unitOfMeasure}</Text>
        </Space>
        <Space align="center">
          Require Sample Origin:
          <Tag
            color={
              getTagColorAndTitle(specimenData?.requiresSampleOrigin)?.color
            }
          >
            {getTagColorAndTitle(specimenData?.requiresSampleOrigin)?.title}
          </Tag>
        </Space>
        <Space align="center">
          Require Blood Draw:
          <Tag
            color={getTagColorAndTitle(specimenData?.requiresBloodDraw)?.color}
          >
            {getTagColorAndTitle(specimenData?.requiresBloodDraw)?.title}
          </Tag>
        </Space>
      </Space>
    </Modal>
  );
};

export default SpecimenDetailsModal;
