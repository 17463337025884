import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";
import decodedUserDetails from "../../utils/decodedUserDetails";

const getOrderSessionSummary = createAsyncThunk(
  "test-orders/get-test-order-session-summary",
  async () => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();
      const config = {
        params: {
          clientId: clientId,
        },
      };
      const response = await api.get(
        URL_TEST_ORDERS + "/" + clientId + "/ready-for-pickup-summary",
        config
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getOrderSessionSummary };
