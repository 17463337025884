import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_SUPPLY_ORDER } from "config/connString";

const getInventoryItemsLookup = createAsyncThunk(
  "supply-orders/get-inventory-items-lookup",
  async () => {
    try {
      const api = useAxios();

      const response = await api.get(URL_SUPPLY_ORDER + "/inventory-items");
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getInventoryItemsLookup };
