import { Button, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPatientExistingOrder,
  getAllBarangay,
  getAllCityMunicipalities,
  getAllPatient,
  getCountryLookUp,
  getPatientInfo,
  getPhilippineAddressTypes,
  getProvinceLookUp,
} from "store";
import {
  Modal,
  Table,
  Tag,
  Button as BtnAntd,
  Input,
  Select,
  ConfigProvider,
  Space,
} from "antd";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { SearchOutlined } from "@ant-design/icons";
import CreateUpdatePatientModal from "./createUpdatePatientModal";
import PatientDetails from "./patientDetails";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import useDebounce from "hooks/useDebounce";
import decodedUserDetails from "utils/decodedUserDetails";
import { handleChangeFields } from "store/slice/inputSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import { EmptyData } from "components/jkt/global/emptyData";
import { useCurrentHeight } from "utils/getScreenSize";
import useTranslation from "hooks/useTranslation";
import notification from "../global/openNotification";

const GetAllPatient = () => {
  const dispatch = useDispatch();
  const [showEditPatientModal, setShowEditPatientModal] = useState(false);
  const [showCreatePatientModal, setShowCreatePatientModal] = useState(false);
  const [showViewPatientInfoModal, setShowViewPatientInfoModal] =
    useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [patientDetails, setPatientDetails] = useState(null);
  const savedInputs = useSelector((state) => state.savedInputs);
  const debouncedInputValue = useDebounce(searchInput);
  const [sortField, setSortField] = useState("dateCreated");
  const [currentPage, setCurrentPage] = useState(1);
  const [patientData, setPatientData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityMunicipalityOptions, setCityMunicipalityOptions] = useState([]);
  const [barangayOptions, setBarangayOptions] = useState([]);
  const [phAddressesTypeOptions, setPhAddressesTypeOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const clientId = decodedUserDetails()?.clientId;
  const { getTranslation } = useTranslation("PatientDataTable");
  const handleCloseModal = () => {
    //Function for closing patient modal, whether it is for create or update
    dispatch(
      handleChangeFields({
        id: 0,
        fullPatientName: "",
        gender: "",
        dateOfBirth: null,
        contactNumber: "",
        emails: [],
        patientEmailForDisplay: [],
        medicalRecordNo: "",
        cardNumber: "",
        governmentId: "",
        phAddresses: [],
        isPatientHasAnAcceptedOrder: false,
      })
    );
    dispatch(
      handleChangeMultiFields({
        patientId: 0,
      })
    );
    setPatientDetails(null);
    setShowViewPatientInfoModal(false);
    setShowEditPatientModal(false);
    setShowCreatePatientModal(false);
  };

  const handleOpenCreatePatientModal = () => {
    //Function for showing create patient modal
    setShowCreatePatientModal(true);
    setShowEditPatientModal(false);
  };

  const handleOpenEditPatientModal = (selectedData) => {
    //Function for showing update patient modal
    handleFetchPatientData(selectedData.id);
    dispatch(
      handleChangeMultiFields({
        patientId: selectedData.id,
      })
    );
    setShowEditPatientModal(true);
    setShowCreatePatientModal(false);
    setShowViewPatientInfoModal(false);
  };

  const handleOpenPatientDetailsModal = (selectedData) => {
    //Function for showing patient details modal
    setShowViewPatientInfoModal(true);
    setPatientDetails(selectedData);
  };

  const handleSearchChange = (e) => {
    //Onchange event that handles the value of search input in patient's table
    setIsLoading(true);
    setSearchInput(e.target.value);
    setCurrentPage(1);
  };
  const handleChange = (page) => {
    //Handler to get the current page of the table
    setCurrentPage(page);
  };
  const handleShowSizeChange = (current, pageSize) => {
    //To know when the table size changes, for example by searching a data
    setPageSize(pageSize);
  };
  const handleChangeSortField = (value) => {
    //Function for sorting data in table
    setSortField(value);
  };
  const handleChangeSortDirection = (value) => {
    //For sorting data in table, whether it is ascending or descending
    setSortDirection(value);
  };

  const handleFetchAllPatientData = useCallback(() => {
    //Fetching of all patients
    if (getAllPatient.pending().type === "patients/get-all-patients/pending") {
      setIsLoading(true);
    }
    dispatch(
      getAllPatient({
        search: debouncedInputValue,
        clientId: clientId,
        pageSize: pageSize,
        pageNumber: currentPage,
        sortField: sortField,
        sortDirection: sortDirection,
      })
    ).then((res) => {
      if (res.type === "patients/get-all-patients/fulfilled") {
        setTotalPages(res.payload.data.totalPages * pageSize);
        setPatientData(res.payload.data.items);
        setIsLoading(false);
      }
    });
  }, [
    clientId,
    currentPage,
    debouncedInputValue,
    dispatch,
    pageSize,
    sortDirection,
    sortField,
  ]);

  const handleFetchPatientData = (patientId) => {
    if (patientId !== 0) {
      handleCheckPatientExistingOrder(patientId);
    }
    dispatch(
      getPatientInfo({
        id: patientId,
      })
    ).then((response) => {
      const responseData = response?.payload?.data;
      const isFetchSuccess = response?.payload?.success;
      if (isFetchSuccess) {
        const phAddressesData = responseData?.phAddresses.map((address) => ({
          id: address?.id,
          type: address?.type,
          barangayId: address?.barangayId === 0 ? null : address?.barangayId,
          cityMunicipalityId:
            address.cityMunicipalityId === 0
              ? null
              : address?.cityMunicipalityId,
          provinceId: address?.provinceId === 0 ? null : address?.provinceId,
          houseBuildingNumber: address?.houseBuildingNumber,
          streetName: address?.streetName,
          postalCode: address?.postalCode,
          country: address?.country,
        }));
        dispatch(
          handleChangeFields({
            id: responseData?.id,
            isActive: responseData?.isActive,
            fullPatientName: `${responseData?.firstname} ${
              responseData?.lastname === null ? "" : responseData?.lastname
            }`,
            gender: responseData?.gender,
            dateOfBirth: moment(responseData?.dateOfBirth),
            contactNumber:
              responseData?.contactNumber === ""
                ? null
                : responseData?.contactNumber,
            emails: responseData?.emails,
            client_ID: responseData?.client_ID,
            medicalRecordNo:
              responseData?.medicalRecordNo === ""
                ? null
                : responseData?.medicalRecordNo,
            cardNumber: responseData?.cardNumber,
            governmentId:
              responseData?.governmentId === ""
                ? null
                : responseData?.governmentId,
            phAddresses: phAddressesData,
          })
        );
      }
      if (!isFetchSuccess) {
        notification.error({
          message: "Failed to fetch patient data",
          description: errorMessagesDescription(
            response?.payload?.response?.data?.errorMessages
          ),
        });
      }
    });
  };

  const handleCheckPatientExistingOrder = (patientId) => {
    //Check if the patient has test order that have already accepted by ABC Laboratory
    if (
      checkPatientExistingOrder.pending().type ===
      "patients/check-existing-order/pending"
    ) {
      setIsLoading(true); //Check if the fetching is being processed
    }
    dispatch(
      checkPatientExistingOrder({
        patientId: patientId,
      })
    ).then((res) => {
      const isFetchSuccess = res?.payload?.success;
      if (isFetchSuccess) {
        dispatch(
          handleChangeFields({
            isPatientHasAnAcceptedOrder: res?.payload?.data,
          })
        );
        setIsLoading(false);
      }
    });
  };

  const dataSource = patientData?.map((val) => {
    //Data that will be displayed in table
    return {
      id: val.id,
      medicalRecordNo: val.medicalRecordNo,
      dateCreated: moment(new Date(val.dateCreated)).format("LLL"),
      patientName: `${val.firstname} ${
        val.lastname === null ? "" : val.lastname
      }`,
      contactNumber: val.contactNumber,
      dateOfBirth: moment(new Date(val.dateOfBirth)).format("LL"),
      isActive: val.isActive,
      phAddresses: val.phAddresses,
      emails: val.emails,
      cardNumber: val.cardNumber,
      governmentId: val.governmentId,
      client_ID: val.client_ID,
      gender: val.gender,
    };
  });

  useEffect(() => {
    //Fetching of patients
    handleFetchAllPatientData();
  }, [
    dispatch,
    savedInputs.refetchData,
    clientId,
    debouncedInputValue,
    pageSize,
    sortField,
    currentPage,
    sortDirection,
    handleFetchAllPatientData,
  ]);

  const tableColData = [
    //Column header of patient table
    {
      title: getTranslation("Medical Record No"),
      dataIndex: "medicalRecordNo",
      key: "medicalRecordNo",
      width: 70,
    },
    {
      title: getTranslation("Patient Name"),
      dataIndex: "patientName",
      key: "patientName",
      width: 140,
    },
    {
      title: getTranslation("Date of Birth"),
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      width: 150,
    },
    {
      title: getTranslation("Order Status"),
      key: "isActive",
      width: 65,
      render: (record) => {
        return (
          <Tag
            key={record?.isActive}
            color={record?.isActive ? "green" : "red"}
          >
            {record?.isActive
              ? getTranslation("Active")
              : getTranslation("Inactive")}
          </Tag>
        );
      },
    },
    {
      title: "",
      key: "",
      render: (record) => (
        <Space direction="horizontal" size={2}>
          <BtnAntd
            size="small"
            type="primary"
            style={{ background: "#1677FF", color: "white" }}
            icon={<InfoIcon fontSize="inherit" />}
            onClick={() => handleOpenPatientDetailsModal(record)}
          >
            {getTranslation("Details")}
          </BtnAntd>
          <BtnAntd
            size="small"
            className="hover:bg-orange-500 bg-orange-600"
            style={{
              border: "1px solid #ed6c02",
              color: "white",
            }}
            icon={<EditIcon fontSize="inherit" />}
            onClick={() => handleOpenEditPatientModal(record)}
          >
            {getTranslation("Update")}
          </BtnAntd>
        </Space>
      ),
      width: 150,
    },
  ];

  useEffect(() => {
    const config = {
      search: "",
      pageSize: 0,
      pageNumber: 1,
      sortField: "Id",
      sortDirection: 0,
    };
    dispatch(getProvinceLookUp()).then((response) => {
      //Fetching of province options
      setProvinceOptions(response?.payload?.data);
    });
    dispatch(getAllCityMunicipalities(config)).then((response) => {
      //Fetching of city municipality options
      setCityMunicipalityOptions(response?.payload?.data?.items);
    });
    dispatch(getAllBarangay(config)).then((response) => {
      //Fetching of barangay options
      setBarangayOptions(response?.payload?.data?.items);
    });
    dispatch(getPhilippineAddressTypes()).then((response) => {
      //Fetching of address type options
      setPhAddressesTypeOptions(response?.payload?.data);
    });
    dispatch(getCountryLookUp()).then((response) => {
      //Fetching of country options
      setCountryOptions(response?.payload?.data);
    });
  }, [dispatch]);

  const renderEmptyData = () => (
    <EmptyData
      description="No Patient Detected"
      action={handleOpenCreatePatientModal}
      btnTitle="Add New Patient"
      renderButton={true}
    />
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            placeholder={getTranslation("Search Patient")}
            prefix={<SearchOutlined />}
            onChange={handleSearchChange}
            value={searchInput}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortField}
            onChange={handleChangeSortField}
            style={{
              width: 120,
            }}
            options={[
              {
                value: "dateCreated",
                label: getTranslation("dateCreated"),
              },
              {
                value: "firstname",
                label: getTranslation("firstname"),
              },
              {
                value: "isActive",
                label: getTranslation("Order Status"),
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: getTranslation("Ascending"),
              },
              {
                value: 1,
                label: getTranslation("Descending"),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Button
            startIcon={<AddOutlinedIcon />}
            variant="text"
            onClick={handleOpenCreatePatientModal}
          >
            {getTranslation("New Patient")}
          </Button>
        </Grid>
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={tableColData}
          dataSource={dataSource}
          loading={isLoading}
          size="small"
          pagination={{
            total: totalPages,
            showSizeChanger: true,
            onShowSizeChange: handleShowSizeChange,
            current: currentPage,
            onChange: handleChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <Modal
        closable
        open={showCreatePatientModal || showEditPatientModal}
        width={900}
        footer={null}
        onCancel={handleCloseModal}
      >
        <CreateUpdatePatientModal
          countryOptions={countryOptions}
          phAddressesTypeOptions={phAddressesTypeOptions}
          provinceOptions={provinceOptions}
          cityMunicipalityOptions={cityMunicipalityOptions}
          barangayOptions={barangayOptions}
          isPatientDataLoading={isLoading}
          handleCloseModal={handleCloseModal}
          handleFetchPatientData={handleFetchPatientData}
        />
      </Modal>
      <PatientDetails
        patientDetails={patientDetails}
        open={showViewPatientInfoModal}
        onCancel={handleCloseModal}
      />
    </>
  );
};

export default GetAllPatient;
