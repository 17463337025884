import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_TESTS } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getLaboratoryTestSpecimenSize = createAsyncThunk(
  "labtests/get-laboratory-test-specimen-size",
  async () => {
    try {
      const api = useAxios();
      const response = await api.get(
        URL_LAB_TESTS + "/laboratory-test-specimen-size"
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getLaboratoryTestSpecimenSize };
