export const typeOptions = [
  //Input type of labtest requirement
  {
    id: 0,
    type: "text",
  },
  {
    id: 1,
    type: "number",
  },
  {
    id: 2,
    type: "date",
  },
  {
    id: 3,
    type: "checkbox",
  },
  {
    id: 4,
    type: "radio",
  },
];

export const timePeriodOptions = [
  {
    value: 0,
    label: "ALL DAY",
  },
  {
    value: 1,
    label: "AM",
  },
  {
    value: 2,
    label: "PM",
  },
];

export const getStringWeekName = (number) => {
  switch (number) {
    case 0:
      return {
        color: "magenta",
        str: "Su",
        long: "Sunday",
      };
    case 1:
      return { color: "gold", str: "M", long: "Monday" };
    case 2:
      return { color: "lime", str: "T", long: "Tuesday" };
    case 3:
      return { color: "green", str: "W", long: "Wednesday" };
    case 4:
      return { color: "cyan", str: "Th", long: "Thursday" };
    case 5:
      return { color: "blue", str: "F", long: "Friday" };
    case 6:
      return { color: "purple", str: "S", long: "Saturday" };
    default:
      return { color: "default", str: "", long: "" };
  }
};
