import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_AUTH } from "config/connString";
import authHeader from "utils/authHeader";

const userRegister = createAsyncThunk(
  "auth/register-new-user",
  async (data) => {
    try {
      const response = await axios.post(
        URL_AUTH + "register",
        {
          username: data.username,
          email: data.email,
          password: data.password === "" ? null : data.password,
          displayName: data.displayName,
        },
        authHeader()
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);

export { userRegister };
