import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const getTestOrderById = createAsyncThunk(
  "test-orders/get-test-order-by-id",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(URL_TEST_ORDERS + "/" + data.id);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getTestOrderById };
