import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ATTACHMENTS } from "config/connString";
import useAxios from "utils/useAxios";

const downloadFile = createAsyncThunk(
  "attachments/download-file",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          filePath: data.filePath,
        },
      };
      const response = api.post(URL_ATTACHMENTS + "/download", config);

      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export { downloadFile };
