import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_BARANGAY } from "config/connString";
import useAxios from "utils/useAxios";

const deleteBarangay = createAsyncThunk(
  "barangay/delete-barangay",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(URL_BARANGAY + "/" + data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { deleteBarangay };
