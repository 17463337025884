import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_TESTS } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getLaboratoryTest = createAsyncThunk(
  "labTest/get-lab-test",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(URL_LAB_TESTS + "/" + data.id);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getLaboratoryTest };
