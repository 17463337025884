import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import getDuplicatedSpecimenNames from "components/jkt/testRequest/proxies/getDuplicatedSpecimenNames";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import { handleChangeLabtestData } from "store/slice/savedLabtestSlice";
import { parsedClientDetails } from "utils/parsedClientDetails";

const useAdditionalInfoFunctions = () => {
  const dispatch = useDispatch();
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const orderData = useSelector((state) => state.orderData);
  const { enableVolumeCheck } = parsedClientDetails();

  const handleInputCheckboxChange = (e, checkboxIdx, setInputValues) => {
    //Handler to track what specimen have been selected
    const { checked } = e.target;

    setInputValues((prevValues) => {
      const updatedValues = prevValues.map((input, index) =>
        index === checkboxIdx
          ? { ...input, value: checked ? "true" : "false" }
          : input
      );
      return updatedValues;
    });
  };

  const handleInputChange = (e, inputIdx, setInputValues) => {
    //For handling labtest requirements value
    const { value } = e.target;

    setInputValues((prevValues) => {
      const updatedValues = prevValues.map((input, idx) =>
        inputIdx === idx ? { ...input, value: value } : input
      );
      return updatedValues;
    });
  };

  const handleSelectSpecimen = (e, selectedData, setSelectedSpecimen) => {
    //Function for selecting specimen through checkbox
    const { checked } = e.target;

    if (checked) {
      setSelectedSpecimen((prevSelectedSpecimen) => [
        ...prevSelectedSpecimen,
        selectedData,
      ]);
    } else {
      setSelectedSpecimen((prevSelectedSpecimen) =>
        prevSelectedSpecimen.filter(
          (selectedSpecimen) => selectedSpecimen.key !== selectedData.key
        )
      );
    }
  };

  const filteredLabtestForPickedup = (labtestData) => {
    const isLabtestTypeTest = labtestData.type === "Test";

    if (isLabtestTypeTest) {
      const singleSelectedLabtestSpecimen =
        labtestData?.testDetails?.labTestSpecimens[0]?.name;
      const currentSavedSpecimenLength =
        savedLabtests?.savedLabtestsIdAndSpecimen?.filter(
          (data) => data.specimen.toString() === singleSelectedLabtestSpecimen
        ).length;

      const currentSavedSpecimenQuantity = parseInt(
        multiStepFormInputs.patientSpecimens?.find(
          (specimen) => specimen.key === singleSelectedLabtestSpecimen
        )?.specimenQuantity
      );

      const specimenVolumeRequirement =
        multiStepFormInputs.patientSpecimens?.find(
          (specimen) => specimen.key === singleSelectedLabtestSpecimen
        )?.specimenVolReqData;

      const maxTestCount = specimenVolumeRequirement?.find(
        (volume) => volume.minVolume === currentSavedSpecimenQuantity
      )?.maxTest;

      if (currentSavedSpecimenLength + 1 > maxTestCount) {
        return true;
      }
    }

    if (!isLabtestTypeTest) {
      const specimenNames = getDuplicatedSpecimenNames(labtestData);
      const filteredSpecimenNames = specimenNames.filter((data) =>
        multiStepFormInputs.patientSpecimens.some(
          (specimen) => specimen.key === data.specimenName
        )
      );
      // eslint-disable-next-line
      return filteredSpecimenNames.map((data) => {
        const currentSavedSpecimenLength =
          savedLabtests?.savedLabtestsIdAndSpecimen?.filter(
            (data) => data.specimen.toString() === data.specimenName
          ).length;
        const currentSavedSpecimenQuantity = parseInt(
          multiStepFormInputs.patientSpecimens?.find(
            (specimen) => specimen.key === data.specimenName
          )?.specimenQuantity
        );

        const specimenVolumeRequirement =
          multiStepFormInputs.patientSpecimens?.find(
            (specimen) => specimen.key === data.specimenName
          )?.specimenVolReqData;

        const maxTestCount = specimenVolumeRequirement?.find(
          (volume) => volume.minVolume === currentSavedSpecimenQuantity
        )?.maxTest;

        if (currentSavedSpecimenLength + specimenNames.length > maxTestCount) {
          return true;
        }
      });
    }
    return false;
  };

  const getPatientSpecimensData = (responseData) => {
    const specimenQuantity = enableVolumeCheck
      ? responseData?.testDetails?.labTestSpecimens
          ?.map((data) =>
            data.testVolumeOrSizeRequirements.length === 0
              ? ""
              : data.testVolumeOrSizeRequirements?.find((req) =>
                  Math.min(req.minTest)
                )?.minVolume
          )
          .toString()
      : "";

    dispatch(
      handleChangeMultiFields({
        patientSpecimens: [
          ...multiStepFormInputs.patientSpecimens,
          {
            requiresBloodDraw:
              responseData?.testDetails?.labTestSpecimens[0]?.requiresBloodDraw,
            requiresSampleOrigin:
              responseData?.testDetails?.labTestSpecimens[0]
                ?.requiresSampleOrigin,
            key: responseData?.testDetails?.labTestSpecimens
              ?.map((data) => data.name)
              .toString(),
            selectedCollectionSiteOption: responseData?.testDetails
              ?.labTestSpecimens[0]?.requiresSampleOrigin
              ? "Right arm"
              : null,
            specimenExpirationInHours:
              responseData?.testDetails?.maxSpecimenAgeInHours,
            unitOfMeasure: responseData?.testDetails?.labTestSpecimens
              ?.map((data) => data.unitOfMeasure)
              .toString(),
            specimenId: parseInt(
              responseData?.testDetails?.labTestSpecimens?.map(
                (data) => data.id
              )
            ),
            specimenQuantity: specimenQuantity,
            notes: "",
            specimenVolReqData:
              responseData?.testDetails?.labTestSpecimens?.map(
                (data) => data.testVolumeOrSizeRequirements
              )[0],
            collectionDateTime: moment().format(),
            collectorId: responseData?.testDetails?.labTestSpecimens[0]
              ?.requiresBloodDraw
              ? ""
              : null,
            collectionSite: responseData?.testDetails?.labTestSpecimens[0]
              ?.requiresSampleOrigin
              ? "Right arm"
              : null,
            status: 0,
            specimen: responseData?.testDetails?.labTestSpecimens
              ?.map((data) => data.name)
              .toString(),
          },
        ],
      })
    );
  };

  const getLabtestIdAndSpecimen = (responseData) => {
    dispatch(
      handleChangeLabtestData({
        savedLabtestsIdAndSpecimen: [
          ...savedLabtests?.savedLabtestsIdAndSpecimen,
          {
            testId: responseData?.id,
            specimenExpirationInHours:
              responseData?.testDetails?.maxSpecimenAgeInHours,
            specimen: responseData?.testDetails?.labTestSpecimens.map(
              (data) => data.name
            ),
          },
        ],
      })
    );
  };

  const getNestedLabtestIdAndSpecimen = (
    filteredSavedSelectedIds,
    testIdsToBeAdded
  ) => {
    dispatch(
      handleChangeLabtestData({
        savedLabtestsIdAndSpecimen: [
          ...filteredSavedSelectedIds,
          ...testIdsToBeAdded?.map((data) => ({
            testId: data.testId,
            specimenExpirationInHours: data.specimenExpirationInHours,
            specimen: data.specimen,
          })),
        ],
      })
    );
  };

  const getNestedPatientSpecimensData = (
    filteredSpecimensToAdd,
    duplicatedSpecimenNames
  ) => {
    //For fetching specimen datas of labtest that has child
    dispatch(
      handleChangeMultiFields({
        patientSpecimens: [
          ...multiStepFormInputs.patientSpecimens,
          ...filteredSpecimensToAdd?.map((data) => {
            return {
              requiresSampleOrigin: data.requiresSampleOrigin,
              requiresBloodDraw: data.requiresBloodDraw,
              unitOfMeasure: data.unitOfMeasure,
              specimen: data.specimen,
              key: data.key,
              selectedCollectionSiteOption: data.requiresSampleOrigin
                ? "Right arm"
                : null,
              specimenExpirationInHours: data.specimenExpirationInHours,
              specimenId: data.specimenId,
              specimenQuantity: enableVolumeCheck ? data.specimenQuantity : "",
              notes: "",
              specimenVolReqData: data.specimenVolReqData,
              collectionDateTime: data.collectionDateTime,
              collectorId: data.requiresBloodDraw ? "" : null,
              collectionSite: data.requiresSampleOrigin ? "Right arm" : null,
              status: data.status,
            };
          }),
        ],
      })
    );
  };

  const getNestedTestOrderDetails = (
    labtestsToBeRemovedInTestOrder,
    responseData,
    testIdsToBeAdded,
    duplicatedSpecimenNames,
    specimensToBeAdded
  ) => {
    const existingPatientSpecimenKey = multiStepFormInputs.patientSpecimens
      .filter((specimen) =>
        specimensToBeAdded.some(
          (specimenToBeAdded) =>
            specimen.specimen === specimenToBeAdded.specimen
        )
      )
      ?.map((data) => data.key);

    const defaultRelatedPatientSpecimenKeyValue =
      responseData?.child?.length > 0
        ? [
            ...new Set(
              duplicatedSpecimenNames?.map((data) => data.specimenName)
            ),
          ]
        : specimensToBeAdded?.map((data) => data.specimen);

    const relatedPatientSpecimenKeysValue =
      orderData.isOrderDataForUpdate && existingPatientSpecimenKey.length > 0
        ? existingPatientSpecimenKey
        : defaultRelatedPatientSpecimenKeyValue;

    dispatch(
      handleChangeMultiFields({
        testOrderDetails: [
          ...labtestsToBeRemovedInTestOrder,
          {
            id: 0,
            testOrderId:
              orderData.selectedTestOrderId !== 0
                ? orderData.selectedTestOrderId
                : 0,
            testId: responseData.id,
            testIds:
              responseData?.child?.length > 0
                ? testIdsToBeAdded?.map((test) => test.testId)
                : null,
            status: "Ordered",
            isStat: false,
            duplicatedSpecimenKeys:
              responseData?.child?.length > 0
                ? duplicatedSpecimenNames?.map((data) => data.specimenName)
                : [
                    responseData?.testDetails?.labTestSpecimens
                      ?.map((data) => data.name)
                      .toString(),
                  ],
            relatedPatientSpecimenKeys: relatedPatientSpecimenKeysValue,
          },
        ],
      })
    );
  };

  const getTestOrderDetails = (
    responseData,
    testIdsToBeAdded,
    duplicatedSpecimenNames,
    specimensToBeAdded
  ) => {
    const existingPatientSpecimenKey = multiStepFormInputs.patientSpecimens
      .filter((item1) =>
        specimensToBeAdded.some((item2) => item1.specimen === item2.specimen)
      )
      .map((item3) => item3.key);

    const defaultRelatedPatientSpecimenKeyValue =
      responseData?.child?.length > 0
        ? [...new Set(specimensToBeAdded?.map((data) => data.specimen))]
        : specimensToBeAdded?.map((data) => data.specimen);

    const relatedPatientSpecimenKeysValue =
      orderData.isOrderDataForUpdate && existingPatientSpecimenKey.length > 0
        ? existingPatientSpecimenKey
        : defaultRelatedPatientSpecimenKeyValue;

    dispatch(
      handleChangeMultiFields({
        testOrderDetails: [
          ...multiStepFormInputs.testOrderDetails,
          {
            id: 0,
            testOrderId:
              orderData.selectedTestOrderId !== 0
                ? orderData.selectedTestOrderId
                : 0,
            testId: responseData?.id,
            testIds:
              responseData?.child?.length > 0
                ? testIdsToBeAdded?.map((test) => test.testId)
                : null,
            status: "Ordered",
            isStat: false,
            duplicatedSpecimenKeys: duplicatedSpecimenNames?.map(
              (data) => data.specimenName
            ),
            relatedPatientSpecimenKeys: relatedPatientSpecimenKeysValue,
          },
        ],
      })
    );
  };

  const getLabtestData = (filteredSavedTest, testDataToBeAdded) => {
    dispatch(
      handleChangeLabtestData({
        savedLabtestsData: [...filteredSavedTest, testDataToBeAdded],
      })
    );
  };

  return {
    handleInputCheckboxChange,
    handleInputChange,
    handleSelectSpecimen,
    filteredLabtestForPickedup,
    getPatientSpecimensData,
    getLabtestIdAndSpecimen,
    getNestedLabtestIdAndSpecimen,
    getNestedPatientSpecimensData,
    getNestedTestOrderDetails,
    getTestOrderDetails,
    getLabtestData,
  };
};

export default useAdditionalInfoFunctions;
