import { Box } from "@mui/material";
import React from "react";
import FirstSection from "./firstSection";
import SecondSection from "./secondSection";
import ThirdSection from "./thirdSection";
import FourthSection from "./fourthSection";
import FifthSection from "./fifthSection";

export const FirstStep = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: {
          xs: "0.3rem",
          sm: "0.3rem",
          md: "0.9rem",
          lg: "2rem",
          xl: "2rem",
        },
      }}
    >
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
    </Box>
  );
};
