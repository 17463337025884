import {
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useNavigate } from "react-router-dom";

const ReceivingSearchInput = ({ handleChangeInputOrderId, inputOrderId }) => {
  const navigate = useNavigate();
  const emptyInputOrderId = inputOrderId.trim().length === 0;

  const navigateToReceivingTestOrder = () => {
    navigate("/lab/receiving/" + inputOrderId);
  };

  const handleSearchValue = () => {
    if (emptyInputOrderId) return;
    navigateToReceivingTestOrder();
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (emptyInputOrderId) return;
    if (e.keyCode === 13) {
      navigateToReceivingTestOrder();
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "70vh" }}
    >
      <Typography color={"green"} variant="h3" gutterBottom>
        <QrCodeScannerIcon color="inherit" fontSize="inherit" /> Scan to view
        Test Order
      </Typography>
      <FormControl variant="filled">
        <InputLabel htmlFor="order-id">Order Number</InputLabel>
        <FilledInput
          id="order-id"
          onChange={handleChangeInputOrderId}
          onKeyDown={handleKeypress}
          autoFocus
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleSearchValue}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Grid>
  );
};

export default ReceivingSearchInput;
