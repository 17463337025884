import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import { theme } from "components/jkt/global/theme";
import MenuBar from "components/jkt/global/menuBar";
import GetAllTestOrder from "components/jkt/admin/testOrder/getAllTestOrder";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";
import TableContentLayout from "views/jkt/layout/tableContentLayout";
import useTranslation from "hooks/useTranslation";

const TestOrderPage = () => {
  const { getTranslation } = useTranslation("SidebarItems");

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={getTranslation("Test Order")} />
        <TableContentLayout>
          <GetAllTestOrder />
        </TableContentLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default TestOrderPage;
