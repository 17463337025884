export const timePeriodOptions = [
  {
    value: 0,
    label: "ALL DAY",
  },
  {
    value: 1,
    label: "AM",
  },
  {
    value: 2,
    label: "PM",
  },
];

export const requirementTypeOptions = [
  {
    value: 0,
    label: "Text",
  },
  {
    value: 1,
    label: "Number",
  },
  {
    value: 2,
    label: "Date",
  },
  {
    value: 3,
    label: "Checkbox",
  },
  {
    value: 4,
    label: "Radio",
  },
];

export const weekOptions = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
];

export const getWeekDay = (schedule) => {
  const weekDayMap = {
    0: { label: "Sunday" },
    1: { label: "Monday" },
    2: { label: "Tuesday" },
    3: { label: "Wednesday" },
    4: { label: "Thursday" },
    5: { label: "Friday" },
    6: { label: "Saturday" },
  };
  const selectedWeekDay = weekDayMap[schedule];
  const validatedSelectedWeekDay =
    selectedWeekDay === undefined ? schedule : selectedWeekDay;

  return validatedSelectedWeekDay;
};

export const getRequirementType = (requirement) => {
  const requirementMap = {
    0: { requirementType: "Text" },
    1: { requirementType: "Number" },
    2: { requirementType: "Date" },
    3: { requirementType: "Checkbox" },
  };

  const selectedRequirement = requirementMap[requirement];
  const validatedSelectedRequirement =
    selectedRequirement === undefined
      ? {
          requirementType: "Radio",
        }
      : selectedRequirement;

  return validatedSelectedRequirement;
};

export const orderStatusTagColor = (orderStatus) => {
  //Function for getting the tag color of order status

  const orderStatusMap = {
    canceled: { color: "gray" },
    pickedup: { color: "blue" },
    accepted: { color: "green" },
    rejected: { color: "gray" },
    readyforpickup: { color: "orange" },
    inclientprocessing: { color: "orange" },
    hold: { color: "gold" },
    pending: { color: "purple" },
    intransit: { color: "geekblue" },
    completeddelivery: { color: "lime" },
    missedpickup: { color: "red" },
  };

  const selectedOrderStatus = orderStatusMap[orderStatus?.toLowerCase()];
  const validatedSelectedOrderStatusColor =
    selectedOrderStatus === undefined
      ? {
          color: "default",
        }
      : selectedOrderStatus;

  return validatedSelectedOrderStatusColor;
};

export const getStringWeekName = (number) => {
  //Getting the week name and color of the schedule of labtest
  switch (number) {
    case 0:
      return {
        color: "magenta",
        str: "Su",
        long: "Sunday",
      };
    case 1:
      return { color: "gold", str: "M", long: "Monday" };
    case 2:
      return { color: "lime", str: "T", long: "Tuesday" };
    case 3:
      return { color: "green", str: "W", long: "Wednesday" };
    case 4:
      return { color: "cyan", str: "Th", long: "Thursday" };
    case 5:
      return { color: "blue", str: "F", long: "Friday" };
    case 6:
      return { color: "purple", str: "S", long: "Saturday" };
    default:
      return { color: "default", str: "", long: "" };
  }
};
