import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CITY_MUNICIPALITY } from "config/connString";
import useAxios from "utils/useAxios";

const getCityMunicipalitiesLookUp = createAsyncThunk(
  "citymunicipalities/citymunicipalities-look-up",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          provinceId: data.provinceId,
        },
      };
      const response = await api.get(
        URL_CITY_MUNICIPALITY +
          "/" +
          data.provinceId +
          "/cities-municipalities-lookup",
        config
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getCityMunicipalitiesLookUp };
