import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "store";
import ReceivingButtons from "./receivingButtons";

const ReceivingMenuBar = ({
  orderStatus,
  onClickAcceptBtn,
  onReturn,
  onShowHoldReasonModal,
  onShowRejectReasonModal,
  isLoading,
  isMinimalOrder,
  hasFetchedData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <AppBar position="fixed">
      <Toolbar>
        {hasFetchedData && (
          <Tooltip title="Return">
            <IconButton
              sx={{ transform: "translateX(-20px)" }}
              onClick={onReturn}
            >
              <KeyboardBackspaceRoundedIcon
                sx={{
                  fontSize: "2rem",
                  cursor: "pointer",
                  color: "white",
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Receiving
        </Typography>
        {hasFetchedData && (
          <ReceivingButtons
            orderStatus={orderStatus}
            onClickAcceptBtn={onClickAcceptBtn}
            onShowHoldReasonModal={onShowHoldReasonModal}
            onShowRejectReasonModal={onShowRejectReasonModal}
            isLoading={isLoading}
            isMinimalOrder={isMinimalOrder}
          />
        )}
        <Tooltip title="Logout">
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
              dispatch(logout());
              navigate("/lab");
            }}
            color="inherit"
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default ReceivingMenuBar;
