import * as React from "react";
import { Button, Table, Tag, Skeleton, Tooltip, Select, Alert } from "antd";
import { Box, Paper } from "@mui/material";
import { getTestsLookUp, getLaboratoryTest } from "store";
import { useDispatch } from "react-redux";
import decodedUserDetails from "utils/decodedUserDetails";
import { sortDataAlphabetically } from "utils/sortDataAlphabetically";
import useSelectDropdownLoader from "hooks/useSelectDropdownLoader";

const filterOption = (inputValue, option) =>
  (option.data.name &&
    option.data.name.toUpperCase().includes(inputValue.toUpperCase())) ||
  (option.data.specimen &&
    option.data.specimen.toUpperCase().includes(inputValue.toUpperCase())) ||
  (option.data.abbreviation &&
    option.data.abbreviation
      .toUpperCase()
      .includes(inputValue.toUpperCase())) ||
  (option.data.displayName &&
    option.data.displayName.toUpperCase().includes(inputValue.toUpperCase()));

const TestSelection = (props) => {
  const {
    type,
    handleSelectedData,
    isForUpdate,
    currentTestUpdate,
    selectedTest,
    setSelectedTest,
    selectedGender,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [tableLoader, setTableLoader] = React.useState(false);
  const [alertPopupOptions, setAlertPopupOptions] = React.useState({
    showAlertPopup: false,
    alertMessage: "",
  });
  const [data, setData] = React.useState([]);
  const clientId = decodedUserDetails()?.clientId;
  const emptySelectedGender = selectedGender === "";
  const sortedSelectedTest = selectedTest?.sort((a, b) =>
    a?.code?.localeCompare(b?.code)
  );
  const { onDropdownVisibleChange, onBlur, isSelectOpening } =
    useSelectDropdownLoader();

  const LoadData = React.useCallback(() => {
    //HANDLE LOADING
    if (
      getTestsLookUp.pending().type === "labtests/get-tests-look-up/pending"
    ) {
      setLoading(true);
    }
    dispatch(
      getTestsLookUp({
        clientId: clientId,
        gender: selectedGender,
      })
    ).then((val) => {
      if (val.type === "labtests/get-tests-look-up/fulfilled") {
        let testIds = currentTestUpdate?.map((element) => {
          return element.id;
        });

        if (type === "Profile") {
          setData(
            val?.payload?.data?.filter(
              (test) => test.type === "Test" && !testIds?.includes(test.id)
            )
          );
        }

        if (type === "Panel") {
          setData(
            val?.payload?.data?.filter(
              (test) =>
                (test.type === "Test" || test.type === "Profile") &&
                !testIds?.includes(test.id)
            )
          );
        }

        if (type === "Package") {
          setData(
            val?.payload?.data?.filter(
              (test) =>
                (test.type === "Test" ||
                  test.type === "Panel" ||
                  test.type === "Profile") &&
                !testIds?.includes(test.id)
            )
          );
        }

        setLoading(false);
      }
    });
  }, [dispatch, currentTestUpdate, type, selectedGender, clientId]);

  React.useEffect(() => {
    handleSelectedData(selectedTest);
    // eslint-disable-next-line
  }, [selectedTest]);

  React.useEffect(() => {
    LoadData();
    if (isForUpdate) {
      setSelectedTest(
        currentTestUpdate?.map((val) => ({
          code: val.code,
          name: val.name,
          type: val.type,
          data: {
            code: val.code,
            id: val.id,
            labTest: "UPDATE",
            name: val.name,
            type: val.type,
          },
        }))
      );
    }
    // eslint-disable-next-line
  }, [currentTestUpdate, dispatch, LoadData, isForUpdate]);

  const isLabtestSpecimenEmpty = async (labtestId) => {
    setTableLoader(true);
    const res = await dispatch(getLaboratoryTest({ id: labtestId }));
    const isSpecimenEmpty =
      res?.payload?.data?.testDetails?.labTestSpecimens?.length === 0;

    setTableLoader(false);
    if (isSpecimenEmpty) {
      return true;
    }
    return false;
  };

  const onSelectOption = async (id, option) => {
    const { code, name, type } = option.data;
    const emptyPatientSpecimens = await isLabtestSpecimenEmpty(id);

    if (emptyPatientSpecimens) {
      return setAlertPopupOptions({
        showAlertPopup: true,
        alertMessage: (
          <>
            <strong>{name}</strong> does not have a configured specimen, it
            can't be added.
          </>
        ),
      });
    }
    setSelectedTest([
      ...selectedTest,
      {
        code: code,
        name: name,
        type: type,
        data: option.data,
      },
    ]);
    setData(data?.filter((value) => value.id !== id));
    handleSelectedData(selectedTest);
    closeAlertPopup();
  };

  const closeAlertPopup = () => {
    setAlertPopupOptions({
      showAlertPopup: false,
      alertMessage: "",
    });
  };

  const handleRemoveSelectedLabtest = (selectedData) => {
    const filteredTest = selectedTest.filter(
      (val) => val.data.id !== selectedData.data.id
    );
    setSelectedTest(filteredTest);

    setData([...data, selectedData.data]);
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    { title: "Type", dataIndex: "type", key: "type" },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (rec, data) => (
        <Button
          type="primary"
          size="small"
          className="danger"
          onClick={() => {
            handleRemoveSelectedLabtest(data);
          }}
          danger
        >
          Remove
        </Button>
      ),
    },
  ];

  const getTagTypeByLabtestType = (type) => {
    const tagTypeMap = {
      Test: "processing",
      Profile: "magenta",
      Panel: "green",
    };

    const selectedTagType = tagTypeMap[type];

    return selectedTagType;
  };

  const labtestOptions = sortDataAlphabetically(data, "name")?.map((val) => ({
    value: val.id,
    label: (
      <>
        <b>({val.code})</b> {val.name}{" "}
        <Tag color={getTagTypeByLabtestType(val?.type)}>{val.type}</Tag>
      </>
    ),
    data: val,
  }));

  return (
    <>
      {type === "" || type === "Test" ? null : (
        <>
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <Paper variant="outlined">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  {alertPopupOptions.showAlertPopup && (
                    <Alert
                      style={{
                        width: "100%",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                      message={alertPopupOptions.alertMessage}
                      type="error"
                      showIcon
                      closable
                      afterClose={closeAlertPopup}
                    />
                  )}
                  <Tooltip
                    placement="top"
                    title={
                      emptySelectedGender
                        ? "Select first the gender to which the 'Type' is applicable."
                        : null
                    }
                    arrow
                  >
                    <Select
                      value={<p></p>}
                      loading={isSelectOpening}
                      disabled={emptySelectedGender}
                      style={{
                        width: 360,
                        margin: "0.4rem 0.6rem",
                      }}
                      onSelect={onSelectOption}
                      virtual={false}
                      showSearch
                      placeholder="Search or select a labtest..."
                      optionFilterProp="children"
                      options={labtestOptions}
                      filterOption={filterOption}
                      onDropdownVisibleChange={onDropdownVisibleChange}
                      onBlur={onBlur}
                    />
                  </Tooltip>
                </Box>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={sortedSelectedTest}
                  loading={loading || tableLoader}
                />
              </Paper>
            </>
          )}
        </>
      )}
    </>
  );
};
export default TestSelection;
