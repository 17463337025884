import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import notification from "components/jkt/global/openNotification";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getQrCodeById } from "store";

const QrChecker = ({ isSelectedOptionToCheckQr, setIsLoading, isLoading }) => {
  const [checkerInput, setCheckerInput] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const checkerInputRef = useRef();
  const dispatch = useDispatch();
  const isCheckerInputEmpty = isFormDirty && !checkerInput;
  const characterInputLimit = 14;

  const handleInputChange = (e) => {
    //For tracking checker's input value
    const { value } = e.target;
    setCheckerInput(value.slice(0, characterInputLimit));
  };

  useEffect(() => {
    //Check if the active tab is for checking of qr, then auto focus the input field
    if (isSelectedOptionToCheckQr) {
      checkerInputRef.current.focus();
    }
  }, [isSelectedOptionToCheckQr, checkerInput]);

  const handleCheckQrCode = (e) => {
    //Submit function for checking the qr code, if it is already pre regsitered
    checkerInputRef.current.focus();
    e.preventDefault();
    if (!checkerInput) {
      return setIsFormDirty(true); //Validation of form input
    } else {
      if (getQrCodeById.pending().type === "qr-code/get-qrcode-by-id/pending") {
        setIsLoading(true); //Check if the request is being processed
      }
      dispatch(
        getQrCodeById({
          qrcode: checkerInput,
        })
      ).then((response) => {
        //Dispatch get action
        const isFetchSuccess = response?.payload?.success; //Check if request is successful
        if (isFetchSuccess) {
          //Display success notification if the request succeed
          notification.success({
            message: "Checked Qr Code",
            description: response?.payload?.successMessage,
          });
          setCheckerInput("");
          setIsLoading(false);
          setIsFormDirty(false);
        }
        if (!isFetchSuccess) {
          //If the request fails, it will automatically show an error message
          notification.error({
            message: "Failed to check qr code",
            description: errorMessagesDescription(
              response?.payload?.response?.data?.errorMessages
            ),
          });
          setCheckerInput("");
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <div
      className={`mt-[2.2rem] px-6 ${
        !isSelectedOptionToCheckQr && "scale-0 hidden"
      } duration-300 ease-in-out`}
    >
      <form onSubmit={handleCheckQrCode} className="flex flex-col gap-1">
        <label className="required">Qr Code ID</label>
        <input
          value={checkerInput}
          ref={checkerInputRef}
          onChange={handleInputChange}
          className={`${
            isCheckerInputEmpty
              ? "border border-red-600 focus:border-red-600"
              : " focus:border-blue-500"
          } rounded-md py-2 px-2 border border-black  focus:outline-none`}
          type="text"
        />
        {isCheckerInputEmpty && (
          <p className="text-red-600 text-xs">Qr Code Id is required</p>
        )}
        <button
          disabled={isLoading}
          className="rounded-md w-full py-2 px-4 mt-3 bg-[#1976D2] disabled:bg-blue-400 text-white"
        >
          {isLoading ? "Checking..." : "Check"}
        </button>
      </form>
    </div>
  );
};

export default QrChecker;
