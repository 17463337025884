import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  savedTestInfo: [],
  fetchedInputFields: [],
  savedRequirementDetails: [],
  isSampleCollectorInputEmpty: false,
  isSampleOriginInputEmpty: false,
};

const savedTestInputsInfo = createSlice({
  name: "savedTestInputsInfo",
  initialState,
  reducers: {
    handleChangeSavedTestInfo: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { handleChangeSavedTestInfo } = savedTestInputsInfo.actions;
export const savedTestInputsInfoReducer = savedTestInputsInfo.reducer;
