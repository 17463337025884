import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_PATIENT } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const getPatientLookup = createAsyncThunk(
  "patients/get-patient-lookup",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;

      const api = useAxios();
      const config = {
        params: {
          clientId: clientId,
          search: "",
          pageSize: 0,
          pageNumber: 1,
        },
      };
      const response = await api.get(
        URL_PATIENT + "/" + clientId + "/patients-lookup",
        config
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getPatientLookup };
