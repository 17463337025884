import { Box, Grid, Skeleton, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { orderStatusTagColor as orderStatusTextColor } from "components/jkt/admin/labtest/data";
import useTranslation from "hooks/useTranslation";

const HeaderContent = (props) => {
  const { orderStatus, orderNumber, isLoading, clientDetails, cancelReason } =
    props;
  const lowerCasedOrderStatus = orderStatus?.toLowerCase();
  const isOrderStatusCancelled = lowerCasedOrderStatus === "canceled";
  const isOrderStatusHold = lowerCasedOrderStatus === "hold";
  const isOrderStatusRejected = lowerCasedOrderStatus === "rejected";
  const { getTranslation } = useTranslation("RequestPage");

  const renderReasonLabel = () => {
    const orderStatusLabelMap = {
      canceled: "Cancellation Reason",
      hold: "Held Reason",
      rejected: "Rejection Reason",
    };

    return orderStatusLabelMap[lowerCasedOrderStatus];
  };

  return (
    <Grid rowSpacing={2} columnSpacing={1} container>
      <Grid
        sx={{ display: "flex", flexDirection: "column" }}
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        item
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "500", color: "darkBlue.main" }}
        >
          {getTranslation("Client no")}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            width: "max-content",
            color: "black",
            marginTop: "0.3rem",
            fontSize: "1.3rem",
          }}
        >
          {isLoading ? (
            <Skeleton variant="rounded" width={210} height={25} />
          ) : (
            clientDetails?.clientCode
          )}
        </Typography>
      </Grid>
      <Grid
        sx={{ display: "flex", flexDirection: "column" }}
        xs={4}
        sm={4}
        md={4}
        lg={4}
        xl={4}
        item
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "500", color: "darkBlue.main" }}
        >
          {getTranslation("Client name")}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            width: "auto",
            color: "black",
            marginTop: "0.3rem",
            fontSize: "1.3rem",
          }}
        >
          {isLoading ? (
            <Skeleton variant="rounded" width={210} height={25} />
          ) : (
            clientDetails?.clientName
          )}
        </Typography>
      </Grid>
      <Grid
        sx={{
          flexDirection: "column",
        }}
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        item
      >
        <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          {isLoading ? (
            <Skeleton variant="rounded" width={80} height={80} />
          ) : (
            <QRCode
              style={{
                display: orderNumber === null ? "none" : "block",
                marginRight: "0.7rem",
              }}
              size={80}
              value={orderNumber + ""}
            />
          )}

          <Box>
            <Typography
              variant="p"
              sx={{ fontWeight: "500", color: "darkBlue.main" }}
            >
              {getTranslation("Order no")}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                width: "max-content",
                color: "black",
                marginTop: "0.3rem",
                fontSize: "1.3rem",
              }}
            >
              {isLoading && (
                <Skeleton variant="rounded" width={100} height={25} />
              )}
              {!isLoading && orderNumber === null ? (
                <Typography
                  sx={{
                    fontStyle: "italic",
                    color: "softBlue.secondary",
                    fontWeight: "bold",
                  }}
                  variant="p"
                >
                  {"[pending]"}
                </Typography>
              ) : (
                orderNumber
              )}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "bold",
                backgroundColor: "#EBEFF8",
                padding: "0.1rem 0.3rem",
                color: orderStatusTextColor(orderStatus).color,
              }}
              variant="p"
            >
              {orderStatus?.toUpperCase()}
            </Typography>
            {(isOrderStatusCancelled ||
              isOrderStatusHold ||
              isOrderStatusRejected) && (
              <Box sx={{ marginTop: "0.3rem" }}>
                <Typography sx={{ color: "darkBlue.main" }} variant="p">
                  {renderReasonLabel()}:{" "}
                  <Typography sx={{ color: "black" }} variant="p">
                    {cancelReason}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeaderContent;
