import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { useDispatch, useSelector } from "react-redux";
import { getClientInfo } from "store";
import { Button } from "antd";
import useClearReduxStore from "hooks/useClearReduxStore";
import useCheckProvidedDate from "hooks/useCheckProvidedDate";
import { handleChangeLabtestData } from "store/slice/savedLabtestSlice";
import { handleChangeOrderData } from "store/slice/orderSlice";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import notification from "components/jkt/global/openNotification";
import SidePanelWrapper from "components/jkt/global/sidePanelWrapper";
import PopConfirm from "components/jkt/global/popConfirm";
import useTranslation from "hooks/useTranslation";
import { parsedClientDetails } from "utils/parsedClientDetails";

const RequestPageSidePanel = ({
  handleNext,
  handlePrev,
  isFirstStep,
  isLastStep,
  steps,
  progressText,
  currentStepIndex,
  setCurrentStepIndex,
}) => {
  const savedInputs = useSelector((state) => state.savedInputs);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const orderData = useSelector((state) => state.orderData);
  const dispatch = useDispatch();
  const [clientDetails, setClientDetails] = useState({});
  const { handleResetReduxStore } = useClearReduxStore();
  const { checkIfProvidedDateInFuture, checkIfProvidedDateExceeded } =
    useCheckProvidedDate();
  const { enableVolumeCheck, enableCollectionSite } = parsedClientDetails();
  const isSampleCollectorEmpty = multiStepFormInputs.patientSpecimens.some(
    (data) => data.collectorId === ""
  );
  const isCollectionSiteEmpty = multiStepFormInputs.patientSpecimens.some(
    (data) => data.collectionSite === ""
  );
  const { getGlobalTranslation, getTranslation } =
    useTranslation("RequestPage");
  const isCollectionDateAndTimeSetInFuture =
    multiStepFormInputs.patientSpecimens.some((data) =>
      checkIfProvidedDateInFuture(data.collectionDateTime)
    );
  const isCollectionDateAndTimeExceeded =
    multiStepFormInputs.patientSpecimens.some((data) =>
      checkIfProvidedDateExceeded(data.collectionDateTime)
    );
  const isTestOrderDetailsEmpty =
    multiStepFormInputs.testOrderDetails.length === 0;
  const isPatientEmpty = multiStepFormInputs.patientId === 0;
  const isClinicalDiagnosisEmpty =
    multiStepFormInputs.diagnosesList.length === 0;
  const isInFirstPage = currentStepIndex === 0;
  const isInSecondPage = currentStepIndex === 1;
  const isPatientWillDrawTheSpecimen =
    multiStepFormInputs.clientWillDrawSpecimen;
  const hasEmptyCollectorId = multiStepFormInputs.patientSpecimens.some(
    (specimen) => specimen.collectorId === 0
  );
  const hasEmptyCollectionSite = multiStepFormInputs.patientSpecimens.some(
    (specimen) => specimen.collectionSite === ""
  );

  const isSpecimenQuantityEmpty = multiStepFormInputs.patientSpecimens.some(
    (specimen) => specimen.specimenQuantity === ""
  );

  const activeSpanStyle = {
    backgroundColor: "#2B469B",
    color: "white",
  };
  const inactiveSpanStyle = {
    backgroundColor: "white",
    color: "#2B469B",
  };
  const activeCheckStyle = {
    backgroundColor: "#2B469B",
    color: "white",
  };
  const inactiveCheckStyle = {
    backgroundColor: "#2B469B",
    color: " white",
  };

  const handleValidateFirstPage = () => {
    if (isInFirstPage && isPatientEmpty) {
      dispatch(
        handleChangeSavedFields({
          isPatientNull: true,
        })
      );
      notification.warning({
        message: `${getTranslation("Patient Name")} ${getGlobalTranslation(
          "is required"
        )}`,
        description: `${getGlobalTranslation(
          "Please select or create patient first before proceeding to the next page"
        )}`,
      });
    }
    if (isInFirstPage && isClinicalDiagnosisEmpty) {
      dispatch(
        handleChangeSavedFields({
          isClinicalDiagnosisEmpty: true,
        })
      );
      notification.warning({
        message: `${getTranslation(
          "Clinical Diagnosis"
        )} ${getGlobalTranslation("is required")}`,
        description: `${getGlobalTranslation(
          "Please select clinical diagnosis first before proceeding to the next page if it will be empty please put N/A"
        )}`,
      });
    }

    if (isInFirstPage && !isPatientEmpty && !isClinicalDiagnosisEmpty) {
      setCurrentStepIndex((prevIndex) => {
        if (prevIndex >= steps.length - 1) {
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }
  };

  const handleValidateSecondPage = () => {
    if (isInSecondPage) {
      handleCheckTestOrderDetails();
      if (isPatientWillDrawTheSpecimen) {
        // const invalidCollectionDateTime = handleValidateSpecimenValidity();

        dispatch(
          handleChangeLabtestData({
            showInvalidSpecimensModal:
              !hasEmptyCollectorId && !hasEmptyCollectionSite ? true : false,
            // savedInvalidCollectionDateTime: invalidCollectionDateTime,
          })
        );

        setCurrentStepIndex((prevIndex) => {
          return prevIndex;
        });

        if (
          currentStepIndex === 1 &&
          // invalidCollectionDateTime.length === 0 &&
          !isSampleCollectorEmpty &&
          !isCollectionSiteEmpty &&
          !isTestOrderDetailsEmpty &&
          !isSpecimenQuantityEmpty &&
          !isCollectionDateAndTimeSetInFuture &&
          !isCollectionDateAndTimeExceeded
        ) {
          setCurrentStepIndex((prevIndex) => {
            if (prevIndex >= steps.length - 1) {
              return prevIndex;
            }
            return prevIndex + 1;
          });

          dispatch(
            handleChangeOrderData({
              orderAgain: false,
            })
          );
          dispatch(
            handleChangeLabtestData({
              showInvalidSpecimensModal: false,
              savedInvalidSpecimens: [],
            })
          );
        }
        handleCheckSampleCollector();
        if (enableCollectionSite) {
          handleCheckCollectionSite();
        }
        handleCheckSpecimenQuantity();
        handleCheckCollectionDateAndTime();
      }

      if (
        currentStepIndex === 1 &&
        !multiStepFormInputs.clientWillDrawSpecimen &&
        !isTestOrderDetailsEmpty
      ) {
        setCurrentStepIndex((prevIndex) => {
          if (prevIndex >= steps.length - 1) {
            return prevIndex;
          }
          return prevIndex + 1;
        });
        dispatch(
          handleChangeOrderData({
            orderAgain: false,
          })
        );
        dispatch(
          handleChangeLabtestData({
            showInvalidSpecimensModal: false,
            savedInvalidSpecimens: [],
          })
        );
      }
    }
  };

  const handleValidateVolumeInSecondPage = () => {
    if (isInSecondPage) {
      handleCheckTestOrderDetails();
      if (isPatientWillDrawTheSpecimen) {
        const invalidSpecimens = handleValidateSpecimens();
        const filteredInvalidSpecimens =
          validatedSpecimensData(invalidSpecimens);
        const filteredRequiredVolume = validateSpecimenVolume();

        dispatch(
          handleChangeLabtestData({
            savedInvalidSpecimens: filteredInvalidSpecimens?.map((data) => ({
              specimen: data.specimen,
              specimenQuantity: data.specimenQuantity,
              unitOfMeasure: data.unitOfMeasure,
              requiredVolume: filteredRequiredVolume,
              specimenVolReqData: data?.specimenVolReqData,
              specimenTotal: multiStepFormInputs?.testOrderDetails
                .flatMap((data) => data?.duplicatedSpecimenKeys)
                .filter((specimenName) => specimenName === data.specimen)
                .length,
            })),
            showInvalidSpecimensModal:
              !isSampleCollectorEmpty && !isCollectionSiteEmpty ? true : false,
          })
        );

        setCurrentStepIndex((prevIndex) => {
          return prevIndex;
        });

        if (
          filteredInvalidSpecimens.length === 0 &&
          currentStepIndex === 1 &&
          !isSampleCollectorEmpty &&
          !isCollectionSiteEmpty &&
          !isTestOrderDetailsEmpty &&
          !isSpecimenQuantityEmpty &&
          !isCollectionDateAndTimeSetInFuture &&
          !isCollectionDateAndTimeExceeded
        ) {
          setCurrentStepIndex((prevIndex) => {
            if (prevIndex >= steps.length - 1) {
              return prevIndex;
            }
            return prevIndex + 1;
          });

          dispatch(
            handleChangeOrderData({
              orderAgain: false,
            })
          );
          dispatch(
            handleChangeLabtestData({
              showInvalidSpecimensModal: false,
              savedInvalidSpecimens: [],
            })
          );
        }
        handleCheckSampleCollector();
        if (enableCollectionSite) {
          handleCheckCollectionSite();
        }
        handleCheckSpecimenQuantity();
        handleCheckCollectionDateAndTime();
      }

      if (
        currentStepIndex === 1 &&
        !multiStepFormInputs.clientWillDrawSpecimen &&
        !isTestOrderDetailsEmpty
      ) {
        setCurrentStepIndex((prevIndex) => {
          if (prevIndex >= steps.length - 1) {
            return prevIndex;
          }
          return prevIndex + 1;
        });
        dispatch(
          handleChangeOrderData({
            orderAgain: false,
          })
        );
        dispatch(
          handleChangeLabtestData({
            showInvalidSpecimensModal: false,
            savedInvalidSpecimens: [],
          })
        );
      }
    }
  };

  // const handleValidateSpecimenValidity = () => {
  //   return multiStepFormInputs.patientSpecimens.filter((data) => {
  //     const expirationHours = data?.specimenExpirationInHours;
  //     const combinedDate = moment(data.collectionDateTime)
  //       .add(expirationHours, "h")
  //       .format();
  //     const currentDate = moment().format();
  //     const invalidDateTime = combinedDate < currentDate;
  //     if (combinedDate >= currentDate) return;

  //     return invalidDateTime;
  //   });
  // };

  const handleValidateSpecimens = () => {
    return multiStepFormInputs?.patientSpecimens?.filter((specimen) => {
      const patientSpecimenKeysLength = multiStepFormInputs?.testOrderDetails
        .flatMap((data) => data?.duplicatedSpecimenKeys)
        .filter((specimenName) => specimenName === specimen.specimen).length;
      const requiredVolume = parseFloat(
        specimen?.specimenVolReqData?.find(
          (req) =>
            req.minTest <= patientSpecimenKeysLength &&
            patientSpecimenKeysLength <= req.maxTest
        )?.minVolume
      );
      const providedQty = parseFloat(specimen.specimenQuantity);
      // eslint-disable-next-line
      if (providedQty >= requiredVolume) return;

      return requiredVolume !== providedQty;
    });
  };

  const validatedSpecimensData = (invalidSpecimens) => {
    return invalidSpecimens?.filter((specimen) => {
      const patientSpecimenKeysLength = multiStepFormInputs?.testOrderDetails
        .flatMap((data) => data?.duplicatedSpecimenKeys)
        .filter((specimenName) => specimenName === specimen.specimen).length;

      const filteredSpecimenVolReqData = specimen?.specimenVolReqData?.some(
        (data) => Math.max(data.maxTest) >= patientSpecimenKeysLength
      );

      return filteredSpecimenVolReqData;
    });
  };

  const validateSpecimenVolume = () => {
    const requiredVolume = multiStepFormInputs?.patientSpecimens?.map(
      (specimen) => {
        const patientSpecimenKeysLength = multiStepFormInputs?.testOrderDetails
          .flatMap((data) => data?.duplicatedSpecimenKeys)
          .filter((specimenName) => specimenName === specimen.specimen).length;
        return specimen?.specimenVolReqData?.find(
          (req) =>
            req.minTest <= patientSpecimenKeysLength &&
            patientSpecimenKeysLength <= req.maxTest
        );
      }
    );

    return requiredVolume.filter((data) => data !== undefined);
  };

  const handleCheckSampleCollector = () => {
    if (isInSecondPage && isSampleCollectorEmpty) {
      dispatch(
        handleChangeSavedFields({
          isSampleCollectorEmpty: true,
        })
      );
      return notification.warning({
        message: `${getTranslation("Sample Collector")} ${getGlobalTranslation(
          "is required"
        )}`,
        description: `${getGlobalTranslation(
          "Please select sample collector for each specimen before proceeding to the next page"
        )}`,
      });
    }
  };

  const handleCheckSpecimenQuantity = () => {
    if (isInSecondPage && isSpecimenQuantityEmpty) {
      dispatch(
        handleChangeSavedFields({
          isSpecimenQuantityEmpty: true,
        })
      );
      dispatch(
        handleChangeLabtestData({
          showInvalidSpecimensModal: false,
          savedInvalidSpecimens: [],
        })
      );
      return notification.warning({
        message: `${getTranslation("Specimen Quantity")} ${getGlobalTranslation(
          "is required"
        )}`,
        description: `${getGlobalTranslation(
          "Please set a quantity for each specimen before proceeding to the next page"
        )}`,
      });
    }
  };

  const handleCheckCollectionDateAndTime = () => {
    if (isInSecondPage) {
      if (isCollectionDateAndTimeSetInFuture) {
        return notification.warning({
          message: "Invalid collection date and time",
          description: "Collection date and time cannot be set in future",
        });
      } else if (isCollectionDateAndTimeExceeded) {
        return notification.warning({
          message: "Invalid collection date and time",
          description:
            "Collection date and time you provided has already exceeded 30 days.",
        });
      }
    }
  };

  const handleCheckCollectionSite = () => {
    if (isInSecondPage && isCollectionSiteEmpty) {
      dispatch(
        handleChangeSavedFields({
          isCollectionSiteEmpty: true,
        })
      );
      dispatch(
        handleChangeLabtestData({
          showInvalidSpecimensModal: false,
          savedInvalidSpecimens: [],
        })
      );
      return notification.warning({
        message: `${getTranslation("Collection Site")} ${getGlobalTranslation(
          "is required"
        )}`,
        description: `${getGlobalTranslation(
          "Please select collection site for each specimen before proceeding to the next page"
        )}`,
      });
    }
  };

  const handleCheckTestOrderDetails = () => {
    dispatch(
      handleChangeLabtestData({
        showInvalidSpecimensModal: false,
        savedInvalidSpecimens: [],
      })
    );
    if (isInSecondPage && isTestOrderDetailsEmpty) {
      dispatch(
        handleChangeSavedFields({
          isTestProfileEmpty: true,
        })
      );
      return notification.warning({
        message: getGlobalTranslation("Labtest is required"),
        description: `${getGlobalTranslation(
          "Please select labtest before proceeding to the next page"
        )}`,
      });
    }
  };

  const handleClickNext = (e) => {
    e.preventDefault();
    if (!isInSecondPage && !isInFirstPage) {
      handleNext();
      dispatch(
        handleChangeOrderData({
          orderAgain: false,
        })
      );
      dispatch(
        handleChangeLabtestData({
          savedInvalidSpecimens: [],
          showInvalidSpecimensModal: false,
        })
      );
    }
    if (enableVolumeCheck) {
      handleValidateVolumeInSecondPage();
    } else {
      handleValidateSecondPage();
    }
    handleValidateFirstPage();
  };

  const handleClickPrev = (e) => {
    e.preventDefault();
    handlePrev();
    dispatch(
      handleChangeOrderData({
        orderAgain: false,
      })
    );
    dispatch(
      handleChangeLabtestData({
        savedInvalidSpecimens: [],
        showInvalidSpecimensModal: false,
      })
    );
  };
  //Fetching of client details
  useEffect(() => {
    dispatch(getClientInfo()).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        setClientDetails(res.payload.data);
      }
    });
  }, [dispatch]);

  const handleDiscardUpdateChanges = () => {
    handleResetReduxStore();
    localStorage.removeItem("searchCollector");
    setCurrentStepIndex(0);
  };

  useEffect(() => {
    if (orderData.orderAgain) {
      setCurrentStepIndex(1);
    }
    // eslint-disable-next-line
  }, [orderData.orderAgain]);

  useEffect(() => {
    if (!orderData.successfullyCreatedOrder) {
      setCurrentStepIndex(0);
    }
    // eslint-disable-next-line
  }, [orderData.successfullyCreatedOrder]);

  return (
    <SidePanelWrapper>
      <Box
        sx={{
          color: "white",
          marginTop: orderData.successfullyCreatedOrder ? "5rem" : "",
        }}
      >
        <Typography
          sx={{
            marginBottom: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: "0.9rem",
            },
            textAlign: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "center",
              xl: "start",
            },
            fontWeight: "bold",
          }}
          variant="h6"
        >
          {clientDetails?.clientName || "RS Example Name"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "max-content",
          }}
        >
          {savedInputs.phAddresses?.length > 0 &&
            savedInputs.phAddresses?.map((address) => (
              <Typography
                key={address.id}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "none",
                    xl: "initial",
                  },
                }}
                variant="p"
              >
                {address?.streetName}
              </Typography>
            ))}
        </Box>
        <Typography
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "initial",
            },
            width: "max-content",
          }}
          variant="p"
        >
          {clientDetails?.contactNumber
            ? clientDetails?.contactNumber
            : "(021) 55512345"}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
          color: "white",
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "block",
          },
        }}
      >
        <Typography
          sx={{ width: "max-content", fontWeight: "bold" }}
          variant="h5"
        >
          {orderData.isOrderDataForUpdate
            ? getTranslation("Updating Order")
            : getTranslation("Creating Order")}
        </Typography>
        {orderData.isOrderDataForUpdate && (
          <PopConfirm
            okText={"Discard changes"}
            cancelText="Cancel"
            onConfirm={handleDiscardUpdateChanges}
            title="Cancel Update"
            placement="leftTop"
            description={
              <p>
                Do you really want to clear all data? <br /> The changes you've
                made will be lost.
              </p>
            }
          >
            <Button
              size="small"
              style={{ marginTop: "0.5rem" }}
              type="primary"
              danger
              className="danger"
            >
              {getGlobalTranslation("Discard changes")}
            </Button>
          </PopConfirm>
        )}
        <Divider
          sx={{
            width: "13rem",
            bgcolor: "white",
            height: "0.2rem",
            marginTop: "1rem",
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: "1.3rem",
          },
        }}
      >
        <Box className={styles["progress-bar"]}>
          {steps.map((_, index) => (
            <Box key={index} className={styles.step}>
              {currentStepIndex <= index && (
                <Box
                  style={
                    currentStepIndex === index
                      ? activeSpanStyle
                      : inactiveSpanStyle
                  }
                  className={styles.bullet}
                >
                  <span>{index + 1}</span>
                </Box>
              )}
              {currentStepIndex > index && (
                <Box
                  style={
                    currentStepIndex === index
                      ? activeCheckStyle
                      : inactiveCheckStyle
                  }
                  className={styles.bullet}
                >
                  <DoneRoundedIcon
                    sx={{
                      height: "23px",
                      width: "23px",
                      marginBottom: "0.1rem",
                    }}
                  />
                </Box>
              )}
              <p className={styles["progress_text"]}>
                {getTranslation(progressText[index])}
              </p>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={styles["button-wrapper"]}>
        {!isFirstStep && (
          <button onClick={handleClickPrev} type="button">
            <KeyboardDoubleArrowLeftIcon
              sx={{ fontSize: "12px", marginRight: "0.2rem" }}
            />
            {getTranslation("Previous")}
          </button>
        )}
        {!isFirstStep && (
          <span
            style={{ display: isLastStep ? "none" : "block" }}
            className={styles["divider"]}
          />
        )}
        {!isLastStep && (
          <button onClick={handleClickNext} type="button">
            {getTranslation("Next")}
            <KeyboardDoubleArrowRightIcon sx={{ fontSize: "12px" }} />
          </button>
        )}
      </Box>
      <Box className={styles["button-wrapper-mobile"]}>
        {!isFirstStep && !orderData.successfullyCreatedOrder && (
          <button onClick={handleClickPrev} type="button">
            <KeyboardDoubleArrowLeftIcon
              sx={{ fontSize: "12px", marginRight: "0.2rem" }}
            />
            {getTranslation("Previous")}
          </button>
        )}
        {!isFirstStep && <span className={styles["divider"]}></span>}
        {!isLastStep && (
          <button onClick={handleClickNext} type="button">
            {getTranslation("Next")}
            <KeyboardDoubleArrowRightIcon
              sx={{ fontSize: "12px", marginLeft: "0.2rem" }}
            />
          </button>
        )}
      </Box>
    </SidePanelWrapper>
  );
};

export default RequestPageSidePanel;
