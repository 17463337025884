import * as React from "react";
import { Modal, Form, Input, Button, Popconfirm, Space } from "antd";
import { useDispatch } from "react-redux";
import notification from "../global/openNotification";
import { Box } from "@mui/material";
import { updateRoles, createRoles, deleteRoles } from "store";
import AssignClaims from "./assignClaims";

const CreateUpdateRole = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
  } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  //ROLE CLAIMS ASSETS
  const [openRoleClaims, setopenRoleClaims] = React.useState(false);

  const handleRoleClaims = () => {
    setopenRoleClaims(!openRoleClaims);
  };

  const LoadUpdateData = React.useCallback(() => {
    form.setFieldsValue({
      name: idForUpdate.name,
    });
  }, [form, idForUpdate]);

  React.useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [dispatch, isForUpdate, LoadUpdateData]);

  const onFinish = (values) => {
    if (isForUpdate) {
      if (updateRoles.pending().type === "rolesClaims/update-roles/pending") {
        setLoading(true);
      }
      dispatch(updateRoles([idForUpdate.id, values])).then((val) => {
        if (val.type === "rolesClaims/update-roles/fulfilled") {
          if (val.payload?.success) {
            handleSubmit();
            setLoading(false);
            notification.success({
              message: "Updated Role",
              description: `Successfully updated ${values.name}`,
            });
          } else {
            setLoading(false);
            val.payload.response.data.errorMessages.forEach((element) => {
              notification.error({
                message: "Updated Role",
                description: element,
              });
            });
          }
        }
      });
    } else {
      if (createRoles.pending().type === "rolesClaims/create-roles/pending") {
        setLoading(true);
      }
      dispatch(createRoles(values)).then((val) => {
        if (val.type === "rolesClaims/create-roles/fulfilled") {
          if (val.payload?.success) {
            handleSubmit();
            setLoading(false);
            notification.success({
              message: "Created Role",
              description: `Successfully created ${values.name}`,
            });
          } else {
            setLoading(false);
            val.payload.response.data.errorMessages.forEach((element) => {
              notification.error({
                message: "Failed to Create Role",
                description: element,
              });
            });
          }
        }
      });
    }
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (deleteRoles.pending().type === "rolesClaims/delete-roles/pending") {
      setLoading(true);
    }
    dispatch(deleteRoles(idForUpdate.id)).then((val) => {
      if (val.type === "rolesClaims/delete-roles/fulfilled") {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted Role",
          description: `Successfully deleted ${idForUpdate.name}`,
        });
      }
    });
  };

  return (
    <Modal
      open={openCreateUpdate}
      title={isForUpdate ? "Update Role" : "Create Role"}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="role"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        {/*  Role Name */}
        <Form.Item
          label="Role Name"
          name="name"
          rules={[
            {
              required: true,
              message: <i>{"Please input Role Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Role Name" />
        </Form.Item>

        {isForUpdate && (
          <Modal
            open={openRoleClaims}
            title={"Role Claims"}
            onCancel={handleRoleClaims}
            footer={null}
            width={800}
          >
            <Box
              sx={{
                height: "28rem",
                overflowY: "scroll",
              }}
            >
              <AssignClaims selectedData={idForUpdate} type={"role"} />
            </Box>
          </Modal>
        )}

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {isForUpdate && (
            <Space size={2}>
              <Button
                loading={loading}
                onClick={handleRoleClaims}
                className="bg-green-600 hover:bg-green-500"
                style={{
                  border: "1px solid #16A34A",
                  color: "white",
                }}
              >
                Role Claims
              </Button>
              <Popconfirm
                title="Delete the role"
                description="Are you sure to delete?"
                onConfirm={handleClickDelete}
                okText="Yes"
                cancelText="No"
                okButtonProps={{ loading: loading, className: "submitBtn" }}
              >
                <Button loading={loading} type="primary" danger>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateRole;
