import { Box } from "@mui/material";
import React from "react";
import { isDataEmpty } from "utils/isDataEmpty";
import renderDetailsText from "utils/renderDetailsText";

const CreatedNotesData = ({ testOrderNotes }) => {
  if (!isDataEmpty(testOrderNotes)) {
    return <Box>{renderDetailsText("Notes", testOrderNotes)}</Box>;
  }
};

export default CreatedNotesData;
