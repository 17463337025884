import { Box } from "@mui/material";
import React from "react";
import HeaderContent from "./headerContent";
import PatientInformation from "./patientInformation";
import TestProfileSection from "./testProfileSection";
import BillingInformation from "./billingInformation";

const TestOrderToBePrinted = React.forwardRef((props, ref) => {
  const {
    clientWillDrawSpecimen,
    encryptedTestOrder,
    orderStatus,
    orderNumber,
    isLoading,
    cancelReason,
    patientDetails,
    provider,
    specimens,
    testOrderDetails,
    diagnosesList,
    specialHandlings,
    courierRemarks,
    testOrderNotes,
    preparedBy,
    clientDetails,
    isMinimalOrder,
    printTestOrder,
  } = props;
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: !printTestOrder ? "2rem" : 0,
      }}
      className="test-order-wrapper"
      ref={ref}
    >
      <HeaderContent
        orderStatus={orderStatus}
        orderNumber={orderNumber}
        clientDetails={clientDetails}
        isLoading={isLoading}
        cancelReason={cancelReason}
      />

      {!isMinimalOrder && (
        <>
          <PatientInformation
            patientDetails={patientDetails}
            provider={provider}
            isLoading={isLoading}
            specimens={specimens}
            clientWillDrawSpecimen={clientWillDrawSpecimen}
          />
          <TestProfileSection
            encryptedTestOrder={encryptedTestOrder}
            testOrderDetails={testOrderDetails}
            diagnosesList={diagnosesList}
            isLoading={isLoading}
            specimens={specimens}
            specialHandlings={specialHandlings}
            courierRemarks={courierRemarks}
            testOrderNotes={testOrderNotes}
            preparedBy={preparedBy}
          />
        </>
      )}

      {isMinimalOrder && (
        <h1 className="text-center text-3xl mt-10 font-semibold">
          LRF Pickup Request
        </h1>
      )}
      <BillingInformation clientDetails={clientDetails} isLoading={isLoading} />
    </Box>
  );
});
export default TestOrderToBePrinted;
