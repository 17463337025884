import { Box } from "@mui/material";
import { Modal, Space, Tag, Typography as AntdTypography } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTestOrderByPatientId } from "store";
import PatientOrdersDropdown from "./patientOrdersDropdown";
import PatientAddressesDropdown from "./patientAddressesDropdown";

const PatientDetails = ({ patientDetails, open, onCancel }) => {
  const { Text, Paragraph } = AntdTypography;
  const dispatch = useDispatch();
  const [patientOrders, setPatientOrders] = useState([]);
  const patientHasOrder = patientOrders?.length > 0;
  const patientHasAddress = patientDetails?.phAddresses?.length > 0;
  const patientId = patientDetails?.id;

  useEffect(() => {
    if (patientId) {
      dispatch(
        getTestOrderByPatientId({
          patientId: patientId,
        }) //Fetching of test order of a specific patient
      ).then((response) => {
        setPatientOrders(response?.payload?.data?.items);
      });
    }
  }, [dispatch, patientId]);

  return (
    <Modal
      title={
        <Paragraph>
          <blockquote>Patient Information</blockquote>
        </Paragraph>
      }
      open={open}
      onCancel={onCancel}
      footer={null}
      width={600}
    >
      <Box
        sx={{
          height: patientDetails?.phAddresses?.length === 0 ? "24rem" : "30rem",
          overflowY: "scroll",
          marginTop: "2rem",
        }}
      >
        <Space style={{ width: "100%" }} direction="vertical">
          <Space align="center">
            Status:
            <Tag color={patientDetails?.isActive ? "green" : "red"}>
              {patientDetails?.isActive ? "Active" : "Inactive"}
            </Tag>
          </Space>
          <Space align="center">
            Patient Name:
            <Text code>{patientDetails?.patientName}</Text>
          </Space>
          <Space align="center">
            ABC Lab Member Number:
            <Text code>
              {patientDetails?.cardNumber === null ||
              patientDetails?.cardNumber === ""
                ? "N/A"
                : patientDetails?.cardNumber}
            </Text>
          </Space>
          <Space align="center">
            Contact Number:
            <Text code>
              {patientDetails?.contactNumber === null ||
              patientDetails?.contactNumber === ""
                ? "N/A"
                : patientDetails?.contactNumber}
            </Text>
          </Space>
          <Space align="center">
            Medical Record Number:
            <Text code>
              {patientDetails?.medicalRecordNo === null ||
              patientDetails?.medicalRecordNo === ""
                ? "N/A"
                : patientDetails?.medicalRecordNo}
            </Text>
          </Space>
          <Space align="center">
            Sex at Birth:
            <Text code>{patientDetails?.gender}</Text>
          </Space>
          <Space align="center">
            Date Of Birth:
            <Text code>{patientDetails?.dateOfBirth}</Text>
          </Space>
          <Space align="center">
            {patientDetails?.emails.length > 1
              ? "Email Addresses:"
              : "Email Address:"}
            {patientDetails?.emails?.length > 0 ? (
              patientDetails?.emails?.map((email) => <Text code>{email}</Text>)
            ) : (
              <Text code>N/A</Text>
            )}
          </Space>
          <Space align="center">
            Government ID:
            <Text code>
              {patientDetails?.governmentId === null ||
              patientDetails?.governmentId === ""
                ? "N/A"
                : patientDetails?.governmentId}
            </Text>
          </Space>
          <Box>
            {patientHasOrder && (
              <PatientOrdersDropdown patientOrders={patientOrders} />
            )}
          </Box>
          <Box>
            {patientHasAddress && (
              <PatientAddressesDropdown patientDetails={patientDetails} />
            )}
          </Box>
        </Space>
      </Box>
    </Modal>
  );
};

export default PatientDetails;
