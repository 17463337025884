import decodedUserDetails from "./decodedUserDetails";

export const parsedClientDetails = () => {
  const clientDetails = JSON.parse(localStorage?.getItem("clInf"));
  const clientConfig = clientDetails?.clientConfig;
  const clientCode = clientDetails?.clientCode;
  const allowEmailSendingOfRequest = clientConfig?.allowEmailSendingOfRequest;
  const enableCollectionSite = clientConfig?.enableCollectionSite;
  const enableProcessingWindowTime = clientConfig?.enableProcessingWindowTime;
  const inClientWindowProcessingTime =
    clientConfig?.inClientProcessingWindowTime;
  const enableVolumeCheck = clientConfig?.enableVolumeCheck;
  const isSuperAdmin = decodedUserDetails()?.isSuperAdmin === "True";
  const hasAdminRole = decodedUserDetails()?.role?.includes("Admin");
  const isClientAbcLab = clientConfig?.clientId === 1;
  const isClientAbcOrIsSuperAdmin = isClientAbcLab || isSuperAdmin;
  const hasClientServiceRole =
    decodedUserDetails()?.role.includes("ClientService") ||
    decodedUserDetails()?.role.includes("Client Service");

  return {
    clientCode,
    clientDetails,
    allowEmailSendingOfRequest,
    enableCollectionSite,
    enableProcessingWindowTime,
    inClientWindowProcessingTime,
    enableVolumeCheck,
    isClientAbcLab,
    isClientAbcOrIsSuperAdmin,
    hasClientServiceRole,
    hasAdminRole,
    isSuperAdmin,
  };
};
