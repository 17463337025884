import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  getClientInfoById,
  getFlattenUserPermissions,
  logout,
  userLogin,
} from "store";
import { LoadingButton } from "@mui/lab";
import { message } from "antd";
import SendIcon from "@mui/icons-material/Send";
import ABCLOGO from "images/ABCLOGO.png";
import useBrowserBackButton from "hooks/useBrowserBackButton";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import decodedUserDetails from "utils/decodedUserDetails";
import parseJwt from "utils/parseJwt";
import { useEffect, useState } from "react";

const theme = createTheme();

export default function LabLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const inputValues = new FormData(event.currentTarget);
    const isSelectedTypeMedicalTechnologist =
      inputValues.get("userType") === "1";
    const isSelectedTypeCourier = inputValues.get("userType") === "2";
    if (isSelectedTypeMedicalTechnologist) {
      onLogin(inputValues, "MedicalTechnologist" || "Medical Technologist");
    }
    if (isSelectedTypeCourier) {
      onLogin(inputValues, "Courier");
    }
  };

  const dynamicNavigation = (loginAs) => {
    const pathNavigationMap = {
      Courier: "/lab/tagging",
      MedicalTechnologist: "/lab/receiving",
      "Medical Technologist": "/lab/receiving",
    };

    const selectedNavigationPath = pathNavigationMap[loginAs];

    return navigate(selectedNavigationPath);
  };

  const onLogin = (inputValues, loginAs) => {
    if (userLogin.pending().type === "auth/login/pending") {
      setIsLoading(true);
    }

    dispatch(
      userLogin({
        username: inputValues.get("userName"),
        password: inputValues.get("password"),
      })
    ).then(async (loginResponse) => {
      const isPostSuccessful = loginResponse?.payload?.success;
      const loginResponseData = loginResponse?.payload?.data;

      if (isPostSuccessful) {
        const parsedToken = parseJwt(loginResponseData?.token);
        const hasAuthorizedRole = parsedToken?.role?.includes(loginAs);
        const isSuperAdmin = parsedToken?.isSuperAdmin === "True";
        const userNeedsToChangePassword =
          parsedToken?.ForcePasswordChange === "True";

        if (!hasAuthorizedRole && !isSuperAdmin) {
          displayLoginErrorNotification();
        } else if (userNeedsToChangePassword) {
          const flattenUserPermissions = await getUserPermissions();
          await Promise.all([flattenUserPermissions]).then((_) => {
            localStorage.setItem(
              "claims",
              JSON.stringify(flattenUserPermissions)
            );
            messageApi.success(
              "Successfully logged in, you need to change your password!"
            );
            setTimeout(() => {
              navigate("/lab-change-user-password");
              setIsLoading(false);
            }, 700);
          });
        } else {
          if (hasAuthorizedRole || isSuperAdmin) {
            const clientDetails = await getClientInfo(
              loginResponseData?.user?.clientId
            );
            const flattenUserPermissions = await getUserPermissions();

            await Promise.all([clientDetails, flattenUserPermissions]).then(
              () => {
                localStorage.setItem("clInf", JSON.stringify(clientDetails));
                localStorage.setItem(
                  "claims",
                  JSON.stringify(flattenUserPermissions)
                );
                messageApi.success("Successfully logged in!");
                setTimeout(() => {
                  dynamicNavigation(loginAs);
                  setIsLoading(false);
                }, 700);
              }
            );
          }
        }
      }
      if (!isPostSuccessful) {
        loginResponse?.payload?.data?.errorMessages.map((errorItem) => {
          setIsLoading(false);
          return messageApi.error(errorItem);
        });
      }
    });
  };

  const displayLoginErrorNotification = () => {
    messageApi.error(
      "Login unsuccessful due to unauthorized user role; kindly contact the system administrator for assistance."
    );
    setIsLoading(false);
    setTimeout(() => {
      dispatch(logout());
    }, 300);
  };

  const getClientInfo = async (clientId) => {
    const clientDetails = await dispatch(getClientInfoById({ id: clientId }));
    return clientDetails?.payload?.data;
  };

  const getUserPermissions = async () => {
    const flattenUserPermissions = await dispatch(getFlattenUserPermissions());

    return flattenUserPermissions?.payload?.data;
  };

  const handleReturn = () => {
    navigate("/lab");
  };

  useBrowserBackButton(handleReturn);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const hasPreviousVisitedRoute = location.key !== "default";

    if (isUserLoggedIn()) {
      if (hasPreviousVisitedRoute) {
        const redirectTimeout = setTimeout(() => {
          navigate(-1); // Navigate back if there's a previous route
        }, 300);

        return () => clearTimeout(redirectTimeout);
      }

      if (decodedUserDetails()?.role?.includes("Courier")) {
        navigate("/lab/tagging"); // Navigate to courier route
      } else if (
        decodedUserDetails()?.role?.includes("MedicalTechnologist") ||
        decodedUserDetails()?.role?.includes("Medical Technologist")
      ) {
        navigate("/lab/receiving"); // Navigate to medical technologist route
      } else if (decodedUserDetails()?.isSuperAdmin === "True") {
        navigate("/test-request"); // Navigate to super admin route
      }
    }
  }, [location, navigate]);

  return (
    <ThemeProvider theme={theme}>
      {contextHolder}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={ABCLOGO} alt="ABC Lab" />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              label="User Name"
              name="userName"
              autoComplete="userName"
              autoFocus
              disabled={isLoading}
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                name="password"
                required
                disabled={isLoading}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="demo-simple-select-label">User Type</InputLabel>
              <Select
                id="userType"
                label="User Type"
                name="userType"
                required={true}
                disabled={isLoading}
              >
                <MenuItem value={1}>Medical Technologist</MenuItem>
                <MenuItem value={2}>Courier</MenuItem>
              </Select>
            </FormControl>

            {isLoading ? (
              <LoadingButton
                sx={{ mt: 2, mb: 2 }}
                loading
                loadingPosition="end"
                endIcon={<SendIcon />}
                variant="contained"
                fullWidth
              >
                Signing In
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
              >
                Sign In
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
