import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ScienceIcon from "@mui/icons-material/Science";
import { Toolbar, Grid, Autocomplete, TextField } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import OrderTagged from "./orderTagged";
import Account from "../labAuth/account";
import FormControl from "@mui/material/FormControl";
import { clientLookUp, getTestOrderSummary, logout } from "store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import BiotechIcon from "@mui/icons-material/Biotech";
import { makeStyles } from "@material-ui/styles";
import PickedUpOrders from "./pickedUpOrders";
import useBrowserBackButton from "hooks/useBrowserBackButton";
import { sortDataAlphabetically } from "utils/sortDataAlphabetically";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import decodedUserDetails from "utils/decodedUserDetails";
import { useCallback, useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  autocompleteRoot: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white", // Change the border color here
      },
      "&:hover fieldset": {
        borderColor: "white", // Change the hover border color here
      },
      "&.Mui-focused fieldset": {
        borderColor: "white", // Change the focused border color here
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      color: "white", // Change the text color here
    },
    "& .MuiFormLabel-root": {
      color: "white", // Change the text color here
    },
    "&.Mui-focused .MuiFormLabel-root": {
      color: "white", // Change the text color here
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Tagging = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [clientData, setClientData] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [patientCount, setPatientCount] = useState(0);
  const [testCount, setTestCount] = useState(0);
  const [specimenCount, setSpecimenCount] = useState(0);
  const dispatch = useDispatch();
  const userNeedsToChangePassword =
    decodedUserDetails()?.ForcePasswordChange === "True";

  useEffect(() => {
    if (!isUserLoggedIn()) {
      window.location.replace("/lab");
    }
    if (userNeedsToChangePassword) {
      window.location.replace("/lab-change-user-password");
    }
  }, [userNeedsToChangePassword]);

  const getClient = useCallback(() => {
    dispatch(clientLookUp()).then((client) => {
      if (client?.payload?.success) {
        setClientData(client.payload.data);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"))?.token;
    if (token === null || token === undefined) {
      dispatch(logout());
      navigate("/lab");
    } else {
      const userDetails = JSON.parse(atob(token.split(".")[1]));
      const isRoleCourier = userDetails?.role?.includes("Courier");
      const isRoleMedicalTechnologist =
        userDetails?.role?.includes("MedicalTechnologist") ||
        userDetails?.role?.includes("Medical Technologist");

      if (userDetails?.isSuperAdmin !== "True") {
        if (!isRoleCourier && !isRoleMedicalTechnologist) {
          dispatch(logout());
          navigate("/lab");
        } else if (!isRoleCourier && isRoleMedicalTechnologist) {
          navigate("/lab/receiving");
        }
      }
    }
    getClient();
  }, [navigate, getClient, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleChangeClient = (val) => {
    if (val !== undefined) {
      setSelectedClientId(val !== null ? val : 0);
      dispatch(
        getTestOrderSummary({
          clientId: val !== null ? val : 0,
          orderTransportStatus: null,
        })
      ).then((orderSummaryItem) => {
        if (
          orderSummaryItem.type ===
          "test-orders/get-test-order-summary/fulfilled"
        ) {
          setOrderCount(orderSummaryItem?.payload?.data?.numberOfOrders);
          setPatientCount(orderSummaryItem?.payload?.data?.numberOfPatients);
          setTestCount(orderSummaryItem?.payload?.data?.numberOfTests);
          setSpecimenCount(orderSummaryItem?.payload?.data?.numberOfSpecimens);
        }
      });
    } else {
      setSelectedClientId(0);
      setOrderCount(0);
      setPatientCount(0);
      setTestCount(0);
      setSpecimenCount(0);
    }
  };

  const clientOptions = sortDataAlphabetically(clientData, "clientName")?.map(
    (client) => ({ label: client?.displayName, value: client?.id })
  );

  const handleRefreshSummary = () => {
    dispatch(
      getTestOrderSummary({
        clientId: selectedClientId,
        orderTransportStatus: null,
      })
    ).then((orderSummaryItem) => {
      if (
        orderSummaryItem.type === "test-orders/get-test-order-summary/fulfilled"
      ) {
        setOrderCount(orderSummaryItem?.payload?.data?.numberOfOrders);
        setPatientCount(orderSummaryItem?.payload?.data?.numberOfPatients);
        setTestCount(orderSummaryItem?.payload?.data?.numberOfTests);
        setSpecimenCount(orderSummaryItem?.payload?.data?.numberOfSpecimens);
      }
    });
  };

  const handleReturn = (e) => {
    e.preventDefault();
    if (value === 0) {
      setValue(0);
      navigate("/lab/tagging");
      window.history.pushState({ id: Math.random() }, null, "/lab/tagging");
    } else if (value === 1) {
      setValue(0);
      navigate("/lab/tagging");
      window.history.pushState({ id: Math.random() }, null, "/lab/tagging");
    } else {
      setValue(1);
      navigate("/lab/tagging");
      window.history.pushState({ id: Math.random() }, null, "/lab/tagging");
    }
  };

  useBrowserBackButton(handleReturn);

  return (
    <Box sx={{ display: "flex" }} className="bg-slate-100">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <AppBar position="fixed" sx={{ top: 0, bottom: "auto" }}>
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <Autocomplete
              options={clientOptions}
              renderInput={(params) => (
                <TextField variant="outlined" {...params} label="Client" />
              )}
              onChange={(event, value, reason) => {
                handleChangeClient(value?.value);
              }}
              classes={{
                root: classes.autocompleteRoot,
              }}
              sx={{
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
            />
          </FormControl>
        </AppBar>
        <Toolbar />
        <Box sx={{ width: "100vw" }} className="bg-slate-100">
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            enableMouseEvents
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <OrderTagged
                clientId={selectedClientId}
                handleRefreshSummary={handleRefreshSummary}
              />
            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <PickedUpOrders clientId={selectedClientId} />
            </TabPanel>

            <TabPanel value={value} index={2} dir={theme.direction}>
              <Account />
            </TabPanel>
          </SwipeableViews>
          <Toolbar />

          <AppBar
            position="fixed"
            color="primary"
            sx={{ top: "auto", bottom: 0 }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item>
                <ScienceIcon />
                Order – {orderCount}
              </Grid>
              <Grid item>
                <PersonIcon />
                Patient - {patientCount}
              </Grid>
              <Grid item>
                <BiotechIcon />
                Tests – {testCount}
              </Grid>
              <Grid item>
                <ScienceIcon />
                Specimens – {specimenCount}
              </Grid>
            </Grid>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="Specimen Receiving"
              selectionFollowsFocus
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#FFFFFF",
                },
              }}
            >
              <Tab icon={<AssignmentIcon />} label="Order" {...a11yProps(0)} />
              <Tab
                icon={<FactCheckIcon />}
                label="Completed Delivery"
                {...a11yProps(1)}
              />
              <Tab
                icon={<AccountCircleIcon />}
                label="Account"
                {...a11yProps(2)}
              />
            </Tabs>
          </AppBar>
        </Box>
      </Box>
    </Box>
  );
};
export default Tagging;
