import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ICD10CODES } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const deleteICD10Codes = createAsyncThunk(
  "icd10codes/delete-icd10codes",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(URL_ICD10CODES + "/" + data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { deleteICD10Codes };
