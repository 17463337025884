import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_DEPT } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const updateLabDepartment = createAsyncThunk(
  "labDepartments/update-lab-department",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.put(URL_LAB_DEPT + "/" + data[0], {
        id: data[0],
        name: data[1].name,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateLabDepartment };
