import { Empty, Tag } from "antd";
import { useEffect, useState, useCallback } from "react";
import * as React from "react";
import moment from "moment";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Typography,
  Skeleton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getAllTestOrderTransportStatus,
  getTestOrderById,
  logout,
} from "store";
import ScanOrder from "./scanOrder";
import { useNavigate } from "react-router-dom";
import { ClockCircleOutlined } from "@ant-design/icons";
import useBrowserBackButton from "hooks/useBrowserBackButton";
import SnackbarNotification from "../receiving/snackbarNotification";

const READY_FOR_PICKUP_ENUM_VALUE = 0;
const MISSED_PICKUP_ENUM_VALUE = 9;

const OrderTagged = (props) => {
  const { clientId, handleRefreshSummary } = props;
  const navigate = useNavigate();
  const [readyForPickupData, setReadyForPickupData] = useState([]);
  const [missedPickupPickupData, setMissedPickupData] = useState([]);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTestOrderFetching, setIsTestOrderFetching] = useState(false);
  const [testOrderData, setTestOrderData] = useState({
    testOrders: [],
    testOrderCount: 0,
    patientSpecimens: [],
    patientSpecimensCount: 0,
  });
  const [snackbarOptions, setSnackbarOptions] = useState({
    snackbarMessage: "",
    openSnackbar: false,
    snackbarType: "",
  });
  const totalNumberOfReadyForPickupOrders = readyForPickupData.length;
  const totalNumberOfMissedPickupOrders = missedPickupPickupData.length;
  const userSelectedClientId = clientId !== 0;
  const dispatch = useDispatch();

  const renderEmptyComponent = (description) => {
    return (
      <Empty style={{ height: "70vh" }} description={<p>{description}</p>} />
    );
  };

  const appendData = useCallback(
    (clientId) => {
      if (
        getAllTestOrderTransportStatus.pending().type ===
        "test-orders/get-all-test-order-transport-status/pending"
      ) {
        setIsLoading(true);
      }
      dispatch(
        getAllTestOrderTransportStatus({
          clientId: clientId,
          search: "",
          pageSize: 999,
          pageNumber: 1,
          sortField: "orderDate",
          sortDirection: 1,
          orderTransportStatus: READY_FOR_PICKUP_ENUM_VALUE,
        })
      ).then((item) => {
        if (
          item.type ===
          "test-orders/get-all-test-order-transport-status/fulfilled"
        ) {
          setIsLoading(false);
          setReadyForPickupData(item.payload.data.items);
        }
        if (
          item.type ===
          "test-orders/get-all-test-order-transport-status/rejected"
        ) {
          setIsLoading(false);
        }
      });
      dispatch(
        getAllTestOrderTransportStatus({
          clientId: clientId,
          search: "",
          pageSize: 999,
          pageNumber: 1,
          sortField: "orderDate",
          sortDirection: 1,
          orderTransportStatus: MISSED_PICKUP_ENUM_VALUE,
        })
      ).then((item) => {
        if (
          item.type ===
          "test-orders/get-all-test-order-transport-status/fulfilled"
        ) {
          setIsLoading(false);
          setMissedPickupData(item.payload.data.items);
        }
        if (
          item.type ===
          "test-orders/get-all-test-order-transport-status/rejected"
        ) {
          setIsLoading(false);
        }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    setReadyForPickupData([]);
    setMissedPickupData([]);
    setSelectedData(null);
    setIsOpenDetails(false);
    appendData(clientId);

    const token = JSON.parse(localStorage.getItem("user"))?.token;

    if (token === null || token === undefined) {
      dispatch(logout());
      navigate("/lab");
    } else {
      const userDetails = JSON.parse(atob(token.split(".")[1]));
      if (
        userDetails?.isSuperAdmin !== "True" &&
        userDetails?.isSuperAdmin !== undefined
      ) {
        if (
          !userDetails?.role.includes("Courier") &&
          userDetails?.role !== undefined
        ) {
          dispatch(logout());
          navigate("/lab");
        }
      }
    }
  }, [dispatch, navigate, clientId, appendData]);

  const showModal = (orderId) => {
    setIsOpenDetails(true);
    if (
      getTestOrderById.pending().type ===
      "test-orders/get-test-order-by-id/pending"
    ) {
      setIsTestOrderFetching(true);
    }
    dispatch(
      getTestOrderById({
        id: orderId,
      })
    ).then((res) => {
      const isFetchSuccess = res?.payload?.success;
      const responseData = res?.payload?.data;
      if (isFetchSuccess) {
        setTestOrderData({
          testOrders: responseData?.testOrderDetails,
          testOrderCount: responseData?.testOrderDetails?.length,
          patientSpecimens: responseData?.patientSpecimens,
          patientSpecimensCount: responseData?.patientSpecimens?.length,
        });
        setIsTestOrderFetching(false);
      }
      if (!isFetchSuccess) {
        setIsTestOrderFetching(false);
      }
    });
  };
  const handleBack = () => {
    setIsOpenDetails(false);
    setSelectedData(null);
    setTestOrderData({
      testOrders: [],
      testOrderCount: 0,
      patientSpecimens: [],
      patientSpecimensCount: 0,
    });
  };

  const handlePickup = () => {
    appendData(clientId);
    setIsOpenDetails(false);
    setSelectedData(null);
    handleRefreshSummary();
  };

  const handleCloseSnackbarNotification = () => {
    setSnackbarOptions({
      snackbarMessage: "",
      openSnackbar: false,
      snackbarType: "",
    });
  };

  const handleOpenSnackbarNotification = (message, type) => {
    setSnackbarOptions({
      snackbarMessage: message,
      openSnackbar: true,
      snackbarType: type,
    });
  };

  const handleReturn = (e) => {
    e.preventDefault();
    setIsOpenDetails(false);
    setSelectedData(null);
    setTestOrderData({
      testOrders: [],
      testOrderCount: 0,
      patientSpecimens: [],
      patientSpecimensCount: 0,
    });
    window.history.pushState({ id: Math.random() }, null, "/lab/tagging");
  };

  useBrowserBackButton(handleReturn); //For controlling browser's back button

  const handleShowOrderDetails = (e, item) => {
    e.preventDefault();
    setSelectedData(item);
    showModal(item.id);
    window.history.pushState({ id: Math.random() }, null, "/lab/tagging");
    window.history.pushState({ id: Math.random() }, null, "/lab/tagging");
  };

  return (
    <Box>
      {!isOpenDetails && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.5rem",
                lg: "1.5rem",
                xl: "1.5rem",
              },
            }}
            variant="p"
          >
            Ready for Pickup Orders{" "}
            <Typography
              sx={{ fontWeight: "bold" }}
              variant="p"
            >{`(${totalNumberOfReadyForPickupOrders})`}</Typography>
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.5rem",
                lg: "1.5rem",
                xl: "1.5rem",
              },
              marginBottom: 10,
            }}
            variant="p"
          >
            Missed Pickup Orders{" "}
            <Typography
              sx={{ fontWeight: "bold" }}
              variant="p"
            >{`(${totalNumberOfMissedPickupOrders})`}</Typography>
          </Typography>
        </Box>
      )}
      {isOpenDetails && (
        <ScanOrder
          isTestOrderFetching={isTestOrderFetching}
          testOrderCount={testOrderData.testOrderCount}
          testOrders={testOrderData.testOrders}
          patientSpecimens={testOrderData.patientSpecimens}
          patientSpecimensCount={testOrderData.patientSpecimensCount}
          handleOpenSnackbarNotification={handleOpenSnackbarNotification}
          item={selectedData}
          handleBack={handleBack}
          handlePickup={handlePickup}
          selectedData={selectedData}
        />
      )}
      {!isOpenDetails && (
        <>
          {clientId !== 0 && isLoading ? (
            <List
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
              }}
            >
              {Array.from({ length: 5 }).map((_, index) => {
                return (
                  <ListItem key={index}>
                    <Box
                      sx={{
                        p: 1,
                        width: "100%",
                      }}
                    >
                      <Skeleton variant="rounded" height={240} />
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          ) : !isLoading &&
            userSelectedClientId &&
            readyForPickupData.length === 0 &&
            missedPickupPickupData.length === 0 ? (
            renderEmptyComponent(
              "No Ready for Pickup and Missed Pickup test orders for this client yet."
            )
          ) : (
            <List>
              {readyForPickupData.map((item) => {
                const patientFirstName = item?.patient?.firstname;
                const patientLastName =
                  item?.patient?.lastname === null
                    ? ""
                    : item?.patient?.lastname;
                const fullName = `${patientFirstName} ${patientLastName}`;

                return (
                  <ListItem key={item?.id}>
                    <Box
                      sx={{
                        p: 1,
                        width: 1,
                      }}
                    >
                      <Card
                        onClick={(e) => {
                          handleShowOrderDetails(e, item);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <CardHeader
                          className="bg-blue-400
                           text-white"
                          title={`Order ID: ${item?.id} - ${item?.orderStatus}`}
                        />

                        <CardContent>
                          <Tag
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              width: "max-content",
                              marginBottom: "0.5rem",
                            }}
                            icon={<ClockCircleOutlined />}
                            color="default"
                          >
                            {moment(item?.orderDate).fromNow()}
                          </Tag>
                          {!item?.isMinimalOrder && (
                            <>
                              <Typography sx={{ mb: 1.5 }}>
                                Patient Name: <b>{fullName}</b>
                              </Typography>
                              <Typography sx={{ mb: 1.5 }}>
                                DOB:{" "}
                                <b>
                                  {moment(item?.patient?.dateOfBirth).format(
                                    "MMMM DD,YYYY"
                                  )}
                                </b>
                              </Typography>
                              <Typography sx={{ mb: 1.5 }}>
                                Gender: <b>{item?.patient?.gender}</b>
                              </Typography>
                            </>
                          )}
                          {item?.isMinimalOrder && (
                            <Typography sx={{ fontWeight: "bold" }}>
                              LRF Pickup Request
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </Box>
                  </ListItem>
                );
              })}
              {missedPickupPickupData.map((item) => {
                const patientFirstName = item?.patient?.firstname;
                const patientLastName =
                  item?.patient?.lastname === null
                    ? ""
                    : item?.patient?.lastname;
                const fullName = `${patientFirstName} ${patientLastName}`;
                return (
                  <ListItem key={item?.id}>
                    <Box
                      sx={{
                        p: 1,
                        width: 1,
                      }}
                    >
                      <Card
                        onClick={(e) => {
                          handleShowOrderDetails(e, item);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <CardHeader
                          className="bg-red-500 text-white"
                          title={`Order ID: ${item?.id} - ${item?.orderStatus}`}
                        />

                        <CardContent>
                          <Tag
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              width: "max-content",
                              marginBottom: "0.5rem",
                            }}
                            icon={<ClockCircleOutlined />}
                            color="default"
                          >
                            {moment(item?.orderDate).fromNow()}
                          </Tag>
                          {!item?.isMinimalOrder && (
                            <>
                              <Typography sx={{ mb: 1.5 }}>
                                Patient Name: <b>{fullName}</b>
                              </Typography>
                              <Typography sx={{ mb: 1.5 }}>
                                DOB:{" "}
                                <b>
                                  {moment(item?.patient?.dateOfBirth).format(
                                    "MMMM DD,YYYY"
                                  )}
                                </b>
                              </Typography>
                              <Typography sx={{ mb: 1.5 }}>
                                Gender: <b>{item?.patient?.gender}</b>
                              </Typography>
                            </>
                          )}
                          {item?.isMinimalOrder && (
                            <Typography sx={{ fontWeight: "bold" }}>
                              LRF Pickup Request
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </Box>
                  </ListItem>
                );
              })}
              {!userSelectedClientId &&
                renderEmptyComponent(
                  "Please select client to view Ready for Pickup and Missed Pickup test orders"
                )}
            </List>
          )}
        </>
      )}
      <SnackbarNotification
        onOpen={snackbarOptions.openSnackbar}
        onClose={handleCloseSnackbarNotification}
        message={snackbarOptions.snackbarMessage}
        severity={snackbarOptions.snackbarType}
        setSnackbarOptions={setSnackbarOptions}
      />
    </Box>
  );
};

export default OrderTagged;
