import { notification as antdNotification } from "antd";

antdNotification.config({
  placement: "bottomRight",
  duration: 4,
  top: 0,
});

const notification = {
  error: ({ message, description }) => {
    antdNotification.error({
      message,
      description,
    });
  },
  warning: ({ message, description }) => {
    antdNotification.warning({
      message,
      description,
    });
  },
  success: ({ message, description }) => {
    antdNotification.success({
      message,
      description,
    });
  },
};
export default notification;
