import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_PATIENT } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const getAllPatient = createAsyncThunk(
  "patients/get-all-patients",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const config = {
        params: {
          clientId: clientId,
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
        },
      };
      const response = await api.get(URL_PATIENT, config);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getAllPatient };
