import { Box } from "@mui/material";
import { Space, Typography } from "antd";
import moment from "moment";
import React from "react";
import renderDetailsText from "utils/renderDetailsText";
import {
  clientWillDrawSpecimen,
  formattedPatientName,
  renderDiagnosesList,
  renderLabtest,
  renderPatientSpecimens,
  renderSpecialHandlings,
} from "./textRenderer";

const { Text } = Typography;

const BeforeModificationData = ({ oldValue, newValue }) => {
  const emptySpecialHandling =
    oldValue?.TestOrderSpecialHandlings?.length === 0;
  const emptyPatientSpecimens = oldValue?.PatientSpecimens?.length === 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.3rem",
          mt: 2,
        }}
      >
        {renderDetailsText(
          "Patient Name",
          formattedPatientName(
            oldValue?.Patient?.Firstname,
            oldValue?.Patient?.Middlename,
            oldValue?.Patient?.Lastname
          )
        )}
        {renderDetailsText("Order Status", oldValue?.OrderStatus)}
        {renderDetailsText(
          "Order Date",
          moment(oldValue?.OrderDate).format("LLL")
        )}
        {renderDetailsText("Client Code", oldValue?.Client?.ClientCode)}
        {renderDetailsText("Client Name", oldValue?.Client?.ClientName)}
        {renderDetailsText(
          "Client Will Draw Specimen",
          clientWillDrawSpecimen(oldValue?.ClientWillDrawSpecimen)
        )}
        {renderDetailsText(
          "Auto Generated Order Number",
          oldValue?.AutoGeneratedOrderNumber
        )}
        <Space
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Text className="text-[0.80rem] font-normal">Diagnoses List:</Text>
          {renderDiagnosesList(
            oldValue?.DiagnosesList,
            "oldValue",
            oldValue,
            newValue
          )}
        </Space>
        <Space
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Text className="text-[0.80rem] font-normal">
            Special Handlings:{" "}
            {oldValue?.TestOrderSpecialHandlings?.length === 0 && (
              <Text keyboard>N/A</Text>
            )}
          </Text>
          {!emptySpecialHandling &&
            renderSpecialHandlings(
              oldValue?.TestOrderSpecialHandlings,
              "oldValue",
              oldValue,
              newValue
            )}
        </Space>
        <Space
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Text className="text-[0.80rem] font-normal">Labtest:</Text>
          {renderLabtest(
            oldValue?.TestOrderDetails,
            "oldValue",
            oldValue,
            newValue
          )}
        </Space>
        <Space
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Text className="text-[0.80rem] font-normal">
            Patient Specimens:{" "}
            {oldValue?.PatientSpecimens?.length === 0 && (
              <Text keyboard>N/A</Text>
            )}
          </Text>
          {!emptyPatientSpecimens &&
            renderPatientSpecimens(
              oldValue?.PatientSpecimens,
              "oldValue",
              oldValue,
              newValue
            )}
        </Space>
      </Box>
    </Box>
  );
};

export default BeforeModificationData;
