import { useSelector } from "react-redux";
import inTranslation from "../data/Translation.IN.json";
import enTranslation from "../data/Translation.EN.json";

const useTranslation = (selectedKey) => {
  const savedInputs = useSelector((state) => state.savedInputs);

  const convertKey = (providedKey) => {
    return providedKey
      .split(".")
      .pop()
      .toString()
      .replace(/\s/g, "")
      .toLowerCase();
  };

  const selectLanguage = (providedKey) => {
    let selectedLanguage;

    if (savedInputs.selectedLanguage === "IN") {
      selectedLanguage = inTranslation[providedKey];
    } else {
      selectedLanguage = enTranslation[providedKey];
    }

    return selectedLanguage;
  };

  const formatObjLanguageToArray = (providedKey) => {
    const selectedLanguage = selectLanguage(providedKey);
    let formattedSelectedLanguage = [];

    for (let key in selectedLanguage) {
      let newObj = {};

      const newKey = convertKey(key);

      newObj[newKey] = selectedLanguage[key];
      formattedSelectedLanguage.push(newObj);
    }

    return formattedSelectedLanguage;
  };

  const formatSelectedWord = (selectedWord) => {
    return selectedWord.replace(/\s/g, "").toLowerCase();
  };

  const getTranslation = (word) => {
    const formattedSelectedLanguage = formatObjLanguageToArray(selectedKey);

    const convertedWord = formatSelectedWord(word);
    const translationObject = formattedSelectedLanguage.filter(
      (data) =>
        Object.keys(data).toString().replace(/\s/g, "").toLowerCase() ===
        convertedWord
    )[0];

    return translationObject ? translationObject[convertedWord] : undefined;
  };

  const getGlobalTranslation = (word) => {
    const formattedSelectedLanguage = formatObjLanguageToArray(["Global"]);

    const convertedWord = formatSelectedWord(word);
    const returnedTranslation = formattedSelectedLanguage.filter(
      (data) =>
        Object.keys(data).toString().replace(/\s/g, "").toLowerCase() ===
        convertedWord
    )[0][convertedWord];

    return returnedTranslation;
  };

  return { getTranslation, getGlobalTranslation };
};

export default useTranslation;
