import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_AUTH } from "config/connString";

const userResetPassword = createAsyncThunk(
  "user/reset-password",
  async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(
        URL_AUTH + "reset-password",
        {
          id: data.id,
          password: data.password,
          resetToken: data.resetToken,
        },
        config
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);

export { userResetPassword };
