import { Box, Grid, Paper, Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { isUserLoggedIn } from "utils/isUserLoggedIn";

const dashboardCardsData = [
  {
    id: 1,
    bgColor: "#81B1F9",
    title: "Open Orders",
    value: 0,
    icon: <ShoppingCartIcon sx={{ fontSize: "2.5rem", color: "white" }} />,
  },
  {
    id: 2,
    bgColor: "#80DFF1",
    title: "Closed Orders",
    value: 0,
    icon: (
      <LocalShippingIcon
        sx={{
          fontSize: "2.5rem",
          color: "white",
          transform: "scaleX(-1)",
        }}
      />
    ),
  },
  {
    id: 3,
    bgColor: "#FADB7E",
    title: "Total Customers",
    value: 0,
    icon: <LocalOfferIcon sx={{ fontSize: "2.5rem", color: "white" }} />,
  },
  {
    id: 4,
    bgColor: "#88BEA4",
    title: "Product Types",
    value: 0,
    icon: <PersonAddAlt1Icon sx={{ fontSize: "2.5rem", color: "white" }} />,
  },
];

const DashboardCards = () => {
  const navigate = useNavigate();
  const { isClientAbcLab } = parsedClientDetails();

  useEffect(() => {
    if (isUserLoggedIn()) {
      if (!isClientAbcLab) {
        navigate("/test-request");
      }
    } else {
      navigate("/login");
    }
  }, [navigate, isClientAbcLab]);

  return (
    <Box
      sx={{
        padding: "1.5rem 2rem",
        backgroundColor: "white",
        borderRadius: "0.2rem",
      }}
    >
      <Grid rowSpacing={3} columnSpacing={3} container>
        {dashboardCardsData?.map((data) => (
          <Grid key={data.id} xs={12} sm={6} md={6} lg={3} xl={3} item>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.8rem 0.8rem",
                backgroundColor: data.bgColor,
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: "bold" }} variant="h4">
                  {data.value}
                </Typography>
                <Typography variant="p">{data.title}</Typography>
              </Box>
              <Box>{data.icon}</Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardCards;
