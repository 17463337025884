import { Box, Typography } from "@mui/material";
import { Empty, Spin } from "antd";
import abcJktLogo from "assests/abc-jkt-logo.png";
import React, { forwardRef } from "react";
import PrintComponent from "./printComponent";
import SessionSummaryDisplay from "./sessionSummaryDisplay";

const PreviousOrdersContent = forwardRef((props, ref) => {
  const {
    clientDetails,
    paginatedOrderData,
    isPreviousTestOrderFetching,
    printPreviousTestOrders,
  } = props;
  const hasPreviousTestOrdersData =
    !isPreviousTestOrderFetching && paginatedOrderData?.length > 0;

  return (
    <Box
      sx={{
        padding: !printPreviousTestOrders ? "1.27cm" : 0,
      }}
      ref={ref}
    >
      {/* No data display */}
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {!isPreviousTestOrderFetching && !hasPreviousTestOrdersData && (
          <Empty />
        )}
      </Box>
      {/* Loading spinner */}
      {isPreviousTestOrderFetching && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "5rem 0",
          }}
        >
          <Spin tip="Please wait..." size="large" />
        </Box>
      )}
      {/* Regular Header */}
      {!printPreviousTestOrders &&
        !isPreviousTestOrderFetching &&
        hasPreviousTestOrdersData && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
              borderBottom: "2px solid black",
              paddingBottom: "1.3rem",
            }}
          >
            <Box>
              <Typography
                variant="p"
                sx={{
                  fontWeight: "500",
                  color: "darkBlue.main",
                  fontSize: "14pt",
                }}
              >
                eRequest Client Master Log
              </Typography>
              <Box sx={{ display: "flex", marginTop: "0.5rem" }}>
                <Typography
                  variant="h5"
                  sx={{
                    width: "max-content",
                    color: "black",
                    marginTop: "0.3rem",
                    fontSize: "12pt",
                    marginRight: "2rem",
                    fontWeight: "600",
                  }}
                >
                  {clientDetails?.clientCode}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    width: "max-content",
                    color: "black",
                    marginTop: "0.3rem",
                    fontSize: "12pt",
                    fontWeight: "600",
                  }}
                >
                  {clientDetails?.clientName}
                </Typography>
              </Box>
            </Box>
            <Box>
              <img
                className="object-cover h-[1.4cm] w-[3.7cm] mt-[0.5rem]"
                alt="ABC Logo"
                src={abcJktLogo}
              />
            </Box>
          </Box>
        )}
      {/* Session summary content for printing */}
      {printPreviousTestOrders && (
        <div className="session-summary-wrapper">
          <PrintComponent
            clientCode={clientDetails?.clientCode}
            clientName={clientDetails?.clientName}
          >
            {hasPreviousTestOrdersData &&
              paginatedOrderData?.map((data, index) => (
                <SessionSummaryDisplay
                  showOrderStatus
                  data={data}
                  index={index}
                  key={data.id}
                />
              ))}
          </PrintComponent>
        </div>
      )}
      {/* Session summary content */}
      {!printPreviousTestOrders &&
        hasPreviousTestOrdersData &&
        paginatedOrderData?.map((data, index) => (
          <SessionSummaryDisplay
            showOrderStatus
            data={data}
            index={index}
            key={data.id}
          />
        ))}
    </Box>
  );
});

export default PreviousOrdersContent;
