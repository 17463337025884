import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const getAllTestOrder = createAsyncThunk(
  "test-orders/get-all-test-order",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_TEST_ORDERS + "/get-all", {
        clientId: data.clientId,
        statuses: data.statuses,
        startOrderDate: data.startOrderDate,
        endOrderDate: data.endOrderDate,
        search: data.search,
        pageSize: data.pageSize,
        pageNumber: data.pageNumber,
        sortField: data.sortField,
        sortDirection: data.sortDirection,
        loadCompleteData: data.loadCompleteData,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getAllTestOrder };
