import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const createLrfPickupRequest = createAsyncThunk(
  "test-orders-minimal/create-lrf-pickup-request",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.post(`${URL_TEST_ORDERS}/minimal`, {
        clientId: data.clientId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createLrfPickupRequest };
