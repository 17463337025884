import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_BARANGAY } from "config/connString";
import useAxios from "utils/useAxios";
const updateBarangay = createAsyncThunk(
  "barangay/update-barangay",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.put(URL_BARANGAY + "/" + data[0], {
        id: data[0],
        name: data[1].name,
        cityMunicipalityId: data[1].cityMunicipalityId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateBarangay };
