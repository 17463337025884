import { Box, Checkbox, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import { handleChangeSavedTestInfo } from "store/slice/savedTestInputsInfo";
import { handleChangeLabtestData } from "store/slice/savedLabtestSlice";
import PopConfirm from "components/jkt/global/popConfirm";
import useTranslation from "hooks/useTranslation";

const SelectedLabtestTable = ({ handleOpenTestModal }) => {
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const savedTestInputsInfo = useSelector((state) => state.savedTestInputsInfo);
  const savedInputs = useSelector((state) => state.savedInputs);
  const dispatch = useDispatch();
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const savedHandlingItems = useSelector((state) => state.specialHandling);
  const isStatPresent = savedHandlingItems.savedHandling.some(
    (data) => data.instructions.toLowerCase() === "stat"
  );
  const orderData = useSelector((state) => state.orderData);

  useEffect(() => {
    //Checking if there are no labtest that allows stat, and set all of the isStat property to false
    if (!isStatPresent) {
      dispatch(
        handleChangeMultiFields({
          testOrderDetails: multiStepFormInputs?.testOrderDetails.map(
            (test) => ({ ...test, isStat: false })
          ),
        })
      );
    }
    // eslint-disable-next-line
  }, [isStatPresent, dispatch]);

  const handleRemoveLabtest = (selectedData) => {
    //Removing of labtest function
    const filteredTestArr = savedLabtests.savedLabtestsData?.filter(
      (test) => test.id !== selectedData.id
    );
    const filteredSavedTestDatas =
      savedLabtests?.savedLabtestsIdAndSpecimen?.filter(
        (data) => data.testId !== selectedData.id
      );

    const filteredTestIds = savedLabtests?.savedLabtestsIdAndSpecimen?.filter(
      (data) => !selectedData?.testIds?.includes(data.testId)
    );

    const filteredTestOrderDetails =
      multiStepFormInputs?.testOrderDetails?.filter(
        (order) => order.testId !== selectedData.id
      );
    const filteredRequirementDetails =
      savedTestInputsInfo.savedRequirementDetails.filter(
        (info) => info.id !== selectedData.id
      );

    const filteredSavedTestInfo = savedTestInputsInfo?.savedTestInfo?.filter(
      (test) => test.id !== selectedData.id
    );

    dispatch(
      handleChangeSavedTestInfo({
        savedTestInfo: filteredSavedTestInfo,
        savedRequirementDetails: filteredRequirementDetails,
      })
    );

    const specimensToBeRemoved = multiStepFormInputs.patientSpecimens.filter(
      (data) => {
        const isSpecimenPresentInOtherTest =
          savedLabtests?.savedLabtestsData?.some(
            (test) =>
              test !== selectedData && test?.specimen?.includes(data.specimen)
          );
        return !isSpecimenPresentInOtherTest;
      }
    );
    const filteredSpecimens = multiStepFormInputs.patientSpecimens.filter(
      (data) =>
        !specimensToBeRemoved.some(
          (specimen) => specimen?.specimen === data.specimen
        )
    );
    dispatch(
      handleChangeMultiFields({
        patientSpecimens: filteredSpecimens,
        testOrderDetails: filteredTestOrderDetails,
      })
    );

    dispatch(
      handleChangeLabtestData({
        savedLabtestsData: filteredTestArr,
        savedLabtestsIdAndSpecimen:
          selectedData.type === "Test"
            ? filteredSavedTestDatas
            : filteredTestIds,
      })
    );
  };

  const handleUpdateStat = (e, selectedId) => {
    //Dynamically update stat
    const { checked } = e.target;
    dispatch(
      handleChangeMultiFields({
        testOrderDetails: multiStepFormInputs?.testOrderDetails.map((test) =>
          test.testId === selectedId
            ? {
                ...test,
                isStat: checked,
              }
            : test
        ),
      })
    );
  };

  const description = <p>{getGlobalTranslation("Are you sure")}?</p>; //Popup message before removing a labtest

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "14fr 22fr 38fr 37fr 23fr",
              sm: "14fr 22fr 38fr 37fr 23fr",
              md: "14fr 22fr 38fr 37fr 23fr",
              lg: "7.2fr 15fr 30fr 37fr 30fr",
              xl: "7.2fr 15fr 30fr 37fr 30fr",
            },
            marginTop: "1rem",

            padding: "0.4rem",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontWeight: "600",
              color: "darkBlue.main",
            }}
          >
            No.
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "600",
              color: "darkBlue.main",
            }}
          >
            {getTranslation("Code")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "600",
              color: "darkBlue.main",
            }}
          >
            {getTranslation("Test Name")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "600",
              color: "grayishBlue.light",
            }}
          >
            {getTranslation("Specimen")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "600",
              color: "grayishBlue.light",
              opacity: isStatPresent ? 1 : 0,
            }}
          >
            STAT
          </Typography>
        </Box>
        <Box
          sx={{
            border: "2px solid",
            borderColor: savedInputs.isTestProfileEmpty
              ? "red"
              : "lightBlue.secondary",
            backgroundColor: "white",
            borderRadius: "5px",
            height: "auto",
            position: "relative",
            padding: "0.4rem",
            paddingBottom:
              savedLabtests?.savedLabtestsData?.length === 0 ? "6rem" : "3rem",
          }}
        >
          {savedLabtests?.savedLabtestsData?.map((data, index) => (
            <Box
              key={data.id}
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "14fr 22fr 39fr 37fr 23fr",
                  sm: "14fr 22fr 39fr 37fr 23fr",
                  md: "14fr 22fr 39fr 37fr 23fr",
                  lg: "5fr 10fr 20fr 24.5fr 20fr",
                  xl: "5fr 10fr 20fr 24.5fr 20fr",
                },
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
                variant="p"
              >
                {index + 1}
              </Typography>
              <Typography
                onClick={() => handleOpenTestModal(data)}
                sx={{
                  color: "darkBlue.light",
                  fontWeight: "bold",
                  fontSize: "0.95rem",
                  cursor: "pointer",
                  textDecoration: "underline",
                  width: "max-content",
                }}
                variant="p"
              >
                {data?.code}
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
                variant="p"
              >
                {data?.name}
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontStyle: "italic",
                  fontSize: "0.9rem",
                }}
                variant="p"
              >
                {data.type === "Test" && data?.specimen?.join("/")}
              </Typography>
              <Box sx={{ transform: "translateY(-9px)", marginLeft: "0.2rem" }}>
                {isStatPresent && data?.isStatAllowed && (
                  <Checkbox
                    checked={
                      multiStepFormInputs.testOrderDetails.find(
                        (test) => test.testId === data.id
                      )?.isStat
                    }
                    onChange={(e) => handleUpdateStat(e, data.id)}
                  />
                )}
              </Box>
              <PopConfirm
                title={getGlobalTranslation("Remove labtest")}
                description={description}
                onConfirm={() => handleRemoveLabtest(data)}
                okText="Yes"
                cancelText="No"
              >
                <CloseIcon
                  sx={{
                    color: "red",
                    fontSize: "1.2rem",
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                    transform: "translateX(22px)",
                    display:
                      data.forUpdate &&
                      orderData.orderStatus.toLowerCase() === "pickedup"
                        ? "none"
                        : "block",
                  }}
                />
              </PopConfirm>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SelectedLabtestTable;
