import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_BARANGAY } from "config/connString";
import useAxios from "utils/useAxios";

const getAllBarangay = createAsyncThunk(
  "barangay/get-all-barangay",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
        },
      };
      const response = await api.get(URL_BARANGAY, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getAllBarangay };
