import {
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Alert,
  Stack,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import {
  userLogin,
  logout,
  refreshUser,
  getClientInfoById,
  getFlattenUserPermissions,
} from "store";
import eRequestLogo from "assests/erequest.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router-dom";
import Copyright from "components/jkt/global/copyright";
import notification from "components/jkt/global/openNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import decodedUserDetails from "utils/decodedUserDetails";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import pkg from "../../../../package.json";
import { isDataEmpty } from "utils/isDataEmpty";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading, error, errorMessage } = useSelector((state) => state.auth);
  const location = useLocation();
  const inLoginPage = location.pathname === "/login";

  // Redirect user to their last visited page if they decided to visit login page even they are already authenticated
  useEffect(() => {
    const previousVisitedRoute = localStorage.getItem("visited-route");

    if (isUserLoggedIn() && inLoginPage) {
      if (previousVisitedRoute !== null) {
        navigate(previousVisitedRoute);
      } else {
        navigate("/test-request");
      }
    }
  }, [navigate, inLoginPage]);

  const showWarningMessage = () => {
    if (isDataEmpty(username)) {
      notification.warning({
        message: "Username is required",
        description: "Please input your username",
      });
    }
    if (isDataEmpty(password)) {
      notification.warning({
        message: "Password is required",
        description: "Please input your password",
      });
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (isDataEmpty(username) || isDataEmpty(password)) {
      setIsFormDirty(true);
      showWarningMessage();
    } else {
      dispatch(
        userLogin({
          username: username,
          password: password,
        })
      ).then((res) => {
        const isLoginSuccessful = res?.payload?.success;
        if (isLoginSuccessful) {
          localStorage.setItem("app-name", pkg.name);
          //LOAD PERMISSION
          fetchFlattenPermissions();
          //GET CLIENT INFO
          saveClientInfoInLocalStorage(res?.payload?.data?.user?.clientId);
        }
      });
    }
  };

  const fetchFlattenPermissions = () => {
    dispatch(getFlattenUserPermissions()).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        localStorage.setItem("claims", JSON.stringify(res.payload.data));

        const userNeedsToChangePassword =
          decodedUserDetails()?.ForcePasswordChange === "True";
        if (!userNeedsToChangePassword) {
          navigate("/test-request");
          window.history.pushState({ id: 1 }, null, "/test-request");
          document.body.style.overflow = "visible";
        } else {
          navigate("/change-password");
          window.history.pushState({ id: 1 }, null, "/change-password");
          document.body.style.overflow = "visible";
        }

        notification.success({
          message: "Login",
          description: "Sign In successful",
        });
      }
      if (!isFetchSuccessful) {
        notification.error({
          message: "Failed to login",
          description: errorMessagesDescription(
            res.payload?.response.data.errorMessages
          ),
        });
      }
    });
  };

  const saveClientInfoInLocalStorage = (userClientId) => {
    dispatch(getClientInfoById({ id: userClientId })).then((val) => {
      const isFetchSuccessful = val?.payload?.success;
      if (isFetchSuccessful) {
        localStorage.setItem("clInf", JSON.stringify(val.payload.data));
      }
    });
  };

  const errorMessageLists = errorMessage?.map((value) => {
    return (
      <Alert variant="outlined" severity="error">
        {value}
      </Alert>
    );
  });

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickForgotPassword = () => {
    dispatch(logout());
    dispatch(refreshUser());
    navigate("/forgot-password");
  };

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            sx={{
              mt: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img alt="eRequest Client" src={eRequestLogo} />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {error ? (
          <Stack sx={{ width: "100%" }} spacing={2}>
            {errorMessageLists}
          </Stack>
        ) : (
          ""
        )}

        <Box component="form" onSubmit={submitForm} sx={{ mt: 1, mb: 2 }}>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <TextField
            error={isFormDirty && isDataEmpty(username)}
            autoFocus={true}
            margin="normal"
            fullWidth
            id="username"
            label="Username*"
            disabled={isLoading}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <TextField
            error={isFormDirty && isDataEmpty(password)}
            fullWidth
            id="password"
            label="Password*"
            type={showPassword ? "text" : "password"}
            disabled={isLoading}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <LoadingButton
              sx={{ mt: 2, mb: 2 }}
              loading={isLoading}
              disabled={isLoading}
              loadingPosition="end"
              endIcon={<SendIcon />}
              variant="contained"
              type="submit"
            >
              {isLoading ? "Signing In..." : "Sign In"}
            </LoadingButton>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Link
                href="#"
                onClick={handleClickForgotPassword}
                variant="body2"
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright color="gray" />
    </Grid>
  );
};
export default LoginForm;
