import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDER_ENCRYPTION } from "config/connString";

const encryptTestOrder = createAsyncThunk(
  "test-order/encrypt-test-order",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(
        URL_TEST_ORDER_ENCRYPTION + "/" + data.orderId,
        {
          orderId: data?.orderId,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { encryptTestOrder };
