import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SAMPLE_COLLECTORS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const getAllSampleCollector = createAsyncThunk(
  "sample-collectors/get-all-sample-collectors",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();
      const config = {
        params: {
          clientId: clientId,
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
        },
      };
      const response = await api.get(URL_SAMPLE_COLLECTORS, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getAllSampleCollector };
