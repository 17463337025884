import { Box, Grid, Typography } from "@mui/material";
import styles from "./styles.module.css";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import useCalculateBirthdate from "hooks/useCalculateBirthdate";
import useTranslation from "hooks/useTranslation";
import { parsedClientDetails } from "utils/parsedClientDetails";

const PatientInformation = ({ printTestOrder }) => {
  const savedInputs = useSelector((state) => state.savedInputs);
  const inputs = useSelector((state) => state.inputs);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const clientWillDrawSpecimen = multiStepFormInputs?.clientWillDrawSpecimen;
  const { getAge } = useCalculateBirthdate();
  const { allowEmailSendingOfRequest } = parsedClientDetails();
  const orderData = useSelector((state) => state.orderData);
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");

  const patientMedicalRecordNo = `${
    savedInputs?.searchMedicalRecordNo === null
      ? ""
      : savedInputs?.searchMedicalRecordNo
  }`;

  const patientInfoLeft = [
    {
      id: 1,
      label: getTranslation("Patient name"),
      value: inputs.fullPatientName,
    },
    {
      id: 2,
      label: getTranslation("Medical Record no"),
      value: patientMedicalRecordNo,
    },
    {
      id: 3,
      label: getTranslation("DateOfBirth"),
      value:
        inputs?.dateOfBirth && moment(inputs?.dateOfBirth).format("DD/MM/YYYY"),
    },
    {
      id: 4,
      label: getTranslation("Patient age"),
      value: `${getAge(inputs?.dateOfBirth)?.years || ""} ${getTranslation(
        "years"
      )} ${getAge(inputs?.dateOfBirth)?.months || ""} ${getTranslation(
        "months"
      )} ${getAge(inputs?.dateOfBirth)?.days || ""} ${getTranslation("days")}`,
    },
    {
      id: 5,
      label: getTranslation("Sex code"),
      value:
        inputs?.gender === "Male" ? "M" : inputs.gender === "Female" ? "F" : "",
    },
  ];

  const patientInfoRight = [
    {
      id: 6,
      label: getTranslation("Date Collected"),
      value: clientWillDrawSpecimen ? moment().format("DD/MM/YYYY") : "",
    },
    {
      id: 7,
      label: getTranslation("Time Collected"),
      value: clientWillDrawSpecimen
        ? moment(
            multiStepFormInputs.patientSpecimens[0]?.collectionDateTime
          ).format("LT")
        : "",
    },
    {
      id: 9,
      label: getTranslation("Physician name"),
      value: `${savedInputs?.physicianName}`,
    },
    {
      id: 10,
      label: getGlobalTranslation("Email address"),
      value: inputs?.patientEmailForDisplay?.map((email) => email).join(", "),
    },
  ];

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography
          sx={{
            color: "darkBlue.main",
            marginBottom: "0.5rem",
            marginTop: "0.7rem",
            fontSize: "1.4rem",
            fontWeight: "semibold",
          }}
          variant="h4"
        >
          {getTranslation("Patient Information")}
        </Typography>
        <p
          style={{
            marginTop: orderData.successfullyCreatedOrder ? "1rem" : "",
          }}
          className={styles.divider}
        />
      </Grid>
      <Grid container>
        <Grid
          sx={{ marginRight: "auto" }}
          xs={printTestOrder ? 5 : 12}
          sm={printTestOrder ? 5 : 12}
          md={5}
          lg={5}
          xl={5}
          item
        >
          <Box
            sx={{ marginRight: "auto" }}
            className={styles["grid-container"]}
          >
            {patientInfoLeft.map((info) => (
              <Box key={info.id} className={styles["grid-item"]}>
                <span className={styles.label}>{info.label}</span>
                <span className={styles.colon}>:</span>
                <span className={styles.value}>{info.value}</span>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid
          xs={printTestOrder ? 7 : 12}
          sm={printTestOrder ? 7 : 12}
          md={7}
          lg={7}
          xl={7}
          item
        >
          <Box className={styles["grid-container"]}>
            {patientInfoRight
              .filter((item) =>
                !allowEmailSendingOfRequest ? item.id !== 10 : item
              )
              .map((info) => (
                <Box key={info.id} className={styles["grid-item"]}>
                  <span className={styles.label}>{info.label}</span>
                  <span className={styles.colon}>:</span>
                  <span className={styles.value}>{info.value}</span>
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientInformation;
