import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import TableContentLayout from "views/jkt/layout/tableContentLayout";
import useTranslation from "hooks/useTranslation";
import { theme } from "components/jkt/global/theme";
import MenuBar from "components/jkt/global/menuBar";
import GetAllLabTest from "components/jkt/admin/labtest/getAllLabTest";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";

const LabTestPage = () => {
  const { getTranslation } = useTranslation("SidebarItems");

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={getTranslation("Laboratory Test")} />
        <TableContentLayout>
          <GetAllLabTest />
        </TableContentLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default LabTestPage;
