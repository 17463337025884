import {
  BarangayPage,
  ChangePasswordPage,
  CityMunicipalityPage,
  ClientPage,
  ClientTypePage,
  ConfirmEmailPage,
  CountryPage,
  DashboardPage,
  ErrorPage,
  ExternalSystemCredentialPage,
  ForgotPasswordPage,
  HealthcareProviderPage,
  Icd10CodesPage,
  LabChangeUserPasswordPage,
  LabDeptPage,
  LabLoginPage,
  LabTestPage,
  LoginPage,
  LogsPage,
  LrfPickupRequestPage,
  PatientPage,
  ProvincePage,
  QrRegistrationPage,
  RegisterNewUserPage,
  ResetPasswordPage,
  RolePage,
  SampleCollectorsPage,
  SessionSummaryPage,
  SpecimenPage,
  SupplyRequestPage,
  TaggingPage,
  TestOrderPage,
  TestRequestPage,
  TestRequestReceivingPage,
  UnAuthorizedPage,
  UserPage,
} from "views/jkt";

export const jktRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
    isProtected: false,
  },
  {
    path: "/confirm-account",
    element: <ConfirmEmailPage />,
    isProtected: false,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
    isProtected: false,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
    isProtected: false,
  },
  {
    path: "/",
    element: <DashboardPage />,
    isProtected: true,
  },
  {
    path: "/change-password",
    element: <ChangePasswordPage />,
    isProtected: true,
  },
  {
    permission: "User",
    path: "/user",
    element: <UserPage />,
    isProtected: true,
  },
  {
    path: "/register-new-user",
    element: <RegisterNewUserPage />,
    isProtected: true,
  },
  {
    permission: "Role",
    path: "/role",
    element: <RolePage />,
    isProtected: true,
  },
  {
    permission: "Laboratory:LabTest:Read",
    path: "/labtest",
    element: <LabTestPage />,
    isProtected: true,
  },
  {
    permission: "Laboratory:Patient:Read",
    path: "/patient",
    element: <PatientPage />,
    isProtected: true,
  },
  {
    permission: "Laboratory:SupplyOrder:Read",
    path: "/supply-request",
    element: <SupplyRequestPage />,
    isProtected: true,
  },
  {
    path: "/external-system-credential",
    element: <ExternalSystemCredentialPage />,
    isProtected: true,
  },
  {
    path: "/test-request",
    element: <TestRequestPage />,
    isProtected: true,
  },
  {
    permission: "Laboratory:MinimalTestOrder:Read",
    path: "/lrf-pickup-request",
    element: <LrfPickupRequestPage />,
    isProtected: true,
  },
  {
    permission: "Administration:Client:Read",
    path: "/client",
    element: <ClientPage />,
    isProtected: true,
  },
  {
    permission: "Administration:ClientType:Read",
    path: "/client-type",
    element: <ClientTypePage />,
    isProtected: true,
  },
  {
    permission: "Administration:LabDepartment:Read",
    path: "/department",
    element: <LabDeptPage />,
    isProtected: true,
  },
  {
    permission: "Laboratory:HealthcareProvider:Read",
    path: "/healthcare-provider",
    element: <HealthcareProviderPage />,
    isProtected: true,
  },
  {
    permission: "Administration:Country:Read",
    path: "/country",
    element: <CountryPage />,
    isProtected: true,
  },
  {
    permission: "Administration:Province:Read",
    path: "/province",
    element: <ProvincePage />,
    isProtected: true,
  },
  {
    permission: "Administration:CityMunicipality:Read",
    path: "/city-municipality",
    element: <CityMunicipalityPage />,
    isProtected: true,
  },
  {
    permission: "Administration:Barangay:Read",
    path: "/barangay",
    element: <BarangayPage />,
    isProtected: true,
  },
  {
    permission: "Logs",
    path: "/logs",
    element: <LogsPage />,
    isProtected: true,
  },
  {
    permission: "Laboratory:TestOrder:Read",
    path: "/test-orders",
    element: <TestOrderPage />,
    isProtected: true,
  },
  {
    permission: "Laboratory:SampleCollector:Read",
    path: "/sample-collector",
    element: <SampleCollectorsPage />,
    isProtected: true,
  },
  {
    permission: "Laboratory:ICD10Code:Read",
    path: "/icd-codes",
    element: <Icd10CodesPage />,
    isProtected: true,
  },
  {
    permission: "Administration:RegisterQRCode:Read",
    path: "/qr-registration",
    element: <QrRegistrationPage />,
    isProtected: true,
  },
  {
    permission: "Laboratory:TestOrderSummary:Read",
    path: "/session-summary",
    element: <SessionSummaryPage />,
    isProtected: true,
  },
  {
    permission: "Laboratory:Specimen:Read",
    path: "/specimen",
    element: <SpecimenPage />,
    isProtected: true,
  },
  {
    path: "*",
    element: <ErrorPage />,
    isProtected: false,
  },
  {
    path: "/unauthorized",
    element: <UnAuthorizedPage />,
    isProtected: false,
  },
  {
    path: "/lab",
    element: <LabLoginPage />,
    isProtected: false,
  },
  {
    path: "/lab/receiving/:testOrderId?",
    element: <TestRequestReceivingPage />,
    isProtected: false,
  },
  {
    path: "/lab/tagging",
    element: <TaggingPage />,
    isProtected: false,
  },
  {
    path: "/lab-change-user-password",
    element: <LabChangeUserPasswordPage />,
    isProtected: false,
  },
];
