import JktApp from "JktApp";
import MnlApp from "MnlApp";
import { isSelectedCountryJkt } from "utils/isSelectedCountryJkt";

function App() {
  if (isSelectedCountryJkt) {
    return <JktApp />;
  }
  return <MnlApp />;
}

export default App;
