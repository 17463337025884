import moment from "moment";

const useCheckProvidedDate = () => {
  const currentDate = moment().format();

  const checkIfProvidedDateInPast = (providedDate) => {
    if (moment(providedDate).format() < currentDate) {
      return true;
    }
    return false;
  };

  const checkIfProvidedDateInFuture = (providedDate) => {
    if (moment(providedDate).format() > currentDate) {
      return true;
    }
    return false;
  };

  const checkIfProvidedDateExceeded = (providedDate) => {
    if (moment().diff(providedDate, "days") > 30) {
      return true;
    }

    return false;
  };

  return {
    checkIfProvidedDateInPast,
    checkIfProvidedDateInFuture,
    checkIfProvidedDateExceeded,
  };
};

export default useCheckProvidedDate;
