import axios from "axios";
import { URL_AUTH } from "config/connString";

const userStorage =
  localStorage.getItem("user") === null
    ? {
        token: null,
        refreshToken: null,
        user: { displayName: null, clientCode: null },
      }
    : JSON.parse(localStorage.getItem("user"));

export async function userRefreshToken() {
  try {
    if (userStorage === null) {
      localStorage.removeItem("user");
      localStorage.removeItem("last-refresh-token");
    } else {
      let token =
        JSON.parse(localStorage.getItem("user")).token !== userStorage.token
          ? JSON.parse(localStorage.getItem("user")).token
          : userStorage.token;

      let refreshToken =
        JSON.parse(localStorage.getItem("user")).refreshToken !==
        userStorage.refreshToken
          ? JSON.parse(localStorage.getItem("user")).refreshToken
          : userStorage.refreshToken;

      const response = await axios.post(URL_AUTH + "refresh-token", {
        token: token,
        refreshToken: refreshToken,
      });
      return response.data;
    }
  } catch (err) {
    return err.response.data;
  }
}
