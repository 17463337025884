import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const deleteUser = createAsyncThunk("user/delete-user", async (data) => {
  try {
    const api = useAxios();
    const response = await api.delete(URL_USER + "/" + data);
    return response.data;
  } catch (error) {
    return error;
  }
});

export { deleteUser };
