import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "api/auth/userLogin";
import { userForgotPassword } from "api/auth/userForgotPassword";
import { userResetPassword } from "api/auth/userResetPassword";
import { userChangePassword } from "api/auth/userChangePassword";
import { userConfirmEmail } from "api/auth/userConfirmEmail";

import {
  FORGOT_PASSWORD_MESSAGE_SUCCESS,
  RESET_PASSWORD_MESSAGE_SUCCESS,
} from "../../config/templateMessage";
import { getFlattenUserPermissions } from "../../api/rolesClaims/getFlattenUserPermissions";
import moment from "moment";

const userStorage = JSON.parse(localStorage.getItem("user"));

const claimsStorage = JSON.parse(localStorage.getItem("claims"));

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    user: {
      displayName: userStorage !== null ? userStorage.user.displayName : null,
      clientId: userStorage !== null ? userStorage.user.clientId : null,
    },
    successMessage: [],
    error: null,
    errorMessage: [],
    claims: claimsStorage !== null ? claimsStorage : [],
  },
  reducers: {
    logout: (state) => {
      state.user = {
        displayName: null,
        clientId: null,
      };
      state.isLoading = false;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
      state.claims = [];
      localStorage.removeItem("user");
      localStorage.removeItem("last-refresh-token");
      localStorage.removeItem("user-login-date");
      localStorage.removeItem("visited-route");
      localStorage.removeItem("claims");
      localStorage.removeItem("clInf");
      localStorage.removeItem("selectedParentCollapseKey");
    },
    clearMessage: (state) => {
      state.isLoading = false;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    },
  },
  extraReducers(builder) {
    //LOGIN
    builder.addCase(userLogin.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.token = action?.payload?.data?.token;
        state.refreshToken = action?.payload?.data?.refreshToken;
        state.user = action?.payload?.data?.user;
        state.isLoading = false;
        state.error = false;
        state.errorMessage = [];
        state.successMessage = [];
        localStorage.setItem("user", JSON.stringify(action?.payload?.data));
        localStorage.setItem(
          "last-refresh-token",
          JSON.stringify({ dt: new Date().toLocaleString() })
        );
        localStorage.setItem("user-login-date", moment().format());
      } else {
        state.isLoading = false;
        state.error = true;
        state.errorMessage = action?.payload?.data?.errorMessages;
        state.successMessage = [];
      }
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action?.payload?.errorMessages;
      state.successMessage = [];
    });

    //FORGOT PASSWORD
    builder.addCase(userForgotPassword.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(userForgotPassword.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.error = false;
        state.errorMessage = [];
        state.successMessage = [FORGOT_PASSWORD_MESSAGE_SUCCESS];
      } else {
        state.isLoading = false;
        state.error = true;
        state.successMessage = [];
        state.errorMessage = action?.payload?.data?.errorMessages;
      }
    });
    builder.addCase(userForgotPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action?.payload?.errorMessages;
      state.successMessage = [];
    });

    //RESET PASSWORD
    builder.addCase(userResetPassword.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(userResetPassword.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.error = false;
        state.errorMessage = [];
        state.successMessage = [RESET_PASSWORD_MESSAGE_SUCCESS];
        localStorage.removeItem("reset-token");
      } else {
        state.isLoading = false;
        state.error = true;
        state.successMessage = [];
        state.errorMessage = action?.payload?.data?.errorMessages;
      }
    });
    builder.addCase(userResetPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action?.payload?.errorMessages;
      state.successMessage = [];
    });

    //CHANGE PASSWORD
    builder.addCase(userChangePassword.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(userChangePassword.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.error = false;
        state.errorMessage = [];
        state.successMessage = [
          action.payload.successMessage === ""
            ? "Password Changed!"
            : action.payload.successMessage,
        ];
        state.token = action.payload.data.token;
        state.refreshToken = action.payload.data.refreshToken;
        state.user = action.payload.data.user;
        localStorage.setItem("user", JSON.stringify(action.payload.data));
        localStorage.setItem(
          "last-refresh-token",
          JSON.stringify({ dt: new Date().toLocaleString() })
        );
      } else {
        if (action.payload.data.statusCode === 400) {
          state.isLoading = false;
          state.error = true;
          state.errorMessage = action?.payload?.data?.errorMessages;
          state.successMessage = [];
        }
      }
    });
    builder.addCase(userChangePassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action?.payload?.errorMessages;
      state.successMessage = [];
    });

    //CONFIRM EMAIL
    builder.addCase(userConfirmEmail.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(userConfirmEmail.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.error = false;
        state.errorMessage = [];
        state.successMessage = [action.payload.successMessage];
      } else {
        state.isLoading = false;
        state.error = true;
        state.successMessage = [];
        state.errorMessage = action?.payload?.data?.errorMessages;
      }
    });
    builder.addCase(userConfirmEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action.payload.errorMessages;
      state.successMessage = [];
    });

    //GET USER CLAIMS
    builder.addCase(getFlattenUserPermissions.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(getFlattenUserPermissions.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.error = false;
        state.errorMessage = [];
        state.claims = action.payload.data;
        localStorage.setItem("claims", JSON.stringify(action.payload.data));
      } else {
        state.isLoading = false;
        state.error = true;
        state.successMessage = [];
        state.errorMessage = action?.payload?.data?.errorMessages;
        state.claims = [];
      }
    });
    builder.addCase(getFlattenUserPermissions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action?.payload?.errorMessages;
      state.successMessage = [];
      state.claims = [];
    });
  },
});
export const { logout, clearMessage } = authSlice.actions;
export const authReducer = authSlice.reducer;
