import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CITY_MUNICIPALITY } from "config/connString";
import useAxios from "utils/useAxios";

const deleteCityMunicipalities = createAsyncThunk(
  "citymunicipalities/delete-citymunicipalities",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(URL_CITY_MUNICIPALITY + "/" + data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { deleteCityMunicipalities };
