import * as React from "react";
import { Grid, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from "react-redux";
import {
  getFlattenUserPermissions,
  createClaims,
  getClaims,
  getUserPermissions,
  addUserClaims,
} from "store";
import { Table, Tag, Tree } from "antd";

function renameKey(obj, oldKey, newKey) {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
}

const AssignClaims = (props) => {
  let { selectedData, type } = props;
  const [permission, setPermission] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [claim, setClaims] = React.useState([]);
  const [initialClaim, setInitialClaims] = React.useState([]);
  const [permissionTree, setPermissionTree] = React.useState([]);
  const [isInitialLoading, setIsInitialLoading] = React.useState(true);

  const dispatch = useDispatch();

  //USER EXPANDED
  const [checkedKeys, setCheckedKeys] = React.useState([]);
  const [initialUserClaims, setInitialUserClaims] = React.useState([]);

  const onCheck = (checkedKeysValue, info) => {
    setCheckedKeys(checkedKeysValue);
  };

  React.useEffect(() => {
    //role type
    if (type === "role") {
      if (
        getFlattenUserPermissions.pending().type ===
        "rolesClaims/get-flatten-user-permissions/pending"
      ) {
        setIsLoading(true);
      }
      //get claims that has already claim
      dispatch(getClaims({ roleId: selectedData.id })).then((data) => {
        const keyClaims = data.payload.data.map((val) => {
          return val.permission;
        });
        setClaims(keyClaims);
        setInitialClaims(keyClaims);
        if (data.type === "rolesClaims/get-claims/fulfilled") {
          dispatch(getFlattenUserPermissions()).then((data) => {
            if (
              data.type === "rolesClaims/get-flatten-user-permissions/fulfilled"
            ) {
              setIsLoading(false);
              const formattedData = data.payload.data.map((val) => {
                const Key = val.permissionKey.split(":");

                return {
                  role: Key[0],
                  module: Key[1],
                  action: Key[2],
                  description: val.description,
                  key: val.permissionKey,
                };
              });
              setPermission(formattedData);
            }
          });
        }
      });
    }

    //user type
    if (type === "user" && isInitialLoading) {
      dispatch(getUserPermissions({ userId: selectedData.id })).then((data) => {
        const arr = data.payload.data.children;
        arr.forEach((obj) => {
          renameKey(obj, "permissionKey", "key");
          renameKey(obj, "name", "title");
          obj.children.forEach((children1) => {
            renameKey(children1, "permissionKey", "key");
            renameKey(children1, "name", "title");
            children1.children.forEach((children2, key2) => {
              renameKey(children2, "permissionKey", "key");
              renameKey(children2, "name", "title");
              var children2Checked = checkedKeys;
              if (
                children2.isGranted &&
                children2.key &&
                !checkedKeys.includes(children2.key)
              ) {
                children2Checked.push(children2.key);
              }
              setCheckedKeys(children2Checked);
              setInitialUserClaims(children2Checked);
            });
          });
        });
        setPermissionTree(data.payload.data.children);
      });
      setIsInitialLoading(false);
    }
  }, [dispatch, selectedData, type, checkedKeys, isInitialLoading]);

  const saveClaims = () => {
    //perform identifying selected claims
    const formattedSubmitData = claim.map((val) => {
      return { permission: val, isGranted: true };
    });

    //save section
    if (createClaims.pending().type === "rolesClaims/create-claims/pending") {
      setIsLoading(true);
    }
    dispatch(
      createClaims({ roleId: selectedData.id, claims: formattedSubmitData })
    ).then((data) => {
      if (data.type === "rolesClaims/create-claims/fulfilled") {
        setIsLoading(false);
        setInitialClaims(claim);
      }
    });
  };

  const columns = [
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Module",
      dataIndex: "module",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
  ];
  var rowSelection = {
    onChange: function (selectedRowKeys, selectedRows) {
      setClaims(selectedRowKeys);
    },
    selectedRowKeys: claim,
  };

  const saveUserClaims = () => {
    dispatch(
      addUserClaims({
        userId: selectedData.id,
        permissionKeys: checkedKeys.map((element) => {
          return { permission: element, isGranted: true };
        }),
      })
    ).then((data) => {
      if (data.type === "user/add-user-claims/fulfilled") {
        setInitialUserClaims(checkedKeys);
      }
    });
  };
  return (
    <Grid>
      {type === "role" ? (
        <>
          <Button
            disabled={isLoading}
            onClick={saveClaims}
            startIcon={<SaveIcon />}
            size="small"
            sx={{ mb: 1, mr: 1 }}
            variant="outlined"
          >
            Save
          </Button>
          {initialClaim === claim ? (
            <Tag color="green" sx={{ mb: 4 }}>
              Saved
            </Tag>
          ) : (
            <Tag color="default" sx={{ mb: 4 }}>
              Unsaved
            </Tag>
          )}

          <div style={{ height: 460, width: "100%" }}>
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              size="small"
              columns={columns}
              dataSource={permission}
            />
          </div>
        </>
      ) : null}
      {type === "user" ? (
        <>
          <Grid sx={{ mb: 2 }}>
            <Button
              startIcon={<SaveIcon />}
              size="small"
              variant="outlined"
              onClick={saveUserClaims}
              sx={{ mr: 1 }}
            >
              Save
            </Button>
            {initialUserClaims === checkedKeys ? (
              <Tag color="green" sx={{ mb: 4 }}>
                Saved
              </Tag>
            ) : (
              <Tag color="default" sx={{ mb: 4 }}>
                Unsaved
              </Tag>
            )}
          </Grid>
          <Tree
            checkable
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            treeData={permissionTree}
          />
        </>
      ) : null}
    </Grid>
  );
};
export default AssignClaims;
