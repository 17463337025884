import { Box, Divider, Typography } from "@mui/material";
import styles from "./styles.module.css";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import SidePanelWrapper from "components/jkt/global/sidePanelWrapper";

const SessionSummarySidePanel = ({
  clientDetails,
  onBeforePrintContent,
  onAfterPrintContent,
  sessionSummaryRef,
}) => {
  const savedInputs = useSelector((state) => state.savedInputs);
  const orderData = useSelector((state) => state.orderData);
  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => sessionSummaryRef.current,
    onBeforeGetContent: onBeforePrintContent,
    onAfterPrint: onAfterPrintContent,
  });

  return (
    <SidePanelWrapper>
      <Box
        sx={{
          color: "white",
          marginTop: orderData.successfullyCreatedOrder ? "5rem" : "",
        }}
      >
        <Typography
          sx={{
            marginBottom: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: "0.9rem",
            },
            textAlign: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "center",
              xl: "start",
            },
            marginTop: "4rem",
          }}
          variant="h6"
        >
          {clientDetails?.clientName || "RS Example Name"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "max-content",
          }}
        >
          {savedInputs.phAddresses?.length > 0 ? (
            savedInputs.phAddresses?.map((address) => (
              <Typography
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "none",
                    xl: "initial",
                  },
                }}
                variant="p"
              >
                {address?.streetName}
              </Typography>
            ))
          ) : (
            <Typography
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "initial",
                },
              }}
              variant="p"
            ></Typography>
          )}
        </Box>
        <Typography
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "initial",
            },
            width: "max-content",
          }}
          variant="p"
        >
          {clientDetails?.contactNumber
            ? clientDetails?.contactNumber
            : "(021) 55512345"}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
          color: "white",
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "block",
          },
        }}
      >
        <Divider
          sx={{
            width: "13rem",
            bgcolor: "white",
            height: "0.2rem",
            marginTop: "1rem",
          }}
        />
      </Box>
      <div className={styles["button-wrapper"]}>
        <button onClick={handlePrint} type="button">
          Print <PrintOutlinedIcon sx={{ fontSize: "1.5rem" }} />
        </button>
        <button onClick={() => navigate("/test-orders")} type="button">
          Order History
        </button>
      </div>
      <div className={styles["button-wrapper-mobile"]}>
        <button onClick={handlePrint} type="button">
          Print <PrintOutlinedIcon sx={{ fontSize: "1.5rem" }} />
        </button>
        <button onClick={() => navigate("/test-orders")} type="button">
          Order History
        </button>
      </div>
    </SidePanelWrapper>
  );
};

export default SessionSummarySidePanel;
