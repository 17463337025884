import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/jkt/global/menuBar";
import { theme } from "components/jkt/global/theme";
import GetAllLogs from "components/jkt/admin/logs/getAllLogs";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";
import TableContentLayout from "views/jkt/layout/tableContentLayout";

const LogsPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"Logs"} />
        <TableContentLayout>
          <GetAllLogs />
        </TableContentLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default LogsPage;
