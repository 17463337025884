import { useMemo, useState } from "react";

const usePagination = (data, pageSize) => {
  const dataLength = data.length;

  const totalPages = useMemo(
    () => Math.ceil(dataLength / pageSize),
    [pageSize, dataLength]
  );
  const [pageNumber, setPageNumber] = useState(0);

  const sliceStartingIndex = pageNumber * pageSize;
  const sliceEndingIndex = pageNumber * pageSize + pageSize;
  const slicedData = useMemo(
    () => data.slice(sliceStartingIndex, sliceEndingIndex),
    [data, sliceStartingIndex, sliceEndingIndex]
  );

  return {
    paginatedData: slicedData,
    pageNumber,
    totalPages,
    setPageNumber,
    disablePrevButton: pageNumber <= 0,
    disableNextButton: pageNumber + 1 === totalPages,
  };
};

export default usePagination;
