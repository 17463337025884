import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_SAMPLE_COLLECTORS } from "../../config/connString";

const getSampleCollectorTypes = createAsyncThunk(
  "sample-collectors/get-sample-collector-types",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(
        URL_SAMPLE_COLLECTORS + "/sample-collector-types"
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getSampleCollectorTypes };
