import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import indonesianFlagIcon from "assests/indonesian-flag.svg";
import americanFlagIcon from "assests/american-flag.svg";
import { Divider, Tooltip } from "@mui/material";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";

const LanguageSelectOption = () => {
  const savedInputs = useSelector((state) => state.savedInputs);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const dispatch = useDispatch();

  const languageOptions = [
    {
      value: "IN",
      label: "Indonesian",
      icon: indonesianFlagIcon,
    },
    {
      value: "EN",
      label: "English",
      icon: americanFlagIcon,
    },
  ];

  const handleToggleSelect = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleSelectLanguage = (selectedLanguage) => {
    localStorage.setItem("selectedLanguage", selectedLanguage);
    dispatch(
      handleChangeSavedFields({
        selectedLanguage: selectedLanguage,
      })
    );
  };

  const handleCloseSelectOption = () => {
    setIsSelectOpen(false);
  };

  useEffect(() => {
    const check = localStorage.getItem("selectedLanguage");
    if (check) {
      dispatch(
        handleChangeSavedFields({
          selectedLanguage: check,
        })
      );
    }
  }, [dispatch]);

  return (
    <div
      tabIndex={1}
      onBlur={handleCloseSelectOption}
      onClick={handleToggleSelect}
      className="relative bg-white px-3 py-1 rounded-md cursor-pointer z-50 border-2 border-[#2B469B]"
    >
      <div className="flex items-center gap-1">
        <img
          className="object-cover h-6 w-6 shadow-md"
          src={
            languageOptions.find(
              (option) => option.value === savedInputs.selectedLanguage
            )?.icon
          }
          alt="Selected Flag"
        />
        <div className="translate-x-[8px]">
          <KeyboardArrowDownRoundedIcon
            sx={{
              color: "black",
              fontSize: "1.7rem",
              transform: isSelectOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "0.3s ease-in-out",
            }}
          />
        </div>
      </div>
      <div
        className={`${
          isSelectOpen
            ? "translate-y-[1px] opacity-100"
            : "-translate-y-5 opacity-0"
        } z-10 duration-300 ease-in-out shadow-md bg-white px-1 border py-2 text-black absolute top-[41px] right-[0.1px] w-full rounded-md`}
      >
        {languageOptions.map((option, index) => (
          <div key={option.label}>
            <Tooltip title={option.label} placement="right" arrow>
              <div
                onClick={() => handleSelectLanguage(option.value)}
                className={`${isSelectOpen ? "block" : "hidden"} ${
                  savedInputs.selectedLanguage === option.value &&
                  "bg-[#7393C2]"
                } my-1 hover:bg-[#E7EBF4] w-full flex flex-col items-center justify-center rounded-md`}
              >
                <img
                  className="object-cover h-8 w-8 p-1"
                  src={option.icon}
                  alt="flag"
                />
              </div>
            </Tooltip>
            {index !== languageOptions.length - 1 && <Divider />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelectOption;
