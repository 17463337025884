import { Box, Grid, Skeleton, Typography } from "@mui/material";
import styles from "../../testRequest/forms/fourthStep/styles.module.css";
import { isDataEmpty } from "utils/isDataEmpty";
import useTranslation from "hooks/useTranslation";

const BillingInformation = (props) => {
  const { clientDetails, isLoading, courierRemarks, testOrderNotes } = props;
  const { getTranslation } = useTranslation("RequestPage");

  const billingInfo = [
    {
      id: 1,
      label: "Payor",
      value: `${clientDetails?.clientName || "RS Example Name"}`,
    },
  ];

  return (
    <Grid sx={{ marginTop: "1.3rem" }} container>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography
          sx={{
            color: "darkBlue.main",
            marginBottom: "0.5rem",
            fontSize: "14pt",
            fontWeight: "bold",
          }}
          variant="p"
        >
          {getTranslation("Billing Information")}
        </Typography>
        <p className={styles.divider} />
      </Grid>
      <Grid sx={{ my: "0.9rem" }} container>
        <Grid xs={12} sm={12} md={5} lg={5} xl={5} item>
          <Typography
            variant="p"
            sx={{
              color: "darkBlue.main",
              fontSize: "11pt",
            }}
          >
            {getTranslation("Bill to client")}
          </Typography>
          <Box className={styles["grid-container"]}>
            {billingInfo.map((info) => (
              <Box key={info.id} className={styles["grid-item"]}>
                <span className={styles.label}>{info.label}</span>
                <span className={styles.colon}>:</span>
                <span className={styles.value}>
                  {isLoading ? <Skeleton /> : info.value}
                </span>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <p className={styles.divider} />
      </Grid>
      <Grid container>
        <Grid xs={12} sm={12} md={5} lg={5} xl={5}>
          <Box className={styles["grid-container"]}>
            <Box className={styles["grid-item"]}>
              <span className={styles.label}>Courier remarks</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>
                {isDataEmpty(courierRemarks) ? "-" : courierRemarks}
              </span>
            </Box>
          </Box>
          <Box className={styles["grid-container"]}>
            <Box className={styles["grid-item"]}>
              <span className={styles.label}>Order notes</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>
                {isDataEmpty(testOrderNotes) ? "-" : testOrderNotes}
              </span>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillingInformation;
