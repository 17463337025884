import { createAsyncThunk } from "@reduxjs/toolkit";
import { VERSION } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";
import { BASE_URL } from "constants/connections";

const getTestsLookUp = createAsyncThunk(
  "labtests/get-tests-look-up",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const response = await api.get(
        `${BASE_URL}/${VERSION}/${clientId}/${data.gender}/tests-lookup`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const getTestsLookUpForUpdate = createAsyncThunk(
  "labtests/get-tests-look-up-for-update",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const specimenIdsQuery = data.specimenIds
        .map((id) => `specimenIds=${id}`)
        .join("&");

      const response = await api.get(
        `${BASE_URL}/${VERSION}/${clientId}/${data.gender}/tests-lookup?${specimenIdsQuery}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getTestsLookUp, getTestsLookUpForUpdate };
