import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_SPECIMEN } from "../../config/connString";

const getSpecimen = createAsyncThunk("specimen/get-specimen", async (data) => {
  try {
    const api = useAxios();
    const response = await api.get(URL_SPECIMEN + "/" + data?.id);
    return response.data;
  } catch (err) {
    return err;
  }
});

export { getSpecimen };
