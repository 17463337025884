import { Box, LinearProgress } from "@mui/material";
import React, { useState } from "react";
import QrRegister from "./qrRegister";
import QrTabs from "./qrTabs";
import QrChecker from "./qrChecker";

const QrLayout = () => {
  const [selectedOption, setSelectedOption] = useState("registerQr");
  const isSelectedOptionToRegisterQr = selectedOption === "registerQr";
  const isSelectedOptionToCheckQr = selectedOption === "checkQr";
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectOption = (selectedTabOption) => {
    //For selecting what form input to be displayed
    setSelectedOption(selectedTabOption);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "25rem",
            height: "23rem",
            padding: "1.2rem 0.8rem",
            backgroundColor: "whitesmoke",
            borderRadius: "0.5rem",
            position: "relative",
            boxShadow: "1px 1px 5px",
          }}
        >
          {isLoading && (
            <LinearProgress
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                borderRadius: "0.5rem",
              }}
            />
          )}
          <QrTabs
            handleSelectOption={handleSelectOption}
            isSelectedOptionToRegisterQr={isSelectedOptionToRegisterQr}
            isSelectedOptionToCheckQr={isSelectedOptionToCheckQr}
            isLoading={isLoading}
          />
          <QrRegister
            isSelectedOptionToRegisterQr={isSelectedOptionToRegisterQr}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
          <QrChecker
            isSelectedOptionToCheckQr={isSelectedOptionToCheckQr}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </>
  );
};

export default QrLayout;
