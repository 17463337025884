import { Box, Chip } from "@mui/material";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Switch,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const ClientInformationsInput = ({
  isForUpdate,
  loading,
  optionType,
  setClientStartDateValue,
  clientStartDateValue,
  optionAddressType,
  countryOptions,
  handleSelectCountry,
  selectedAddressIds,
  provinceOptions,
  handleSelectProvince,
  cityMunicipalityOptions,
  handleSelectCityMunicipality,
  barangayOptions,
}) => {
  return (
    <div className="mt-16">
      {/* Code */}
      <Form.Item
        label="Code"
        name="code"
        rules={[
          {
            required: true,
            message: <i>{"Please input Client Code."}</i>,
          },
        ]}
      >
        <Input disabled={isForUpdate} placeholder="Code" />
      </Form.Item>
      {/* Name */}
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: <i>{"Please input Client Name."}</i>,
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      {/* Type */}
      <Form.Item
        label="Type"
        name="type"
        rules={[
          {
            required: true,
            message: <i>{"Please input Client Type."}</i>,
          },
        ]}
      >
        <Select
          loading={loading}
          options={optionType}
          maxLength={5}
          placeholder={"Type"}
        />
      </Form.Item>
      {/* Contact Name */}
      <Form.Item
        label="Contact Name"
        name="contactName"
        rules={[
          {
            required: true,
            message: <i>{"Please input Contact Name."}</i>,
          },
        ]}
      >
        <Input placeholder="Contact Name" />
      </Form.Item>
      {/* Contact Number */}
      <Form.Item
        label="Contact Number"
        name="contactNumber"
        rules={[
          {
            required: true,
            message: <i>{"Please input Contact Number."}</i>,
          },
        ]}
      >
        <Input placeholder="Contact Number" />
      </Form.Item>

      {/* Client Start Date*/}
      <Form.Item
        label="Client Start Date"
        name="clientStartDate"
        rules={[
          {
            required: true,
            message: <i>{"Please input Client Start Date."}</i>,
          },
        ]}
      >
        <DatePicker
          onChange={(date) => setClientStartDateValue(date)}
          placeholder="Client Start Date"
          format={"DD/MM/YYYY"}
        />
      </Form.Item>

      {/* Client End Date*/}
      <Form.Item label="Client End Date" name="clientEndDate">
        <DatePicker
          disabled={clientStartDateValue === ""}
          disabledDate={(currentDate) =>
            currentDate < dayjs(clientStartDateValue)
          }
          placeholder="Client End Date"
          format={"DD/MM/YYYY"}
        />
      </Form.Item>

      {/* Client Address */}
      <Divider>Address</Divider>
      <Form.List name="phAddresses">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, key) => (
              <Box
                border={2}
                borderRadius={4}
                sx={{
                  m: 2,
                  borderColor: "primary.main",
                  padding: 1,
                  position: "relative",
                }}
                key={key}
              >
                <Row>
                  <Divider orientation="left" plain>
                    <Chip label={`Address #${key + 1}`} />
                  </Divider>
                </Row>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "18rem",
                  }}
                >
                  <Form.Item label="Address Type" name={[field.name, "type"]}>
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      virtual={false}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionAddressType}
                      placeholder={"Address Type"}
                    />
                  </Form.Item>
                </Box>
                <Row gutter={20}>
                  <Col key={key} span={12}>
                    {/* Country */}
                    <Form.Item label="Country" name={[field.name, "country"]}>
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        virtual={false}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={countryOptions}
                        onChange={handleSelectCountry}
                        placeholder={"Country"}
                      />
                    </Form.Item>
                  </Col>
                  <Col key={key} span={12}>
                    {/* Province */}
                    <Form.Item
                      required={!isForUpdate}
                      label="Province"
                      name={[field.name, "provinceId"]}
                    >
                      <Select
                        disabled={
                          selectedAddressIds.country === null ||
                          provinceOptions.length === 0
                        }
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        virtual={false}
                        onChange={handleSelectProvince}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        rules={[
                          {
                            required: true,
                            message: <i>{"Please select Province."}</i>,
                          },
                        ]}
                        options={provinceOptions}
                        placeholder={"Province"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col key={key} span={13}>
                    {/* City Municipality */}
                    <Form.Item
                      required={!isForUpdate}
                      label="City Municipality"
                      name={[field.name, "cityMunicipalityId"]}
                    >
                      <Select
                        disabled={
                          selectedAddressIds.provinceId === null ||
                          cityMunicipalityOptions.length === 0
                        }
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        virtual={false}
                        onChange={handleSelectCityMunicipality}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <i>{"Please select City Municipality."}</i>
                            ),
                          },
                        ]}
                        options={cityMunicipalityOptions}
                        placeholder={"City Municipality"}
                      />
                    </Form.Item>
                  </Col>
                  <Col key={key} span={11}>
                    {/* Barangay */}
                    <Form.Item
                      label="Barangay"
                      name={[field.name, "barangayId"]}
                    >
                      <Select
                        disabled={
                          selectedAddressIds.cityMunicipalityId === null ||
                          barangayOptions.length === 0
                        }
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        virtual={false}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={barangayOptions}
                        placeholder={"Barangay"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col key={key} span={15}>
                    {/* Postal Code */}
                    <Form.Item
                      label="House Building No"
                      name={[field.name, "houseBuildingNumber"]}
                    >
                      <Input placeholder="House Building No" />
                    </Form.Item>
                  </Col>
                  <Col key={key} span={9}>
                    {/* House Building Number*/}
                    <Form.Item
                      label="Postal Code"
                      name={[field.name, "postalCode"]}
                    >
                      <Input placeholder="Postal Code" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} key={key}>
                    {/* Street Name */}
                    <Form.Item
                      label="Stret Name"
                      name={[field.name, "streetName"]}
                    >
                      <Input placeholder="Street Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Box sx={{ position: "absolute", top: 6, right: 10 }}>
                  <Popconfirm
                    cancelText="No"
                    okText="Yes"
                    description="Are you sure you want to remove this address?"
                    title={`Removing Address #${key + 1}`}
                    onConfirm={() => remove(key)}
                  >
                    <MinusCircleOutlined
                      style={{ fontSize: "1.2rem", color: "red" }}
                    />
                  </Popconfirm>
                </Box>
              </Box>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add new address
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {/* Status */}
      {isForUpdate && (
        <Form.Item label="Status" name="isActive" valuePropName="checked">
          <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
        </Form.Item>
      )}
    </div>
  );
};

export default ClientInformationsInput;
