import { Grid } from "@mui/material";
import { Skeleton } from "antd";
import homePageImg from "images/homePageImg.png";
import { useState } from "react";

const LoginPanel = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Grid
      item
      sm={4}
      md={7}
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {!imageLoaded && (
        <Skeleton.Image
          active
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
          }}
        />
      )}
      <img
        src={homePageImg}
        alt="Home"
        style={{
          display: imageLoaded ? "block" : "none",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        onLoad={() => setImageLoaded(true)}
      />
    </Grid>
  );
};
export default LoginPanel;
