import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import TestModal from "./labtestModal/testModal";
import { useDispatch, useSelector } from "react-redux";
import { getLabTestRequirements } from "store";
import { notification } from "antd";
import SelectedLabtestTable from "./selectedLabtestTable";
import LabtestSearchInputs from "./labtestSearchInputs";
import ProfilePanelPackageSpecimenSelectorModal from "./labtestModal/profilePanelPackageSpecimenSelectorModal";
import useAdditionalInfoFunctions from "./labtestModal/useAdditionalInfoFunctions";
import useFetchSpecificLabtest from "components/jkt/testRequest/proxies/useFetchSpecificLabtest";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import getDuplicatedSpecimenNames from "components/jkt/testRequest/proxies/getDuplicatedSpecimenNames";
import getSpecimenRecursively from "components/jkt/testRequest/proxies/getSpecimenRecursively";
import getSpecimenDataRecursively from "components/jkt/testRequest/proxies/getSpecimenDataRecursively";
import getLabtestRequirementsRecursively from "components/jkt/testRequest/proxies/getLabtestRequirementsRecursively";
import getSpecimenOptionsRecursively from "components/jkt/testRequest/proxies/getSpecimenOptionsRecursively";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import AdditionalPatientInfoModal from "./labtestModal/additionalPatientInfoModal";
import TestProfileInfoDetails from "./labtestModal/testProfileInfoDetails";
import ResultComponent from "components/jkt/global/resultComponent";
import LoadingSpinner from "components/jkt/global/loadingSpinner";
import useTranslation from "hooks/useTranslation";
import { parsedClientDetails } from "utils/parsedClientDetails";
import moment from "moment";

const FirstSection = () => {
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const orderData = useSelector((state) => state.orderData);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [testInput, setTestInput] = useState("");
  const [fetchedInputFields, setFetchedInputFields] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [selectedTestDatas, setSelectedTestDatas] = useState(null);
  const [selectedTestProfileData, setSelectedTestProfileData] = useState([]);
  const [showMultiSpecimenSelectorModal, setShowMultiSpecimenSelectorModal] =
    useState(false);
  const [showMultiLabRequirementsModal, setShowMultiLabRequirementsModal] =
    useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [showSpecimenModal, setShowSpecimenModal] = useState(false);
  const [selectedSpecimenType, setSelectedSpecimenType] = useState("");
  const [specimenOptions, setSpecimenOptions] = useState([]);
  const { testData, isTestLoading } = useFetchSpecificLabtest(
    selectedTestProfileData.id
  );
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const { enableVolumeCheck } = parsedClientDetails();
  const isOrderStatusPickedUp =
    orderData.orderStatus.toLowerCase() === "pickedup";
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const {
    filteredLabtestForPickedup,
    getPatientSpecimensData,
    getLabtestIdAndSpecimen,
    getNestedLabtestIdAndSpecimen,
    getNestedPatientSpecimensData,
    getNestedTestOrderDetails,
    getTestOrderDetails,
    getLabtestData,
  } = useAdditionalInfoFunctions();

  const handleCloseAdditionalPatientInfoModal = () => {
    //Function for closing additional patient information modal
    const clearedInputValues = inputValues.map((_, index) => ({
      id: index,
      value: "",
      description: "",
      isRequired: false,
    }));
    setInputValues(clearedInputValues);
    setIsOpen(false);
  };

  const handleCloseMultiSpecimenSelectorModal = () => {
    setShowMultiSpecimenSelectorModal(false);
  };

  const handleCloseMultiLabRequirementsModal = () => {
    setShowMultiLabRequirementsModal(false);
  };
  const handleOpenTestModal = (selectedTest) => {
    //Function for opening labtest information modal
    setSelectedTestProfileData(selectedTest);
    setSelectedSpecimenType(selectedTest.type);
    setShowTestModal(true);
  };

  const handleCloseTestModal = () => {
    //Function for closing labtest information modal
    setSelectedTestProfileData([]);
    setShowTestModal(false);
    setSelectedSpecimenType("");
  };

  const handleShowWarningModal = (message) => {
    //For showing warning message modal
    setIsLoading(false);
    setShowWarningModal(true);
    setWarningMessage(message);
    setTimeout(() => {
      setShowWarningModal(false);
      setWarningMessage(null);
    }, 7000);
  };

  const handleCloseWarningModal = () => {
    //For closing warning message modal
    setShowWarningModal(false);
    setWarningMessage(null);
  };

  //For adding labtest in list
  const handleAddLabtest = (selectedLabTest) => {
    setTestInput(""); //After selecting a labtest, clear the labtest dropdown search input field
    setIsLoading(true); //Sets the loading to true

    const isSelectedLabtestTestAlreadySaved = savedLabtests?.savedLabtestsData
      ?.map((data) => data.id)
      ?.includes(selectedLabTest.id); //Check if the selected labtest is already added in test array

    const parentLabtestNames = savedLabtests?.savedLabtestsData?.filter(
      (test) => test?.testIds?.includes(selectedLabTest.id)
    ); //Check if the added labtest is present in profile/panel/package
    const isSelectedLabtestInsideOfParent = parentLabtestNames.length > 0;

    if (isSelectedLabtestTestAlreadySaved && !isSelectedLabtestInsideOfParent) {
      //Checking if selected test is already added
      setIsLoading(false);
      handleShowWarningModal(
        <p style={{ fontSize: "1rem", color: "black" }}>
          <strong style={{ color: "red" }}>{selectedLabTest.name}</strong> is
          already added
        </p> //Display a notification if it is already added
      );
    }

    if (isSelectedLabtestInsideOfParent && isSelectedLabtestInsideOfParent) {
      //Check if the added labtest is present in profile/panel/package
      setIsLoading(false);
      handleShowWarningModal(
        <p style={{ fontSize: "1rem", color: "black" }}>
          <strong style={{ color: "red" }}> {selectedLabTest.name}</strong>{" "}
          {getGlobalTranslation("IsAlreadyIncludedIn")}{" "}
          <strong style={{ color: "green" }}>
            {parentLabtestNames.map((test) => test.name).join(", ")}
          </strong>
        </p> //Display a notification if it is already added
      );
    }

    if (
      !isSelectedLabtestTestAlreadySaved &&
      !isSelectedLabtestInsideOfParent
    ) {
      //If the labtest can be added
      dispatch(
        handleChangeSavedFields({
          isTestProfileEmpty: false,
          isSampleCollectorEmpty: false,
          isSpecimenQuantityEmpty: false,
          isCollectionSiteEmpty: false,
        })
      );
      getSelectedLabtestDataFromApi(selectedLabTest); //Get all the data of the labtest that will be added
    }
  };

  const getSelectedLabtestDataFromApi = (selectedLabTest) => {
    dispatch(
      getLabTestRequirements({
        id: selectedLabTest.id,
      }) //Get the labtest requirements of the labtest
    ).then((response) => {
      const responseData = response?.payload?.data; //Instead of using response.payload.data, created a variable so it is readable
      const isDataFetchedSuccessfully = response?.payload?.success; //Check if the data is fetched successfully
      const labtestHasChild = responseData?.child?.length > 0;
      const isLabtestSpecimenEmpty =
        responseData?.testDetails?.labTestSpecimens?.length === 0;

      if (isDataFetchedSuccessfully) {
        //Check if the data is fetched successfully
        setIsLoading(false);
        setSelectedTestDatas(responseData);

        if (isLabtestSpecimenEmpty) {
          return showEmptyLabtestWarning(selectedLabTest.name);
        }

        if (isOrderStatusPickedUp) {
          if (filteredLabtestForPickedup(responseData)) {
            return handleShowWarningModal(
              <p style={{ fontSize: "1rem", color: "black" }}>
                Adding{" "}
                <strong style={{ color: "red" }}>{selectedLabTest.name}</strong>{" "}
                will exceed the required maximum number of tests allowed for the
                provided specimen.
              </p> //Display a notification if the test will exceed the current max test of specimen
            );
          }
        }

        const savedSpecimenIds = multiStepFormInputs?.patientSpecimens
          .map((data) => data.specimenId)
          .toString(); //Get all of the saved saved patient specimens id, and convert them to a string. This will be use later for filtering

        const isSpecimenCanBeAdded = !savedSpecimenIds.includes(
          responseData.testDetails?.labTestSpecimens
            ?.map((data) => data.id)
            .toString()
        ); //Check if the spsecimen can be added, or it is not already present

        const singleTestHasAdditionalInformation =
          responseData?.testDetails?.labTestRequirements?.length > 0; //Check if the labtest has an additional patient information

        const duplicatedSpecimenNames =
          getDuplicatedSpecimenNames(responseData); //Recursively fetched unflitered specimens, it means that they are duplicated

        const specimensToBeAdded = getSpecimenRecursively(responseData); //Recursively fetched filtered specimens, duplicates are removed

        const testIdsToBeAdded = getSpecimenDataRecursively(responseData);

        const childLabTestRequirements =
          getLabtestRequirementsRecursively(responseData); //Recursively fetched additional patient information that is inside of panel/profile/package
        const parentLabtestHasLabRequirements =
          childLabTestRequirements.length > 0;

        const recursiveSpecimenOptions =
          getSpecimenOptionsRecursively(responseData); //Recursively fetched specimen options
        const filteredSavedSelectedIds =
          savedLabtests?.savedLabtestsIdAndSpecimen?.filter(
            (data) =>
              !testIdsToBeAdded.map((data) => data.testId).includes(data.testId)
          ); //Filter recursively fetched labtest id, check if one of the labtest is already added

        const filteredSavedTest = savedLabtests?.savedLabtestsData?.filter(
          (data) =>
            !testIdsToBeAdded.map((data) => data.testId).includes(data.id)
        ); //Filtered recursively fetched labtest if it is already added

        const listsOfLabtestThatWillBeRemoved =
          savedLabtests?.savedLabtestsData?.filter((data) =>
            testIdsToBeAdded.map((data) => data.testId)?.includes(data.id)
          ); //Get all of the labtest that needs to be removed, before we add panel/profile/package

        const labtestsToBeRemovedInTestOrder =
          multiStepFormInputs.testOrderDetails?.filter(
            (data) =>
              !testIdsToBeAdded.map((data) => data.testId).includes(data.testId)
          ); //Labtests that will be removed in test order details

        const singleTestHasMultipleSpecimen =
          responseData.testDetails?.labTestSpecimens.length > 1; //Check if labtest has multiple specimens
        const profilePackagePanelHasMultipleSpecimen =
          selectedLabTest.type !== "Test" &&
          recursiveSpecimenOptions?.length > 0;

        if (!parentLabtestHasLabRequirements) {
          //Check if the panel/profile/package test doesn't have any additional patient information
          getNestedLabtestIdAndSpecimen(
            filteredSavedSelectedIds,
            testIdsToBeAdded
          );
        }
        if (
          singleTestHasAdditionalInformation ||
          singleTestHasMultipleSpecimen ||
          parentLabtestHasLabRequirements ||
          profilePackagePanelHasMultipleSpecimen
        ) {
          //Check if labtest has additional patient information, it has multiple specimens and its child also has additional patient information
          if (
            parentLabtestHasLabRequirements &&
            !profilePackagePanelHasMultipleSpecimen
          ) {
            //Check if the child of added package/panel/profile labtest has additional patient information
            //Then get all of its labtest requirements
            setFetchedInputFields(childLabTestRequirements);
            getNestedLabtestRequirements(childLabTestRequirements);
            setShowMultiLabRequirementsModal(true);
          } else if (
            !parentLabtestHasLabRequirements &&
            profilePackagePanelHasMultipleSpecimen
          ) {
            getNestedSpecimenOptions(recursiveSpecimenOptions);
            setShowMultiSpecimenSelectorModal(true);
          } else if (
            parentLabtestHasLabRequirements &&
            profilePackagePanelHasMultipleSpecimen
          ) {
            getNestedLabtestRequirements(childLabTestRequirements);
            getNestedSpecimenOptions(recursiveSpecimenOptions);
            setFetchedInputFields(childLabTestRequirements);
            setShowMultiSpecimenSelectorModal(true);
            setShowMultiLabRequirementsModal(true);
          } else if (
            selectedLabTest.type === "Test" &&
            (singleTestHasAdditionalInformation ||
              singleTestHasMultipleSpecimen)
          ) {
            //If it is a type of test, then just get the response
            setShowSpecimenModal(true);
            setFetchedInputFields(responseData);
            getLabtestRequirements(responseData);
            getSpecimenOptions(responseData);
            handleOpenModal();
          }

          setTestInput("");
        } else {
          //If the added labtest is a type of test, then use the default response and add that to the test order details
          getTestOrderDetails(
            responseData,
            testIdsToBeAdded,
            duplicatedSpecimenNames,
            specimensToBeAdded
          );

          const testDataToBeAdded = {
            id: responseData.id,
            type: selectedLabTest.type,
            testIds: labtestHasChild
              ? testIdsToBeAdded?.map((test) => test.testId)
              : null,
            name: responseData.name,
            code: responseData.code,
            specimenExpirationInHours:
              responseData?.testDetails?.maxSpecimenAgeInHours,
            specimen: labtestHasChild
              ? [...new Set(specimensToBeAdded?.map((data) => data?.specimen))]
              : [
                  responseData?.testDetails?.labTestSpecimens
                    ?.map((data) => data.name)
                    .toString(),
                ],
            isStatAllowed: responseData?.testDetails?.isStatAllowed,
            requiresBloodDraw:
              responseData?.testDetails?.labTestSpecimens?.[0]
                .requiresBloodDraw,
            requiresSampleOrigin:
              responseData?.testDetails?.labTestSpecimens?.[0]
                .requiresSampleOrigin,
          };

          getLabtestData(filteredSavedTest, testDataToBeAdded);
          getNestedTestOrderDetails(
            labtestsToBeRemovedInTestOrder,
            responseData,
            testIdsToBeAdded,
            duplicatedSpecimenNames,
            specimensToBeAdded
          );

          if (listsOfLabtestThatWillBeRemoved.length > 0) {
            //Labtest that will be removed, because it is inside of package/profile/panel that is newly added
            handleShowWarningModal(
              <p style={{ fontSize: "1rem", color: "black" }}>
                <strong style={{ color: "red" }}>
                  {" "}
                  {listsOfLabtestThatWillBeRemoved
                    ?.map((test) => test.name)
                    .join(", ")}
                </strong>{" "}
                {getGlobalTranslation("IsRemovedBecauseItIsIncludedIn")}{" "}
                <strong style={{ color: "green" }}>
                  {selectedLabTest.name}
                </strong>
              </p>
            );
          }

          if (responseData?.child.length > 0) {
            //Check if the response has child
            const filteredSpecimensToAdd = specimensToBeAdded?.filter(
              (specimen) => {
                return !multiStepFormInputs?.patientSpecimens?.some(
                  (data) => specimen.specimenId === data.specimenId
                );
              }
            ); //Filter the recursively fetched specimens
            if (filteredSpecimensToAdd.length > 0) {
              //Only add the specimen that is not present in patientSpecimens property
              getNestedPatientSpecimensData(
                filteredSpecimensToAdd,
                duplicatedSpecimenNames
              );
            }
          } else {
            if (isSpecimenCanBeAdded) {
              getPatientSpecimensData(responseData);
            }
            getLabtestIdAndSpecimen(responseData);
          }
          //After adding a labtest, success toast notification is displayed saying it is added successfully
          showToastNotification(
            "success",
            getGlobalTranslation("Added labtest"),
            `${selectedLabTest.name} ${getGlobalTranslation(
              "added successfully"
            )}`
          );
          if (isLabtestSpecimenEmpty) {
            showEmptyLabtestWarning(selectedLabTest.name);
          }
        }
      }

      if (!isDataFetchedSuccessfully) {
        //If the request fails, error toast notification is displayed
        showToastNotification(
          "error",
          "Failed to add Laboratory Test",
          `${errorMessagesDescription(
            response?.payload?.response.data.errorMessages
          )}`
        );
      }
    });
  };

  const showToastNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const showEmptyLabtestWarning = (labtestName) => {
    showToastNotification(
      "error",
      `Unable to add ${labtestName}`,
      " The selected test/profile does not have a configured specimen. Please contact ABC Lab."
    );
  };

  const getSpecimenOptions = (responseData) => {
    setSpecimenOptions(
      responseData?.testDetails?.labTestSpecimens?.map((data) => ({
        requiresSampleOrigin: data?.requiresSampleOrigin,
        requiresBloodDraw: data?.requiresBloodDraw,
        specimen: data?.name,
        key: data?.name,
        unitOfMeasure: data?.unitOfMeasure,
        specimenId: data?.id,
        specimenExpirationInHours:
          responseData?.testDetails?.maxSpecimenAgeInHours,
        specimenQuantity: enableVolumeCheck
          ? data.testVolumeOrSizeRequirements.find((data) =>
              Math.min(data.minTest)
            )?.minVolume
          : "",
        notes: "",
        specimenVolReqData: data.testVolumeOrSizeRequirements,
        collectionDateTime: moment().format(),
        collectorId: data?.requiresBloodDraw ? "" : null,
        collectionSite: data?.requiresSampleOrigin ? "" : null,
        status: data.status,
      }))
    );
  };

  const getNestedSpecimenOptions = (recursiveSpecimenOptions) => {
    setSpecimenOptions(
      recursiveSpecimenOptions?.map((data) => ({
        labTestInfoId: data.labTestInfoId,
        requiresSampleOrigin: data.requiresSampleOrigin,
        requiresBloodDraw: data.requiresBloodDraw,
        specimen: data?.specimen,
        key: data?.key,
        specimenExpirationInHours: data?.specimenExpirationInHours,
        unitOfMeasure: data?.unitOfMeasure,
        specimenId: data?.specimenId,
        specimenQuantity: enableVolumeCheck ? data.specimenQuantity : "",

        notes: "",
        specimenVolReqData: data.specimenVolReqData,
        collectionDateTime: data?.collectionDateTime,
        collectorId: data?.collectorId,
        collectionSite: data?.collectionSite,
        status: data?.status,
      }))
    );
  };

  const getLabtestRequirements = (responseData) => {
    setInputValues(
      responseData?.testDetails?.labTestRequirements?.map((field) => ({
        id: field.id,
        labTestInfoId: field.labTestInfoId,
        isRequired: field.isRequired,
        value: field.requirementType === 3 ? "false" : "",
        description: field.requirementDetails,
      }))
    );
  };

  const getNestedLabtestRequirements = (childLabTestRequirements) => {
    setInputValues(
      childLabTestRequirements?.map((field, index) => ({
        id: index + 1,
        labTestInfoId: field.labTestInfoId,
        isRequired: field.isRequired,
        value: field.requirementType === 3 ? "false" : "",
        description: field.requirementDetails,
      }))
    );
  };

  return (
    <Grid sx={{ color: "grayishBlue.light" }} container>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography
          sx={{ color: "darkBlue.main", fontWeight: "600", fontSize: "0.9rem" }}
          variant="p"
          className="required"
        >
          {getTranslation("Add tests / profiles")}
        </Typography>
      </Grid>
      <Box
        sx={{
          backgroundColor: "grayishBlue.main",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          padding: "1.3rem 1.7rem",
          marginTop: "0.5rem",
        }}
      >
        <LabtestSearchInputs
          handleAddLabtest={handleAddLabtest}
          testInput={testInput}
          setTestInput={setTestInput}
        />
        <SelectedLabtestTable handleOpenTestModal={handleOpenTestModal} />
      </Box>
      <TestModal isOpen={isOpen || showSpecimenModal}>
        <AdditionalPatientInfoModal
          setShowSpecimenModal={setShowSpecimenModal}
          specimenOptions={specimenOptions}
          showSpecimenModal={showSpecimenModal}
          setTestInput={setTestInput}
          selectedTestDatas={selectedTestDatas}
          inputValues={inputValues}
          setInputValues={setInputValues}
          handleCloseAdditionalPatientInfoModal={
            handleCloseAdditionalPatientInfoModal
          }
          fetchedInputFields={fetchedInputFields}
        />
      </TestModal>
      <TestModal
        isOpen={showMultiSpecimenSelectorModal || showMultiLabRequirementsModal}
      >
        <ProfilePanelPackageSpecimenSelectorModal
          specimenOptions={specimenOptions}
          selectedTestDatas={selectedTestDatas}
          inputValues={inputValues}
          setInputValues={setInputValues}
          fetchedInputFields={fetchedInputFields}
          handleCloseMultiLabRequirementsModal={
            handleCloseMultiLabRequirementsModal
          }
          handleCloseMultiSpecimenSelectorModal={
            handleCloseMultiSpecimenSelectorModal
          }
          handleCloseAdditionalPatientInfoModal={
            handleCloseAdditionalPatientInfoModal
          }
        />
      </TestModal>
      <TestProfileInfoDetails
        selectedSpecimenType={selectedSpecimenType}
        handleOpenTestModal={handleOpenTestModal}
        handleCloseTestModal={handleCloseTestModal}
        showTestModal={showTestModal}
        testData={testData}
        isTestLoading={isTestLoading}
      />

      <ResultComponent
        onCancel={handleCloseWarningModal}
        modalWidth={550}
        status="warning"
        title={getGlobalTranslation(
          "LabtestCannotBeAddedSinceItIsInsideOfProfile"
        )}
        subTitle={warningMessage}
        buttons={null}
        openModal={showWarningModal}
      />
      <LoadingSpinner open={isLoading} />
    </Grid>
  );
};

export default FirstSection;
