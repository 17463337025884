import { Tab, Tabs } from "@mui/material";
import React from "react";

const SessionSummaryTab = ({ selectedTab, onTabChange }) => {
  return (
    <Tabs
      value={selectedTab}
      onChange={(event, newValue) => onTabChange(newValue)}
      aria-label="Session Summary Tab"
    >
      <Tab label="Ready For Pickup" />
      <Tab label="Previous Orders" />
    </Tabs>
  );
};

export default SessionSummaryTab;
