import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useClearReduxStore from "hooks/useClearReduxStore";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import useTranslation from "hooks/useTranslation";
import { parsedClientDetails } from "utils/parsedClientDetails";

const RegularItemRoute = ({ pageName }) => {
  const { getTranslation } = useTranslation("SidebarItems");
  const { isClientAbcLab, isSuperAdmin } = parsedClientDetails();
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const { openSidebar } = useSelector((state) => state.appBarSettings);
  const { claims } = useSelector((state) => state.auth);
  const showLrfPickupRequestItem = claims?.find(
    (claim) => claim?.permissionKey === "Laboratory:MinimalTestOrder:Read"
  )?.isGranted;
  const showSupplyRequestItem = claims?.find(
    (claim) => claim?.permissionKey === "Laboratory:SupplyOrder:Read"
  )?.isGranted;
  const navigate = useNavigate();
  const { handleResetReduxStore } = useClearReduxStore();
  const translatedTestRequestText = getTranslation("TestRequest");
  const translatedSupplyRequestText = getTranslation("SupplyRequest");

  const removeSelectedCollapseKey = () => {
    localStorage.removeItem("selectedParentCollapseKey");
  };
  const navigateToDashboard = () => {
    navigate("/");
    if (multiStepFormInputs.patientId !== 0) {
      handleResetReduxStore();
    }
    removeSelectedCollapseKey();
  };

  const navigateToRequest = () => {
    navigate("/test-request");
    removeSelectedCollapseKey();
  };

  const navigateToLrfPickupRequest = () => {
    navigate("/lrf-pickup-request");
    removeSelectedCollapseKey();
  };

  const navigateToSuppliesRequest = () => {
    navigate("/supply-request");
    removeSelectedCollapseKey();
  };

  const navigateToExternalSystemCredentials = () => {
    navigate("/external-system-credential");
    removeSelectedCollapseKey();
  };

  return (
    <>
      {(isClientAbcLab || isSuperAdmin) && (
        <Tooltip
          arrow
          placement="right"
          title={!openSidebar ? "Dashboard" : ""}
        >
          <ListItemButton
            selected={pageName === "Dashboard"}
            onClick={navigateToDashboard}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ whiteSpace: openSidebar ? "normal" : "none" }}
              primary="Dashboard"
            />
          </ListItemButton>
        </Tooltip>
      )}
      <Tooltip
        arrow
        placement="right"
        title={!openSidebar ? translatedTestRequestText : ""}
      >
        <ListItemButton
          selected={pageName === translatedTestRequestText}
          onClick={navigateToRequest}
        >
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText
            sx={{ whiteSpace: openSidebar ? "normal" : "none" }}
            primary={translatedTestRequestText}
          />
        </ListItemButton>
      </Tooltip>
      {showSupplyRequestItem && (
        <Tooltip
          arrow
          placement="right"
          title={!openSidebar ? translatedSupplyRequestText : ""}
        >
          <ListItemButton
            selected={pageName === translatedSupplyRequestText}
            onClick={navigateToSuppliesRequest}
          >
            <ListItemIcon>
              <VaccinesIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ whiteSpace: openSidebar ? "normal" : "none" }}
              primary={translatedSupplyRequestText}
            />
          </ListItemButton>
        </Tooltip>
      )}
      {showLrfPickupRequestItem && (
        <Tooltip
          arrow
          placement="right"
          title={!openSidebar ? "LRF Pickup Request" : ""}
        >
          <ListItemButton
            selected={pageName === "LRF Pickup Request"}
            onClick={navigateToLrfPickupRequest}
          >
            <ListItemIcon>
              <NoteAltIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ whiteSpace: openSidebar ? "normal" : "none" }}
              primary="LRF Pickup Request"
            />
          </ListItemButton>
        </Tooltip>
      )}
      {isSuperAdmin && (
        <Tooltip
          arrow
          placement="right"
          title={!openSidebar ? "External System Credential" : ""}
        >
          <ListItemButton
            selected={pageName === "External System Credential"}
            onClick={navigateToExternalSystemCredentials}
          >
            <ListItemIcon>
              <LockPersonIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ whiteSpace: openSidebar ? "normal" : "none" }}
              primary="External System Credential"
            />
          </ListItemButton>
        </Tooltip>
      )}
    </>
  );
};

export default RegularItemRoute;
