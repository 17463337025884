import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getUserInfoById = createAsyncThunk(
  "user/get-user-info-by-id",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(URL_USER + "/user-info/" + data);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getUserInfoById };
