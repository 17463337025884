import abcJktLogo from "assests/abc-jkt-logo.png";

const PrintSessionSummaryHeader = ({ clientCode, clientName }) => {
  return (
    <div className="session-summary-header">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-[14pt] text-left font-semibold text-[#294992]">
            eRequest Client Master Log
          </h1>
          <div className="flex items-center gap-3">
            <p className="text-[11pt] font-semibold">{clientCode}</p>
            <p className="text-[11pt] font-semibold">{clientName}</p>
          </div>
        </div>
        <div>
          <img
            src={abcJktLogo}
            className="object-cover h-[1.4cm] w-[3.7cm]"
            alt="ABC Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default PrintSessionSummaryHeader;
