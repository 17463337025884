import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ROLE_CLAIMS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const getRoles = createAsyncThunk("rolesClaims/get-roles", async () => {
  try {
    const api = useAxios();
    const response = await api.get(URL_ROLE_CLAIMS);
    return response.data;
  } catch (error) {
    return error;
  }
});

export { getRoles };
