import { Modal, Space, Typography } from "antd";
import React from "react";
import { checkedData } from "utils/checkedData";

const { Paragraph, Text } = Typography;

const HealthcareProviderDetails = ({
  selectedInfoDetails,
  openInfo,
  handleOkInfo,
  handleCancelInfo,
  getClientName,
}) => {
  const modalTitle = `${selectedInfoDetails?.firstName} ${checkedData(
    selectedInfoDetails?.lastName
  )}`;
  return (
    <Modal
      title={
        <Paragraph>
          <blockquote>{modalTitle}</blockquote>
        </Paragraph>
      }
      open={openInfo}
      onOk={handleOkInfo}
      onCancel={handleCancelInfo}
      footer={null}
    >
      <Space direction="vertical">
        <Space align="center">
          Client
          <Text code>{getClientName(selectedInfoDetails?.clientId)}</Text>
        </Space>
        <Space align="center">
          First Name
          <Text code>{selectedInfoDetails?.firstName}</Text>
        </Space>
        <Space align="center">
          Last Name
          <Text code>
            {selectedInfoDetails?.lastName === null
              ? ""
              : selectedInfoDetails?.lastName}
          </Text>
        </Space>
      </Space>
    </Modal>
  );
};

export default HealthcareProviderDetails;
