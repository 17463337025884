import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getLabTestRequirements } from "store";

const useFetchSpecificLabtest = (selectedTestDataId) => {
  const [testData, setTestData] = useState([]);
  const [isTestLoading, setIsTestLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTestDataId !== null) {
      if (
        getLabTestRequirements.pending().type ===
        "labTest/get-lab-test-requirements/pending"
      ) {
        setIsTestLoading(true);
      }
      dispatch(
        getLabTestRequirements({
          id: selectedTestDataId,
        })
      ).then((res) => {
        if (res.type === "labTest/get-lab-test-requirements/fulfilled") {
          setIsTestLoading(false);
          setTestData(res.payload.data);
        }
      });
    }
  }, [selectedTestDataId, dispatch]);

  return { testData, isTestLoading };
};

export default useFetchSpecificLabtest;
