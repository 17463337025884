import { Grid, createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import * as React from "react";
import LoginPanel from "components/jkt/auth/loginPanel";
import ResetPasswordForm from "components/jkt/auth/resetPasswordForm";
const theme = createTheme();
const ResetPasswordPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <LoginPanel />
        <ResetPasswordForm />
      </Grid>
    </ThemeProvider>
  );
};
export default ResetPasswordPage;
