import React from "react";
import { Grid } from "@mui/material";
import SpecialInstructionInput from "./specialInstructionInput";
import DiagnosisInputs from "./diagnosisInputs";

const FifthSection = () => {
  return (
    <Grid rowSpacing={2} columnSpacing={4} sx={{ marginTop: 3 }} container>
      <Grid
        sx={{ display: "flex", flexDirection: "column" }}
        xs={12}
        sm={12}
        md={7}
        lg={7}
        xl={7}
        item
      >
        <DiagnosisInputs />
      </Grid>
      <Grid xs={12} sm={12} md={5} lg={5} xl={5} item>
        <SpecialInstructionInput />
      </Grid>
    </Grid>
  );
};

export default FifthSection;
