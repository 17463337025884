import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: 0,
  firstName: "",
  collectorType: "Phlebotomist",
  sampleCollectorFullName: "",
  lastName: "",
  isActive: true
};

const sampleCollectorInputSlice = createSlice({
  name: "sampleCollectorInputs",
  initialState,
  reducers: {
    handleChangeSampleCollectorFields: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { handleChangeSampleCollectorFields } =
  sampleCollectorInputSlice.actions;
export const sampleCollectorInputReducer = sampleCollectorInputSlice.reducer;
