import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_EXTERNAL_SYSTEM_CREDENTIALS } from "../../config/connString";

const authenticateExternalSystemCredential = createAsyncThunk(
  "external-system-credentials/authenticate",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_EXTERNAL_SYSTEM_CREDENTIALS, {
        systemName: data.systemName,
        userName: data.userName,
        password: data.password,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { authenticateExternalSystemCredential };
