import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_AUTH } from "config/connString";

const userConfirmEmail = createAsyncThunk(
  "auth/confirm-email",
  async (data) => {
    try {
      const response = await axios.get(URL_AUTH + "confirm-email", {
        params: {
          userId: data.id,
          token: data.token,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);

export { userConfirmEmail };
