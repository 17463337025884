import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDER_DECRYPTION } from "config/connString";

const decryptTestOrder = createAsyncThunk(
  "test-order/decrypt-test-order",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_TEST_ORDER_DECRYPTION, {
        encryptedText: data.encryptedText,
        saltId: data.saltId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { decryptTestOrder };
