import pkg from "../../package.json";

export const getLatestFeatureUpdates = () => {
  const defaultAppName = localStorage.getItem("app-name");

  if (defaultAppName && pkg.name !== defaultAppName) {
    localStorage.setItem("app-name", pkg.name);
    window.location.reload();
  } else if (!defaultAppName) {
    const saveAppNameTimeout = setTimeout(() => {
      localStorage.setItem("app-name", pkg.name);
    }, 1000);

    return () => clearTimeout(saveAppNameTimeout);
  }
};
