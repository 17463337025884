import { Box, Grid, Skeleton, Typography } from "@mui/material";
import styles from "components/jkt/testRequest/forms/fourthStep/styles.module.css";
import TestProfileSectionTwo from "./testProfileSectionTwo";
import useTranslation from "hooks/useTranslation";

const TestProfileSection = (props) => {
  const {
    testOrderDetails,
    diagnosesList,
    specimens,
    isLoading,
    specialHandlings,
    courierRemarks,
    testOrderNotes,
    preparedBy,
  } = props;
  const { getTranslation } = useTranslation("RequestPage");
  const hasPatientSpecimens = specimens?.length > 0 && specimens !== null;
  const isDiagnosisEmpty = diagnosesList?.length === 0;
  const isSpecialHandlingEmpty = specialHandlings?.length === 0;

  const displaySampleCollectorName = (sampleCollectorName, label = null) => {
    const emptySampleCollector = specimens[0]?.collector === null;
    if (emptySampleCollector) {
      return "";
    }
    return label === "sampleCollectorType"
      ? `${sampleCollectorName} -`
      : sampleCollectorName;
  };

  const sampleCollectorType = hasPatientSpecimens
    ? displaySampleCollectorName(
        specimens[0]?.collector?.collectorType,
        "sampleCollectorType"
      )
    : "";

  const sampleCollectorFirstName = hasPatientSpecimens
    ? displaySampleCollectorName(specimens[0]?.collector?.firstName)
    : "";

  const sampleCollectorLastName = hasPatientSpecimens
    ? displaySampleCollectorName(specimens[0]?.collector?.lastName)
    : "";

  return (
    <Box>
      <Grid sx={{ marginTop: "1.3rem" }} container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography
            sx={{
              color: "darkBlue.main",
              marginBottom: "0.5rem",
              fontSize: "14pt",
              fontWeight: "bold",
            }}
            variant="p"
          >
            {getTranslation("Test / profile selection")}
          </Typography>
          <p className={styles.divider} />
        </Grid>
      </Grid>
      <Grid columnSpacing={3} sx={{ marginTop: "0.4rem" }} container>
        <Grid xs={7} sm={7} md={7} lg={7} xl={7} item>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "69fr 150fr 400fr auto",
                sm: "69fr 150fr 400fr auto",
                md: "40fr 75fr 400fr auto",
                lg: "40fr 75fr 400fr auto",
                xl: "40fr 75fr 400fr auto",
              },
            }}
          >
            <Typography
              variant="p"
              sx={{
                color: isLoading ? "" : "darkBlue.main",
                fontSize: "11pt",
              }}
            >
              No.
            </Typography>
            <Typography
              variant="p"
              sx={{
                color: isLoading ? "" : "darkBlue.main",
                fontSize: "11pt",
              }}
            >
              {getTranslation("Code")}
            </Typography>
            <Typography
              variant="p"
              sx={{
                color: isLoading ? "" : "darkBlue.main",
                fontSize: "11pt",
              }}
            >
              {getTranslation("Test name")}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: isLoading ? "" : "#EBEFF8",
              paddingBottom: "1rem",
              position: "relative",
              marginTop: "0.5rem",
              display: testOrderDetails?.length === 0 ? "none" : "block",
            }}
          >
            {isLoading ? (
              <Skeleton />
            ) : (
              testOrderDetails &&
              testOrderDetails.map((data, index) => (
                <Box
                  key={data.id}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      xs: "45fr 120fr 400fr auto",
                      sm: "45fr 120fr 400fr auto",
                      md: "35fr 75fr 400fr auto",
                      lg: "35fr 75fr 400fr auto",
                      xl: "35fr 75fr 400fr auto",
                    },
                    padding: "0.2rem 0.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "11pt",
                    }}
                    variant="p"
                  >
                    {index + 1}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "11pt",
                    }}
                    variant="p"
                  >
                    {data?.labTest?.code}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "11pt",
                    }}
                    variant="p"
                  >
                    {data?.labTest?.name}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
        </Grid>
        <Grid xs={5} sm={5} md={5} lg={5} xl={5} item>
          <Box>
            <Typography
              variant="p"
              sx={{
                color: isLoading ? "" : "darkBlue.main",
                fontSize: "11pt",
              }}
            >
              {getTranslation("Clinical diagnosis")}:
            </Typography>
            {!isDiagnosisEmpty && (
              <ul className="pl-5 list-disc">
                {diagnosesList?.map((list) => (
                  <li className="text-[11pt]" key={list.id}>
                    {list.displayName}
                  </li>
                ))}
              </ul>
            )}
          </Box>
          <Box>
            <Typography
              variant="p"
              sx={{
                color: isLoading ? "" : "darkBlue.main",
                fontSize: "11pt",
              }}
            >
              {getTranslation("Special instructions")}:
            </Typography>
            {!isSpecialHandlingEmpty && (
              <ul className="pl-5 list-disc">
                {specialHandlings &&
                  specialHandlings?.map((data) => {
                    let specialInstruction = "";
                    const statText = (
                      <li className="text-[11pt]">
                        STAT - [
                        <span className="font-bold text-sm px-1">
                          {testOrderDetails
                            ?.filter((test) => test.isStat === true)
                            ?.map((data) => data.labTest.name)
                            .join(", ")}
                        </span>
                        ]
                      </li>
                    );
                    if (data.instructions.toLowerCase() === "stat") {
                      specialInstruction = statText;
                    } else {
                      specialInstruction = `${data.instructions}`;
                    }
                    return <li key={data.id}>{specialInstruction}</li>;
                  })}
              </ul>
            )}
            {isSpecialHandlingEmpty && <p>-</p>}
          </Box>
        </Grid>
      </Grid>
      <TestProfileSectionTwo
        diagnosesList={diagnosesList}
        sampleCollectorName={`${sampleCollectorType} ${sampleCollectorFirstName} ${
          sampleCollectorLastName === null ? "" : sampleCollectorLastName
        }`}
        preparedBy={preparedBy}
        specimens={specimens}
        isLoading={isLoading}
        testOrderDetails={testOrderDetails}
        specialHandlings={specialHandlings}
        courierRemarks={courierRemarks}
        testOrderNotes={testOrderNotes}
      />
    </Box>
  );
};

export default TestProfileSection;
