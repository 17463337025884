import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getAllClientType = createAsyncThunk(
  "clientTypes/get-all-client-types",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
        },
      };
      const response = await api.get(URL_CLIENT, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getAllClientType };
