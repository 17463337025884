import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ROLE_CLAIMS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const createClaims = createAsyncThunk(
  "rolesClaims/create-claims",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.post(
        URL_ROLE_CLAIMS + "/" + data.roleId + "/claims",
        {
          newClaims: data.claims,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createClaims };
