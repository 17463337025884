import * as React from "react";
import { Grid, Button } from "@mui/material";
import { getAllBarangay, getAllCityMunicipalities } from "store/index";
import { useDispatch } from "react-redux";
import { Table, Input, ConfigProvider, Select, Button as BtnAntD } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CreateUpdateBarangay from "./createUpdateBarangay";
import useDebounce from "hooks/useDebounce";
import { EmptyData } from "components/jkt/global/emptyData";
import { useCurrentHeight } from "utils/getScreenSize";
import useCheckPermission from "hooks/useCheckPermission";

const GetAllBarangay = () => {
  const dispatch = useDispatch();
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToModify = handleVerifyPermission(
    "Administration:Barangay:Modify"
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = React.useState("name");
  const [sortDirection, setSortDirection] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [barangayData, setBarangayData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [current, setCurrent] = React.useState(1);

  const [isForUpdate, setIsForUpdate] = React.useState(false);
  const [idForUpdate, setIdForUpdate] = React.useState(0);

  const [cityMunicipalityData, setCityMunicipalityData] = React.useState([]);

  const LoadData = React.useCallback(() => {
    //HANDLE LOADING
    if (getAllBarangay.pending().type === "barangay/get-all-barangay/pending") {
      setIsLoading(true);
    }

    dispatch(
      getAllBarangay({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      })
    ).then((element) => {
      if (element.type === "barangay/get-all-barangay/fulfilled") {
        const barangayResponse = element.payload.data?.items;
        setIsLoading(false);
        setTotal(element.payload.data.totalPages * pageSize);

        dispatch(
          getAllCityMunicipalities({
            search: "",
            pageSize: 0,
            pageNumber: 1,
            sortField: "Id",
            sortDirection: 0,
          })
        ).then((dataEl) => {
          if (
            dataEl.type ===
            "citymunicipalities/get-all-citymunicipalities/fulfilled"
          ) {
            const cityMunicipalitiesResponse = dataEl.payload.data?.items;
            setCityMunicipalityData(cityMunicipalitiesResponse);
            const newBarangayData = barangayResponse.map((newData) => {
              return {
                ...newData,
                cityMunicipalityName: cityMunicipalitiesResponse.filter(
                  (cityMun) => cityMun.id === newData.cityMunicipalityId
                )[0]?.name,
              };
            });
            setBarangayData(newBarangayData);
          }
        });
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
  ]);

  React.useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  const onChange = (page) => {
    setCurrent(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No Barangay Detected"
      action={showCreateUpdate}
      btnTitle="Add New Barangay"
      renderButton={accessRightsToModify}
    />
  );

  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = React.useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(0);
  };
  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };
  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
  };

  const handleUpdate = (id) => {
    setIsForUpdate(true);
    setIdForUpdate(id);
  };

  const handleChangeSortField = (value) => {
    setSortStr(value);
  };

  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 480,
    },
    {
      title: "City Municipality",
      dataIndex: "cityMunicipalityName",
      key: "cityMunicipalityName",
      width: 100,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 150,
      render: (rec, row) => (
        <>
          {accessRightsToModify ? (
            <BtnAntD
              size="small"
              icon={<EditIcon fontSize="inherit" />}
              className="hover:bg-orange-500 bg-orange-600"
              style={{
                border: "1px solid #ed6c02",
                color: "white",
              }}
              onClick={() => {
                handleUpdate(row);
                showCreateUpdate();
              }}
            >
              Update
            </BtnAntD>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            size=""
            placeholder="Search Barangay"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortStr}
            onChange={handleChangeSortField}
            style={{
              width: 120,
            }}
            options={[
              {
                value: "name",
                label: "Name",
              },
              {
                value: "cityMunicipalityId",
                label: "Barangay",
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          />
        </Grid>

        {barangayData.length !== 0 && accessRightsToModify ? (
          <Grid item xs={12} md={4} lg={4}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              New Barangay
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={
            accessRightsToModify
              ? columns
              : columns.filter((col) => col.dataIndex !== "")
          }
          dataSource={barangayData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
          rowKey={(record) => record.id}
        />
      </ConfigProvider>
      <CreateUpdateBarangay
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
        cityMunicipalityData={cityMunicipalityData}
      />
    </>
  );
};
export default GetAllBarangay;
