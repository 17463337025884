import { createTheme, ThemeProvider, CssBaseline, Grid } from "@mui/material";
import LoginForm from "components/mnl/auth/loginForm";
import LoginPanel from "components/mnl/auth/loginPanel";
import { useEffect } from "react";

const theme = createTheme();
const LoginPage = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <LoginPanel />
        <LoginForm />
      </Grid>
    </ThemeProvider>
  );
};

export default LoginPage;
