import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const findDuplicateTestOrder = createAsyncThunk(
  "test-orders/find-duplicate-test-order",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.post(
        `${URL_TEST_ORDERS}/find-duplicate-test-order`,
        {
          patientId: data.patientId,
          testIds: data.testIds,
          testOrderId: data.testOrderId,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { findDuplicateTestOrder };
