import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT_INFO } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const createClient = createAsyncThunk("clients/create-client", async (data) => {
  try {
    const clientId = decodedUserDetails()?.clientId;
    const api = useAxios();

    const response = await api.post(URL_CLIENT_INFO, {
      clientCode: data[0].code,
      clientName: data[0].name,
      clientType_ID: data[0].type,
      contactName: data[0].contactName,
      contactNumber: data[0].contactNumber,
      clientStartDate: data[1].clientStartDate,
      clientEndDate: data[1].clientEndDate,
      isActive: true,
      clientConfig: {
        clientId: clientId,
        registeredUserCap: data[0].registeredUserCap,
        allowEmailSendingOfRequest:
          data[0].allowEmailSendingOfRequest === undefined
            ? false
            : data[0]?.allowEmailSendingOfRequest,
        enableCollectionSite:
          data[0].enableCollectionSite === undefined
            ? false
            : data[0]?.enableCollectionSite,
        enableVolumeCheck:
          data[0].enableVolumeCheck === undefined
            ? false
            : data[0]?.enableVolumeCheck,
        enableProcessingWindowTime:
          data[0].enableProcessingWindowTime === undefined
            ? false
            : data[0]?.enableProcessingWindowTime,
        inClientProcessingWindowTime: data[0].enableProcessingWindowTime
          ? data[0].inClientProcessingWindowTime
          : 0,
      },
      phAddresses: data[1].phAddresses,
    });
    return response.data;
  } catch (error) {
    return error;
  }
});

export { createClient };
