import { Form, InputNumber, Switch } from "antd";
import React from "react";

const ClientConfigurationsInput = ({ isProcessingWindowTimeEnabled }) => {
  return (
    <div className="mt-16">
      {/* Registered User Cap */}
      <Form.Item
        label="Registered User Cap"
        name="registeredUserCap"
        rules={[
          {
            required: true,
            message: <i>{"Please specify Registered User Cap."}</i>,
          },
        ]}
      >
        <InputNumber className="w-40" placeholder="Registered User Cap" />
      </Form.Item>

      {/* Allow Email Sending Request */}
      <Form.Item
        label="Allow Email Sending Request"
        name="allowEmailSendingOfRequest"
        valuePropName="checked"
      >
        <Switch checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Item>

      {/* Enable Collection Site */}
      <Form.Item
        label="Enable Collection Site"
        name="enableCollectionSite"
        valuePropName="checked"
      >
        <Switch checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Item>
      {/* Enable Volume Check */}
      <Form.Item
        label="Enable Volume Check"
        name="enableVolumeCheck"
        valuePropName="checked"
      >
        <Switch checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Item>
      {/* Enable Processing Window Time */}
      <Form.Item
        label="Enable Processing Window Time"
        name="enableProcessingWindowTime"
        valuePropName="checked"
      >
        <Switch checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Item>

      {/* Client Processing Window Time */}
      {isProcessingWindowTimeEnabled && (
        <Form.Item
          label="Client Processing Window Time"
          name="inClientProcessingWindowTime"
          rules={[
            {
              required: true,
              message: <i>{"Please input Client Processing Window Time."}</i>,
            },
          ]}
        >
          <InputNumber
            className="w-60"
            placeholder="Client Processing Window Time"
          />
        </Form.Item>
      )}
    </div>
  );
};

export default ClientConfigurationsInput;
