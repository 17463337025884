import React from "react";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "antd";
import { Box, Divider, Typography } from "@mui/material";
import moment from "moment";
import { handleChangeLabtestData } from "store/slice/savedLabtestSlice";
import useConvertHours from "hooks/useConvertHours";

const VolumeCheckerModal = ({ setCurrentStepIndex }) => {
  const { convertHoursToDay, convertProvidedCollectionDateTime } =
    useConvertHours();
  const dispatch = useDispatch();
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedInvalidSpecimenNames = savedLabtests.savedInvalidSpecimens.map(
    (data) => data.specimen
  );
  const hasInvalidCollectionDateTime =
    savedLabtests.savedInvalidCollectionDateTime.length > 0;
  const showVolMeasurementType = savedLabtests.savedInvalidSpecimens.some(
    (specimen) => specimen?.unitOfMeasure.toLowerCase().includes("ml")
  );
  const showQtyMeasurementType = savedLabtests.savedInvalidSpecimens.some(
    (specimen) =>
      specimen?.unitOfMeasure.includes("tube") ||
      specimen?.unitOfMeasure.includes("pc")
  );

  const hasInvalidSpecimens = savedLabtests.savedInvalidSpecimens.length > 0;
  const insufficientVolQtyText = `${showVolMeasurementType ? "volume" : ""} ${
    showVolMeasurementType && showQtyMeasurementType ? "/" : ""
  } ${showQtyMeasurementType ? "quantity" : ""}`;
  const showCollectionDateTimeWarningText = hasInvalidCollectionDateTime
    ? "collection date and time"
    : "";
  const showComma =
    (showVolMeasurementType || showQtyMeasurementType) &&
    hasInvalidCollectionDateTime
      ? ","
      : "";
  const addScrollProperty =
    savedLabtests.savedInvalidCollectionDateTime.length > 2 ||
    savedLabtests.savedInvalidSpecimens.length > 2;

  const modalTitle =
    hasInvalidSpecimens && hasInvalidCollectionDateTime
      ? "The specimen " +
        insufficientVolQtyText +
        " and collection date time provided did not meet the sample requirement"
      : hasInvalidSpecimens
      ? "The specimen " +
        insufficientVolQtyText +
        " provided did not meet the sample requirement"
      : "There is a problem with the collection date time you provided";

  const getMeasurementType = (unitOfMeasure) => {
    const unitOfMeaureValueMap = {
      ml: {
        measurementType: "volume",
        abbreviation: "Vol",
      },
      tube: {
        measurementType: "quantity",
        abbreviation: "Qty",
      },
      pc: {
        measurementType: "quantity",
        abbreviation: "Qty",
      },
      cm: {
        measurementType: "Size",
        abbreviation: "size",
      },
    };

    const selectedUnitOfMeasure =
      unitOfMeaureValueMap[unitOfMeasure.toLowerCase()];

    return selectedUnitOfMeasure;
  };

  return (
    <Modal
      width={770}
      title={
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <WarningRoundedIcon sx={{ fontSize: "3rem", color: "#FAAD14" }} />{" "}
          {modalTitle}
        </Typography>
      }
      open={
        savedLabtests.showInvalidSpecimensModal &&
        (savedLabtests.savedInvalidSpecimens.length > 0 ||
          savedLabtests.savedInvalidCollectionDateTime.length > 0)
      }
      centered
      footer={[
        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
          <Button
            className="submitBtn"
            onClick={() => {
              dispatch(
                handleChangeLabtestData({
                  showInvalidSpecimensModal: false,
                })
              );
              setCurrentStepIndex(1);
            }}
            type="primary"
          >
            I will update
          </Button>
        </Box>,
      ]}
      closable={false}
    >
      <Box
        sx={{
          height: addScrollProperty ? "30rem" : "25rem",
          overflowY: addScrollProperty ? "scroll" : "auto",
        }}
      >
        <Box>
          {savedLabtests?.savedInvalidSpecimens?.map((specimen) => {
            const savedCollectionDateTimeSpecimens =
              savedLabtests.savedInvalidCollectionDateTime.map(
                (data) => data.specimen
              );
            const isSpecimenPresent = savedCollectionDateTimeSpecimens.includes(
              specimen.specimen
            );
            const providedDateTime = moment(
              multiStepFormInputs.patientSpecimens.find(
                (data) => data.specimen === specimen.specimen
              )?.collectionDateTime
            );

            const currentDate = moment();
            const diffInHours = currentDate.diff(providedDateTime, "hours");
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    margin: "0.7rem 0",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "green",
                      marginTop: "0.5rem",
                      fontSize: "1rem",
                    }}
                    variant="p"
                  >
                    {specimen.specimen}
                  </Typography>
                  <Typography sx={{ marginTop: "0.5rem" }} variant="p">
                    No of Test/s that will use this specimen:{" "}
                    <span className="font-bold bg-[#E7EBF4] p-[0.35rem] mx-1 text-red-600">
                      {specimen.specimenTotal}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <Typography variant="p">
                    Provided{" "}
                    {getMeasurementType(specimen?.unitOfMeasure)?.abbreviation}:{" "}
                    <span className="font-bold bg-[#E7EBF4] p-[0.35rem] mx-1 text-red-600">
                      {specimen?.specimenQuantity}
                    </span>
                    <span className="font-bold pl-1">
                      {specimen?.unitOfMeasure}
                    </span>
                  </Typography>
                  <Typography variant="p">
                    The minimum{" "}
                    {
                      getMeasurementType(specimen?.unitOfMeasure)
                        ?.measurementType
                    }{" "}
                    required for the test/s requested is:{" "}
                    <span className="font-bold bg-[#E7EBF4] p-[0.35rem] mx-1 text-green-900">
                      {
                        specimen.requiredVolume.find((vol) =>
                          specimen.specimenVolReqData.includes(vol)
                        )?.minVolume
                      }
                    </span>
                    <span className="font-bold pl-1">
                      {specimen?.unitOfMeasure}
                    </span>
                  </Typography>
                  {isSpecimenPresent && (
                    <Typography sx={{ marginTop: "0.3rem" }} variant="p">
                      Provided Collection Date Time:{" "}
                      <span className="font-bold bg-[#E7EBF4] p-[0.35rem] mx-1 text-red-600">
                        {convertProvidedCollectionDateTime(diffInHours)} ago
                      </span>
                    </Typography>
                  )}
                  {specimen.specimenExpirationInHours !== null &&
                    isSpecimenPresent && (
                      <Typography
                        sx={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        variant="p"
                      >
                        The provided collection date and time are outside the
                        valid range. Please ensure that the specimen was
                        collected within the past{" "}
                        <span className="font-bold bg-[#E7EBF4] p-[0.35rem] mx-1 text-green-600">
                          {convertHoursToDay(
                            savedLabtests.savedInvalidCollectionDateTime.find(
                              (data) => data.specimen === specimen.specimen
                            )?.specimenExpirationInHours
                          )}
                        </span>{" "}
                        to guarantee test accuracy.
                      </Typography>
                    )}
                  {specimen.specimenExpirationInHours === null && (
                    <Typography
                      sx={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                      variant="p"
                    >
                      The specimen validity of this labtest has not been set,
                      but please provide a future date
                    </Typography>
                  )}
                </Box>
                <Divider sx={{ margin: "0.5rem 0" }} />
              </Box>
            );
          })}
          {savedLabtests?.savedInvalidCollectionDateTime
            ?.filter(
              (specimen) =>
                !savedInvalidSpecimenNames.includes(specimen.specimen)
            )
            ?.map((data) => {
              const providedDateTime = moment(
                multiStepFormInputs.patientSpecimens.find(
                  (specimen) => specimen.specimen === data.specimen
                )?.collectionDateTime
              );

              const currentDate = moment();
              const diffInHours = currentDate.diff(providedDateTime, "hours");

              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "green",
                      marginRight: "0.5rem",
                      fontSize: "1rem",
                    }}
                    variant="p"
                  >
                    {data.specimen}
                  </Typography>
                  <Typography sx={{ marginTop: "0.8rem" }} variant="p">
                    Provided Collection Date and Time:{" "}
                    <span className="font-bold bg-[#E7EBF4] p-[0.35rem] mx-1 text-red-600">
                      {convertProvidedCollectionDateTime(diffInHours)} ago
                    </span>
                  </Typography>
                  {data.specimenExpirationInHours !== null && (
                    <Typography
                      sx={{ marginTop: "1rem", marginBottom: "0.3rem" }}
                      variant="p"
                    >
                      The provided collection date and time are outside the
                      valid range. Please ensure that the specimen was collected
                      within the past{" "}
                      <span className="font-bold bg-[#E7EBF4] p-[0.35rem] mx-1 text-green-600">
                        {convertHoursToDay(data.specimenExpirationInHours)}
                      </span>{" "}
                      to guarantee test accuracy.
                    </Typography>
                  )}
                  {data.specimenExpirationInHours === null && (
                    <Typography
                      sx={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                      variant="p"
                    >
                      The specimen validity of this labtest has not been set,
                      but please provide a future date
                    </Typography>
                  )}
                  <Divider sx={{ margin: "0.5rem 0" }} />
                </Box>
              );
            })}
        </Box>{" "}
        <Box sx={{ padding: "0 2rem", marginTop: "3rem" }}>
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontStyle: "italic",
              textAlign: "center",
            }}
            variant="p"
          >
            Please update the sample {insufficientVolQtyText}
            {showComma} {showCollectionDateTimeWarningText} and ensure that the
            actual specimen submitted and {insufficientVolQtyText}
            {showComma} {showCollectionDateTimeWarningText} entered match.
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default VolumeCheckerModal;
