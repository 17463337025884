import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_HEALTHCARE_PROVIDER } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

// This api call is being used in request page to update physician/healthcare provider

const updateHealthcareProvider = createAsyncThunk(
  "healthcareProviders/update-healthcare-provider",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const response = await api.put(URL_HEALTHCARE_PROVIDER + "/" + data.id, {
        id: data.id,
        licenseNumber: null,
        licenseExpirationDate: null,
        firstName: data.firstName,
        lastName: data.lastName,
        title: null,
        specialty: null,
        phoneNumber: null,
        email: null,
        clientId: clientId,
        isActive: data.isActive,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateHealthcareProvider };
