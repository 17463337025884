import * as React from "react";
import { Grid, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  Space,
  Table,
  Input,
  ConfigProvider,
  Tag,
  Select,
  Button as BtnAntD,
  Badge,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CreateUpdateLabTest from "./createUpdateLabTest";
import "./getAllLabTest.css";
import LabtestDetails from "./labtestDetails";
import useCheckPermission from "hooks/useCheckPermission";
import { getAllLabTest } from "store";
import useDebounce from "hooks/useDebounce";
import { EmptyData } from "components/jkt/global/emptyData";
import { getLabTestRequirements } from "store";
import LoadingSpinner from "components/jkt/global/loadingSpinner";
import { getStringWeekName } from "./data";
import { nanoid } from "@reduxjs/toolkit";
import { useCurrentHeight } from "utils/getScreenSize";
import useTranslation from "hooks/useTranslation";

const GetAllLabTest = () => {
  const dispatch = useDispatch();
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToModify = handleVerifyPermission(
    "Laboratory:LabTest:Modify"
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [isLabtestDetailsLoading, setIsLabtestDetailsLoading] =
    React.useState(false);
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = React.useState("name");
  const [sortDirection, setSortDirection] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [labTestData, setLabTestData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [current, setCurrent] = React.useState(1);
  const [isForUpdate, setIsForUpdate] = React.useState(false);
  const [idForUpdate, setIdForUpdate] = React.useState(null);
  const { getTranslation } = useTranslation("LaboratoryTestDataTable");

  const LoadData = React.useCallback(() => {
    if (getAllLabTest.pending().type === "labtests/get-all-labtests/pending") {
      setIsLoading(true); //To check if the get request is pending
    }

    dispatch(
      getAllLabTest({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      }) //Fetching of all the labtest from api
    ).then((element) => {
      if (element?.payload?.success) {
        setIsLoading(false);
        setLabTestData(element.payload.data.items);
        setTotal(element.payload.data.totalPages * pageSize);
      } // Getting the response and calculates the total page that will be displayed in the table
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
  ]);

  React.useEffect(() => {
    LoadData(); //Invoking of get labtest request
  }, [dispatch, LoadData]);

  //MODAL FOR INFO USER
  const [openInfo, setOpenInfo] = React.useState(false);
  const [selectedInfoDetails, setSelectedInfoDetails] = React.useState(null);

  const showModalInfo = () => {
    setOpenInfo(true);
  };

  const handleOkInfo = () => {
    setOpenInfo(false);
  };

  const handleCancelInfo = () => {
    setOpenInfo(false);
    setSelectedInfoDetails(null);
  };

  const onChange = (page) => {
    //To get the current page of the labtest
    setCurrent(page);
    setIsLoading(true);
  };

  const onShowSizeChange = (current, pageSize) => {
    //To get the page size of the labtest
    setPageSize(pageSize);
  };

  const handleChangeSearch = (val) => {
    //This is where the filtering of search in table happens
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No Laboratory Test Detected"
      action={showCreateUpdate}
      btnTitle="Add New Laboratory Test"
      renderButton={accessRightsToModify}
    />
  );

  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = React.useState(false);

  const handleCancel = () => {
    //Closing of create and update labtest modal
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(null);
  };
  const showCreateUpdate = () => {
    //Showing of create and update labtest modal
    setOpenCreateUpdate(true);
  };
  const refetchLabtestData = () => {
    //Refetching of labtest data if it is changed
    LoadData();
    setOpenCreateUpdate(false);
    setIdForUpdate(null);
    setIsForUpdate(false);
  };

  const handleUpdate = (id) => {
    //Getting the id of labtest that will be updated
    setIsForUpdate(true);
    setIdForUpdate(id);
  };

  const handleChangeSortField = (value) => {
    //For sort field filtering in labtest table
    setSortStr(value);
  };

  const handleChangeSortDirection = (value) => {
    //For sort direction filtering in labtest if it will be ascending or descending
    setSortDirection(value);
  };

  const handleFetchLabtestDetails = (selectedId) => {
    if (
      getLabTestRequirements.pending().type ===
      "labTest/get-lab-test-requirements/pending"
    ) {
      setIsLabtestDetailsLoading(true);
    }
    dispatch(getLabTestRequirements({ id: selectedId })).then((item) => {
      if (item.type === "labTest/get-lab-test-requirements/fulfilled") {
        setSelectedInfoDetails(item.payload.data);
        showModalInfo();
        setIsLabtestDetailsLoading(false);
      }
    });
  };

  const columns = [
    //Labtest table column
    {
      title: getTranslation("Code"),
      dataIndex: "code",
      key: "code",
      width: 90,
    },
    {
      title: getTranslation("Name"),
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: getTranslation("Type"),
      dataIndex: "type",
      key: "type",
      width: 60,
    },
    {
      title: getTranslation("Orderable"),
      dataIndex: "orderable",
      key: "orderable",
      width: 50,
      render: (rec, row) => {
        const isLabtestOrderable = row.orderable;
        return (
          <Tag color={isLabtestOrderable ? "green" : "red"}>
            {isLabtestOrderable ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      title: getTranslation("Running Schedule"),
      dataIndex: "schedules",
      key: "schedules",
      width: 215,
      render: (rec, row) =>
        row.type === "Test"
          ? rec?.length > 0
            ? [0, 1, 2, 3, 4, 5, 6].map((item) => {
                const isScheduleActive =
                  rec.filter((i) => i.day === item).length > 0;
                return (
                  <Tag color={isScheduleActive ? "green" : "gray"}>
                    {getStringWeekName(item).str}
                  </Tag>
                );
              })
            : "No schedule assigned."
          : "",
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 140,

      render: (rec, row) => (
        <Space direction="horizontal" size={2}>
          {rec.type === "Test" ? (
            <BtnAntD
              size="small"
              type="primary"
              icon={<InfoIcon fontSize="inherit" />}
              onClick={() => {
                handleFetchLabtestDetails(row.id);
              }}
              className="submitBtn"
            >
              {getTranslation("Details")}
            </BtnAntD>
          ) : null}
          {accessRightsToModify ? (
            <BtnAntD
              size="small"
              icon={<EditIcon fontSize="inherit" />}
              className="hover:bg-orange-500 bg-orange-600"
              style={{
                border: "1px solid #ed6c02",
                color: "white",
              }}
              onClick={() => {
                showCreateUpdate();
                handleUpdate(row.id);
              }}
            >
              {getTranslation("Update")}
            </BtnAntD>
          ) : null}
        </Space>
      ),
    },
  ];

  const renderLabTestChildren = (value) => {
    return value.map((dataVal) => {
      return {
        id: dataVal.id,
        code: dataVal.code,
        name: dataVal.name,
        orderable: dataVal.orderable,
        type: dataVal.type,
        children:
          dataVal?.child.length === 0
            ? null
            : renderLabTestChildren(dataVal.child),
        key: `${nanoid()}/${dataVal.id}`,
        testDetails: dataVal?.testDetails,
        schedules:
          dataVal.type === "Test" ? dataVal.testDetails?.schedules : "",
      };
    });
  };

  const rowsVal = labTestData.map((val) => {
    return {
      id: val.id,
      code: val.code,
      name: val.name,
      orderable: val.orderable,
      type: val.type,
      children:
        val?.child.length === 0 ? null : renderLabTestChildren(val.child),
      key: `${nanoid()}/${val.id}`,
      testDetails: val?.testDetails,
      schedules: val.type === "Test" ? val.testDetails?.schedules : "",
    };
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            size=""
            placeholder={getTranslation("Search Test")}
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortStr}
            onChange={handleChangeSortField}
            options={[
              {
                value: "code",
                label: getTranslation("Code"),
              },
              {
                value: "name",
                label: getTranslation("Name"),
              },
              {
                value: "type",
                label: getTranslation("Type"),
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: getTranslation("Ascending"),
              },
              {
                value: 1,
                label: getTranslation("Descending"),
              },
            ]}
          />
        </Grid>
        {rowsVal.length !== 0 && accessRightsToModify ? (
          <Grid item xs={12} md={2} lg={2}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              {getTranslation("New Labtest")}
            </Button>
          </Grid>
        ) : null}
        <Grid item xs={12} md={4} lg={4}>
          <Space style={{ paddingTop: "0.5rem" }}>
            <Badge
              style={{ marginRight: "0.3rem" }}
              status="success"
              text={getTranslation("Scheduled to run")}
            />
            <Badge
              status="default"
              text={getTranslation("Not scheduled to run")}
            />
          </Space>
        </Grid>
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={columns}
          dataSource={rowsVal}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <LabtestDetails
        getStringWeekName={getStringWeekName}
        handleCancelInfo={handleCancelInfo}
        handleOkInfo={handleOkInfo}
        openInfo={openInfo}
        selectedInfoDetails={selectedInfoDetails}
      />
      <CreateUpdateLabTest
        setIdForUpdate={setIdForUpdate}
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        refetchLabtestData={refetchLabtestData}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
        setSearchText={setSearchText}
      />
      <LoadingSpinner open={isLabtestDetailsLoading} />
    </>
  );
};
export default GetAllLabTest;
