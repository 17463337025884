import { createSlice } from "@reduxjs/toolkit";
import { getPatientInfo } from "api/patient/getPatientInfo";
import { getPatientLookup } from "api/patient/getPatientLookup";

const searchPatient = createSlice({
  name: "searchPatient",
  initialState: {
    data: [],
    isLoading: false,
    successMessage: [],
    error: null,
    errorMessage: [],
  },
  extraReducers(builder) {
    builder.addCase(getPatientLookup.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
      state.data = [];
    });
    builder.addCase(getPatientLookup.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoading = false;
        state.error = false;
        state.successMessage = state.successMessage ? state.successMessage : [];
        state.data = action.payload.data;
        state.errorMessage = state.errorMessage ? state.errorMessage : [];
      } else {
        state.isLoading = false;
        state.error = true;
        state.errorMessage = action.payload.response.errorMessages;
        state.successMessage = [];
        state.data = [];
      }
    });
    builder.addCase(getPatientLookup.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = ["Fetch error!"];
      state.successMessage = [];
      state.data = [];
    });

    //GET PATIENT INFO
    builder.addCase(getPatientInfo.pending, (state) => {
      state.data = [];
      state.isLoading = true;
      state.error = false;
      state.errorMessage = [];
      state.successMessage = [];
    });
    builder.addCase(getPatientInfo.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.data = action.payload.data;
        state.isLoading = false;
        state.error = false;
        state.errorMessage = state.errorMessage ? state.errorMessage : [];
        state.successMessage = state.successMessage ? state.successMessage : [];
      } else {
        state.data = [];
        state.isLoading = false;
        state.error = true;
        state.errorMessage = action.payload.response.errorMessages;
        state.successMessage = [];
      }
    });
    builder.addCase(getPatientInfo.rejected, (state) => {
      state.data = [];
      state.isLoading = false;
      state.error = true;
      state.errorMessage = ["Fetch error!"];
      state.successMessage = [];
    });
  },
});

export const searchPatientReducer = searchPatient.reducer;
