import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const getAllTestOrderTransportStatus = createAsyncThunk(
  "test-orders/get-all-test-order-transport-status",
  async (data) => {
    try {
      const api = useAxios();

      const config = {
        params: {
          clientId: data.clientId,
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
          orderTransportStatus: data.orderTransportStatus,
        },
      };
      const response = await api.get(
        URL_TEST_ORDERS + "/" + data.clientId + "/transport-status",
        config
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getAllTestOrderTransportStatus };
