import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_COUNTRIES } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const createCountry = createAsyncThunk(
  "countries/create-countries",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.post(URL_COUNTRIES, {
        name: data.name,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createCountry };
