import { Grid, createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import * as React from "react";
import ForgotPasswordForm from "components/mnl/auth/forgotPasswordForm";
import LoginPanel from "components/mnl/auth/loginPanel";
const theme = createTheme();
const ForgotPasswordPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <LoginPanel />
        <ForgotPasswordForm />
      </Grid>
    </ThemeProvider>
  );
};
export default ForgotPasswordPage;
