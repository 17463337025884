import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const emailPatientTestOrder = createAsyncThunk(
  "test-orders/email-patient-test-order",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.post(
        URL_TEST_ORDERS + "/email-patient-test-order",
        {
          patientId: data.patientId,
          filePath: data.filePath,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { emailPatientTestOrder };
