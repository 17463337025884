import { Box } from "@mui/material";
import React from "react";

const SidePanelWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: "darkBlue.secondary",
        height: {
          xs: "13rem",
          sm: "13rem",
          md: "13rem",
          lg: "13rem",
          xl: 1,
        },
        width: {
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: "19%",
        },
        right: { xl: 0, lg: 0 },
        top: { xl: 0, lg: 0 },
        position: {
          xs: "static",
          sm: "static",
          md: "static",
          lg: "static",
          xl: "fixed",
        },
        zIndex: { xs: "20", sm: "20", md: "20", lg: "4", xl: "4" },
      }}
    >
      <Box
        sx={{
          padding: {
            xs: "1rem 2rem",
            sm: "1rem 2rem",
            md: "2rem 2rem",
            lg: "5rem 1.9rem",
            xl: "5rem 1.9rem",
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SidePanelWrapper;
