import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSpecificSampleCollector } from "store";
import moment from "moment";
import { handleChangeSampleCollectorFields } from "store/slice/sampleCollectorInputSlice";

const useFetchSpecificSampleCollector = (selectedSampleCollectorId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedSampleCollectorId !== null) {
      dispatch(
        getSpecificSampleCollector({
          id: selectedSampleCollectorId,
        })
      ).then((res) => {
        if (res.payload.success) {
          const sampleCollectorData = res?.payload?.data;
          dispatch(
            handleChangeSampleCollectorFields({
              id: sampleCollectorData?.id,
              licenseNumber: sampleCollectorData?.licenseNumber,
              licenseExpirationDate: sampleCollectorData?.licenseExpirationDate
                ? moment(sampleCollectorData?.licenseExpirationDate)
                : "",
              firstName: sampleCollectorData?.firstName,
              lastName: sampleCollectorData?.lastName,
              collectorType: sampleCollectorData?.collectorType,
              clientId: +sampleCollectorData?.clientId,
              employeeId: sampleCollectorData?.employeeId,
              phoneNumber: sampleCollectorData?.phoneNumber,
              email: sampleCollectorData?.email,
              employmentStartDate: moment(
                sampleCollectorData?.employmentStartDate
              ),
              employmentEndDate: moment(sampleCollectorData?.employmentEndDate),
              isActive: sampleCollectorData?.isActive,
            })
          );
        }
      });
    }
  }, [selectedSampleCollectorId, dispatch]);
};

export default useFetchSpecificSampleCollector;
