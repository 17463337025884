import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import pageNotFound from "assests/error.svg";

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center flex-col min-h-screen fixed w-full bg-[#F4F7FF] md:gap-2">
      <img className="w-80 h-80" src={pageNotFound} alt="Page Not Found" />
      <h1 className="text-2xl md:text-6xl text-[#113569]">Page not found</h1>
      <p className="text-md md:text-xl">
        Sorry, we couldn't find the page you're looking for.
      </p>
      <Button
        startIcon={<ArrowBackIcon />}
        variant="contained"
        onClick={() => navigate(-1)}
        color="primary"
        sx={{ mt: 2 }}
      >
        Return
      </Button>
    </div>
  );
}
