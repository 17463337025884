import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  savedLabtestsData: [],
  savedLabtestsIdAndSpecimen: [],
  savedInvalidSpecimens: [],
  savedInvalidCollectionDateTime: [],
  showInvalidSpecimensModal: false,
};

const savedLabtestSlice = createSlice({
  name: "savedLabtests",
  initialState,
  reducers: {
    handleChangeLabtestData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { handleChangeLabtestData } = savedLabtestSlice.actions;
export const savedLabtestReducer = savedLabtestSlice.reducer;
