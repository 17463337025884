import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import { parsedClientDetails } from "utils/parsedClientDetails";

const AuthorizedRoute = ({ permission, children }) => {
  const { claims } = useSelector((state) => state.auth);
  const location = useLocation();
  const { isSuperAdmin, hasAdminRole } = parsedClientDetails();
  const isAdmin = isSuperAdmin || hasAdminRole;
  const isUserAllowed = claims?.find(
    (claim) => claim?.permissionKey === permission
  )?.isGranted;

  const adminPermissions =
    permission === "Role" || permission === "User" || permission === "Logs";

  if (!isUserLoggedIn()) return <Navigate to="/login" replace />;

  if (isUserAllowed) {
    return children;
  }

  if (adminPermissions) {
    if (isAdmin) {
      return children;
    }
  }

  return <Navigate to="/unauthorized" state={{ from: location }} replace />;
};

export default AuthorizedRoute;
