import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const formatArrayQuery = (arrayToBeFormatted, queryParam) => {
  const formattedArrayQuery = arrayToBeFormatted
    .map((data) => `${queryParam}=${data}`)
    .join("&");

  return formattedArrayQuery;
};

const getLabWorkloads = createAsyncThunk(
  "test-orders/get-lab-workloads",
  async (data) => {
    try {
      const api = useAxios();
      const statuses = formatArrayQuery(data.statuses, "statuses");
      const clientIds = formatArrayQuery(data.clientIds, "clientIds");
      const departmentId =
        typeof data.departmentId === "number"
          ? `departmentId=${data?.departmentId}`
          : "";

      const response = await api.get(
        URL_TEST_ORDERS +
          "/" +
          "lab-workload?" +
          departmentId +
          "&" +
          statuses +
          "&" +
          clientIds
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getLabWorkloads };
