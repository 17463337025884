import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/jkt/global/menuBar";
import { theme } from "components/jkt/global/theme";
import GetAllHealthcareProvider from "components/jkt/admin/healthcareProvider/getAllHealthcareProvider";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";
import TableContentLayout from "views/jkt/layout/tableContentLayout";
import useTranslation from "hooks/useTranslation";

const HealthcareProviderPage = () => {
  const { getTranslation } = useTranslation("SidebarItems");

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={getTranslation("Healthcare Provider")} />
        <TableContentLayout>
          <GetAllHealthcareProvider />
        </TableContentLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default HealthcareProviderPage;
