import { createSlice } from "@reduxjs/toolkit";
import decodedUserDetails from "utils/decodedUserDetails";
const clientId = parseInt(decodedUserDetails()?.clientId);

const initialState = {
  id: 0,
  fullPatientName: "",
  isActive: true,
  gender: "",
  dateOfBirth: "" || null,
  contactNumber: "" || null,
  emails: [],
  client_ID: clientId,
  medicalRecordNo: "" || null,
  cardNumber: "",
  governmentId: "",
  phAddresses: [],
  patientEmailForDisplay: [],
  isPatientHasAnAcceptedOrder: false,
};

const inputSlice = createSlice({
  name: "inputs",
  initialState,
  reducers: {
    handleChangeFields: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { handleChangeFields } = inputSlice.actions;
export const inputReducer = inputSlice.reducer;
