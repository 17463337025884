import { Box } from "@mui/material";
import React from "react";

const QrTabs = ({
  isSelectedOptionToCheckQr,
  isSelectedOptionToRegisterQr,
  isLoading,
  handleSelectOption,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        position: "relative",
      }}
    >
      <h4
        onClick={() => handleSelectOption("registerQr")}
        className={`text-lg cursor-pointer ${
          isSelectedOptionToRegisterQr && "scale-0"
        } ${isLoading && "pointer-events-none"} duration-300 ease-in-out`}
      >
        REGISTER QR CODE
      </h4>
      <h4
        onClick={() => handleSelectOption("checkQr")}
        className={`text-lg cursor-pointer ${
          isSelectedOptionToCheckQr && "scale-0"
        } ${isLoading && "pointer-events-none"} duration-300 ease-in-out`}
      >
        CHECK QR CODE
      </h4>
      <div
        className={`bg-[#1976D2] px-4 py-1 text-white absolute top-0 left-3 rounded-md cursor-pointer duration-300 ease-in-out ${
          isSelectedOptionToRegisterQr ? "translate-x-1" : "translate-x-48"
        }`}
      >
        {isSelectedOptionToCheckQr ? "CHECK QR CODE" : "REGISTER QR CODE"}
      </div>
    </Box>
  );
};

export default QrTabs;
