import { Skeleton } from "@mui/material";

const setSkeletonStyles = (width, height) => {
  return { transform: "translateY(-1px)", width: width, height: height };
};

export const inputSkeleton = (
  <Skeleton sx={setSkeletonStyles("100%", "2.5rem")} />
);
export const genderSkeleton = (
  <Skeleton sx={setSkeletonStyles("14rem", "2.5rem")} />
);
export const statusSkeleton = (
  <Skeleton sx={setSkeletonStyles("7rem", "2.5rem")} />
);
export const birthDateSkeleton = (
  <Skeleton sx={setSkeletonStyles("16rem", "3rem")} />
);
export const fullWidthSkeleton = (
  <Skeleton sx={setSkeletonStyles("100%", "2.5rem")} />
);
