import { Box, Button } from "@mui/material";
import { Card, Empty, Tag } from "antd";
import React from "react";
import PopConfirm from "components/mnl/global/popConfirm";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import notification from "components/mnl/global/openNotification";
import { useDispatch, useSelector } from "react-redux";
import { createSupplyOrder } from "store";
import { parsedClientDetails } from "utils/parsedClientDetails";

const { Meta } = Card;

const AddedInventoryItemsDisplay = ({
  selectRequestItemToBeUpdated,
  renderSelectedInventoryName,
  requestedItems,
  removeItem,
  selectedClientCode,
  clearRequestedItems,
  requestedBy,
  validateFormInputs,
  isLoading,
  setIsLoading,
}) => {
  const requestedItemsTotalLength = requestedItems.length;
  const isRequestItemsEmpty = requestedItemsTotalLength === 0;
  const { isClientAbcLab } = parsedClientDetails();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const requestSourceValue = isClientAbcLab ? "ERequestCS" : "ERequestClient";
  const enableModalScroll = requestedItems?.length >= 4;

  const onSubmit = () => {
    validateFormInputs();

    if (
      createSupplyOrder.pending().type ===
      "supply-orders/create-supply-order/pending"
    ) {
      setIsLoading(true);
    }
    const formattedRequestedItems = requestedItems.map(
      ({ id, inventoryItemNameValue, ...rest }) => rest
    );
    dispatch(
      createSupplyOrder({
        requestedItems: formattedRequestedItems,
        clientCode: selectedClientCode,
        createdBy: user?.displayName,
        requestedBy: !isClientAbcLab ? user?.displayName : requestedBy,
        requestSource: requestSourceValue,
      })
    ).then((res) => {
      const isPostSuccessful = res?.payload?.success;

      if (isPostSuccessful) {
        setIsLoading(false);
        clearRequestedItems();
        notification.success({
          message: "Created Supply Order",
          description: "Successfully created supply order",
        });
      }

      if (!isPostSuccessful) {
        setIsLoading(false);
      }
    });
  };

  const cardContentWrapper = (label, value) => {
    return (
      <p className="text-sm">
        {label}: <Tag style={{ fontWeight: "bold" }}>{value}</Tag>
      </p>
    );
  };
  const renderCardTitle = (
    inventoryItemName,
    inventoryItemQuantity,
    inventoryItemPurpose
  ) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {cardContentWrapper("Item", inventoryItemName)}
        {cardContentWrapper("Quantity", inventoryItemQuantity)}
        {inventoryItemPurpose !== "" &&
          cardContentWrapper("Purpose", inventoryItemPurpose)}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: {
          xs: "auto",
          sm: "auto",
          md: "25rem",
          lg: "25rem",
          xl: "25rem",
        },
        padding: "1.2rem 0.8rem",
        backgroundColor: {
          xs: "white",
          sm: "white",
          md: "whitesmoke",
          lg: "whitesmoke",
          xl: "whitesmoke",
        },
        borderRadius: "0.5rem",
        boxShadow: {
          xs: "none",
          sm: "none",
          md: "1px 1px 5px",
          lg: "1px 1px 5px",
          xl: "1px 1px 5px",
        },
        height: "100%",
        position: "relative",
      }}
    >
      <Box>
        <h1 className="text-center text-2xl font-bold my-3">
          Added Items {!isRequestItemsEmpty && `(${requestedItemsTotalLength})`}
        </h1>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            overflowY: enableModalScroll ? "scroll" : "hidden",
            height: enableModalScroll ? "550px" : "auto",
          }}
        >
          {requestedItems.map((item) => {
            const selectedRequestItemData = {
              ...item,
            };

            return (
              <Card
                key={item.id}
                actions={[
                  <button
                    onClick={() =>
                      selectRequestItemToBeUpdated(selectedRequestItemData)
                    }
                  >
                    {" "}
                    <EditOutlined key="edit-item" /> Update
                  </button>,
                  <PopConfirm
                    onConfirm={() => removeItem(item.id)}
                    title="Are you sure?"
                  >
                    <button className="hover:text-red-500 w-full h-full">
                      <DeleteOutlined key="remove-item" /> Remove
                    </button>
                  </PopConfirm>,
                ]}
                hoverable
                style={{ marginBottom: 3 }}
              >
                <Meta
                  title={renderCardTitle(
                    renderSelectedInventoryName(item?.inventoryItemId),
                    item.quantityRequested,
                    item.purpose
                  )}
                />
              </Card>
            );
          })}
        </Box>
      </Box>
      {isRequestItemsEmpty && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Empty description="No inventory items added yet" />
        </Box>
      )}
      {!isRequestItemsEmpty && (
        <Button
          onClick={onSubmit}
          disabled={isLoading}
          sx={{
            width: "95%",
            mt: 2,
            position: "absolute",
            bottom: 20,
            left: 0,
            right: 0,
            mx: "auto",
          }}
          type="button"
          variant="contained"
        >
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
      )}
    </Box>
  );
};

export default AddedInventoryItemsDisplay;
