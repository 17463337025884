import { createSlice } from "@reduxjs/toolkit";

const appBarSlice = createSlice({
  name: "appBarSettings",
  initialState: {
    openSidebar: true,
    expandAdminSidebarItems: true,
    expandLaboratorySidebarItems: true,
  },
  reducers: {
    setToggleSidebar: (state) => {
      state.openSidebar = !state.openSidebar;
    },
    setOpenSidebar: (state) => {
      state.openSidebar = true;
    },
    setCloseSidebar: (state) => {
      state.openSidebar = false;
    },
    setExpandAdminSidebarItems: (state) => {
      state.expandAdminSidebarItems = !state.expandAdminSidebarItems;
    },
    setExpandLaboratorySidebarItems: (state) => {
      state.expandLaboratorySidebarItems = !state.expandLaboratorySidebarItems;
    },
    setExpandBothSidebarItems: (state) => {
      state.expandAdminSidebarItems = true;
      state.expandLaboratorySidebarItems = true;
    },
  },
});

export const {
  setToggleSidebar,
  setOpenSidebar,
  setCloseSidebar,
  setExpandAdminSidebarItems,
  setExpandLaboratorySidebarItems,
  setExpandBothSidebarItems,
} = appBarSlice.actions;
export const appBarReducer = appBarSlice.reducer;
