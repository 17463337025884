import { Box, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import { handleChangeHandling } from "store/slice/specialHandlingSlice";
import PopConfirm from "components/jkt/global/popConfirm";
import useTranslation from "hooks/useTranslation";

const SelectedInstructionsTable = () => {
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedHandlingItems = useSelector((state) => state.specialHandling);
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const dispatch = useDispatch();

  //Description before deleting a clinical diagnosis
  const description = //Description before deleting a clinical diagnosis
    <p>{getGlobalTranslation("Are you sure")}?</p>;

  //Deletion / Removal of special instrucion
  const handleDeleteHandling = (selectedId) => {
    const filteredHandling = multiStepFormInputs.specialHandlingIds.filter(
      (handling) => handling !== selectedId
    );

    const filteredSavedHandling = savedHandlingItems.savedHandling.filter(
      (handling) => handling.id !== selectedId
    );
    dispatch(
      handleChangeMultiFields({
        specialHandlingIds: filteredHandling,
      })
    );
    dispatch(
      handleChangeHandling({
        savedHandling: filteredSavedHandling,
      })
    );
  };

  return (
    <Box sx={{ marginTop: "1rem" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "25fr 60fr",
            sm: "25fr 60fr",
            md: "7fr 35fr",
            lg: "7fr 35fr",
            xl: "7fr 35fr",
          },
          padding: "0.4rem",
        }}
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "bold", color: "darkBlue.main" }}
        >
          No.
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontWeight: "bold",
            color: "darkBlue.main",
          }}
        >
          {getTranslation("Instruction")}
        </Typography>
      </Box>
      <Box
        sx={{
          border: "2px solid",
          borderColor: "lightBlue.secondary",
          backgroundColor: "white",
          borderRadius: "5px",
          height: "auto",
          position: "relative",
          padding: "0.4rem",
          paddingBottom: "6rem",
          width: {
            xs: "100%",
            sm: "100%",
            md: "97%",
            lg: "97%",
            xl: "97%",
          },
        }}
      >
        {savedHandlingItems?.savedHandling &&
          savedHandlingItems?.savedHandling.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "25fr 60fr",
                  sm: "25fr 60fr",
                  md: "7fr 35fr",
                  lg: "7fr 35fr",
                  xl: "7fr 35fr",
                },
                marginTop: "0.3rem",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                variant="p"
              >
                {index + 1}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                  width: "15rem",
                }}
                variant="p"
              >
                {data.displayName || data.instructions}
              </Typography>
              <PopConfirm
                title={getGlobalTranslation("Remove instruction")}
                description={description}
                onConfirm={() => handleDeleteHandling(data.id)}
                okText="Yes"
                cancelText="No"
              >
                <CloseIcon
                  sx={{
                    color: "red",
                    fontSize: "1.4rem",
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                    transform: {
                      xs: "translateX(20px)",
                      sm: "translateX(20px)",
                      md: "translateX(24px)",
                      lg: "translateX(24px)",
                      xl: "translateX(24px)",
                    },
                  }}
                />
              </PopConfirm>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default SelectedInstructionsTable;
