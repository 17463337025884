import { Box, Button } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import CloseIcon from "@mui/icons-material/Close";

const ReceivingButtons = ({
  orderStatus,
  onClickAcceptBtn,
  onShowHoldReasonModal,
  onShowRejectReasonModal,
  isLoading,
}) => {
  const getOrderStatus = (selectedOrderStatus) => {
    if (orderStatus?.toLowerCase() !== selectedOrderStatus) {
      return false;
    }
    return true;
  };

  const isStatusHold = getOrderStatus("hold");
  const isStatusPending = getOrderStatus("pending");
  const isStatusCompletedDelivery = getOrderStatus("completeddelivery");

  const showAcceptButton =
    isStatusPending || isStatusCompletedDelivery || isStatusHold;
  const showRejectButton = showAcceptButton;

  return (
    <Box sx={{ display: "flex", gap: 2, mr: 4 }}>
      {showAcceptButton && (
        <Button
          color="success"
          variant="contained"
          size="large"
          sx={{ width: "100%" }}
          onClick={onClickAcceptBtn}
          disabled={isLoading}
          endIcon={<CheckIcon />}
        >
          Accept
        </Button>
      )}
      {isStatusCompletedDelivery && (
        <Button
          variant="contained"
          size="large"
          sx={{
            width: "100%",
            backgroundColor: "#facc15",
            ":hover": {
              bgcolor: "#eab308",
              color: "white",
            },
          }}
          onClick={onShowHoldReasonModal}
          disabled={isLoading}
          endIcon={<BlockIcon />}
        >
          Hold
        </Button>
      )}
      {showRejectButton && (
        <Button
          color="error"
          variant="contained"
          size="large"
          sx={{ width: "100%" }}
          onClick={onShowRejectReasonModal}
          disabled={isLoading}
          endIcon={<CloseIcon />}
        >
          Reject
        </Button>
      )}
    </Box>
  );
};

export default ReceivingButtons;
