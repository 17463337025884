import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/jkt/global/menuBar";
import { theme } from "components/jkt/global/theme";
import Toolbar from "@mui/material/Toolbar";
import FormContent from "components/jkt/testRequest/forms/formLayout/formContent";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";
import useTranslation from "hooks/useTranslation";

const TestRequestPage = () => {
  const { getTranslation } = useTranslation("SidebarItems");
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={getTranslation("TestRequest")} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            flexGrow: 1,
            height: "100%",
            minHeight: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <FormContent />
        </Box>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default TestRequestPage;
