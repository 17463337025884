import { Select } from "antd";
import React from "react";

const statusOptions = [
  {
    value: 0,
    label: "All",
  },
  {
    value: 1,
    label: "Active",
  },
  {
    value: 2,
    label: "Inactive",
  },
];

const StatusFilter = ({ selectedUserStatus, handleChangeUserStatus }) => {
  return (
    <Select
      defaultValue={selectedUserStatus}
      onChange={handleChangeUserStatus}
      className="ml-2 w-24"
      options={statusOptions}
    />
  );
};

export default StatusFilter;
