import parseJwt from "./parseJwt";

const decodedUserDetails = () => {
  try {
    const userStorageString = localStorage.getItem("user");
    if (!userStorageString) {
      // console.error("No user data in localStorage.");
      return null;
    }

    const userStorage = JSON.parse(userStorageString);
    const token = userStorage?.token;

    if (!token) {
      // console.error("No token found in user data.");
      return null;
    }

    const parsedToken = parseJwt(token);
    if (!parsedToken) {
      // console.error("Failed to parse token.");
      return null;
    }

    return {
      ...parsedToken,
      clientId: +parsedToken.clientId,
    };
  } catch (error) {
    // console.error("Error in decodedUserDetails:", error);
    return null;
  }
};

export default decodedUserDetails;
