import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SAMPLE_COLLECTORS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const updateSampleCollector = createAsyncThunk(
  "sample-collectors/update-sample-collector",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const response = await api.put(URL_SAMPLE_COLLECTORS + "/" + data.id, {
        id: data.id,
        licenseNumber: null,
        licenseExpirationDate: null,
        firstName: data.firstName,
        lastName: data.lastName,
        collectorType: data.collectorType,
        employeeId: null,
        clientId: clientId,
        phoneNumber: null,
        email: null,
        employmentStartDate: null,
        employmentEndDate: null,
        isActive: data.isActive,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const requestUpdateSampleCollector = createAsyncThunk(
  "sample-collectors/req-update-sample-collectors",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.put(URL_SAMPLE_COLLECTORS + "/" + data.id, {
        id: data.id,
        licenseNumber: data.licenseNumber,
        licenseExpirationDate: data.licenseExpirationDate,
        firstName: data.firstName,
        lastName: data.lastName,
        collectorType: data.collectorType,
        employeeId: data.employeeId,
        clientId: +data.clientId,
        phoneNumber: data.phoneNumber,
        email: data.email,
        employmentStartDate: data.employmentStartDate,
        employmentEndDate: data.employmentEndDate,
        isActive: data.isActive,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateSampleCollector, requestUpdateSampleCollector };
