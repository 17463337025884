import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_TESTS } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getLaboratoryTestType = createAsyncThunk(
  "labtests/get-laboratory-test-types",
  async () => {
    try {
      const api = useAxios();
      const response = await api.get(URL_LAB_TESTS + "/laboratory-test-types");
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getLaboratoryTestType };
