import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_LOGS } from "../../config/connString";

const getLogs = createAsyncThunk("logs/get-log", async (data) => {
  try {
    const api = useAxios();
    const response = await api.get(URL_LOGS + "/" + data.id);
    return response.data;
  } catch (err) {
    return err;
  }
});

export { getLogs };
