import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_BARANGAY } from "config/connString";
import useAxios from "utils/useAxios";

const getBarangayLookUp = createAsyncThunk(
  "barangay/get-barangay-look-up",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          cityMunicipalityId: data.cityMunicipalityId,
        },
      };
      const response = await api.get(
        URL_BARANGAY + "/" + data.cityMunicipalityId + "/barangays-lookup",
        config
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export { getBarangayLookUp };
