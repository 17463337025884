import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFlattenUserPermissions } from "store";

const useCheckPermission = () => {
  const [flattenPermissions, setFlattenPermissions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFlattenUserPermissions()).then((response) => {
      setFlattenPermissions(response?.payload?.data);
    });
  }, [dispatch]);

  const handleVerifyPermission = (permission) => {
    if (
      flattenPermissions?.find((claim) => claim?.permissionKey === permission)
        ?.isGranted
    ) {
      return true;
    }
    return false;
  };

  return { handleVerifyPermission };
};

export default useCheckPermission;
