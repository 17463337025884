import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_PROVINCES } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const deleteProvince = createAsyncThunk(
  "provinces/delete-provinces",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(URL_PROVINCES + "/" + data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { deleteProvince };
