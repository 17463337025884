import React from "react";
import { useLocation } from "react-router-dom";
import Copyright from "./copyright";
import { jktRoutes } from "routes/jktRoutes";

const Footer = () => {
  const location = useLocation();
  const isRouteInvalid = !jktRoutes.some((route) =>
    route.path.includes(location.pathname)
  );
  const isInAuthPage =
    location.pathname === "/login" ||
    location.pathname === "/forgot-password" ||
    location.pathname === "/confirm-account" ||
    location.pathname === "/lab" ||
    location.pathname === "/lab-change-user-password" ||
    location.pathname === "/unauthorized" ||
    location.pathname === "/reset-password";

  return (
    <footer
      className={`h-11 fixed bottom-0 bg-[#2A4993] w-full flex justify-center items-center z-40 ${
        (isInAuthPage || isRouteInvalid) && "hidden"
      }`}
    >
      <Copyright color="white" />
    </footer>
  );
};

export default Footer;
