import { useEffect } from "react";

const useBrowserBackButton = (callback) => {
  useEffect(() => {
    window.addEventListener("popstate", callback);
    return () => {
      window.removeEventListener("popstate", callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useBrowserBackButton;
