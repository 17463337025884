import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const getTestOrderAuditTrail = createAsyncThunk(
  "test-orders/get-test-order-audit-trail",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(
        `${URL_TEST_ORDERS}/${data.id}/audit-trail`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getTestOrderAuditTrail };
