import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_TEST_ORDERS } from "../../config/connString";

const getTestOrderSummary = createAsyncThunk(
  "test-orders/get-test-order-summary",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          clientId: data.clientId,
          orderTransportStatus: data.orderTransportStatus,
        },
      };
      const response = await api.get(
        URL_TEST_ORDERS + "/" + data.clientId + "/test-order-summary",
        config
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getTestOrderSummary };
