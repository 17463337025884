import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_AUTH } from "config/connString";
import decodedUserDetails from "utils/decodedUserDetails";
import authHeader from "utils/authHeader";
import useAxios from "utils/useAxios";

const userChangePassword = createAsyncThunk(
  "auth/change-password",
  async (data) => {
    const userId = decodedUserDetails()?.id;
    const api = useAxios();

    try {
      const response = await api.put(
        URL_AUTH + "change-password",
        {
          userId: userId,
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
        authHeader()
      );

      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);

export { userChangePassword };
