import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_CLIENT_INFO } from "../../config/connString";
import decodedUserDetails from "../../utils/decodedUserDetails";

const getClientInfo = createAsyncThunk("client/get-client-info", async () => {
  try {
    const clientId = decodedUserDetails()?.clientId;
    const api = useAxios();
    const response = await api.get(URL_CLIENT_INFO + "/" + clientId);
    return response.data;
  } catch (err) {
    return err;
  }
});

export { getClientInfo };
