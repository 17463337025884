import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_LAB_TESTS } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const updateLabTest = createAsyncThunk(
  "labTest/update-labTest",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.put(URL_LAB_TESTS + "/" + data[3], {
        id: data[3],
        name: data[0].name,
        code: data[0].code,
        type: data[0].type,
        abbreviation: data[0]?.abbreviation,
        alias: data[0].alias,
        orderable: data[0].orderable === undefined ? true : data[0].orderable,
        applicableTo: data[0].applicableTo,
        clientId: data[0].clientId,
        testDetails: data[1],
        childIds: data[2],
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateLabTest };
